export interface IModelValueConstructor<TValue> {
    getter?: () => TValue;
    setter?: (value: TValue) => void;
}

export class ModalValue<TValue> {
    public getter: () => TValue;
    public setter: (value: TValue) => void;

    constructor(modelValue?: IModelValueConstructor<TValue>) {
        if (modelValue != null) {
            this.getter = modelValue.getter;
            this.setter = modelValue.setter;
        }
    }
}
