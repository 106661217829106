import { LocalizationService } from '../../../src/app/services/localization.service';
import { environment } from '../../../src/environments/environment';
import { Design } from '../../Entities/Design';
import { KnownRegion } from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Enums';
import { BrowserService } from '../../Services/browser-service';
import { CodeListService } from '../../../src/app/services/code-list.service';
import { DocumentService } from '../../Services/document-service';
import { ProductInformationService } from '../../Services/product-information.service';
import { UserService } from '../../Services/user-service';
import { IUserSettingsService, UserSettingsService } from '../../Services/user-settings-service';
import { Bind, control, Translation } from '../controls';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective } from '../Modal/Modal';
import { TextBox } from '../TextBox/TextBox';
import { Email } from '../TextBox/Validators/Email';
import { Url } from '../TextBox/Validators/Url';
import template from './UserSettings.html';

export class UserSettingsModalController extends BaseModalController<UserSettings> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'user',
        'localization',
        'codeList',
        'document',
        'browser',
        'control',
        'userSettings',
        '$window',
        'productInformation'
    ];

    public submitted: boolean;

    public loaded: boolean;

    public nameTextBox: TextBox;
    public companyNameTextBox: TextBox;
    public addressTextBox: TextBox;
    public phoneNumberTextBox: TextBox;
    public emailTextBox: TextBox;
    public faxTextBox: TextBox;
    public websiteTextBox: TextBox;

    private pendingSave: boolean;

    private $scope: ng.IScope;
    private user: UserService;
    private localization: LocalizationService;
    private codeList: CodeListService;
    private document: DocumentService;
    private userSettings: IUserSettingsService;
    private browser: BrowserService;
    private $window: ng.IWindowService;
    private productInformationService: ProductInformationService;

    private design: Design;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        user: UserService,
        localization: LocalizationService,
        codeList: CodeListService,
        document: DocumentService,
        browser: BrowserService,
        control: UserSettings,
        userSettings: UserSettingsService,
        $window: ng.IWindowService,
        productInformationService: ProductInformationService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.$scope['localization'] = this.localization = localization;
            this.codeList = codeList;
            this.user = user;
            this.document = document;
            this.browser = browser;
            this.userSettings = userSettings;
            this.design = this.user.design;
            this.$window = $window;
            this.productInformationService = productInformationService;

            this.loaded = false;

            // don't close the modal if save is pending (close on destroy)
            this.$scope.$on('modal.closing', (event: ng.IAngularEvent, result?: any) => {
                if (result !== 'destroy' && this.pendingSave) {
                    event.preventDefault();
                }
            });

            this.nameTextBox = new TextBox({
                id: 'user-settings-name',
                title: new Translation('Agito.Hilti.Purchaser.UserSettings.Name'),
                required: true,
                disabled: this.isExternalRussianUser ? true : new Bind('ctrl.submitted || !ctrl.loaded')
            });

            this.companyNameTextBox = new TextBox({
                id: 'user-settings-company-name',
                title: new Translation('Agito.Hilti.Purchaser.UserSettings.CompanyName'),
                disabled: this.isExternalRussianUser ? true : new Bind('ctrl.submitted || !ctrl.loaded')
            });

            this.addressTextBox = new TextBox({
                id: 'user-settings-address',
                title: new Translation('Agito.Hilti.Purchaser.UserSettings.Address'),
                disabled: this.isExternalRussianUser ? true : new Bind('ctrl.submitted || !ctrl.loaded')
            });

            this.phoneNumberTextBox = new TextBox({
                id: 'user-settings-phone-number',
                title: new Translation('Agito.Hilti.Purchaser.UserSettings.PhoneNumber'),
                disabled: this.isExternalRussianUser ? true : new Bind('ctrl.submitted || !ctrl.loaded')
            });

            this.emailTextBox = new TextBox({
                id: 'user-settings-email',
                title: new Translation('Agito.Hilti.Purchaser.UserSettings.Email'),
                disabled: this.isExternalRussianUser ? true : new Bind('ctrl.submitted || !ctrl.loaded'),
                validators: {
                    email: new Email()
                }
            });

            this.faxTextBox = new TextBox({
                id: 'user-settings-fax',
                title: new Translation('Agito.Hilti.Purchaser.UserSettings.Fax'),
                disabled: this.isExternalRussianUser ? true : new Bind('ctrl.submitted || !ctrl.loaded')
            });

            this.websiteTextBox = new TextBox({
                id: 'user-settings-website',
                title: new Translation('Agito.Hilti.Purchaser.UserSettings.Website'),
                disabled: new Bind('ctrl.submitted || !ctrl.loaded'),
                validators: {
                    email: new Url()
                }
            });

            // load the controls to text boxes
            if (this.isExternalRussianUser) {
                if (this.userSettings.ccmsUserSettings != null) {
                    if (this.userSettings.ccmsUserSettings.FullName != null) {
                        this.nameTextBox.model = this.userSettings.ccmsUserSettings.FullName;
                    }
                    if (this.userSettings.ccmsUserSettings.CompanyName != null) {
                        this.companyNameTextBox.model = this.userSettings.ccmsUserSettings.CompanyName;
                    }
                    if (this.userSettings.ccmsUserSettings.Address != null) {
                        this.addressTextBox.model = this.userSettings.ccmsUserSettings.Address;
                    }
                    if (this.userSettings.ccmsUserSettings.Phone != null) {
                        this.phoneNumberTextBox.model = this.userSettings.ccmsUserSettings.Phone;
                    }
                    if (this.userSettings.ccmsUserSettings.EmailAddress != null) {
                        this.emailTextBox.model = this.userSettings.ccmsUserSettings.EmailAddress;
                    }
                    if (this.userSettings.ccmsUserSettings.Fax != null) {
                        this.faxTextBox.model = this.userSettings.ccmsUserSettings.Fax;
                    }
                }
            }
            else {
                if (this.userSettings.settings.user.general.name.value != null) {
                    this.nameTextBox.model = this.userSettings.settings.user.general.name.value;
                }
                if (this.userSettings.settings.user.companyDetails.companyName.value != null) {
                    this.companyNameTextBox.model = this.userSettings.settings.user.companyDetails.companyName.value;
                }
                if (this.userSettings.settings.user.companyDetails.address.value != null) {
                    this.addressTextBox.model = this.userSettings.settings.user.companyDetails.address.value;
                }
                if (this.userSettings.settings.user.companyDetails.phoneNumber.value != null) {
                    this.phoneNumberTextBox.model = this.userSettings.settings.user.companyDetails.phoneNumber.value;
                }
                if (this.userSettings.settings.user.companyDetails.email.value != null) {
                    this.emailTextBox.model = this.userSettings.settings.user.companyDetails.email.value;
                }
                if (this.userSettings.settings.user.companyDetails.fax.value != null) {
                    this.faxTextBox.model = this.userSettings.settings.user.companyDetails.fax.value;
                }
            }

            if (this.userSettings.settings.user.companyDetails.website.value != null) {
                this.websiteTextBox.model = this.userSettings.settings.user.companyDetails.website.value;
            }

            this.loaded = true;
        })(this.$onInit);
    }

    public get isExternalRussianUser() {
        return environment.russianDataProtectionRegulationEnabled && !this.user.isInternalHiltiUser() && this.user.authentication.subscription_info.CountryOfResidence == 'RU';
    }

    public get getSAPAccountDisclaimer() {
        const region = this.userSettings.getRegionById(KnownRegion.Russia);
        const regionLanguage = this.userSettings.getRegionLanguage(KnownRegion.Russia);
        return this.localization.getLocalizedString('Agito.Hilti.Purchaser.UserSettings.DataFromSAPAccount.Text')
            .replace('{countryContactEmail}', region.contactUrl)
            .replace('{countryContactPhone}', region.supportPhone)
            .replace('{privacyPolicy}', `<a href="${regionLanguage.hiltiDataPrivacyUrl}" target="_blank">${this.localization.getLocalizedString('Agito.Hilti.Profis3.UserSettings.DataFromSAPAccount.Text.PrivacyPolicy')}</a>`);
    }

    public get softwareSellingLinkAvailable(){
        const link = this.getSoftwareSellingLink();
        return link != null
            && link != '';
    }

    private get form() {
        return this.$scope['form'] as ng.IFormController;
    }

    public openSoftwareSellingLink() {
        const link = this.getSoftwareSellingLink();
        this.$window.open(link, '_blank');
    }


    public save() {
        if (this.submitted || !this.form.$valid) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        // copy meta-data
        if (!this.isExternalRussianUser) {
            this.userSettings.settings.user.general.name.value = this.nameTextBox.model;
            this.userSettings.settings.user.companyDetails.companyName.value = this.companyNameTextBox.model;
            this.userSettings.settings.user.companyDetails.address.value = this.addressTextBox.model;
            this.userSettings.settings.user.companyDetails.phoneNumber.value = this.phoneNumberTextBox.model;
            this.userSettings.settings.user.companyDetails.email.value = this.emailTextBox.model;
            this.userSettings.settings.user.companyDetails.fax.value = this.faxTextBox.model;
        }
        this.userSettings.settings.user.companyDetails.website.value = this.websiteTextBox.model;

        // call user settings save method
        this.userSettings.save().then(() => {
            if (this.design != null) {
                this.design.calculateImmediately()
                    .finally(() => {
                        this.pendingSave = false;
                    })
                    .then(() => {
                        this.close();
                    })
                    .catch(() => {
                        this.submitted = false;
                    });
            } else {
                this.pendingSave = false;
                this.close();
            }
        })
            .catch(() => {
                this.submitted = false;
                this.pendingSave = false;
            });
    }

    public close() {
        this.$uibModalInstance.close();
    }

    private getSoftwareSellingLink() {
        return this.productInformationService.regionLinksForUser()?.SoftwareSellingLink;
    }
}

export interface IUserSettingsConstructor {

}

@control('UserSettings')
export class UserSettings extends Modal<UserSettings> {

    constructor(ctor?: IUserSettingsConstructor) {
        super({
            template,
            templateUrl: 'UserSettings.html',
            controller: UserSettingsModalController,
            windowClass: 'default-modal settings-modal user-settings-modal',
            size: 'lg',
            ...ctor
        });
    }
}

class UserSettingsController extends ModalController<UserSettings> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(UserSettings, $scope, $element, $attrs, $uibModal);
    }
}

export class UserSettingsDirective extends ModalDirective {
    constructor() {
        super(UserSettingsController, UserSettings);
    }
}

