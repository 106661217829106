import {
    control, ControlDirective, ControlProperty, IWebControl, IWebControlConstructor, method,
    property, WebControl, WebControlController
} from '../../controls';
import template from './Button.html';

export enum ButtonSize {
    normal,
    full
}

export enum ButtonType {
    primary,
    secondary
}

export interface IButtonConstructor extends IWebControlConstructor {
    title?: string | ControlProperty;
    text?: string | ControlProperty;
    image?: string | ControlProperty;
    buttonSize?: ButtonSize | ControlProperty;
    buttonType?: ButtonType | ControlProperty;
}

@control('HiltiStyledButton')
export class Button extends WebControl implements IWebControl {

    public static click = 'click';

    @property()
    public title: string;

    @property()
    public text: string;

    @property()
    public image: string;

    @property()
    public buttonSize: ButtonSize;

    @property()
    public buttonType: ButtonType;
    constructor(button?: IButtonConstructor) {
        super(button);
    }

    @method()
    public focus() { }
}

class ButtonController extends WebControlController<Button> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse'
    ];

    public title: string;
    public text: string;
    public image: string;
    public buttonSize: ButtonSize;
    public buttonType: ButtonType;

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $compile: ng.ICompileService, $parse: ng.IParseService) {
        super(Button, $scope, $element, $attrs, $compile, $parse);
    }

    public get buttonClassObject() {
        return {
            'hilti-styled-size-full': this.buttonSize == ButtonSize.full,
            ['hilti-styled-button-' + ButtonType[this.buttonType || ButtonType.primary]]: true
        };
    }

    public focus() {
        this.element.querySelector<HTMLButtonElement>('.button-element').focus();
    }

    public onClick() {
        this.trigger(Button.click);
    }
}

export class ButtonDirective extends ControlDirective {
    constructor() {
        super(ButtonController, Button, template);
    }
}
