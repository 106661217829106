import {
    control, ControlDirective, ControlProperty, property, WebControl, WebControlController
} from '../controls';
import { Tooltip } from '../ControlTooltip/ControlTooltip';
import { IRegionControl, IRegionControlConstructor } from '../Region/Region';
import { RegionControlSize } from '../Region/RegionControlSize';
import template from './RadioButton.html';

export interface IRadioButtonConstructor<TValue extends Object> extends IRegionControlConstructor {
    text?: string | ControlProperty;
    value?: TValue | ControlProperty;
    model?: TValue | ControlProperty;
}

@control('RadioButton')
export class RadioButton<TValue extends Object> extends WebControl implements IRegionControl {

    @property()
    public title: string;

    @property()
    public size: RegionControlSize;

    @property()
    public tooltip: Tooltip;

    @property()
    public text: string;

    @property()
    public value: TValue;

    @property()
    public model: TValue;
    constructor(radioButton?: IRadioButtonConstructor<TValue>) {
        super(radioButton);
    }
}

class RadioButtonController<TValue extends Object> extends WebControlController<RadioButton<TValue>> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse'
    ];

    public title: string;
    public size: RegionControlSize;
    public tooltip: Tooltip;
    public text: string;
    public value: TValue;
    public model: TValue;

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $compile: ng.ICompileService, $parse: ng.IParseService) {
        super(RadioButton, $scope, $element, $attrs, $compile, $parse);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            // constants
            this.$scope['RegionControlSize'] = RegionControlSize;
        })(this.$onInit);
    }

    public focus() {
        this.element.querySelector<HTMLInputElement>('.radio-input').focus();
    }
}

export class RadioButtonDirective extends ControlDirective {
    constructor() {
        super(RadioButtonController, RadioButton, template);
    }
}
