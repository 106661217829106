import { getCodeListTextDeps } from '../../../src/app/entities/codeLists/codeList';
import { ILabelProps } from '../../../src/app/entities/controls/control-props';
import {
    Unit, UnitGroup
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Common.Units';
import { LocalizationService } from '../../../src/app/services/localization.service';
import { CodeListService, ProjectCodeList } from '../../../src/app/services/code-list.service';
import { UnitService } from '../../Services/unit-service';
import { UserSettingsService } from '../../Services/user-settings-service';
import { control } from '../controls';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective } from '../Modal/Modal';
import template from './DesignFileInfo.html';

export class DesignFileInfoModalController extends BaseModalController<DesignFileInfo> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'localization',
        'control',
        'codeList',
        'userSettings',
        'unit'
    ];

    public designName: string;
    public labelAnchorFamilyName: ILabelProps;
    public labelBaseMaterialThickness: ILabelProps;
    public labelAnchorDiameter: ILabelProps;
    public labelEmbedmentDepth: ILabelProps;
    public labelHoleSize: ILabelProps;

    private $scope: ng.IScope;
    private codelists: CodeListService;
    private userSettings: UserSettingsService;
    private unitService: UnitService;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        public localization: LocalizationService,
        control: DesignFileInfo,
        codeList: CodeListService,
        userSettings: UserSettingsService,
        unitService: UnitService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.codelists = codeList;
            this.userSettings = userSettings;
            this.unitService = unitService;

            this.initControls();

            // focus ok button
            this.$uibModalInstance.rendered
                .then(() => document.querySelector<HTMLButtonElement>('.design-file-info-modal #design-file-info-close-button').focus());
        })(this.$onInit);
    }

    private get form() {
        return this.$scope['form'] as ng.IFormController;
    }

    public save() {
        this.$uibModalInstance.close();
    }

    public close() {
        this.$uibModalInstance.close();
    }

    private formatLength(value: number) {
        const codeListDeps = getCodeListTextDeps(this.localization);
        value = +value.toString().replace(',', '.').split(' ').join('');
        const unitLabel = this.codelists.codelist[ProjectCodeList.UnitLength].find((item) => item.id == this.userSettings.settings.user.units.lengthId.value).getTranslatedNameText(codeListDeps);
        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Length);
        const userUnit = +this.userSettings.settings.user.units.lengthId.value as Unit;
        const userValue = this.unitService.convertUnitValueArgsToUnit(value, defaultUnit, userUnit);
        return this.unitService.formatNumber(userValue, this.unitService.getPrecision(userUnit)) + ' ' + unitLabel;
    }

    private initControls() {

        this.designName = this.control.designName;

        this.labelAnchorFamilyName = {
            controlId: 'label-anchor-family-name',
            text: this.control.anchorFamilyName,
            title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.DesignFileInfo.AnchorFamilyName')
        };

        this.labelBaseMaterialThickness = {
            controlId: 'label-base-material-thickness',
            text: this.formatLength(this.control.baseMaterialThickness == null ? 0 : this.control.baseMaterialThickness),
            title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.DesignFileInfo.BaseMaterialThickness'),
            hidden: this.control.isManualDesign ? true : false
        };

        this.labelAnchorDiameter = {
            controlId: 'label-anchor-diameter',
            text: this.formatLength(this.control.anchorDiameter == null ? 0 : this.control.anchorDiameter),
            title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.DesignFileInfo.AnchorDiameter'),
            hidden: this.control.isManualDesign ? true : false
        };

        this.labelEmbedmentDepth = {
            controlId: 'label-embedment-depth',
            text: this.formatLength(this.control.embedmentDepth == null ? 0 : this.control.embedmentDepth),
            title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.DesignFileInfo.EmbedmentDepth'),
            hidden: this.control.isManualDesign ? true : false
        };

        this.labelHoleSize = {
            controlId: 'label-hole-size',
            text: this.formatLength(this.control.holeSize == null ? 0 : this.control.holeSize),
            title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.DesignFileInfo.HoleSize'),
            hidden: this.control.isManualDesign ? true : false
        };
    }
}

export interface IDesignFileInfoConstructor {
}

@control('DesignFileInfo')
export class DesignFileInfo extends Modal<DesignFileInfo> {

    public designName: string;
    public anchorFamilyName: string;
    public baseMaterialThickness: number;
    public anchorDiameter: number;
    public embedmentDepth: number;
    public holeSize: number;

    public isManualDesign: boolean;

    constructor(ctor?: IDesignFileInfoConstructor) {
        super({
            template,
            templateUrl: 'DesignFileInfo.html',
            controller: DesignFileInfoModalController,
            windowClass: 'default-modal design-file-info-modal',
            size: 'lg',
            ...ctor
        });
    }

    public setDesignName(designName: string) {
        this.designName = designName;
    }

    public setAnchorFamilyName(anchorFamilyName: string) {
        this.anchorFamilyName = anchorFamilyName;
    }

    public setBaseMaterialThickness(baseMaterialThickness: number) {
        this.baseMaterialThickness = baseMaterialThickness;
    }

    public setAnchorDiameter(anchorDiameter: number) {
        this.anchorDiameter = anchorDiameter;
    }

    public setEmbedmentDepth(embedmentDepth: number) {
        this.embedmentDepth = embedmentDepth;
    }

    public setHoleSize(holeSize: number) {
        this.holeSize = holeSize;
    }

    public setIsManualDesign(isManualDesign: boolean) {
        this.isManualDesign = isManualDesign;
    }
}

class DesignFileInfoController extends ModalController<DesignFileInfo> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal',
    ];

    constructor($scope: ng.IScope,
                $element: ng.IAugmentedJQuery,
                $attrs: ng.IAttributes,
                $uibModal: ng.ui.bootstrap.IModalService) {
        super(DesignFileInfo, $scope, $element, $attrs, $uibModal);
    }
}

export class DesignFileInfoDirective extends ModalDirective {
    constructor() {
        super(DesignFileInfoController, DesignFileInfo);
    }
}
