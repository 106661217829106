import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    HttpErrorResponse,
    HttpEvent, HttpHandler, HttpInterceptor, HttpResponseBase
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { ApiHttpRequest } from '../services/api.service';
import { ModalService } from '../../../App/Services/modal-service';
import { IApplicationError } from '../entities/application-error';
import { urlPath } from '../ModuleConstants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private modal: ModalService
    ) { }

    public intercept(req: ApiHttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authentication header if needed
        if (environment.authenticationRequired.some((url) => req.url.startsWith(url))) {
            if (this.authService.isAuthenticated) {
                const authentication = this.authService.authentication;

                const headers: { [name: string]: string | string[] } = {
                    Authorization: `Bearer ${authentication.accessToken}`
                };

                if (environment.includeHCHeaders) {
                    // only in development mode
                    // on hilti environment this will be added automatically by OAG
                    headers['HC-UID'] = (authentication as any)['userId'];
                    headers['HC-User'] = (authentication as any)['userName'];
                    headers['HC-License'] = (authentication as any)['license'];
                }

                // new request
                const orgReq = req;
                req = orgReq.clone({ setHeaders: headers });
                req.supressErrorMessage = orgReq.supressErrorMessage;
            }
            else {
                window.location.href = urlPath.unauthenticated;
            }
        }

        // invoke request
        try {
            return next.handle(req)
                .pipe(
                    catchError(error => {
                        this.handleError(req, error);
                        throw error;
                    })
                );
        }
        catch (error) {
            this.handleError(req, error);
            throw error;
        }
    }

    private handleError(req: ApiHttpRequest<any>, error: any) {
        const httpResponse = error instanceof HttpResponseBase ? error : undefined;

        if (httpResponse?.status == 401 && environment.authenticationRequired.some(x => req.url.startsWith(x))) {
            if (httpResponse instanceof HttpErrorResponse && httpResponse?.error?.reason === 'LoginOtherDevice') {
                this.modal.unauthorizedAccess.open();
                return;
            }

            // invalidate access token
            this.authService.invalidateAuthentication();

            // redirect to default path
            window.location.href = urlPath.unauthenticated;
            return;
        }

        if (
            req.supressErrorMessage === true
            || httpResponse?.status === 403
            || httpResponse?.status === 0 && !this.authService.isAuthenticated
        ) {
            // Don't show errors
            return;
        }

        const param: IApplicationError = {
            httpResponse
        };
        this.modal.alert.openServiceError(param);
    }
}
