import escape from 'lodash-es/escape';
import moment from 'moment';
import {
    catchError, Observable, Subject, of
} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LoggerService, LogType } from './logger.service';
import { environment } from '../../environments/environment';
import {
    DateTimeFormat, LanguageTranslations, NumberFormat
} from '../generated-modules/Hilti.PE.TranslationsService.Shared.Entities';

export interface ISanitizeTags {
    sub?: boolean;
    sup?: boolean;
    p?: boolean;
    br?: boolean;
    h1?: boolean;
    ol?: boolean;
    li?: boolean;
    b?: boolean;
    a?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class LocalizationService {
    public static readonly pseudoLanguage = 'en-ZW';
    public static readonly pseudoLanguageLcid = 12297;

    public static readonly htmlStartTagRegExp = new RegExp('<\\s*html\\s*>', 'gi');
    public static readonly htmlEndTagRegExp = new RegExp('<\\s*\/\\s*html\\s*>', 'gi');

    public static readonly H1OlLi: ISanitizeTags = {
        h1: true,
        li: true,
        ol: true
    };

    public static readonly PBrB: ISanitizeTags = {
        p: true,
        br: true,
        b: true
    };

    public static readonly A: ISanitizeTags = {
        a: true
    };

    public static readonly SubSup: ISanitizeTags = {
        sub: true,
        sup: true
    };

    public translationsLoaded = false;
    public dateTimeFormat: DateTimeFormat;
    public numberFormat: NumberFormat;

    private _localizationChange = new Subject<void>();
    public localizationChange = this._localizationChange.asObservable();

    private _selectedLanguage: string;
    private _selectedLanguageLCID: number;
    private _resourceFileLoaded = false;
    private _warnings: { [key: string]: string } = {};

    private _translations: { [key: string]: string } = {};
    private readonly _translationLibrary = 'QuantityCalculator';

    /**
     * Initializes a new instance of the LocalizationService class.
     */
    constructor(
        private http: HttpClient,
        private logger: LoggerService
    ) { }

    /**
     * Gets selected language
     */
    public get selectedLanguage() {
        return this._selectedLanguage;
    }

    public get selectedLanguageLCID() {
        return this._selectedLanguageLCID;
    }

    public moment(date?: Date) {
        return moment(date).locale(this.selectedLanguage);
    }

    public initialize(data: LanguageTranslations) {
        this.setTranslations(data);
        this.translationsLoaded = true;
    }

    /**
     * Get translations for requested language
     *
     * @param1: requested language translations
     */
    public getTranslations(language: string): Observable<void> {
        // don't do anything if selected language is equal to requested language
        if (this._selectedLanguage == language) {
            return of(null);
        }
        else if (language) {
            this._selectedLanguage = language;
        }

        // both empty
        if (!this._selectedLanguage) {
            // set default language
            this._selectedLanguage = environment.defaultLanguage;
        }

        // call service to receive translations for selected language
        this.getTranslationsFromService(this._selectedLanguage).subscribe((translations) => {
            this.setTranslations(translations);
            this.translationsLoaded = true;
        });

        return of(null);
    }

    /**
     * Get localized string from key
     */
    public getLocalizedString(key: string, tags?: ISanitizeTags) {

        if (!this._resourceFileLoaded) {
            return '';
        }

        let result: string;
        // make sure the dictionary has valid data
        if (Object.keys(this._translations).length != 0) {

            // set the result
            result = this._translations[key];

            // html escape the string
            if (tags != null) {
                result = this.sanitizeText(result, tags);
            }
        }

        // return the value to the call
        if (result == null) {
            // log missing key
            if (this._warnings[key] == null) {
                this._warnings[key] = `Missing localized string: ${key}`;

                this.logger.log(this._warnings[key], LogType.warn);
            }

            return `#?${key}?#`;
        }
        else {
            return result;
        }
    }

    public sanitizeText(value: string, tags: ISanitizeTags) {
        if (value == null || value == '') {
            return value;
        }

        const whiteSpace = new RegExp('[ ]{2,}|[\\t]{1,}|[\\r\\n]+', 'gi'); // white spaces, TABs and newlines are present. Remove all of this occurances.
        value = value.replace(whiteSpace, '');

        if (this.isHtml(value)) {
            return value.replace(LocalizationService.htmlStartTagRegExp, '').replace(LocalizationService.htmlEndTagRegExp, '');
        }

        value = escape(value);

        const brTagRegExp = new RegExp('&lt;\\s*br\\s*\/\\s*&gt;', 'gi');

        Object.entries(tags).forEach(([key, tagValue]: [string, boolean]) => {
            if (tagValue) {
                if (key == 'br') {
                    value = value.replace(brTagRegExp, '<br />');
                }
                else if (key == 'a') {
                    value = value
                        .replace(/&lt;/g, '<')
                        .replace(/&gt;/g, '>')
                        .replace(/&quot;/g, '"');
                }
                else {
                    const reg = new RegExp('&lt;' + key + '&gt;(.*?)&lt;\/' + key + '&gt;', 'gi');

                    let index = value.indexOf('&lt;' + key + '&gt;') >= 0 ? value.indexOf(key) : 0; // regular expression will (group) replace only HTML tags on top level. Loop will take care of the ones, that are nested.

                    for (let i = 0; i < index; i++) {

                        value = value.replace(reg, (fullMatch, group) => {
                            return '<' + key + '>' + group + '</' + key + '>';
                        });

                        index = value.indexOf('&lt;' + key + '&gt;') >= 0 ? value.indexOf(key) : 0; // re-evaluate if replacent is needed
                    }
                }
            }
        });

        return value;
    }

    public isHtml(value: string) {
        return LocalizationService.htmlStartTagRegExp.test(value) && LocalizationService.htmlEndTagRegExp.test(value);
    }

    /**
     * Save translations into dictionary
     *
     * Default language translations are returned in case when selected language is not default language.
     */
    private setTranslations(data: LanguageTranslations) {
        if (data == null) {
            this.logger.log('No translations!', LogType.error);
            return;
        }

        // set translations
        this._translations = data.Translations;
        // update current LCID and Language name
        // update LCID
        this._selectedLanguageLCID = data.Language.LCID;
        // update language name
        this._selectedLanguage = data.Language.Name;
        // set language formats
        this.setLanguageFormats(data);

        // set the flag that the resource are loaded
        this._resourceFileLoaded = true;
        // broadcast that the file has been loaded
        this._localizationChange.next();
    }

    private getTranslationsFromService(languageCultureName: string) {
        const url = `${environment.translationsWebServiceUrl}${this._translationLibrary}/${languageCultureName}`;

        return this.http.get<LanguageTranslations>(url).pipe(
            catchError((error) => {
                this.logger.logServiceError(error, 'TranslationsService', 'GetTranslations');

                throw new Error(error);
            }));
    }

    private setLanguageFormats(language: LanguageTranslations) {
        this.dateTimeFormat = language.DateTimeFormat;
        this.numberFormat = language.NumberFormat;
    }
}
