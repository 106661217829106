import { replace } from '../../src/app/helpers/string-helper';
import { LocalizationService } from '../../src/app/services/localization.service';

export const enum NumberType {
    integer,
    real
}

export class NumberService {
    public static $inject = [
        'localization'
    ];

    private localization: LocalizationService;

    constructor(localization: LocalizationService) {
        this.localization = localization;
    }

    public parse(value: string, type: NumberType) {
        if (value == null) {
            return null;
        }

        if (typeof value === 'number') {
            return value;
        }

        if (typeof value !== 'string') {
            return Number.NaN;
        }

        value = value.trim();

        if (value == '') {
            return Number.NaN;
        }

        const lowerCase = value.toLowerCase();

        // infinity
        if (value.toLowerCase() == '∞' || lowerCase == 'inf' || lowerCase == 'infinity') {
            return Number.POSITIVE_INFINITY;
        }

        if (value.toLowerCase() == '-∞' || lowerCase == '-inf' || lowerCase == '-infinity') {
            return Number.NEGATIVE_INFINITY;
        }

        // type
        if (type == NumberType.integer) {
            if (/^\-?\d+$/.test(value)) {
                return parseInt(value, 10);
            }

            return Number.NaN;
        }
        else {
            const numberFormat = this.localization.numberFormat;

            // 160 -> no-break space, 32 -> normal space
            value = value.replace(String.fromCharCode(160), String.fromCharCode(32));

            if (numberFormat.NumberGroupSeparator != null) {
                value = replace(value, numberFormat.NumberGroupSeparator, '');
            }

            // When dot(.) is not number decimal separator nor is number decimal separator then return NaN if number contains dot(.)
            // because it is a forbidden character
            if (numberFormat.NumberDecimalSeparator != null) {
                if (numberFormat.NumberDecimalSeparator != '.'
                    && numberFormat.NumberDecimalSeparator != '.'
                    && value.indexOf('.') >= 0) {
                    return Number.NaN;
                }

                value = replace(value, numberFormat.NumberDecimalSeparator, '.');
            }

            if (/^\-?\d*(\.\d*)?$/.test(value)) {
                return parseFloat(value);
            }

            return Number.NaN;
        }
    }

    public format(value: number, decimals?: number, toFixed?: boolean) {
        if (value == null) {
            return null;
        }

        // infinity
        if (value == Number.POSITIVE_INFINITY) {
            return '∞';
        }

        if (value == Number.NEGATIVE_INFINITY) {
            return '-∞';
        }

        const numberFormat = this.localization.numberFormat;
        let stringValue = this.numberToString(this.round(value, decimals), decimals, toFixed);
        const negative = stringValue.startsWith('-');

        // remove minus
        if (negative) {
            stringValue = stringValue.substring(1);
        }

        if (numberFormat.NumberDecimalSeparator != null) {
            stringValue = replace(stringValue, '.', numberFormat.NumberDecimalSeparator);
        }

        if (numberFormat.NumberGroupSeparator != null && numberFormat.NumberGroupSizes != null) {
            let index = stringValue.length;
            const repeat = numberFormat.NumberGroupSizes[numberFormat.NumberGroupSizes.length - 1] != 0;
            let groupSizes = numberFormat.NumberGroupSizes;

            if (stringValue.indexOf(numberFormat.NumberDecimalSeparator || '.') > 0) {
                index = stringValue.indexOf(numberFormat.NumberDecimalSeparator || '.');
            }

            if (!repeat) {
                groupSizes = groupSizes.slice(0, groupSizes.length - 1);
            }

            for (let i = 0; i < groupSizes.length; i++) {
                const count = groupSizes[i];

                if (index - count > 0) {
                    index = index - count;
                    stringValue = stringValue.substring(0, index) + numberFormat.NumberGroupSeparator + stringValue.substring(index);
                }
            }

            if (repeat) {
                const repeatCount = groupSizes[groupSizes.length - 1];

                while (index - repeatCount > 0) {
                    index = index - repeatCount;
                    stringValue = stringValue.substring(0, index) + numberFormat.NumberGroupSeparator + stringValue.substring(index);
                }
            }
        }

        // add minus
        if (negative) {
            stringValue = `-${stringValue}`;
        }

        return stringValue;
    }

    public round(value: number, decimals?: number) {
        if (decimals == null || decimals <= 0) {
            decimals = 0;
        }

        return +(this.numberToString(Math.round(+(this.numberToString(value) + 'e+' + decimals))) + 'e-' + decimals);
    }

    public numberToString(value: number, decimals?: number, toFixed?: boolean) {
        if (value == null) {
            return null;
        }

        let stringValue = value.toString();

        if (Math.abs(value) < 1.0) {
            const e = parseInt(value.toString().split('e-')[1]);
            if (e) {
                value *= Math.pow(10, e - 1);
                stringValue = (value < 0 ? '-0.' : '0.') + (new Array(e)).join('0') + value.toString().substring(value < 0 ? 3 : 2);
            }
        }
        else {
            let e = parseInt(value.toString().split('+')[1]);
            if (e > 20) {
                e -= 20;
                value /= Math.pow(10, e);
                stringValue = value + (new Array(e + 1)).join('0');
            }
        }

        if (toFixed) {
            const dotIndex = stringValue.indexOf('.');

            if (dotIndex == -1) {
                stringValue += '.';
            }

            // CR: decimals can be null but no null check is done
            const decimalLength = dotIndex != -1 ? stringValue.substring(dotIndex).length : 1;
            for (let i = decimalLength - 1; i < decimals; i++) {
                stringValue += '0';
            }
        }

        return stringValue;
    }
}
