<app-control-title
    [focusControlId]="id"
    [title]="title"
    [tooltip]="tooltip">
</app-control-title>

<input [id]="id" [name]="id" class="input" [class.submitted]="submitted" [type]="type" [minLength]="minLength"
    [maxLength]="maxLength" [ngModel]="value" (ngModelChange)="ngModelChange($event)" #ngModel="ngModel"
    [disabled]="disabled" [placeholder]="placeholder" autocomplete="off" autocorrect="off" autocapitalize="off"
    spellcheck="false" (blur)="blur?.emit($event.target.value)" (keydown.enter)="blur?.emit($event.target.value)" />
