export const moduleName = {
    main: 'HiltiPurchaser'
};

export const controllerName = {
    root: 'rootController',
    projectAndDesign: 'projectAndDesignController',
    bom: 'bomController',
    login: 'loginController'
};

export const directiveName = {
    i18n: 'i18n',
    focusOut: 'agtFocusOut',
    change: 'agtChange',
    textSanitize: 'agtTextSanitize',
    checkbox: 'agtCheckbox',
    checkboxDisabled: 'agtCheckboxDisabled',
    radio: 'agtRadio',
    radioDisabled: 'agtRadioDisabled',
    stepperIncrement: 'agtStepperIncrement',
    stepperDecrement: 'agtStepperDecrement',
    updateOnEnter: 'agtUpdateOnEnter',
    controlTooltip: 'agtControlTooltip'
};

export const templateName = {
    projectAndDesign: 'project-and-design.html',
    bom: 'bom.html',
    header: 'header.html',
    leftNavigation: 'left-navigation.html',
    version: 'version.html'
};

export const urlPath = {
    projectAndDesign: '/projectAndDesign',
    projectAndDesignPathParams: '/projectAndDesign/:guid?',
    authenticationCallback: '/oauth_callback',
    unauthenticated: '/auth',
    bom: '/bom',
    logout: '/logout'
};

export const serviceName = {
    apiService: 'apiService',
    appConfig: 'appConfig',
    applicationVersionsService: 'applicationVersionsService',
    appStorage: 'appStorage',
    authentication: 'authentication',
    authService: 'authService',
    bom: 'bom',
    browser: 'browser',
    changes: 'changes',
    clipboard: 'clipboard',
    codeList: 'codeList',
    componentProviderService: 'componentProviderService',
    data: 'data',
    dateTime: 'dateTime',
    document: 'document',
    errorHandler: 'errorHandler',
    favorites: 'favorites',
    featureVisibilityService: 'featureVisibilityService',
    guid: 'guid',
    id: 'id',
    import: 'import',
    keylight: 'keylight',
    launchDarklyService: 'launchDarklyService',
    license: 'license',
    loading: 'loading',
    localization: 'localization',
    logger: 'logger',
    modal: 'modal',
    number: 'number',
    productinformation: 'productInformation',
    promise: 'promise',
    regionOrder: 'regionOrder',
    sessionStorage: 'sessionStorage',
    tracking: 'tracking',
    unit: 'unit',
    user: 'user',
    userSettings: 'userSettings',
    versionDetailsService: 'versionDetailsService',
    view: 'view',
};

export const storageKey = {
    loginState: 'loginState'
};
