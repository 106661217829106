// translation directive that can handle dynamic strings
// updates the text value of the attached element
// usage <span data-i18n="TOKEN" ></span>
// or

import { LocalizationService } from '../../src/app/services/localization.service';

// <span data-i18n="TOKEN|VALUE1|VALUE2" ></span>
export class i18nDirective implements ng.IDirective {

    public restrict = 'AEC';

    private _localization: LocalizationService;
    constructor(localization: LocalizationService) {
        this._localization = localization;
    }

    // Expose a static func so that it can be used to register directive.
    static Factory(): ng.IDirectiveFactory {
        // Create factory function which when invoked with dependencies by
        // angular will return newed up instance passing the timeout argument
        const directive: ng.IDirectiveFactory =
            (localization: LocalizationService) => new i18nDirective(localization);
        // directive's injection list
        directive.$inject = ['localization'];
        return directive;
    }

    public link: ng.IDirectiveLinkFn = ($scope, element, attributes): void => {
        const localizationChangeSubscription = this._localization.localizationChange.subscribe(() => {
            this.UpdateText(element, attributes);
        });

        attributes.$observe('i18n', () => {
            this.UpdateText(element, attributes);
        });

        $scope.$on('$destroy', () => {
            localizationChangeSubscription.unsubscribe();
        });
    }

    private UpdateText = (element: JQuery, attributes: any): void => {
        const token: string = attributes.i18n;
        // this._localization.GetLocalizedString
        const values = token.split('|');
        if (values.length >= 1) {
            // construct the tag to insert into the element
            let tag = this._localization.getLocalizedString(values[0]);
            // update the element only if data was returned
            if ((tag !== null) && (tag !== undefined) && (tag !== '')) {
                if (values.length > 1) {
                    for (let index = 1; index < values.length; index++) {
                        const target = '{' + (index - 1) + '}';
                        tag = tag.replace(target, values[index]);
                    }
                }

                // insert the text into the element
                element.html(tag);
                // or
                // element.text(tag);
            }
        }
    }
}
