import { IAlertParameters } from '../../src/app/entities/alert';
import {
    DesignStandard, DesignType, ProjectDesignBaseEntity, ProjectDesingFileStorageFormat
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.PEDependencies';
import {
    ValidationStatus
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Design';
import { ModalTranslation } from '../Controls/Modal/BaseModal';
import { Design, IBaseDesign } from '../Entities/Design';
import {
    IConvertAndCheckDesignRequestItemExtended, IConvertAndCheckDesignResponse, Project
} from '../Entities/Project';
import { DocumentService } from './document-service';
import { ModalService } from './modal-service';
import { UserService } from './user-service';

export interface IImportData {
    openDesign: boolean;
    renameDesign: boolean;
    design: Design;
    designPromise: ng.IPromise<Design>;
    projectDesign: ProjectDesignBaseEntity;
}

export class ImportService {
    public static $inject = [
        '$q',
        'modal',
        'document',
        'user'
    ];

    private $q: ng.IQService;
    private modal: ModalService;
    private document: DocumentService;
    private user: UserService;

    constructor($q: ng.IQService, modal: ModalService, document: DocumentService, user: UserService) {
        this.$q = $q;
        this.modal = modal;
        this.document = document;
        this.user = user;
    }

    public importDesign(project: Project, projectDesign: File | Blob, name?: string, design?: Design) {
        // set name
        if (projectDesign instanceof File && name == null) {
            name = projectDesign.name;
        }
        else if (name == null) {
            throw new Error('Name not provided.');
        }

        // name has to end with '.profis3' or '.pe'
        const nameLowerCase = name.toLowerCase();

        // If trying to import profis anchor file, show a modal saying it's not possible
        if (nameLowerCase.endsWith('.pa2')) {
            const param: IAlertParameters = {
                title: new ModalTranslation('Agito.Hilti.Purchaser.FileUpload.InvalidVersion.Title'),
                message: new ModalTranslation('Agito.Hilti.Purchaser.ConnectionsGrid.DesignNoLongerValid')
            };
            return this.modal.alert.open(param)[0].result.closed
                .then(() => this.$q.reject(null) as ng.IPromise<IImportData>);
        }

        if (!nameLowerCase.endsWith('.profis3') && !nameLowerCase.endsWith('.pe')) {
            const param: IAlertParameters = {
                title: new ModalTranslation('Agito.Hilti.Purchaser.FileUpload.WrongTypeAlert.Title'),
                message: new ModalTranslation('Agito.Hilti.Purchaser.FileUpload.WrongTypeAlert.Message')
            };
            return this.modal.alert.open(param)[0].result.closed
                .then(() => this.$q.reject(null) as ng.IPromise<IImportData>);
        }

        const importAndUploadDesign = (secondTry: boolean, project: Project, projectDesign: string, name?: string, nameProposal?: string, design?: Design): ng.IPromise<any> => {
            let fileName = name.substr(0, name.lastIndexOf('.')).trim();
            const fileNameProposal = nameProposal != null ? nameProposal.substr(0, nameProposal.lastIndexOf('.')).trim() : null;
            const fileExtension = name.split('.').pop();

            return this.$q.when()
                .then<IConvertAndCheckDesignResponse>(() => {
                    const items: IConvertAndCheckDesignRequestItemExtended[] = [{
                        DesignId: null,
                        RawProjectDesign: projectDesign,
                        StorageFormat: ProjectDesingFileStorageFormat.Xml,
                        DesignName: this.getProposalOrFileName(fileNameProposal, fileName)
                    } as IConvertAndCheckDesignRequestItemExtended];

                    return project.convertAndCheckDesign(items);
                })
                .then((response): ng.IPromise<any> => {
                    const param: IAlertParameters =  {
                        title: new ModalTranslation('Agito.Hilti.Purchaser.FileUpload.InvalidVersion.Title')
                    };
                    switch (response.ConvertAndCheckDesignResponseItems[0].ValidationStatus) {
                        case ValidationStatus.Invalid:
                            param.message = new ModalTranslation('Agito.Hilti.Purchaser.FileUpload.InvalidVersion.Message.DesignInvalid');
                            return this.modal.alert.open(param)[0].result.closed.then(() => this.$q.reject(response));
                        case ValidationStatus.NoLongerValid:
                            param.message = new ModalTranslation('Agito.Hilti.Purchaser.FileUpload.InvalidVersion.Message.DesignNoLongerValid');
                            return this.modal.alert.open(param)[0].result.closed.then(() => this.$q.reject(response));
                        case ValidationStatus.AnchorChanged:
                            param.message = new ModalTranslation('Agito.Hilti.Purchaser.FileUpload.InvalidVersion.Message.DesignAnchorChanged');
                            return this.modal.alert.open(param)[0].result.closed.then(() => this.$q.reject(response));
                        case ValidationStatus.Error:
                            param.message = new ModalTranslation('Agito.Hilti.Purchaser.FileUpload.InvalidVersion.Message.DesignError');
                            return this.modal.alert.open(param)[0].result.closed.then(() => this.$q.reject(response));
                        case ValidationStatus.Ok:
                        case ValidationStatus.CalculationTypeChanged:
                            // Do nothing.
                            break;
                        default:
                            throw new Error('Unknown validation status.');
                    }

                    const result = response.ConvertAndCheckDesignResponseItems[0].ProjectDesign;
                    const validationStatus = response.ConvertAndCheckDesignResponseItems[0].ValidationStatus;

                    // Masonry ACI designs are not supported in Purchaser
                    if (result.ProjectDesignType == DesignType.Masonry && result.Options.DesignStandard == DesignStandard.ACI) {
                        param.message = new ModalTranslation('Agito.Hilti.Purchaser.FileUpload.InvalidVersion.Message.NotSupported');
                        return this.modal.alert.open(param)[0].result.closed.then(() => this.$q.reject(response));
                    }

                    result.ProjectName = project.name;
                    result.DesignName = this.getProposalOrFileName(fileNameProposal, fileName);

                    if (secondTry) {
                        const opened = this.modal.confirmChange.open('confirm-design-exists',
                            new ModalTranslation('Agito.Hilti.Purchaser.Naming.DesignNameExists.Title'),
                            new ModalTranslation('Agito.Hilti.Purchaser.Naming.DesignNameExists.Import.Message'),
                            new ModalTranslation('Agito.Hilti.Purchaser.Naming.DesignNameExists.Keep'),
                            null,
                            () => {
                                if (secondTry) {
                                    fileName = fileNameProposal;
                                }

                                return this.document.addDesign(project.id, fileName, result, true, secondTry ? false : true)
                                    .then(((documentDesign: IBaseDesign) => {
                                        this.user.project.importDesignToPurchaserDataEntity(documentDesign.id, documentDesign.designName, result, validationStatus);
                                        this.modal.confirmChange.close(documentDesign);
                                        return documentDesign;
                                    }));
                            },
                            null)[0].result;

                        return opened.result as ng.IPromise<IImportData>;
                    } else {
                        return this.document.addDesign(project.id, fileName, result, true, secondTry ? false : true)
                            .then((documentDesign => {
                                result.DesignName = documentDesign.designName;
                                return this.user.project.importDesignToPurchaserDataEntity(documentDesign.id, documentDesign.designName, result, validationStatus);
                            }));
                    }
                })
                .catch(exception => {
                    if (exception.status == 409 && !secondTry && exception.data.content.conflictType == 0) {
                        return importAndUploadDesign(true, project, projectDesign, name, exception.data.content != null && exception.data.content.nameProposal != null ? exception.data.content.nameProposal + '.' + fileExtension : name, design);
                    }

                    return this.$q.reject(exception);
                });
        };

        const defer = this.$q.defer<IImportData>();
        const reader = new FileReader();
        reader.addEventListener('loadend', () => {
            importAndUploadDesign(false, project, reader.result as string, name, null, design)
                .then(value => {
                    defer.resolve(value);
                })
                .catch(value => {
                    defer.reject(value);
                });
        });
        reader.readAsText(projectDesign);
        return defer.promise;
    }

    private getProposalOrFileName(fileNameProposal: string, fileName: string) {
        return fileNameProposal != null ? fileNameProposal : fileName;
    }
}
