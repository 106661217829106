import {
    control, Control, ControlController, ControlDirective, ControlProperty, IControlConstructor,
    property
} from '../controls';
import template from './DropdownHeader.html';

export interface IDropdownHeaderConstructor extends IControlConstructor {
    text?: string | ControlProperty;
}

@control('DropdownHeader')
export class DropdownHeader extends Control {

    @property()
    public text: string;
    constructor(dropdownHeader?: IDropdownHeaderConstructor) {
        super(dropdownHeader);
    }
}

export class DropdownHeaderDirective extends ControlDirective {
    constructor() {
        super(DropdownHeaderController, DropdownHeader, template);
    }
}

class DropdownHeaderController extends ControlController<DropdownHeader> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs'
    ];

    public text: string;

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes) {
        super(DropdownHeader, $scope, $element, $attrs);
    }
}
