import { GuidService } from '../../../src/app/guid.service';
import { LocalizationService } from '../../../src/app/services/localization.service';
import {
    control, ControlDirective, ControlProperty, method, property, WebControl, WebControlController
} from '../controls';
import { Tooltip } from '../ControlTooltip/ControlTooltip';
import { IRegionControl, IRegionControlConstructor } from '../Region/Region';
import { RegionControlSize } from '../Region/RegionControlSize';
import template from './TextBox.html';
import { Required } from './Validators/Required';

export enum ModelUpdate {
    immediate,
    blur
}

export interface ITextBoxConstructor extends IRegionControlConstructor {
    postfix?: string | ControlProperty;
    model?: string | ControlProperty;
    rawModel?: string | ControlProperty;
    placeholder?: string | ControlProperty;
    required?: boolean | ControlProperty;
    customError?: boolean | ControlProperty;
    customErrorMessage?: string | ControlProperty;
    update?: ModelUpdate | ControlProperty;
    attrMax?: string | ControlProperty;
    attrMin?: string | ControlProperty;
    attrType?: string | ControlProperty;
    stepper?: boolean | ControlProperty;
    stepperDelay?: number | ControlProperty;
    validateDisabled?: boolean | ControlProperty;
}

@control('TextBox')
export class TextBox extends WebControl implements IRegionControl {

    public static blurChange = 'blurChange';

    @property()
    public title: string;

    @property()
    public size: RegionControlSize;

    @property()
    public tooltip: Tooltip;

    @property()
    public postfix: string;

    @property()
    public model: string;

    @property()
    public rawModel: string;

    @property()
    public placeholder: string;

    @property()
    public required: boolean;

    @property()
    public customError: boolean;

    @property()
    public customErrorMessage: string;

    @property()
    public update: ModelUpdate;

    @property()
    public attrType: string;

    @property()
    public attrMin: string;

    @property()
    public attrMax: string;

    @property()
    public stepper: boolean;

    @property()
    public stepperDelay: number;

    @property()
    public validateDisabled: boolean;
    constructor(textBox?: ITextBoxConstructor) {
        super(textBox);
    }

    @method()
    public focus() { }
}

export class TextBoxDirective extends ControlDirective {
    constructor() {
        super(TextBoxController, TextBox, template);
    }
}

export class TextBoxController extends WebControlController<TextBox> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse',
        'guid',
        'localization'
    ];

    public title: string;
    public size: RegionControlSize;
    public tooltip: Tooltip;
    public postfix: string;
    public model: string;
    public rawModel: string;
    public placeholder: string;
    public required: boolean;
    public customError: boolean;
    public customErrorMessage: string;
    public update: ModelUpdate;
    public attrType: string;
    public attrMin: string;
    public attrMax: string;
    public stepper: boolean;
    public stepperDelay: number;
    public validateDisabled: boolean;

    private inputElement: HTMLInputElement;
    private postfixElement: HTMLInputElement;
    private onInputChangeFunction: (...args: any[]) => any;
    private onInputBlurFunction: (...args: any[]) => any;

    private guid: GuidService;
    private localization: LocalizationService;

    constructor(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $compile: ng.ICompileService,
        $parse: ng.IParseService,
        guid: GuidService,
        localization: LocalizationService
    ) {
        super(TextBox, $scope, $element, $attrs, $compile, $parse);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.guid = guid;

            // constants
            this.$scope['RegionControlSize'] = RegionControlSize;
            this.$scope['localization'] = this.localization = localization;

            this.attrType = this.attrType || 'text';

            this.inputElement = this.element.querySelector('.data');
            this.postfixElement = this.element.querySelector('.postfix');

            // dom events
            this.inputElement.addEventListener('input', this.onInputChangeFunction = () => this.$scope.$applyAsync(() => this.onInputChange()), false);
            this.inputElement.addEventListener('blur', this.onInputBlurFunction = () => this.$scope.$applyAsync(() => this.onInputBlur()), false);

            // watches
            this.$scope.$watch('ctrl.rawModel', (...args: any[]) => this.onRawModelChange.apply(this, args));
            this.$scope.$watch('ctrl.model', (...args: any[]) => this.onModelChange.apply(this, args));

            // scope events
            this.$scope.$on('$destroy', (...args: any[]) => this.destroy.apply(this, args));

            // required validator
            this.validators['required'] = new Required();

            this.rawModel = this.model;
        })(this.$onInit);
    }

    public focus() {
        this.inputElement.focus();
        this.inputElement.select();
    }

    public keyPress($event: JQueryKeyEventObject) {
        if ($event.keyCode == 13) {
            this.updateModelValue();
        }
    }

    private onInputChange() {
        const inputValue = this.inputElement.value;

        if (this.postfix == null || this.postfix == '') {
            this.postfixElement.value = '';
        }
        else {
            this.postfixElement.value = inputValue != null && inputValue != '' ? inputValue + this.postfix : '';
        }
    }

    private onRawModelChange() {
        if (this.postfix == null || this.postfix == '') {
            this.postfixElement.value = '';
        }
        else {
            this.postfixElement.value = this.rawModel != null && this.rawModel != '' ? this.rawModel + this.postfix : '';
        }

        if (this.update == null || this.update == ModelUpdate.immediate) {
            this.model = this.rawModel;
        }
    }

    private onModelChange() {
        this.rawModel = this.model;
    }

    private onInputBlur() {
        this.ngModel.$setTouched();

        this.updateModelValue();
    }

    private updateModelValue() {
        if (this.update == ModelUpdate.blur && this.model !== this.rawModel) {
            this.model = this.rawModel;

            this.trigger(TextBox.blurChange);
        }
    }

    private destroy() {
        this.inputElement.removeEventListener('input', this.onInputChangeFunction);
        this.inputElement.removeEventListener('input', this.onInputBlurFunction);
    }
}

