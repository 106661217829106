<app-control-title
    [focusControlId]="id"
    [title]="title"
    [tooltip]="tooltip">
</app-control-title>

<div class="radio-container" [id]="id">
    <label class="radio" *ngFor="let item of items" [ngbTooltip]="ngbTooltipTemplate(item.tooltip, itemTooltipTemplate)">
        <ng-template #itemTooltipTemplate>
            <app-content-tooltip [tooltip]="item.tooltip"></app-content-tooltip>
        </ng-template>

        <input class="radio-input" [id]="item.id" [name]="radioName" [checked]="selectedValue == item.value"
            (change)="onSelected(item)" type="radio" [disabled]="item.disabled || disabled">
        <span class="radio-button"></span>
        <span class="radio-label">{{item.text}}</span>
    </label>
</div>
