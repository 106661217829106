import { Region } from '../../../src/app/entities/codeLists/region';
import {
    Unit, UnitGroup
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Common.Units';
import {
    DesignStatus
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Enums';
import { LocalizationService } from '../../../src/app/services/localization.service';
import conceptualAnchorImage from '../../../src/images/conceptual-anchor.png';
import { IStepperOptions, stepperDelay } from '../../Directives/stepper';
import { CodeListService, ProjectCodeList } from '../../../src/app/services/code-list.service';
import { NumberService } from '../../Services/number-service';
import { UnitService } from '../../Services/unit-service';
import { UserService } from '../../Services/user-service';
import { UserSettingsService } from '../../Services/user-settings-service';
import { ConceptualPicture } from '../ConceptualPicture/ConceptualPicture';
import { ContactHilti } from '../ContactHilti/ContactHilti';
import {
    control, ControlDirective, ControlProperty, IWebControlConstructor, property, WebControl,
    WebControlController
} from '../controls';
import { DesignFileInfo } from '../DesignFileInfo/DesignFileInfo';
import { Popup } from '../Popup/Popup';
import template from './ConnectionsGrid.html';

interface IConnectionSizeDisplay {
    articleQuantity: string;
    articleNumber?: string;
    tFixMax?: string;
    name?: string;
}

enum AnchorType {
    mechanical,
    chemical,
    sieveSleeve
}

export interface IConnection {
    id: string;
    designName: string;
    designInfo: IConnectionDesignInfo;
    mechanicalAnchor: IConnectionAnchor;
    chemicalAnchor?: IConnectionAnchor;
    numberOfAnchors: number;
    required: number;
    selected: boolean;
    manual: boolean;
    designStatus: DesignStatus;
    sieveSleeveAnchors: IConnectionAnchor[];
}

export interface IConnectionAnchor {
    id: number;
    name: string;
    /**
     * If the anchor is chemical, this represents the computed amount (number of mortar packs needed).
     * If the anchor is mechanical, this represents the number of anchors.
     */
    total: number;
    sizes?: IConnectionSize[];
    selectedSize?: string;
    invalidVolume?: boolean;
}

export interface IConnectionSize {
    articleQuantity: number;
    articleNumber?: string;
    tFixMax?: number;
    name?: string;
}

export interface IConnectionDesignInfo {
    anchorFamilyName?: string;
    plateThickness?: number;
    anchorDiameter?: number;
    embedmentDepth?: number;
    holeSize?: number;
}

export interface IConnectionsGridConstructor extends IWebControlConstructor {
    connections?: IConnection[] | ControlProperty;
    loadData?: (from: number) => ng.IPromise<IConnection[]> | ControlProperty;
}

@control('ConnectionsGrid')
export class ConnectionsGrid extends WebControl {

    public static connectionsChanged = 'connectionsChanged';

    @property()
    public connections: IConnection[];

    @property()
    public loadData: (from: number) => ng.IPromise<IConnection[]>;

    @property()
    public fullyLoaded: boolean;

    @property()
    public lastAction: boolean;
    constructor(ctor?: IConnectionsGridConstructor) {
        super(ctor);
    }
}

export class ConnectionsGridController extends WebControlController<ConnectionsGrid> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse',
        'localization',
        'codeList',
        'userSettings',
        'unit',
        'user',
        'number'
    ];

    private static readonly loadingConnectionId = 'loadingConnection';
    private static readonly countMin = 1;
    private static readonly countMax = 1000;

    public connections: IConnection[];
    public loadData: (from: number) => ng.IPromise<IConnection[]>;
    public fullyLoaded: boolean;
    public lastAction: boolean;

    public connectionSize: Popup<string>;
    public designInfo: DesignFileInfo;
    public conceptualPicture: ConceptualPicture;
    public stepperOptions: IStepperOptions;

    public contactHilti: ContactHilti;

    private loadingPromise: ng.IPromise<void>;
    private destroyed: boolean;

    private _displayConnections: IConnection[] = [];
    private _loadingConnection = { id: ConnectionsGridController.loadingConnectionId } as IConnection;

    private localization: LocalizationService;
    private codeList: CodeListService;
    private userSettings: UserSettingsService;
    private unit: UnitService;
    private user: UserService;
    private number: NumberService;

    constructor(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $compile: ng.ICompileService,
        $parse: ng.IParseService,
        localization: LocalizationService,
        codeList: CodeListService,
        userSettings: UserSettingsService,
        unit: UnitService,
        user: UserService,
        number: NumberService
    ) {
        super(
            ConnectionsGrid,
            $scope,
            $element,
            $attrs,
            $compile,
            $parse
        );

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope['localization'] = this.localization = localization;
            this.codeList = codeList;
            this.userSettings = userSettings;
            this.unit = unit;
            this.user = user;
            this.number = number;

            this.lastAction = null;

            this.stepperOptions = {
                delay: stepperDelay,
                min: ConnectionsGridController.countMin,
                max: ConnectionsGridController.countMax
            };

            this.$scope['AnchorType'] = AnchorType;
            this.$scope['loadingConnectionId'] = ConnectionsGridController.loadingConnectionId;
            this.$scope['DesignStatus'] = DesignStatus;
            this.$scope['isDesignValid'] = ConnectionsGridController.isDesignValid;

            this.connectionSize = new Popup<string>({
                windowClass: 'default-modal popup-modal connection-size',
                popupClass: 'connection-size',
                valueProperty: 'articleNumber',
                info: () => { this.conceptualPicture.open(); }
            });

            this.designInfo = new DesignFileInfo();
            this.contactHilti = new ContactHilti();

            this.conceptualPicture = new ConceptualPicture({
                image: conceptualAnchorImage
            });

            this.sizeHeader = this.sizeHeader.bind(this);
            requestAnimationFrame(this.sizeHeader);

            this.$scope.$on('$destroy', this.destroy.bind(this));
        })(this.$onInit);
    }

    public static isDesignValid(designStatus: DesignStatus) {
        return designStatus === DesignStatus.Ok ||
            designStatus === DesignStatus.NoLongerValid ||
            designStatus === DesignStatus.CalculationTypeChanged;
    }

    public get validConnections() {
        if (this.connections == null) {
            return [];
        }

        return this.connections.filter(connection => ConnectionsGridController.isDesignValid(connection.designStatus));
    }

    public get allSelected() {
        const all = this.validConnections != null && this.validConnections.length > 0 && this.validConnections.every(connection => connection.selected);
        return all;
    }

    public set allSelected(selected) {
        for (const connection of this.validConnections || []) {
            connection.selected = selected;
        }

        this.triggerConnectionsChanged();

        this.lastAction = selected;
    }

    public get displayConnections() {
        if (this.connections == null) {
            if (this._displayConnections == null) {
                this._displayConnections = [];
            }
            else {
                this._displayConnections.splice(0, this._displayConnections.length);
            }

            return this._displayConnections;
        }

        // clear array
        this._displayConnections.splice(0, this._displayConnections.length);

        // add items
        this._displayConnections.push(...this.connections);

        // add loading row
        if (this._displayConnections != null && this._displayConnections.length > 0 && this.loadData != null && !this.fullyLoaded && this._displayConnections[this._displayConnections.length - 1].id != ConnectionsGridController.loadingConnectionId) {
            this._displayConnections.push(this._loadingConnection);
        }

        return this._displayConnections;
    }

    public get hasConnections() {
        return this.validConnections.length > 0;
    }

    public get region() {
        return (this.codeList.codelist[ProjectCodeList.Region] as Region[]).find(region => region.id == this.userSettings.settings.user.general.regionId.value);
    }

    public get supportEmail() {
        return this.region.contactUrl.replace('mailto:', '');
    }

    public generateAnchorDescription(anchor: IConnectionAnchor, anchorType: AnchorType, manual: boolean) {
        if (anchor == null) {
            return '';
        }

        const selectedSize = anchor.selectedSize != null && anchor.selectedSize != '' ? anchor.sizes.find(size => size.articleNumber == anchor.selectedSize) : null;

        // no article number
        if (selectedSize == null || selectedSize.articleNumber == null || selectedSize.articleNumber == '' || !this.hasArticleNumber(anchor)) {
            return this.localization.getLocalizedString('Agito.Hilti.Purchaser.PleaseContactHilti');
        }

        let description = '';

        if (selectedSize != null) {
            if (selectedSize.name != null && selectedSize.name != '') {
                description += (description != '' ? ' ' : '') + selectedSize.name;
            }

            if (selectedSize.articleQuantity != null) {
                switch (anchorType) {
                    case AnchorType.sieveSleeve: break;
                    case AnchorType.mechanical:
                        description += (description != '' ? ' ' : '') + this.unit.formatInternalValueAsDefault(selectedSize.articleQuantity, UnitGroup.Length);
                        break;
                    case AnchorType.chemical:
                        description += (description != '' ? ' ' : '') + this.unit.formatInternalValueAsDefault(selectedSize.articleQuantity, UnitGroup.Volume);
                        break;
                    default:
                        throw new Error('Unknown anchor type.');
                }
            }

            if (anchorType == AnchorType.mechanical && selectedSize.tFixMax != null && !manual) {
                description += (description != '' ? ' ' : '') + `(t fix max: ${this.unit.formatInternalValueAsDefault(selectedSize.tFixMax, UnitGroup.Length)})`;
            }

            if (selectedSize.articleNumber != null) {
                description += (description != '' ? ' ' : '') + `/ ${selectedSize.articleNumber}`;
            }
        }

        return description;
    }

    public formatNumber(value: number) {
        if (value == null) {
            return undefined;
        }

        return this.unit.formatNumber(value);
    }

    public formatVolumeCalculation(value: number) {
        if (value == 0) {
            return '-';
        }

        return this.formatNumber(value);
    }

    public formatVolumeCalculationError(productName: string): string {
        return this.localization.getLocalizedString('Agito.Hilti.Purchaser.Grid.VolumeCalculationFailed')
            .replace('{productName}', productName)
            .replace('{supportEmail}', this.supportEmail);
    }

    public isVolumeCalculationError(chemicalAnchor: IConnectionAnchor): boolean {
        // "total" is the computed amount (number of mortar packs) needed, if it's not defined or it's 0, it means volume calculation failed
        return chemicalAnchor.total == null || chemicalAnchor.total == 0;
    }

    public editSize(connection: IConnection, anchor: IConnectionAnchor, anchorType: AnchorType) {

        this.connectionSize.popupTitle = this.localization.getLocalizedString('Agito.Hilti.Purchaser.ConnectionsGrid.ConnectionSize.Title');

        const lenghtUnit = this.unit.getDefaultUnit(UnitGroup.Length);
        const lengthUnitFormat = this.unit.getUnitStrings(lenghtUnit)[0];

        // what columns do we have
        let hasName = false;
        let hasArticleQuantity = false;
        let hasTFixMax = false;

        for (const size of anchor.sizes) {
            if (size.name != null && size.name != '') {
                hasName = true;
            }

            if (size.articleQuantity != null) {
                hasArticleQuantity = true;
            }

            if (size.tFixMax != null) {
                hasTFixMax = true;
            }

            if (hasName && hasArticleQuantity && hasTFixMax) {
                break;
            }
        }

        // no t fix max
        if (anchorType == AnchorType.chemical || anchorType == AnchorType.sieveSleeve || connection.manual) {
            hasTFixMax = false;
        }

        // columns
        this.connectionSize.columns = [];

        if (hasName) {
            this.connectionSize.columns.push({
                property: 'name',
                title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.ConnectionsGrid.ConnectionSize.Name')
            });
        }

        if (hasArticleQuantity) {
            let articleQuantityUnit: Unit = null;
            switch (anchorType) {
                case AnchorType.sieveSleeve: break;
                case AnchorType.mechanical:
                    articleQuantityUnit = this.unit.getDefaultUnit(UnitGroup.Length);
                    break;
                case AnchorType.chemical:
                    articleQuantityUnit = this.unit.getDefaultUnit(UnitGroup.Volume);
                    break;
                default:
                    throw new Error('Unknown anchor type.');
            }

            const articleQuantityUnitFormat = this.unit.getUnitStrings(articleQuantityUnit)[0];

            this.connectionSize.columns.push({
                property: 'articleQuantity',
                title: `${this.localization.getLocalizedString('Agito.Hilti.Purchaser.ConnectionsGrid.ConnectionSize.ArticleQuantity')} [${articleQuantityUnitFormat}]`
            });
        }

        if (hasTFixMax) {
            this.connectionSize.columns.push({
                property: 'tFixMax',
                title: `${this.localization.getLocalizedString('Agito.Hilti.Purchaser.ConnectionsGrid.ConnectionSize.TFixMax')} [${lengthUnitFormat}]`
            });
        }

        this.connectionSize.columns.push({
            property: 'articleNumber',
            title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.ConnectionsGrid.ConnectionSize.ArticleNumber')
        });

        // items
        this.connectionSize.items = anchor.sizes.map(size => ({
            name: size.name,
            articleNumber: size.articleNumber,
            articleQuantity: (() => {
                switch (anchorType) {
                    case AnchorType.sieveSleeve:
                        return '';
                    case AnchorType.mechanical:
                        return this.unit.formatInternalValueAsDefault(size.articleQuantity, UnitGroup.Length, true);
                    case AnchorType.chemical:
                        return this.unit.formatInternalValueAsDefault(size.articleQuantity, UnitGroup.Volume, true);
                    default:
                        throw new Error('Unknown anchor type.');
                }
            })(),
            tFixMax: this.unit.formatInternalValueAsDefault(size.tFixMax, UnitGroup.Length, true),
        } as IConnectionSizeDisplay));

        // selected item
        this.connectionSize.selectedValue = anchor.selectedSize;

        this.connectionSize.onSelect = (articleNumber) => {
            anchor.selectedSize = articleNumber;

            this.triggerConnectionsChanged(connection);
        };

        // open
        this.connectionSize.open();
    }

    public bindCount(connection: IConnection) {
        return (value: string) => {
            // setter
            if (value !== undefined) {
                let count = this.unit.parseNumber(value);

                if (count != null && !Number.isNaN(count)) {
                    // round to a whole number
                    count = this.number.round(count);

                    // min value
                    if (count < ConnectionsGridController.countMin) {
                        count = ConnectionsGridController.countMin;
                    }

                    // max value
                    if (count > ConnectionsGridController.countMax) {
                        count = ConnectionsGridController.countMax;
                    }

                    connection.required = count;

                    this.triggerConnectionsChanged(connection);
                }
            }

            // getter
            return this.unit.formatNumber(connection.required);
        };
    }

    public selectedChanged(connection: IConnection) {
        this.triggerConnectionsChanged(connection);
    }

    public openDesignInfo(connection: IConnection) {
        const designInfo = connection.designInfo;

        this.designInfo.setDesignName(connection.designName);
        this.designInfo.setAnchorFamilyName(designInfo.anchorFamilyName);
        this.designInfo.setBaseMaterialThickness(designInfo.plateThickness);
        this.designInfo.setAnchorDiameter(designInfo.anchorDiameter);
        this.designInfo.setEmbedmentDepth(designInfo.embedmentDepth);
        this.designInfo.setHoleSize(designInfo.holeSize);
        this.designInfo.setIsManualDesign(connection.manual);

        this.designInfo.open();
    }

    public hasArticleNumber(anchor: IConnectionAnchor) {
        return anchor != null && anchor.sizes != null && anchor.sizes.length > 0 && anchor.sizes.every(size => size.articleNumber != null && size.articleNumber != '');
    }

    public scrolledToEnd() {
        if (this.loadData != null && this.loadingPromise == null && !this.fullyLoaded) {
            this.loadingPromise = this.loadData(this.connections != null ? this.connections.length : 0)
                .then(connections => {
                    if (connections != null && connections.length > 0) {
                        this.connections = this.connections || [];

                        this.updateSelection(connections);
                        this.connections.push(...connections);
                    }
                    else {
                        this.fullyLoaded = true;
                    }
                })
                .finally(() => {
                    this.loadingPromise = null;
                });
        }
    }

    public calculateConnectionSize(connection: IConnection) {
        if (connection.id == ConnectionsGridController.loadingConnectionId) {
            return 64;
        } // loading indicator

        let size = 130; // design with mechanical and chemical anchor

        for (const anchor in connection.sieveSleeveAnchors) { // add for each sieve sleeve anchor
            size += 50;
        }

        if (!ConnectionsGridController.isDesignValid(connection.designStatus)) { // add for invalid design
            size += 50;
        }

        return size;
    }

    private updateSelection(connections: IConnection[]) {
        if (this.lastAction != null) {
            // if user clicks select all while designs are loading, sever might return some designs which are not included
            // select those designs and send update to server
            if (connections.some(c => ConnectionsGridController.isDesignValid(c.designStatus) && c.selected != this.lastAction)) {
                connections.forEach(c => {
                    if (ConnectionsGridController.isDesignValid(c.designStatus)) {
                        c.selected = this.lastAction;
                    }
                });

                this.triggerConnectionsChanged(...connections);
            }
        }
    }

    private sizeHeader() {
        if (!this.destroyed) {
            const header = this.element.querySelector<HTMLElement>('.connections-grid-header');
            const rows = this.element.querySelector<HTMLElement>('.connections-grid-row');

            if (header != null && rows != null) {
                const rowWidth = rows.clientWidth;

                header.style.width = rowWidth + 'px';
            }
            else if (header != null) {
                header.style.width = '';
            }

            requestAnimationFrame(this.sizeHeader);
        }
    }

    private triggerConnectionsChanged(...connections: IConnection[]) {
        this.trigger(ConnectionsGrid.connectionsChanged, connections != null && connections.length > 0 ? connections : this.connections);
    }

    private destroy() {
        this.destroyed = true;
    }
}

export class ConnectionsGridDirective extends ControlDirective {
    constructor() {
        super(ConnectionsGridController, ConnectionsGrid, template);

    }
}
