import { IApplicationError } from '../../src/app/entities/application-error';
import { urlPath } from '../../src/app/ModuleConstants';
import { environment } from '../../src/environments/environment';
import { ModalService } from './modal-service';
import { PromiseService } from './promise-service';
import { UserService } from './user-service';

export interface IRequestConfig extends ng.IRequestShortcutConfig {
    /** Don't show error specific messages to the user (like an error popup). */
    ignoreErrors?: boolean;
}

interface IInterceptorRequestConfig extends IRequestConfig, ng.IRequestConfig { }

/**
* Interceptor is regular service (factory) which allow us to capture every XHR request and manipulate it before sending it to the back- end API or
* after receiving the response from the API.
*
* https://docs.angularjs.org/api/ng/service/$http#interceptors
*
* TODO:
* In our case we are interested to capture each request before sending it so we can set the bearer token,
* as well we are interested in checking if the response from back- end API contains errors which means we need to check the error code returned so
* if its 401 then we redirect the user to the log-in page.
**/
export class HttpInterceptor implements ng.IHttpInterceptor {
    public static Factory = [
        '$q',
        '$location',
        'user',
        'modal',
        'promise', (
            $q: ng.IQService,
            $location: ng.ILocationService,
            user: UserService,
            modal: ModalService,
            promise: PromiseService
        ) => new HttpInterceptor($q, $location, user, modal, promise)
    ];

    private $q: ng.IQService;
    private $location: ng.ILocationService;
    private user: UserService;
    private modal: ModalService;
    private promise: PromiseService;

    /**
    * Initializes a new instance of the HTTPInterceptor class.
    **/
    constructor(
        $q: ng.IQService,
        $location: ng.ILocationService,
        user: UserService,
        modal: ModalService,
        promise: PromiseService
    ) {
        this.$q = $q;
        this.$location = $location;
        this.user = user;
        this.modal = modal;
        this.promise = promise;
    }

    public request = (config: IInterceptorRequestConfig): ng.IRequestConfig | ng.IPromise<ng.IRequestConfig> => {
        config.headers = config.headers || {};

        // add authentication header if needed
        if (environment.authenticationRequired.some((url) => config.url.startsWith(url))) {
            if (this.user.isAuthenticated) {
                config.headers['Authorization'] = `Bearer ${this.user.authentication.accessToken}`;

                if (environment.includeHCHeaders) {
                    // only in development mode
                    // on hilti environment this will be added automatically by OAG
                    config.headers['HC-UID'] = (this.user.authentication as any)['userId'];
                    config.headers['HC-User'] = (this.user.authentication as any)['userName'];
                    config.headers['HC-License'] = (this.user.authentication as any)['license'];
                }
            }
            else {
                const response: ng.IHttpResponse<string> = {
                    status: 401,
                    statusText: 'Unauthorized',
                    data: 'unauthenticated',
                    headers: undefined,
                    config,
                    xhrStatus: 'complete'
                };

                return this.$q.reject(response);
            }
        }

        return config;
    }

    public requestError = (rejection: any) => {
        return this.$q.reject(rejection);
    }

    public response = (response: any) => {
        return response;
    }

    public responseError = (rejection: ng.IHttpResponse<any>) => {
        const config = rejection.config as IInterceptorRequestConfig;

        if (rejection.status === 401) {

            // logout on AppConfig.AuthenticationRequired urls
            if (config != null && environment.authenticationRequired.some((url) => config.url.startsWith(url))) {
                if (rejection.statusText == 'LoginOtherDevice' || rejection.data['reason'] == 'LoginOtherDevice') {
                    this.modal.unauthorizedAccess.open();
                }
                else {
                    // invalidate access token
                    this.user.invalidateAuthentication();
                    // redirect to default path
                    this.$location.path(urlPath.unauthenticated);
                }
            }
        }
        else if (rejection.status === 403) {

        }
        else if (rejection.status === -1 && !this.user.isAuthenticated) {
            // Don't show errors
        }
        else if (config == null || !config.ignoreErrors && !this.promise.isTimeoutResolved(config.timeout)) {
            // show error message (ignore canceled, unauthenticated and forbidden requests)
            const param: IApplicationError = {
                response: rejection
            };
            this.modal.alert.openServiceError(param);
        }

        return this.$q.reject(rejection);
    }
}
