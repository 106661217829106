import cloneDeep from 'lodash-es/cloneDeep';
import debounce from 'lodash-es/debounce';

import { IAlertParameters } from '../../src/app/entities/alert';
import { IApplicationError } from '../../src/app/entities/application-error';
import { Change } from '../../src/app/entities/change';
import { TrackChanges } from '../../src/app/entities/track-changes';
import { UnitGroup } from '../../src/app/generated-modules/Hilti.PE.Purchaser.Common.Units';
import {
    CreatePurchaserProjectDataFromBomRequest, PrepareOrderUrlResponse, PrintBomRow,
    PurchaserDataEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data';
import {
    BillOfMaterialDetailsEntity, BillOfMaterialEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.BillOfMaterial';
import {
    AnchorType
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Enums';
import {
    UIPropertyConfig, UIPropertyValue
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Display';
import {
    PropertyUpdateResultEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.UpdateData';
import { GuidService } from '../../src/app/guid.service';
import { PropertyMetaData, UIProperties } from '../../src/app/properties/properties';
import { ErrorHandlerService } from '../../src/app/services/error-handler.service';
import { LocalizationService } from '../../src/app/services/localization.service';
import { LoggerService, LogMessage } from '../../src/app/services/logger.service';
import { environment } from '../../src/environments/environment';
import { ArticleNumberNotFound } from '../Controllers/bom-controller';
import { ModalTranslation } from '../Controls/Modal/BaseModal';
import { BrowserService } from './browser-service';
import { ChangesService } from '../../src/app/services/changes.service';
import { IRequestConfig } from './http-interceptor-service';
import { ModalService } from './modal-service';
import { PromiseService } from './promise-service';
import { TrackingService } from './tracking-service';
import { UnitService } from './unit-service';
import { UserService } from './user-service';
import { UserSettingsService } from './user-settings-service';

interface IModelWatch {
    last: { [property: number]: Object };
}

const updateDebounce = 0;

export class BomService {
    public static $inject = [
        '$q',
        'user',
        '$timeout',
        '$http',
        'localization',
        'logger',
        'changes',
        '$rootScope',
        'guid',
        'promise',
        'modal',
        'browser',
        'unit',
        'errorHandler',
        'tracking',
        'userSettings',
    ];
    public scopeProjectId: string;
    public uniqueId: string;
    public loading: boolean;
    public purchaserDataEntity: PurchaserDataEntity;

    private $q: ng.IQService;
    private user: UserService;
    private localization: LocalizationService;
    private $timeout: ng.ITimeoutService;
    private $http: ng.IHttpService;
    private logger: LoggerService;
    private modelChangesInternal: TrackChanges;
    private changes: ChangesService;
    private $rootScope: ng.IRootScopeService;
    private guid: GuidService;
    private promise: PromiseService;
    private modal: ModalService;
    private browser: BrowserService;
    private unit: UnitService;
    private onServiceErrorHandler: ErrorHandlerService;
    private tracking: TrackingService;
    private userSettings: UserSettingsService;

    private removeModelWatch: Function;
    private updateId: string;
    private modelWatch: IModelWatch;
    private updateDebounce: (updateId: string) => void;
    private updateDefer: ng.IDeferred<BomService>;
    private updateHttpCancel: ng.IDeferred<void>;
    private lastModelChanges: Change[];
    private promises: ng.IPromise<{ id: string, jsonData: Object }>[];

    private _model: { [property: number]: Object };

    constructor(
        $q: ng.IQService,
        user: UserService,
        $timeout: ng.ITimeoutService,
        $http: ng.IHttpService,
        localization: LocalizationService,
        logger: LoggerService,
        changes: ChangesService,
        $rootScope: ng.IRootScopeService,
        guid: GuidService,
        promise: PromiseService,
        modal: ModalService,
        browser: BrowserService,
        unit: UnitService,
        onServiceErrorHandler: ErrorHandlerService,
        tracking: TrackingService,
        userSettings: UserSettingsService,
    ) {
        this.$q = $q;
        this.user = user;
        this.$timeout = $timeout;
        this.$http = $http;
        this.localization = localization;
        this.logger = logger;
        this.changes = changes;
        this.$rootScope = $rootScope;
        this.guid = guid;
        this.promise = promise;
        this.modal = modal;
        this.browser = browser;
        this.unit = unit;
        this.onServiceErrorHandler = onServiceErrorHandler;
        this.tracking = tracking;
        this.userSettings = userSettings;

        this.updateDebounce = debounce((...args: any[]) => { this.$rootScope.$apply(() => { this.updateDebounceInternal.apply(this, args); }); }, updateDebounce);
        this.updateDefer = this.$q.defer<BomService>();
        this.uniqueId = this.guid.new();

        this.scopeProjectId = `bom_${this.uniqueId.replace(/-/g, '_')}`;
        this.$rootScope[this.scopeProjectId] = this;

        this.model = this.model != null ? this.model : {};
        this.promises = [];

        if (this.removeModelWatch != null) {
            this.removeModelWatch();

            this.removeModelWatch = null;
            this.modelWatch = null;
        }

        this.removeModelWatch = this.$rootScope.$watch<Object>(`${this.scopeProjectId}.model`, (model, oldModel) => {
            if (model === oldModel) {
                return;
            }

            // call BL and update
            this.updateBomProperties();
        }, true);

        this.modelWatch = this.$rootScope['$$watchers'][0];
    }
    public get model() {
        return this._model;
    }
    public set model(model) {
        this._model = model;
        // this.modelChanges.set(this.model);
    }

    private get modelChanges() {
        if (this.modelChangesInternal == null || this.modelChangesInternal == undefined) {
            this.modelChangesInternal = new TrackChanges({
                collapse: true,
                ignoreUndefined: true,
                shallowChanges: true,
                changesService: this.changes,
                loggerService: this.logger
            });

            this.modelChangesInternal.set(this._model);
        }

        return this.modelChangesInternal;
    }

    public loadBomData(regionId: number) {
        const url = `${environment.purchaserApplicationWebServiceUrl}CreatePurchaserProjectDataFromAllBoms`;
        const params = {
            LanguageLCID: this.localization.selectedLanguageLCID,
            RegionId: regionId
        };

        const defer = this.$q.defer<void>();

        // load boms data
        return this.$http.post<PropertyUpdateResultEntity>(url, params).
            then((updateResult) => {
                this.updateBomData(updateResult.data);

                defer.resolve();
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'PurchaserApplication', 'CreatePurchaserProjectDataFromAllBoms');

                defer.reject();
            });
    }

    public updatePurchaserDataEntityOptions(updateUserSettings: boolean = false): void {
        if (this.purchaserDataEntity == null ||
            this.purchaserDataEntity.Options == null) {
            return;
        }

        if (updateUserSettings) {
            this.purchaserDataEntity.Options.RegionId = this.userSettings.settings.user.general.regionId.value;
            this.purchaserDataEntity.Options.LanguageLCID = this.userSettings.settings.user.general.languageId.value;
            this.purchaserDataEntity.Options.UnitArea = this.userSettings.settings.user.units.areaId.value;
            this.purchaserDataEntity.Options.UnitLength = this.userSettings.settings.user.units.lengthId.value;
            this.purchaserDataEntity.Options.UnitVolume = this.userSettings.settings.user.units.volumeId.value;
        }
    }

    public addNewBom(newBom: BillOfMaterialEntity, newBomDetails: BillOfMaterialDetailsEntity[], dataEntity: PurchaserDataEntity) {
        this.loading = true;
        const url = `${environment.purchaserApplicationWebServiceUrl}CreatePurchaserProjectDataFromBom`;
        this.purchaserDataEntity.Designs = dataEntity.Designs;

        const params: CreatePurchaserProjectDataFromBomRequest = {
            Entity: this.purchaserDataEntity,
            Bom: newBom,
            BomDetails: newBomDetails
        };
        const config: IRequestConfig = { ignoreErrors: true };
        let addedBom: BillOfMaterialEntity;

        const defer = this.$q.defer<number>();

        this.$http.post<PropertyUpdateResultEntity>(url, params, config).
            then((updateResult) => {
                this.tracking.trackOnBomCreated(dataEntity);

                updateResult.data.PurchaserData.Boms.forEach((bom) => {
                    if (!this.purchaserDataEntity.Boms.some((uiBom) => uiBom.BomId == bom.BomId)) {
                        addedBom = bom;
                    }
                });

                this.updateBomData(updateResult.data);

                defer.resolve(addedBom.BomId);
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'PurchaserApplication', 'CreatePurchaserProjectDataFromBom');
                if (response.status == 404) {
                    this.onServiceErrorHandler.showProjectArchivedModal();
                }
                defer.reject();
            })
            .finally(() => {
                this.loading = false;
            });

        return defer.promise;
    }

    public placeOrder(bomId: number) {
        this.loading = true;
        const url = `${environment.purchaserApplicationWebServiceUrl}PrepareOrderUrl`;
        const params = {
            regionId: this.purchaserDataEntity.Options.RegionId,
            bomId
        };

        const defer = this.$q.defer<void>();

        this.$http.post<PrepareOrderUrlResponse>(url, params).
            then((preparedOrderUrl) => {
                if (preparedOrderUrl.data.DisplayErrorMessage) {
                    const param: IAlertParameters = {
                        title: new ModalTranslation('Agito.Hilti.Purchaser.PlaceOrder.UrlDoesNotExist.Title'),
                        message: new ModalTranslation('Agito.Hilti.Purchaser.PlaceOrder.UrlDoesNotExist.Message'),
                        applicationError: {
                            requestPayload: params,
                            responsePayload: preparedOrderUrl
                        } as IApplicationError
                    };
                    this.modal.alert.open(param);
                } else if (preparedOrderUrl.data.DisplayInformationMessage) {
                    this.modal.confirmChange.open('OrderUrlInformation',
                        new ModalTranslation('Agito.Hilti.Purchaser.OrderUrlInformation.Title'),
                        new ModalTranslation('Agito.Hilti.Purchaser.OrderUrlInformation.Message'),
                        new ModalTranslation('Agito.Hilti.Purchaser.Yes'),
                        new ModalTranslation('Agito.Hilti.Purchaser.No'),
                        () => this.onConfirmOrderUrlInformation(preparedOrderUrl.data.Url),
                        (...args: any[]) => this.onCancelOrderUrlInformation.apply(this, args));
                } else {
                    this.openHiltiOnlineOrderUrl(preparedOrderUrl.data.Url);
                }

                defer.resolve();
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'PurchaserApplication', 'PrepareOrderUrl');
                defer.reject();
            })
            .finally(() => {
                this.loading = false;
            });

        return defer.promise;
    }

    public exportToExcel(bomDetails: BillOfMaterialDetailsEntity[], bomName: string) {
        this.loading = true;
        const url = `${environment.purchaserApplicationWebServiceUrl}GenerateBomExcel`;
        const params = {
            bomDetailsCollection: bomDetails,
            language: (this.localization.selectedLanguage != undefined && this.localization.selectedLanguage != '')
                ? this.localization.selectedLanguage
                : environment.defaultLanguage
        };
        const config: ng.IRequestShortcutConfig = {
            responseType: 'blob'
        };

        const defer = this.$q.defer<void>();

        this.$http.post<Blob>(url, params, config).
            then((response) => {
                this.tracking.trackOnBomExportedToExcel();
                this.browser.downloadBlob(response.data, bomName + '.xlsx');
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'PurchaserApplication', 'GenerateBomExcel');
                defer.reject();
            })
            .finally(() => {
                this.loading = false;
            });

        return defer.promise;
    }

    public archiveBom(bomId: number) {
        this.loading = true;
        const url = `${environment.purchaserApplicationWebServiceUrl}RemoveBom`;
        const params = {
            entity: this.purchaserDataEntity,
            bomId
        };

        const defer = this.$q.defer<void>();

        this.$http.post<PropertyUpdateResultEntity>(url, params).
            then((updateResult) => {
                this.updateBomData(updateResult.data);

                defer.resolve();
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'PurchaserApplication', 'RemoveBom');
                defer.reject();
            })
            .finally(() => {
                this.loading = false;
            });

        return defer.promise;
    }

    public printBom(bomDetails: BillOfMaterialDetailsEntity[]) {
        const url = environment.baseUrl + 'PrintBom';

        // data
        const printData = bomDetails.map(bomDetail => {
            let lengthOrSize: string = null;
            const product: string = null;

            if (bomDetail.AnchorType == null || bomDetail.AnchorType == AnchorType.Insert) {
                lengthOrSize = this.unit.formatInternalValueAsDefault(bomDetail.LengthOrSize, UnitGroup.Length);
            }
            else if (bomDetail.AnchorType == AnchorType.Mortar || bomDetail.AnchorType == AnchorType.Capsule) {
                lengthOrSize = this.unit.formatInternalValueAsDefault(bomDetail.LengthOrSize, UnitGroup.Volume);
            }
            else if (bomDetail.AnchorType == AnchorType.SieveSleeve) {
                lengthOrSize = '';
            }
            else {
                throw new Error('Unknown AnchorType.');
            }

            return {
                ItemNumber: bomDetail.ArticleNumber == null || bomDetail.ArticleNumber == '' || bomDetail.ArticleNumber == ArticleNumberNotFound ? this.localization.getLocalizedString('Agito.Hilti.Purchaser.PleaseContactHilti') : bomDetail.ArticleNumber,
                LengthOrSize: lengthOrSize,
                Product: bomDetail.AnchorName,
                Required: bomDetail.TotalInProject,
                Total: bomDetail.OrderAmount
            } as PrintBomRow;
        });

        // form data
        const formData: { [name: string]: number | string } = {};
        for (let i = 0; i < printData.length; i++) {
            const detail = printData[i];

            for (const name in detail) {
                const value = detail[name];

                formData[`Rows[${i}].${name}`] = value;
            }
        }

        // header data
        formData['Language'] = this.localization.selectedLanguage;
        formData['Authorization'] = `Bearer ${this.user.authentication.accessToken}`;

        if (environment.includeHCHeaders) {
            formData['UserId'] = (this.user.authentication as any)['userId'];
            formData['UserName'] = (this.user.authentication as any)['userName'];
            formData['License'] = (this.user.authentication as any)['license'];
        }

        this.browser.post(url, formData, true);
    }

    /**
     * Change the model without triggering changes.
     * @param fn The function that changes the model.
     */
    public changeModel(fn: (model: { [property: number]: Object }) => void) {
        const oldModel = cloneDeep(this.model);
        let changes: { [property: string]: Change };

        try {
            fn(this.model);
        }
        finally {
            changes = this.changes.getShallowChanges(oldModel, this.model);

            // set watch values
            if (this.modelWatch != null) {
                for (const propertyId in changes) {
                    this.modelWatch.last[propertyId] = cloneDeep(changes[propertyId].newValue);
                }
            }

            // set track changes values
            if (Object.keys(changes).length > 0) {
                for (const propertyId in changes) {
                    this.modelChanges.setOriginalProperty(propertyId, cloneDeep(changes[propertyId].newValue));
                }
            }
        }

        return changes;
    }

    public updateBomProperties() {
        this.loading = true;
        this.updateId = this.guid.new();

        if (updateDebounce == 0) {
            this.updateDebounceInternal(this.updateId);
        }
        else {
            this.updateDebounce(this.updateId);
        }

        return !this.loading ? null : this.updateDefer.promise;
    }

    private updateBomData(data: PropertyUpdateResultEntity) {
        this.purchaserDataEntity = data.PurchaserData;
        this.purchaserDataEntity.Designs = [];
        this.updateFromProperties(data.Properties as any);
    }

    private updateFromProperties(properties: UIProperties) {
        const modelChanges = this.updateModel(properties);

        // join changes
        const changes: { [propertyId: string]: { modelChange: Change, isHidden: boolean, isDisabled: boolean, allowedValues: number[] } } = {};

        // model changes
        for (const modelChangeKey in modelChanges) {
            const propertyId = parseInt(modelChangeKey, 10);
            const modelChange = modelChanges[modelChangeKey];

            if (changes[propertyId] == null) {
                changes[propertyId] = { modelChange: null, isHidden: null, isDisabled: null, allowedValues: null };
            }

            changes[propertyId].modelChange = modelChange;
        }
    }

    private updateModel(properties: UIProperties) {
        return this.changeModel((model) => {
            for (const name in properties) {
                const propertyConfig: UIPropertyConfig = (properties as any)[name];

                // don't update the model if there's already a change pending
                if (propertyConfig != null && !this.modelChanges.changes.some((change) => change.name == propertyConfig.Property.toString())) {
                    model[propertyConfig.Property] = propertyConfig.Value;
                }
            }
        });
    }

    private clearModelWatch() {
        if (this.modelWatch != null) {
            for (const propertyId in this.model) {
                this.modelWatch.last[propertyId] = this.model[propertyId];
            }
        }
    }

    private updateDebounceInternal(updateId: string) {
        this.modelChanges.observe();
        this.cancelHttpUpdate();

        if (this.modelChanges.changes != null && this.modelChanges.changes.length > 0) {
            this.updateHttpCancel = this.$q.defer<void>();

            const url = `${environment.purchaserApplicationWebServiceUrl}UpdateProperties`;
            const params = {
                data: this.purchaserDataEntity,
                properties: this.modelChanges.changes.map((change) => {
                    return {
                        Property: parseInt(change.name, 10),
                        ValueJson: JSON.stringify(change.newValue)
                    } as UIPropertyValue;
                })
            };

            this.$http.post<PropertyUpdateResultEntity>(url, params, { timeout: this.updateHttpCancel.promise })
                .then((response) => {
                    // set data
                    this.updateBomData(response.data);

                    // resolve update promise
                    if (this.updateId == updateId) {
                        this.updateDefer.resolve(this);
                        this.updateDefer = this.$q.defer<BomService>();
                    }

                    return response;
                })
                .catch((response: ng.IHttpResponse<Object>) => {
                    // if request was canceled don't reject the promise since a new request was started
                    if (!this.promise.isResponseTimeoutResolved(response)) {
                        this.logger.logServiceError(response, 'PurchaserApplication', 'UpdateProperties');

                        // reject update promise
                        if (this.updateId == updateId) {
                            this.updateDefer.reject(response);
                            this.updateDefer = this.$q.defer<BomService>();
                        }
                    }

                    return response;
                })
                .then((response) => {
                    // remove loading flag if request is not canceled
                    if (!this.promise.isResponseTimeoutResolved(response)) {
                        this.updateHttpCancel = null;
                        this.lastModelChanges = null;
                    }
                })
                .finally(() => {
                    if (this.updateId == updateId) {
                        this.loading = false;
                    }
                });

            // print changes
            this.logger.logGroup(new LogMessage({
                message: 'Update'
            }), this.modelChanges.changes.map((change) => {
                const metaData = PropertyMetaData.getById(change.name as any);

                return new LogMessage({
                    message: (metaData != null ? metaData.name : change.name) + ': %o => %o',
                    args: [this.trim(change.oldValue), this.trim(change.newValue)]
                });
            }));

            // clear model changes
            this.lastModelChanges = this.modelChanges.changes.slice();  // we might need them if we cancel the request
            this.modelChanges.clear();
        }
        else if (this.promises.length <= 0) {
            this.loading = false;
        }
    }

    private cancelHttpUpdate() {
        if (this.updateHttpCancel != null) {
            this.updateHttpCancel.resolve();
            this.updateHttpCancel = null;

            // get the canceled changes back
            if (this.lastModelChanges != null) {
                this.modelChanges.changes = this.lastModelChanges.concat(this.modelChanges.changes);
                this.lastModelChanges = null;

                this.modelChanges.observe();
            }

            // print
            this.logger.log('Calculate canceled');
        }
    }

    private trim(value: any, length: number = 100) {
        if (value != null && typeof value == 'string') {
            const stringValue = (value as string);

            return stringValue.length > length ? stringValue.substring(0, length) + ' ...' : stringValue;
        }

        return value;
    }

    private onConfirmOrderUrlInformation(orderUrl: string) {
        this.modal.confirmChange.close();
        this.openHiltiOnlineOrderUrl(orderUrl);
    }

    private onCancelOrderUrlInformation() {
        this.modal.confirmChange.close();
    }

    private openHiltiOnlineOrderUrl(orderUrl: string) {
        this.tracking.trackOnBomExportedToHol();
        window.open(orderUrl, '_blank');
    }
}
