import { LocalizationService } from '../../../src/app/services/localization.service';
import { Project } from '../../Entities/Project';
import {
    BillOfMaterialDetailsEntity
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.BillOfMaterial';
import { BomService } from '../../Services/bom-service';
import { CodeListService } from '../../../src/app/services/code-list.service';
import { UnitService } from '../../Services/unit-service';
import { UserSettingsService } from '../../Services/user-settings-service';
import { Bind, control, WebControlVisibility } from '../controls';
import { GridBomDetails } from '../GridBomDetails/GridBomDetails';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective } from '../Modal/Modal';
import template from './BomDetails.html';

export class BomDetailsModalController extends BaseModalController<BomDetails> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'localization',
        'control',
        'codeList',
        'userSettings',
        'unit',
        'bom'
    ];

    public bomDetails: BillOfMaterialDetailsEntity[];
    public bomDetailsGrid: GridBomDetails;
    public project: Project;
    public bomName: string;
    public bomCreatedDate: string;
    public bomId: number;

    private $scope: ng.IScope;
    private localization: LocalizationService;
    private codelists: CodeListService;
    private userSettings: UserSettingsService;
    private unitService: UnitService;
    private bomService: BomService;
    private submitted: boolean;
    private pendingSave: boolean;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        localization: LocalizationService,
        control: BomDetails,
        codeList: CodeListService,
        userSettings: UserSettingsService,
        unitService: UnitService,
        bom: BomService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.$scope['localization'] = this.localization = localization;
            this.codelists = codeList;
            this.userSettings = userSettings;
            this.unitService = unitService;
            this.bomService = bom;

            this.initControls();

            // don't close the modal if save is pending
            this.$scope.$on('modal.closing', (event) => {
                if (this.pendingSave) {
                    event.preventDefault();
                }
            });

            this.bomDetailsGrid = new GridBomDetails({
                id: 'bom-details-grid',
                items: new Bind(`ctrl.bomDetails`),
                visibility: WebControlVisibility.visible,
            });

            // focus ok button
            this.$uibModalInstance.rendered
                .then(() => document.querySelector<HTMLButtonElement>('.bom-details-modal #bom-details-close-button').focus());
        })(this.$onInit);
    }

    private get form() {
        return this.$scope['form'] as ng.IFormController;
    }

    public save() {
        this.prepareDataForSave();

        const promise = this.project.updatePurchaserProperties();

        if (promise == null) {
            this.pendingSave = false;
            this.submitted = false;
            this.close();
        } else {
            promise
                .finally(() => {
                    this.pendingSave = false;
                })
                .then(() => {
                    this.close();
                })
                .catch(() => {
                    this.submitted = false;
                });
        }
    }

    public close() {
        this.$uibModalInstance.close();
    }

    public exportToExcel() {
        this.prepareDataForSave();

        const promise = this.project.updatePurchaserProperties();

        if (promise == null) {
            this.bomService.exportToExcel(this.bomDetails, this.bomName + ' ' + this.bomCreatedDate);
            this.pendingSave = false;
            this.submitted = false;
            this.close();
        } else {
            promise
                .finally(() => {
                    this.pendingSave = false;
                })
                .then(() => {
                    this.bomService.exportToExcel(this.bomDetails, this.bomName + ' ' + this.bomCreatedDate);
                    this.close();
                })
                .catch(() => {
                    this.submitted = false;
                });
        }
    }

    public placeOrderOnHol() {
        this.prepareDataForSave();

        const promise = this.project.updatePurchaserProperties();

        if (promise == null) {
            this.bomService.placeOrder(this.bomId);
            this.pendingSave = false;
            this.submitted = false;
            this.close();
        } else {
            promise
                .finally(() => {
                    this.pendingSave = false;
                })
                .then(() => {
                    this.bomService.placeOrder(this.bomId);
                    this.close();
                })
                .catch(() => {
                    this.submitted = false;
                });
        }
    }

    private initControls() {
        this.bomDetails = this.copyBomDetails(this.control.bomDetails);
        this.project = this.control.project;
        this.bomName = this.control.bomName;
        this.bomCreatedDate = this.control.bomCreatedDate;
        this.bomId = this.control.bomId;
    }

    private copyBomDetails(bomDetails: BillOfMaterialDetailsEntity[]) {
        const bomDetailsArray: BillOfMaterialDetailsEntity[] = [];

        bomDetails.forEach((bom) => {
            bomDetailsArray.push({
                BomId: bom.BomId,
                BomDetailsId: bom.BomDetailsId,
                AnchorFamilyName: bom.AnchorFamilyName,
                AnchorName: bom.AnchorName,
                AnchorType: bom.AnchorType,
                ArticleNumber: bom.ArticleNumber,
                LengthOrSize: bom.LengthOrSize,
                OrderAmount: bom.OrderAmount,
                TotalInProject: bom.TotalInProject
            } as BillOfMaterialDetailsEntity);
        });

        return bomDetailsArray;
    }

    private prepareDataForSave() {
        if (this.submitted || !this.form.$valid) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        this.bomDetails.forEach((bomDetails) => {
            const extBomDetails = this.control.bomDetails
                .find((externalBomDetails) => externalBomDetails.BomDetailsId == bomDetails.BomDetailsId);
            extBomDetails.OrderAmount = bomDetails.OrderAmount;
        });
    }
}

export interface IBomDetailsConstructor {
}

@control('BomDetails')
export class BomDetails extends Modal<BomDetails> {

    public bomDetails: BillOfMaterialDetailsEntity[];
    public project: Project;
    public bomName: string;
    public bomCreatedDate: string;
    public bomId: number;

    constructor(ctor: IBomDetailsConstructor) {
        super({
            template,
            templateUrl: 'bom-details-modal-template.html',
            controller: BomDetailsModalController,
            windowClass: 'default-modal bom-details-modal',
            size: 'lg',
            ...ctor
        });
    }

    public setBomDetails(bomDetails: BillOfMaterialDetailsEntity[]) {
        this.bomDetails = bomDetails;
    }

    public setProject(project: Project) {
        this.project = project;
    }

    public setBomName(bomName: string) {
        this.bomName = bomName;
    }

    public setBomCreatedDate(bomCreatedDate: string) {
        this.bomCreatedDate = bomCreatedDate;
    }

    public setBomId(bomId: number) {
        this.bomId = bomId;
    }
}

class BomDetailsController extends ModalController<BomDetails> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal',
    ];

    constructor($scope: ng.IScope,
                $element: ng.IAugmentedJQuery,
                $attrs: ng.IAttributes,
                $uibModal: ng.ui.bootstrap.IModalService) {
        super(BomDetails, $scope, $element, $attrs, $uibModal);
    }
}

export class BomDetailsDirective extends ModalDirective {
    constructor() {
        super(BomDetailsController, BomDetails);
    }
}
