import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppStorageService {
    get<T>(key: string) {
        return JSON.parse(localStorage.getItem(key)) as T;
    }

    set<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }
}
