import {
    LicenseComparisonPackagesEntity
} from '../../generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Codelists';
import {
    LicenseSuiteType
} from '../../generated-modules/Hilti.PE.Purchaser.Integrations.PEWebServices.Entities';
import { CodeList, ICodeListConstructor } from './codeList';

export interface IRegionLicenseFeatureConstructor extends ICodeListConstructor {
    regionId?: number;
    licenseSuiteType?: LicenseSuiteType;
    translationKey?: string;
}

export class RegionLicenseFeature extends CodeList {
    public regionId: number;
    public licenseSuiteType: LicenseSuiteType;
    public translationKey: string;

    constructor(codeList?: IRegionLicenseFeatureConstructor) {
        super(codeList);

        if (codeList != null) {
            this.id = codeList.id;
            this.regionId = codeList.regionId;
            this.licenseSuiteType = codeList.licenseSuiteType;
            this.translationKey = codeList.translationKey;
        }
    }

    public static fromService(codeList: LicenseComparisonPackagesEntity) {
        const result = new RegionLicenseFeature({
            id: codeList.Id,
            regionId: codeList.RegionId,
            licenseSuiteType: codeList.LicenseSuiteType,
            translationKey: codeList.TranslationKey
        });

        return result;
    }

    public cloneDeep() {
        const codeList: RegionLicenseFeature = super.cloneDeep() as RegionLicenseFeature;

        codeList.id = this.id;
        codeList.regionId = this.regionId;
        codeList.licenseSuiteType = this.licenseSuiteType;
        codeList.translationKey = this.translationKey;

        return codeList;
    }
}
