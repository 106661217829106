import { IValidatorContext, Validator, WebControlVisibility } from '../../controls';
import { DropdownController } from '../Dropdown';

export interface RequiredContext<TValue extends Object> extends IValidatorContext<DropdownController<TValue>> {
        watch: Function;
    }

export class Required<TValue extends Object> extends Validator<DropdownController<TValue>, RequiredContext<TValue>> {
        public add(context: RequiredContext<TValue>) {
            // refresh validation
            setTimeout(() => context.$scope.$applyAsync(() => {
                context.watch = context.$scope.$watchGroup(['ctrl.required', 'ctrl.selectedValue'], () => {
                    context.ngModel.$validate();
                });
            }));
        }

        public remove(context: RequiredContext<TValue>) {
            // remove watch
            context.watch();
        }

        public isValid(context: RequiredContext<TValue>) {
            return context.control.visibility != WebControlVisibility.visible || !context.control.required || context.control.selectedValue != null;
        }
    }
