import { Injectable, Type } from '@angular/core';

// When changing this enum also change the declarations list in src\app\app.module.ts.
// This DeclarationType enum and declarations list in src\app\app.module.ts *MUST* be the same!!!
export enum DeclarationType {
    L10nDirective,
    L10nPipe,
    CollapseComponent,
    LabelComponent,
    ContentTooltipComponent,
    CheckboxButtonComponent,
    ControlTitleComponent,
    DropdownComponent,
    LoadingComponent,
    NumericTextBoxComponent,
    TextBoxComponent,
    RadioButtonComponent,
    SectionComponent,
    VersionComponent
}

@Injectable({
  providedIn: 'root'
})
export class ComponentProviderService {
  private dictionary: Record<DeclarationType, Type<object>>;


  public register(components: Record<DeclarationType, Type<object>>) {
    this.dictionary = components;
  }

  public get(type: DeclarationType): Type<object> {
    return this.dictionary[type];
  }
}
