import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { LocalizationService } from '../../services/localization.service';
import { ngbTooltipTemplate, Tooltip } from '../content-tooltip/content-tooltip.component';

export const enum ControlTitleLook {
    Normal              = 'Normal',
    HiltiStyled         = 'HiltiStyled',
}

@Component({
    selector: 'app-control-title',
    templateUrl: './control-title.component.html',
    styleUrls: ['./control-title.component.scss']
})
export class ControlTitleComponent implements OnChanges {

    @Input()
    public focusControlId: string;

    @Input()
    public title: string;

    @Input()
    public tooltip: Tooltip;

    public ngbTooltipTemplate = ngbTooltipTemplate;

    public titleHtml: string;

    constructor(
        private localizationService: LocalizationService
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.title != null) {
            this.titleHtml = this.localizationService.sanitizeText(this.title, LocalizationService.SubSup);
        }
    }
}
