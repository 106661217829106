import { PickProperties } from 'ts-essentials';

export function replace(value: string, find: string, replace: string) {
    return value.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export function format(format: string, ...args: any[]) {
    return format.replace(/{(\d+)}/g, (match, number) => {
        return typeof args[number] != 'undefined' ? args[number] : match;
    });
}

export function formatKeyValue(format: string, args: { [key: string]: string }) {
    return format.replace(/{(.+?)}/g, (match, key) => {
        return typeof args[key] != 'undefined' ? args[key] : match;
    });
}

export function sortByUnicode<T, K extends keyof PickProperties<T, string>>(collection: T[], property: K) {
    return [...collection].sort((a, b) => (a[property] as unknown as string).localeCompare(b[property] as unknown as string));
}

function escapeRegExp(str: string) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}
