import {
    Component, EventEmitter, Input, NgZone, OnChanges, Output, SimpleChanges
} from '@angular/core';

import { UnitService, UnitValue } from '../../../../App/Services/unit-service';
import { Unit } from '../../generated-modules/Hilti.PE.Purchaser.Common.Units';
import { randomString } from '../../helpers/random';
import { LocalizationService } from '../../services/localization.service';
import { Tooltip } from '../content-tooltip/content-tooltip.component';
import { TextBoxType } from '../text-box/text-box.component';

export interface NumericTextBoxProps {
    id?: string;
    title?: string;
    value?: number;
    unit?: Unit;
    decimalSeparator?: string;
    groupSeparator?: string;
    placeholder?: string | number;
    disabled?: boolean;
    minValue?: number;
    maxValue?: number;
    type?: TextBoxType;
    tooltip?: Tooltip;
    prefix?: string;
    isValid?: boolean;
    submitted?: boolean;
}

enum StepperType {
    increment,
    decrement
}

@Component({
    selector: 'app-numeric-text-box',
    templateUrl: './numeric-text-box.component.html',
    styleUrls: ['./numeric-text-box.component.scss']
})
export class NumericTextBoxComponent implements OnChanges {

    @Input()
    public id: string = randomString(8);

    @Input()
    public title: string;

    @Input()
    public value: number;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('unit')
    public inputUnit: Unit;

    @Input()
    public decimalSeparator: string;

    @Input()
    public groupSeparator: string;

    @Output()
    public valueChange = new EventEmitter<number>();

    @Input()
    public placeholder: string | number;

    @Input()
    public disabled = false;

    @Input()
    public minValue: number;

    @Input()
    public maxValue: number;

    @Input()
    public type: TextBoxType = 'text';

    @Input()
    public tooltip: Tooltip;

    @Input()
    public prefix: string;

    @Output()
    public isValid = new EventEmitter<boolean>();

    @Input()
    public submitted = false;

    @Output()
    public submittedChange = new EventEmitter<boolean>();

    public displayValue: string;

    private timeoutId: number;
    private intervalId: number;

    constructor(
        private localizationService: LocalizationService,
        private unitService: UnitService,
        private ngZone: NgZone
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.value != null) {
            this.updateDisplayValue();
        }
    }

    public displayPlaceholder() {
        if (this.placeholder != null) {
            if (typeof this.placeholder == 'string') {
                return this.placeholder;
            }
            else {
                return this.formatDisplayValue(this.placeholder);
            }
        }

        return null;
    }

    public l10n(key: string) {
        return this.localizationService.getLocalizedString(key);
    }

    public incrementTooltip() {
        return this.l10n('Agito.Hilti.Profis3.TextBox.StepperIncrement');
    }

    public decrementTooltip() {
        return this.l10n('Agito.Hilti.Profis3.TextBox.StepperDecrement');
    }

    public debounce(stepperType: StepperType) {
        this.timeoutId = window.setTimeout(() => {
            this.intervalId = window.setInterval(() =>
                this.ngZone.run(() => {
                    this.changeValue(stepperType);
                }), 70);
        }, 500);
    }

    public stopDebounce() {
        if (this.intervalId != null) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }

        if (this.timeoutId != null) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }

    public onUserChange(value: string) {
        this.parseAndSet(value);
    }

    public increment() {
        this.changeValue(StepperType.increment);
    }

    public decrement() {
        this.changeValue(StepperType.decrement);
    }

    private get unit() {
        return this.inputUnit ?? Unit.None;
    }

    private get unitGroup() {
        const unitGroup = this.unitService.getUnitGroupFromUnit(this.unit);

        return unitGroup;
    }

    private get internalUnit() {
        const internalUnit = this.unitService.getInternalUnit(this.unitGroup);

        return internalUnit;
    }

    private changeValue(stepperType: StepperType) {
        if (this.value != null) {
            // convert value from internal unit to user selected unit
            let value = this.unitService.convertUnitValueToUnit(({ value: this.value, unit: this.internalUnit }) as UnitValue, this.unit).value;

            // find step to increment / decrement
            const stepValue = this.unitService.incDecValueByUnit(this.unit);

            // update value
            value += stepperType == StepperType.increment ? stepValue : -(stepValue);

            let formattedUnitValue = this.unitService.formatUnitValue(({ value: value, unit: this.unit }) as UnitValue);

            // display value
            this.parseAndSet(formattedUnitValue);
        }
        else if (this.placeholder != null && (typeof this.placeholder == 'number')) {
            this.setValue(this.placeholder);
        }
    }

    private parseAndSet(input: string) {
        const unitValue = this.unitService.parseUnitValue(input, this.unitGroup, this.unit);

        let value: number = null;
        if (unitValue != null && !Number.isNaN(unitValue.value)) {
            value = this.unitService.convertUnitValueToUnit(unitValue, this.internalUnit).value

            if (value > this.maxValue && this.maxValue != null) {
                value = this.maxValue;
            }

            if (value < this.minValue && this.minValue != null) {
                value = this.minValue;
            }
        }

        this.setValue(value);
    }

    private formatDisplayValue(value: number) {
        if (value == null) {
            return null;
        }

        let formattedValue: string;
        if (this.unit != Unit.None) {
            formattedValue = this.formatUnitAndValue(value);
        }
        else {
            formattedValue = this.formatValue(value);
        }

        return `${this.prefix ?? ''}${formattedValue}`;
    }

    private formatUnitAndValue(value: number) {
        const defaultPrecision = this.unitService.getPrecision(this.unit);

        const formattedValue = this.unitService.formatUnitValueArgs(
            this.unitService.convertUnitValueArgsToUnit(value, this.internalUnit, this.unit),
            this.unit,
            defaultPrecision);

        return formattedValue;
    }

    private formatValue(value: number) {
        return this.unitService.formatNumber(
            value,
            this.unitService.getPrecision(Unit.None),
            null
        );
    }

    private updateDisplayValue() {
        this.displayValue = this.formatDisplayValue(this.value);
    }

    private setValue(value: number) {
        this.value = value;
        this.updateDisplayValue();

        this.valueChange.emit(this.value);
    }
}
