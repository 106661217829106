<p [id]="id" class="header" [class.header-section-box]="isSectionBoxStyled">
    <span class="title">{{title}}</span>

    <button [id]="(id) + '-button'" class="toggle-content" type="button" (click)="toggleCollapsed()" [ngbTooltip]="collapseTooltip">
        <span class="pe-ui-common-sprite" [class.pe-ui-common-sprite-lines]="!collapsed" [class.pe-ui-common-sprite-lines-expanded]="collapsed"></span>
    </button>
</p>

<div class="content" [class.content-section-box]="isSectionBoxStyled"
    *ngIf="!collapsed">
    <slot></slot>
</div>
