import { Injectable } from '@angular/core';
import { replace } from '../helpers/string-helper';
import { LocalizationService } from './localization.service';

export const enum DateTimePattern {
    shortDate,
    shortTime,
    longDate,
    longTime,
    dateTime
}

@Injectable({
    providedIn: 'root'
})
export class DateTimeService {

    constructor(
        private localization: LocalizationService
    ) { }


    public format(date: Date, pattern?: DateTimePattern) {
        if (this.localization.selectedLanguage == null || this.localization.selectedLanguage == '') {
            throw new Error('Language not set.');
        }

        if (date == null) {
            return null;
        }

        if (pattern == null) {
            pattern = DateTimePattern.shortDate;
        }

        return this.localization.moment(date).format(this.getFormat(pattern));
    }

    public getFormat(pattern: DateTimePattern) {
        if (this.localization.selectedLanguage == null || this.localization.selectedLanguage == '') {
            throw new Error('Language not set.');
        }

        let dotNetFormat: string = null;

        switch (pattern) {
            case DateTimePattern.longDate:
                dotNetFormat = this.localization.dateTimeFormat.LongDatePattern;
                break;

            case DateTimePattern.longTime:
                dotNetFormat = this.localization.dateTimeFormat.LongTimePattern;
                break;

            case DateTimePattern.shortDate:
                dotNetFormat = this.localization.dateTimeFormat.ShortDatePattern;
                break;

            case DateTimePattern.shortTime:
                dotNetFormat = this.localization.dateTimeFormat.ShortTimePattern;
                break;

            case DateTimePattern.dateTime:
                return 'lll';

            default:
                throw new Error('Unknown pattern.');
        }

        return this.dotNetToMomentFormat(dotNetFormat);
    }

    // converts Date to Microsoft JSON date string
    public toMicrosoftFormat(value: Date) {
        if (value == null) {
            return null;
        }

        return '/Date(' + value.getTime() + ')/';
    }

    // add other conversions ...
    // http://momentjs.com/docs/#/displaying/
    // https://msdn.microsoft.com/en-us/library/8kb3ddd4(v=vs.110).aspx
    private dotNetToMomentFormat(dotNetFormat: string) {
        let momentFormat = dotNetFormat;

        momentFormat = replace(momentFormat, 'd', 'D');
        momentFormat = replace(momentFormat, 'y', 'Y');
        momentFormat = replace(momentFormat, 'tt', 'A');
        momentFormat = replace(momentFormat, 't', 'A');

        return momentFormat;
    }
}
