import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ngbTooltipTemplate, Tooltip } from '../content-tooltip/content-tooltip.component';

@Component({
    selector: 'app-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnChanges {
    @Input()
    public id: string;

    @Input()
    public title: string;

    @Input()
    public text: string | string[];

    @Input()
    public disabled: boolean = false;

    @Input()
    public tooltip: Tooltip;

    public ngbTooltipTemplate = ngbTooltipTemplate;


    public textLines: string[];


    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.text != null) {
            // Ensure string[] ...
            const text = Array.isArray(this.text)
                ? this.text
                : [this.text];

            // and filter out empty entries.
            this.textLines = text.filter(t => t != null && typeof t == 'string' && t != '');
        }
    }
}
