

import {
    Language as LanguageEntity
} from '../../generated-modules/Hilti.PE.Purchaser.Entities.Translations';
import { CodeList, ICodeListConstructor } from './codeList';

export interface ILanguageConstructor extends ICodeListConstructor {
    culture?: string;
}

export class Language extends CodeList {
    public culture: string;

    constructor(
        codeList?: ILanguageConstructor
    ) {
        super(codeList);

        if (codeList != null) {
            this.culture = codeList.culture;
        }
    }

    public static fromService(codeList: LanguageEntity) {
        return new Language({
            id: codeList.LCID,
            culture: codeList.LanguageName,
            nameResourceKey: codeList.DisplayName
        });
    }

    public cloneDeep() {
        const codeList: Language = super.cloneDeep() as Language;

        codeList.culture = this.culture;

        return codeList;
    }
}
