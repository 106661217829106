// Support for upgrading services from AngularJS to Angular.
import { ModalService } from './Services/modal-service';


// modal-service
export function modalServiceFactory(i: any) {
    return i.get('modal');
}
export const modalServiceProvider = {
    provide: ModalService,
    useFactory: modalServiceFactory,
    deps: ['$injector']
};
