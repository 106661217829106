import sortBy from 'lodash-es/sortBy';

import {
    getCodeListTextDeps, ICodeListTextDeps
} from '../../../src/app/entities/codeLists/codeList';
import { Language } from '../../../src/app/entities/codeLists/language';
import { Region } from '../../../src/app/entities/codeLists/region';
import { RegionLanguage } from '../../../src/app/entities/codeLists/region-language';
import { WorldArea } from '../../../src/app/entities/codeLists/world-area';
import { LocalizationService } from '../../../src/app/services/localization.service';
import { CodeListService, ProjectCodeList } from '../../../src/app/services/code-list.service';
import { UserService } from '../../Services/user-service';
import { UserSettingsService } from '../../Services/user-settings-service';
import { Button, ButtonType } from '../Button/Button';
import { control } from '../controls';
import { Dropdown } from '../Dropdown/Dropdown';
import { DropdownItem } from '../DropdownItem/DropdownItem';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective } from '../Modal/Modal';
import template from './SelectRegionLanguage.html';

interface WorldAreaCountryRecommendation {
    worldAreaIndex: number;
    countryCode: string | null;
}

export class SelectRegionLanguageModalController extends BaseModalController<SelectRegionLanguage> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'control',
        'codeList',
        'userSettings',
        'localization',
        'user',
    ];

    public selectRegionButton: Button;
    public countryDropdown: Dropdown<RegionLanguage>;
    public worldAreaDropdown: Dropdown<WorldArea>;

    private $scope: ng.IScope;
    private codeList: CodeListService;
    private userSettings: UserSettingsService;
    private localization: LocalizationService;
    private user: UserService;

    private countryWorldAreaSuggestion: WorldAreaCountryRecommendation;

    private codeListDeps: ICodeListTextDeps;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        control: SelectRegionLanguage,
        codeList: CodeListService,
        userSettings: UserSettingsService,
        localization: LocalizationService,
        user: UserService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.codeList = codeList;
            this.userSettings = userSettings;
            this.localization = localization;
            this.user = user;

            this.countryWorldAreaSuggestion = this.determineSelectionSuggested();

            this.worldAreaChanged = this.worldAreaChanged.bind(this);

            this.codeListDeps = getCodeListTextDeps(this.localization);

            // don't close the modal if we didn't save
            this.$scope.$on('modal.closing', (event, result) => {
                if (result == null || !(result instanceof RegionLanguage)) {
                    event.preventDefault();
                }
            });

            const worldAreaCodeList = sortBy(
                this.codeList.codelist[ProjectCodeList.WorldArea] as WorldArea[],
                worldArea => worldArea.name);

            this.worldAreaDropdown = new Dropdown<WorldArea>({
                id: 'select-region-worldArea',
                title: this.localization.getLocalizedString(
                    'Agito.Hilti.Purchaser.SelectRegionLanguage.WorldAreaDropdown.Title'),
                required: true,
                disabled: false,
                items: worldAreaCodeList.map((worldArea) => new DropdownItem({
                    value: worldArea,
                    text: worldArea.getTranslatedNameText(this.codeListDeps)
                })),
                selectedValue: worldAreaCodeList[this.countryWorldAreaSuggestion.worldAreaIndex]
            });

            this.countryDropdown = new Dropdown<RegionLanguage>({
                id: 'select-region-country',
                title: this.localization.getLocalizedString(
                    'Agito.Hilti.Purchaser.SelectRegionLanguage.CountryDropdown.Title'),
                required: true,
                disabled: false,
                items: null,
                selectedValue: null
            });

            this.selectRegionButton = new Button({
                id: 'select-region',
                disabled: false,
                buttonType: ButtonType.primary,
                text: this.localization.getLocalizedString(
                    'Agito.Hilti.Purchaser.SelectRegionLanguage.ContinueButton')
            });

            this.$scope.$watch(
                'ctrl.worldAreaDropdown.selectedValue',
                this.worldAreaChanged);
        })(this.$onInit);
    }

    public get regionLanguages() {
        return this.codeList.codelist[ProjectCodeList.RegionLanguage] as RegionLanguage[];
    }

    public selectRegionLanguage() {
        const regionLanguage = this.countryDropdown.selectedValue;
        const language = (this.codeList.codelist[ProjectCodeList.Language] as Language[]).find((language) => language.id == regionLanguage.lcid);

        this.userSettings.settings.user.general.regionId.value = regionLanguage.regionId;
        this.userSettings.settings.user.general.languageId.value = language.id;

        this.localization.getTranslations(language.culture).subscribe(() => {
            this.userSettings.save();
        });

        this.close(regionLanguage);
    }

    public close(regionLanguage: RegionLanguage) {
        this.$uibModalInstance.close(regionLanguage);
    }

    private worldAreaChanged() {
        if (this.worldAreaDropdown.selectedValue == null) {
            return;
        }

        const selectedWorldArea = this.worldAreaDropdown.selectedValue;
        let regionCodeList = (this.codeList.codelist[ProjectCodeList.Region] as Region[]).filter(x => x.worldAreaId == selectedWorldArea.id);

        const regionLanguageCodeList = regionCodeList
            .flatMap(region => (this.codeList.codelist[ProjectCodeList.RegionLanguage] as RegionLanguage[])
                .filter(regionLanguage => regionLanguage.regionId == region.id));

        this.countryDropdown.items = regionLanguageCodeList.map((regionLanguage) => new DropdownItem({
            value: regionLanguage,
            text: regionLanguage.getTranslatedNameText(this.codeListDeps),
            image: regionLanguage.image
        }));

        let countryIndex = 0;

        if (this.countryWorldAreaSuggestion != null &&
            this.countryWorldAreaSuggestion.countryCode != null) {
            regionCodeList = regionCodeList.filter(x => x.countryCode == this.countryWorldAreaSuggestion.countryCode);

            if (regionCodeList.length > 0) {
                const region = regionCodeList[0];
                const regionLanguage = regionLanguageCodeList.filter(x => x.regionId == region.id && x.defaultForRegion)[0];

                countryIndex = regionLanguageCodeList.indexOf(regionLanguage);
            }
        }

        // Suggestion should happen only once - setting in to null ensures this.
        this.countryWorldAreaSuggestion = null;

        this.countryDropdown.selectedValue = regionLanguageCodeList[countryIndex];
    }

    private determineSelectionSuggested(): WorldAreaCountryRecommendation {
        let suggestedCountryCode: string;

        try {
            suggestedCountryCode =
                this.user.authentication.country ||
                this.user.authentication.countryOfResidence ||
                null;

            suggestedCountryCode = suggestedCountryCode.toLowerCase();
        }
        catch {
            suggestedCountryCode = null;
        }

        let result: WorldAreaCountryRecommendation = {
            worldAreaIndex: 0,
            countryCode: null,
        };

        if (suggestedCountryCode == null) {
            return result;
        }

        const worldAreaCodeList = sortBy(this.codeList.codelist[ProjectCodeList.WorldArea] as WorldArea[], worldArea => worldArea.name);
        const countryWorldArea = (this.codeList.codelist[ProjectCodeList.Region] as Region[]).filter(x => x.countryCode == suggestedCountryCode)[0];

        if (countryWorldArea == null) {
            return result;
        }

        const worldArea = worldAreaCodeList.filter(x => x.id == countryWorldArea.worldAreaId)[0];
        const worldAreaIndex = worldAreaCodeList.indexOf(worldArea);

        result = {
            worldAreaIndex,
            countryCode: suggestedCountryCode
        };

        return result;
    }
}

export interface ISelectRegionLanguageConstructor {

}

@control('SelectRegionLanguage')
export class SelectRegionLanguage extends Modal<SelectRegionLanguage> {

    constructor(ctor?: ISelectRegionLanguageConstructor) {
        super({
            template,
            templateUrl: 'select-region-language-modal-template.html',
            controller: SelectRegionLanguageModalController,
            windowClass: 'default-modal select-region-language-modal',
            size: 'md' as any,
            ...ctor
        });
    }
}

class SelectRegionLanguageController extends ModalController<SelectRegionLanguage> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $uibModal: ng.ui.bootstrap.IModalService
    ) {
        super(
            SelectRegionLanguage,
            $scope,
            $element,
            $attrs,
            $uibModal
        );
    }
}

export class SelectRegionLanguageDirective extends ModalDirective {
    constructor() {
        super(
            SelectRegionLanguageController,
            SelectRegionLanguage);
    }
}
