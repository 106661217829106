import { UserLicense } from '../../src/app/generated-modules/Hilti.PE.Licensing.Entities';
import { UserLicenseFeature } from '../../src/app/generated-modules/Hilti.PE.Licensing.Enums';

export class LicenseService {
    public userLicense: UserLicense;
    public displayTrialInformation: boolean;

    public get floatingLimitReached(): boolean {
        return this.userLicense ? this.userLicense.Features.indexOf(UserLicenseFeature.NoFloatingLicensesAvailable) > -1 : false;
    }

    public initialize(data: UserLicense) {
        this.userLicense = data;
    }

    public isTrial() {
        return this.userLicense ? this.userLicense.Features.indexOf(UserLicenseFeature.Trial) > -1 : false;
    }

    public isFree() {
        const result = this.userLicense ?
            this.userLicense.Features.indexOf(UserLicenseFeature.Free) > -1 ||
            this.userLicense.Features.indexOf(UserLicenseFeature.NoLicense) > -1 ||
            this.userLicense.Features.indexOf(UserLicenseFeature.LicenseParsingFailed) > -1 :
            false;

        return result;
    }

    public isTrialExpired() {
        return this.userLicense ? this.userLicense.Features.indexOf(UserLicenseFeature.TrialExpired) > -1 : false;
    }

    public isNoLicenseHandledAsFree() {
        return this.userLicense.Features.indexOf(UserLicenseFeature.NoLicense) > -1
    }

    public isLicenseParsingFailed() {
        return this.userLicense.Features.indexOf(UserLicenseFeature.LicenseParsingFailed) > -1
    }

    public getRemainingDays() {
        if (!this.userLicense) return 0;

        const remaningMillisends = new Date(this.userLicense.ExpiresAt as any).valueOf() - new Date().valueOf();
        if (remaningMillisends <= 0) return 0;
        // how to correctly determine remaining days (wrt daylight saving changes, leap seconds, etc)
        return Math.ceil(remaningMillisends / (24 * 60 * 60 * 1000));
    }

    // Used same logic as in tracking
    public getLicense() {
        if (this.userLicense == null
            || this.userLicense.Features.length == 0
            || this.userLicense.Features.some(l => l == UserLicenseFeature.None)) {
            return 'NOLICENSE';
        }

        if (this.userLicense.Features.indexOf(UserLicenseFeature.Advnc) > -1
            || this.userLicense.Features.indexOf(UserLicenseFeature.Hrail) > -1
            || this.userLicense.Features.indexOf(UserLicenseFeature.Basic) > -1) {
            return 'PREMIUM';
        }

        if (this.userLicense.Features.indexOf(UserLicenseFeature.Trial) > -1) {
            return 'TRIAL';
        }

        return 'STANDARD';
    }
}
