import debounce from 'lodash-es/debounce';
import isEqual from 'lodash-es/isEqual';

import {
    MenuType
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Display.Enums';
import { GuidService } from '../../src/app/guid.service';
import { environment } from '../../src/environments/environment';
import { LoggerService } from '../../src/app/services/logger.service';
import { UserService } from './user-service';

const updateDelay = 500;

export interface UserSortedTabPostEntity {
    MenuId: number;
    SortedTabRegionsIds: string[];
}

export class RegionOrderService {
    public static $inject = [
        '$http',
        'user',
        '$q',
        'logger',
        'guid',
        '$rootScope',
        'favorites'
    ];

    public updating: boolean;

    private $http: ng.IHttpService;
    private user: UserService;
    private $q: ng.IQService;
    private logger: LoggerService;
    private guid: GuidService;
    private $rootScope: ng.IRootScopeService;

    private debounceSave: (order: string[], updateId: string, menu_id: number) => void;
    private setDefer: ng.IDeferred<string[]>;
    private updateId: string;

    private leftMenuProjectOrder: string[];

    constructor(
        $http: ng.IHttpService,
        user: UserService,
        $q: ng.IQService,
        logger: LoggerService,
        guid: GuidService,
        $rootScope: ng.IRootScopeService
    ) {
        this.$http = $http;
        this.user = user;
        this.$q = $q;
        this.logger = logger;
        this.guid = guid;
        this.$rootScope = $rootScope;

        this.setDefer = this.$q.defer<string[]>();

        this.debounceSave = debounce((...args: any[]) => { this.$rootScope.$apply(() => { this.saveInternal.apply(this, args); }); }, updateDelay);
    }

    private get userSettingsServicePublicUrlBase() {
        return `${environment.userSettingsWebServiceUrl}user-settings/`;
    }

    public get(menu_id: number) {
        switch (menu_id) {
            case MenuType.LeftMenuProject:
                return this.leftMenuProjectOrder;
            default:
                throw new Error('Unknown menu type.');
        }
    }

    public update(order: string[], menu_id: number) {
        order = order || [];

        // don't update if order didn't change
        if (isEqual(order, this.get(menu_id) || [])) {
            return this.$q.when(order);
        }

        this.set(order, menu_id);

        this.updating = true;
        this.updateId = this.guid.new();

        const promise = this.setDefer.promise;

        if (environment.enableMenuOrder) {
            this.debounceSave(order, this.updateId, menu_id);
        }

        return promise;
    }

    public initialize() {
        if (!environment.enableMenuOrder) {
            return null;
        }

        const url = `${this.userSettingsServicePublicUrlBase}Favorites/UserMenuOrder`;

        return this.$http.get<{ [key: number]: string[] }>(url)
            .then<void>((response) => {
                for (const key in response.data) {
                    this.set(response.data[key], parseInt(key, 10));
                }
            })
            .catch<void>((response) => {
                this.logger.logServiceError(response, 'RegionOrderService', 'initialize');

                return this.$q.reject(response);
            });
    }

    private set(order: string[], menu_id: number) {
        switch (menu_id) {
            case MenuType.LeftMenuProject:
                this.leftMenuProjectOrder = order;
                break;
        }
    }

    private saveToDatabase(order: string[], menu_id: number) {
        if (!this.user.isAuthenticated) {
            throw new Error('Unauthenticated');
        }

        const url = `${this.userSettingsServicePublicUrlBase}Favorites/UpdateUserMenuOrder`;

        const data: UserSortedTabPostEntity = {
            MenuId: menu_id,
            SortedTabRegionsIds: order
        };

        return this.$http.put<boolean>(url, data)
            .then<void>(() => {
                return undefined;
            })
            .catch<void>((response) => {
                this.logger.logServiceError(response, 'RegionOrderService', 'saveToDatabase');

                return this.$q.reject(response);
            });
    }

    private saveInternal(order: string[], updateId: string, menu_id: number) {
        this.saveToDatabase(order, menu_id)
            .then(() => {
                this.setDefer.resolve(order);
            })
            .catch((response) => {
                this.setDefer.reject(response);
            })
            .then(() => {
                // remove updating flag
                if (this.updateId == updateId) {
                    this.updating = false;
                }

                this.setDefer = this.$q.defer<string[]>();
            });
    }
}
