import padStart from 'lodash-es/padStart';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


export const enum LogType {
    debug,
    error,
    info,
    warn
}

export interface ILogMessageConstructor {
    message?: string;
    args?: any[];
}

export class LogMessage {
    public message: string;
    public args: any[];

    constructor(logMessage?: ILogMessageConstructor) {
        if (logMessage != null) {
            this.message = logMessage.message;
            this.args = logMessage.args;
        }
    }
}


@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    /**
     * Log message to browser console
     */
    public log(message: string, logType?: LogType, ...args: any[]) {
        if (!environment.isLogEnabled) {
            return;
        }

        this.print(this.formatMessage(message), logType, args);
    }

    public logServiceError<TData>(response: any, serviceName: string, requestName: string) {
        this.log(`Calling web service failed (Error::${serviceName}::${requestName}). Error message: ${response.data}`, LogType.error);
    }

    public logServiceRequest(serviceName: string, fnName: string, ...args: any[]) {
        if (!environment.isLogEnabled) {
            return;
        }

        this.logService(serviceName, 'request', fnName, ...args);
    }

    public logServiceResponse(serviceName: string, fnName: string, ...args: any[]) {
        if (!environment.isLogEnabled) {
            return;
        }

        this.logService(serviceName, 'response', fnName, ...args);
    }

    public logGroup(group: LogMessage, logs: LogMessage[], logType?: LogType, noTimestamp?: boolean) {
        const consoleGroup = (console.groupCollapsed || console.group) as (group: string, ...args: any[]) => void;
        const consoleGroupEnd = console.groupEnd;

        if (consoleGroup != null && consoleGroupEnd != null) {
            consoleGroup.apply(console, [noTimestamp ? group.message : this.formatMessage(group.message)].concat(group.args || []) as [string, ...any[]]);

            for (const log of logs) {
                this.print(log.message, logType, log.args);
            }

            consoleGroupEnd.apply(console);
        }
        else {
            this.log.apply(this, ([group.message] as any[]).concat(logType).concat(group.args || []));

            for (const log of logs) {
                this.print(`\t${log.message}`, logType, log.args);
            }
        }
    }

    public logGroupFn(group: LogMessage, fn: () => void, logType?: LogType) {
        const consoleGroup = (console.groupCollapsed || console.group) as (group: string, ...args: any[]) => void;
        const consoleGroupEnd = console.groupEnd;

        if (consoleGroup != null && consoleGroupEnd != null) {
            consoleGroup.apply(console, [this.formatMessage(group.message)].concat(group.args || []) as [string, ...any[]]);

            fn();

            consoleGroupEnd.apply(console);
        }
        else {
            this.log.apply(this, ([group.message] as any[]).concat(logType).concat(group.args || []));

            fn();
        }
    }

    protected logService(serviceName: string, type: string, fnName: string, ...args: any[]) {
        if (type == null || type == '') {
            return;
        }

        args = args || [];

        let argsFormat = args.length > 0 ? ': ' : '';
        for (const _ of args) {
            argsFormat += '%o ';
        }

        if (args.length > 0) {
            argsFormat = argsFormat.substring(0, argsFormat.length - 1);
        }

        // trim strings
        for (let i = 0; i < args.length; i++) {
            args[i] = this.trimStringToLength(args[i]);
        }

        this.log(`${serviceName}::${fnName}.${type}${argsFormat}`, LogType.debug, ...args);
    }

    private trimStringToLength(value: any, length = 100) {
        if (value != null && typeof value == 'string' && value.length > length) {
            return value.substring(0, length - 4) + ' ...';
        }

        return value;
    }

    private formatMessage(message: string) {
        const currentdate = new Date();
        const datetime = `${padStart(currentdate.getDate().toString(), 2, '0')}/${padStart((currentdate.getMonth() + 1).toString(), 2, '0')}/${currentdate.getFullYear()} @ ${padStart(currentdate.getHours().toString(), 2, '0')}:${padStart(currentdate.getMinutes().toString(), 2, '0')}:${padStart(currentdate.getSeconds().toString(), 2, '0')}`;

        return `(${datetime}): ${message}`;
    }

    private print(message: string, logType?: LogType, args?: any[]) {
        // tslint:disable: no-console
        switch (logType) {
            case LogType.debug:
                console.debug(message, ...args);
                break;

            case LogType.error:
                console.error(message, ...args);
                break;

            case LogType.warn:
                console.warn(message, ...args);
                break;

            case LogType.info:
                console.info(message, ...args);
                break;

            default:
                console.log(message, ...args);
                break;
        }
        // tslint:enable: no-console
    }
}
