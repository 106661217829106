import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalizationService } from '../services/localization.service';

@Directive({
    /* eslint @angular-eslint/directive-selector: ["off"] */
    // tslint:disable-next-line: directive-selector
    selector: '[l10n]'
})
export class L10nDirective implements OnInit, OnChanges, OnDestroy {
    @Input('l10n')
    public key: string;

    private elementRef: ElementRef;
    private localizationService: LocalizationService;
    private localizationChangeSubscription: Subscription;

    constructor(elementRef: ElementRef, localizationService: LocalizationService) {
        this.elementRef = elementRef;
        this.localizationService = localizationService;
    }

    ngOnInit(): void {
        this.localizationChangeSubscription = this.localizationService.localizationChange.subscribe(() => {
            this.renderString();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['key'] != null) {
            this.renderString();
        }
    }

    ngOnDestroy(): void {
        // unsubscribe from this.localization.subject
        if (this.localizationChangeSubscription != null) {
          this.localizationChangeSubscription.unsubscribe();
        }
    }

    private renderString() {
        const element = this.elementRef.nativeElement as HTMLElement;
        element.innerHTML = this.localizationService.getLocalizedString(this.key);
    }
}
