import angular from 'angular';
import difference from 'lodash-es/difference';
import escape from 'lodash-es/escape';
import groupBy from 'lodash-es/groupBy';

import { CodeList } from '../../../src/app/entities/codeLists/codeList';
import { LocalizationService } from '../../../src/app/services/localization.service';
import {
    control, ControlDirective, ControlProperty, property, Translation, WebControl,
    WebControlController
} from '../controls';
import { Tooltip } from '../ControlTooltip/ControlTooltip';
import { DropdownHeader } from '../DropdownHeader/DropdownHeader';
import { DropdownItem } from '../DropdownItem/DropdownItem';
import { IRegionControl, IRegionControlConstructor } from '../Region/Region';
import { RegionControlSize } from '../Region/RegionControlSize';
import template from './Dropdown.html';
import { Required } from './Validators/Required';

const keyEnter = 13;
const keyUp = 38;
const keyDown = 40;
const keyTab = 9;
const keySpace = 32;

export enum DropdownType {
    inline,
    block
}

export interface IDropdownConstructor<TValue extends Object> extends IRegionControlConstructor {
    items?: (DropdownItem<TValue> | DropdownHeader)[] | ControlProperty;
    selectedValue?: TValue | ControlProperty;
    notSelectedText?: string | ControlProperty;
    notSelectedImage?: string | ControlProperty;
    dropdownType?: DropdownType | ControlProperty;
    required?: boolean | ControlProperty;
}

@control('Dropdown')
export class Dropdown<TValue extends Object> extends WebControl implements IRegionControl {

    @property()
    public title: string;

    @property()
    public footNotes: string;

    @property()
    public size: RegionControlSize;

    @property()
    public tooltip: Tooltip;

    @property()
    public items: (DropdownItem<TValue> | DropdownHeader)[];

    @property()
    public selectedValue: TValue;

    @property()
    public notSelectedText: string;

    @property()
    public notSelectedImage: string;

    @property()
    public dropdownType: DropdownType;

    @property()
    public required: boolean;

    @property()
    public maxHeight: string;

    @property()
    public tag: string;
    constructor(dropdown?: IDropdownConstructor<TValue>) {
        super(dropdown);

        this.dropdownType = this.dropdownType != null ? this.dropdownType : DropdownType.block;
    }

    public static fromCodeList(codeList: CodeList[] | ng.IPromise<CodeList[]>, dropdown?: IDropdownConstructor<number>, hideImage?: boolean) {
        const control = new Dropdown(dropdown);

        if (codeList != null) {
            if (Array.isArray(codeList)) {
                control.setItemsFromCodeList(codeList as CodeList[], hideImage);
            }
            else {
                (codeList as ng.IPromise<CodeList[]>).then((codeList) => {
                    control.setItemsFromCodeList(codeList, hideImage);
                });
            }
        }

        return control;
    }

    public setItemsFromCodeList(codeList: CodeList[], hideImage?: boolean) {
        this.items = [];

        const groupedCodeList = groupBy(codeList, 'groupResourceKey');

        for (const key in groupedCodeList || []) {
            const groupResourceKey: string = key;
            const codeListItems = groupedCodeList[groupResourceKey];

            // header
            if (groupResourceKey != null && groupResourceKey != '' && groupResourceKey != 'undefined') {
                this.items.push(new DropdownHeader({
                    text: new Translation(groupResourceKey)
                }));
            }

            // items
            for (const codeListItem of codeListItems) {
                this.items.push(new DropdownItem<number>({
                    id: `${this.id}-${codeListItem.id}`,
                    value: codeListItem.id,
                    text: codeListItem.nameResourceKey,
                    image: hideImage == true ? null : codeListItem.image
                }));
            }
        }
    }
}

export class DropdownDirective extends ControlDirective {
    constructor() {
        super(DropdownController, Dropdown, template);

        this.transclude = true;
    }
}

export class DropdownController<TValue extends Object> extends WebControlController<Dropdown<TValue>> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse',
        'localization'
    ];

    public title: string;
    public footNotes: string;
    public size: RegionControlSize;
    public tooltip: Tooltip;
    public items: (DropdownItem<TValue> | DropdownHeader)[];
    public selectedValue: TValue | ((value?: TValue) => TValue);
    public notSelectedText: string;
    public notSelectedImage: string;
    public dropdownType: DropdownType;
    public required: boolean;
    public maxHeight: string;

    public opend: boolean;
    private keyDownFlag: boolean;

    private itemWatches: Function[] = [];
    private controlContainerElement: HTMLElement;
    private buttonElement: HTMLElement;
    private dropdownItemsElement: HTMLElement;
    private onDocumentClickFn: (...args: any[]) => void;
    private itemsInitialized: boolean;

    private disposed: boolean;
    private searchText: string;
    private searchTimeout: number;

    constructor(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $compile: ng.ICompileService,
        $parse: ng.IParseService,
        private localization: LocalizationService
    ) {
        super(Dropdown, $scope, $element, $attrs, $compile, $parse);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.keyDownFlag = false;
            this.dropdownType = this.dropdownType != null ? this.dropdownType : DropdownType.block;

            // constants
            this.$scope['RegionControlSize'] = RegionControlSize;
            this.$scope['DropdownType'] = DropdownType;

            const element = $element[0];
            this.controlContainerElement = element.querySelector('.control-container');
            this.buttonElement = element.querySelector('.dropdown-button');
            this.dropdownItemsElement = element.querySelector('.dropdown-items');

            // DOM events
            angular.element(document).on('click', this.onDocumentClickFn = this.onDocumentClick.bind(this));

            // items watch
            this.$scope.$watchCollection<DropdownItem<TValue>[]>('ctrl.items', (items, oldItems) => {
                const init = items === oldItems;
                const addedItems = init ? items || [] : difference(items, oldItems) || [];
                const removedItems = init ? [] : difference(oldItems, items) || [];

                // remove item watches
                for (const itemWatch of this.itemWatches) {
                    itemWatch();
                }

                this.itemWatches = [];

                // watch items selected property
                for (const item of this.items || []) {
                    // set item $scope
                    if (item.$scope == null) {
                        item.$scope = this.control.$scope;
                    }

                    // watch for selected change
                    if (item instanceof DropdownItem) {
                        this.itemWatches.push(this.$scope.$watch<boolean>(() => (item as DropdownItem<TValue>).selected, (selected, oldSelected) => {
                            if (this.items.some((i) => i === item) && selected !== oldSelected && !this.opend && selected) {
                                this.SelectedValue = (item as DropdownItem<TValue>).value;
                            }
                        }));
                    }
                }

                // hide dropdown on item click
                const itemClick = (value: TValue) => {
                    this.SelectedValue = value;

                    this.opend = false;
                    this.buttonElement.focus();
                };

                // remove item events
                for (const removedItem of removedItems) {
                    removedItem.off(DropdownItem.click, itemClick);
                }

                // add item events
                for (const addedItem of addedItems) {
                    addedItem.on(DropdownItem.click, itemClick);
                }

                this.setItemsSelectedProperty(this.SelectedValue);
            });

            // sync select value change to all items
            this.$scope.$watch('ctrl.SelectedValue', () => {
                this.setItemsSelectedProperty(this.SelectedValue);
            });

            // on opend
            this.$scope.$watch('ctrl.opend', (opend, oldOpend) => {
                this.setItemsSelectedProperty(this.SelectedValue);

                if (opend) {
                    const openAndScroll = () => {
                        this.dropdownItemsElement.classList.add('opend');
                        this.scrollIntoView(this.selectedItem);
                    };

                    // initialize items on open
                    if (!this.itemsInitialized) {
                        this.itemsInitialized = true;
                        this.bindControls(this.element.querySelector('.dropdown-items'), 'ctrl.items');

                        this.$timeout(openAndScroll);
                    }
                    else {
                        openAndScroll();
                    }

                    this.searchText = '';
                }
                else {
                    this.dropdownItemsElement.classList.remove('opend');

                    if (this.searchTimeout != null) {
                        window.clearTimeout(this.searchTimeout);
                        this.searchTimeout = null;
                    }
                }

                if (opend !== oldOpend && !opend) {
                    this.ngModel.$setTouched();
                }
            });

            // required validator
            this.validators['required'] = new Required<TValue>();

            // dispose event
            this.onDisposed(this.dispose.bind(this));

            // destroy event
            this.$scope.$on('$destroy', this.destroy.bind(this));
        })(this.$onInit);
    }

    public get SelectedValue() {
        return typeof this.selectedValue == 'function' ? (this.selectedValue as Function)() : this.selectedValue;
    }

    public set SelectedValue(val: TValue) {
        if (typeof this.selectedValue == 'function') {
            (this.selectedValue as Function)(val);
        }
        else {
            this.selectedValue = val;
        }
    }

    public get selectedItem() {
        return this.items?.find((item) => item instanceof DropdownItem && item.value == this.SelectedValue) as DropdownItem<TValue>;
    }

    public get image() {
        return this.selectedItem != null ? this.selectedItem.image : this.notSelectedImage;
    }

    public get text() {
        let text: string = null;

        if (this.selectedItem != null) {
            text = this.selectedItem.text;
        }
        else if (this.notSelectedText != null) {
            text = this.notSelectedText;
        }
        else {
            text = this.localization.getLocalizedString('Agito.Hilti.Purchaser.Dropdown.NoneSelected');
        }

        // one space
        if (text == null || text.trim() == '') {
            text = '&nbsp;';
        }
        else {
            text = escape(text);
        }

        return text;
    }

    public get isOpen() {
        return this.opend;
    }

    public onKeyDown(event: JQueryEventObject) {
        if (this.opend) {
            const eventCode = event.which || event.keyCode || event.charCode;
            if (eventCode == keyEnter || eventCode == keyTab || eventCode == keySpace) {
                // event.preventDefault();
                this.keyDownFlag = true;

                // set selected value
                const selectedItem = this.items.find((item) => item instanceof DropdownItem && item.selected) as DropdownItem<TValue>;
                if (selectedItem != null) {
                    this.SelectedValue = selectedItem.value;
                }

                this.opend = false;
                this.buttonElement.focus();
            }
            else if (eventCode == keyDown) {
                event.preventDefault();

                const dropdownItems = this.items.filter((item) => item instanceof DropdownItem) as DropdownItem<TValue>[];
                const selectedIndex = dropdownItems.findIndex((item) => item.selected);

                if (selectedIndex != -1) {
                    if (selectedIndex < dropdownItems.length - 1) {
                        const item = dropdownItems[selectedIndex];
                        const nextItem = dropdownItems[selectedIndex + 1];

                        item.selected = false;
                        nextItem.selected = true;

                        this.scrollIntoView(nextItem);
                    }
                }
                // select first if none is selected
                else if (dropdownItems != null && dropdownItems.length > 0) {
                    dropdownItems[0].selected = true;
                    this.scrollIntoView(dropdownItems[0]);
                }
            }
            else if (eventCode == keyUp) {
                event.preventDefault();

                const dropdownItems = this.items.filter((item) => item instanceof DropdownItem) as DropdownItem<TValue>[];
                const selectedIndex = dropdownItems.findIndex((item) => item.selected);

                if (selectedIndex != -1) {
                    if (selectedIndex > 0) {
                        const item = dropdownItems[selectedIndex];
                        const previousItem = dropdownItems[selectedIndex - 1];

                        item.selected = false;
                        previousItem.selected = true;

                        this.scrollIntoView(previousItem);
                    }
                }
                // select first if none is selected
                else if (dropdownItems != null && dropdownItems.length > 0) {
                    dropdownItems[0].selected = true;
                    this.scrollIntoView(dropdownItems[0]);
                }
            }
        }
    }

    public onKeyPress(event: JQueryEventObject) {
        if (this.opend) {
            event.preventDefault();
            const eventCode = event.which || event.keyCode || event.charCode;

            if (this.searchTimeout != null) {
                window.clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = window.setTimeout(() => {
                this.searchTimeout = null;
                this.searchText = '';
            }, 1000);

            this.searchText += String.fromCharCode(eventCode);

            const dropdownItems = this.items.filter((item) => item instanceof DropdownItem) as DropdownItem<TValue>[];
            const itemToSelect = dropdownItems.find((item) => item.text.substr(0, this.searchText.length).toLowerCase() == this.searchText.toLowerCase());

            if (itemToSelect != null) {
                const itemToDeselect = dropdownItems.find((item) => item.selected);

                if (itemToDeselect != null) {
                    itemToDeselect.selected = false;
                }

                itemToSelect.selected = true;
                this.scrollIntoView(itemToSelect);
            }
        }
    }

    public onClick() {
        if (this.keyDownFlag) {
            this.keyDownFlag = false;
        }
        else {
            if (this.items != null && this.items.length > 0) {
                this.opend = !this.opend;
            }
        }
    }

    public onDocumentClick(event: JQueryEventObject) {
        const target = event.target as HTMLElement;

        if (!this.controlContainerElement.contains(target) && this.opend) {
            this.$scope.$apply(() => {
                this.opend = false;
            });
        }
    }

    public focus() {
        this.buttonElement.focus();
    }

    private setItemsSelectedProperty(value: TValue) {
        if (this.items != null) {
            for (const item of this.items) {
                if (item instanceof DropdownItem) {
                    item.selected = value == item.value;
                }
            }
        }
    }

    private scrollIntoView(item: DropdownItem<TValue>) {
        const offset = 2;

        const dropdownItems = this.items.filter((item) => item instanceof DropdownItem) as DropdownItem<TValue>[];
        const selectedIndex = dropdownItems.findIndex(i => i === item);

        if (selectedIndex != -1) {
            const selectedElement = this.dropdownItemsElement.querySelectorAll('.dropdown-item')[selectedIndex] as HTMLElement;
            const dropdownFullHeight = this.dropdownItemsElement.scrollHeight;
            const dropdownHeight = this.dropdownItemsElement.offsetHeight;

            // we have a scrollbar
            if (dropdownFullHeight > dropdownHeight) {
                const selectedElementHeight = selectedElement.offsetHeight;
                const dropdownTop = this.dropdownItemsElement.scrollTop;
                const dropdownBottom = this.dropdownItemsElement.scrollTop + dropdownHeight;

                // missing top
                if (selectedElement.offsetTop < dropdownTop) {
                    this.dropdownItemsElement.scrollTop = selectedElement.offsetTop - offset;
                }
                // missing bottom
                else if (selectedElement.offsetTop + selectedElementHeight + offset * 2 > dropdownBottom) {
                    this.dropdownItemsElement.scrollTop = selectedElement.offsetTop - dropdownHeight + selectedElementHeight + offset * 2;
                }
            }
        }
    }

    private dispose() {
        if (!this.disposed) {
            this.disposed = true;

            for (const item of this.items || []) {
                item.dispose();
            }

            this.$scope.$destroy();
        }
    }

    private destroy() {
        angular.element(document).off('click', this.onDocumentClickFn);

        if (this.searchTimeout != null) {
            window.clearTimeout(this.searchTimeout);
            this.searchTimeout = null;
        }
    }
}
