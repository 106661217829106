import 'angular';
import 'angular-vs-repeat';

import ngSortable from 'agito.ng-sortable';
import angular from 'angular';
import ngRoute from 'angular-route';
import ngSanitize from 'angular-sanitize';
import uiBootstrapButtons from 'angular-ui-bootstrap/src/buttons';
import uiBootstrapDropdown from 'angular-ui-bootstrap/src/dropdown/index-nocss';
import uiBootstrapModal from 'angular-ui-bootstrap/src/modal/index-nocss';
import uiBootstrapTooltip from 'angular-ui-bootstrap/src/tooltip/index-nocss';
import getPkce from 'oauth-pkce';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import {
    CheckboxButtonComponent
} from '../src/app/components/checkbox-button/checkbox-button.component';
import { CollapseComponent } from '../src/app/components/collapse/collapse.component';
import {
    ContentTooltipComponent
} from '../src/app/components/content-tooltip/content-tooltip.component';
import { ControlTitleComponent } from '../src/app/components/control-title/control-title.component';
import { DropdownComponent } from '../src/app/components/dropdown/dropdown.component';
import { LabelComponent } from '../src/app/components/label/label.component';
import { LoadingComponent } from '../src/app/components/loading/loading.component';
import {
    NumericTextBoxComponent
} from '../src/app/components/numeric-text-box/numeric-text-box.component';
import { RadioButtonComponent } from '../src/app/components/radio-button/radio-button.component';
import { SectionComponent } from '../src/app/components/section/section.component';
import { TextBoxComponent } from '../src/app/components/text-box/text-box.component';
import { VersionComponent } from '../src/app/components/version/version.component';
import { GuidService } from '../src/app/guid.service';
import {
    controllerName, directiveName, moduleName, serviceName, storageKey, templateName, urlPath
} from '../src/app/ModuleConstants';
import { ApiService } from '../src/app/services/api.service';
import { AppConfigService } from '../src/app/services/app-config.service';
import { AppStorageService } from '../src/app/services/app-storage.service';
import { ApplicationVersionsService } from '../src/app/services/application-versions.service';
import { AuthService } from '../src/app/services/auth.service';
import { ChangesService } from '../src/app/services/changes.service';
import { ClipboardService } from '../src/app/services/clipboard.service';
import { CodeListService } from '../src/app/services/code-list.service';
import { ComponentProviderService } from '../src/app/services/component-provider.service';
import { DateTimeService } from '../src/app/services/date-time.service';
import { ErrorHandlerService } from '../src/app/services/error-handler.service';
import { FeatureVisibilityService } from '../src/app/services/feature-visibility.service';
import { LaunchDarklyService } from '../src/app/services/launch-darkly.service';
import { LocalizationService } from '../src/app/services/localization.service';
import { LoggerService, LogType } from '../src/app/services/logger.service';
import { SessionStorageService } from '../src/app/services/session-storage.service';
import { VersionDetailsService } from '../src/app/services/version-details.service';
import { ViewService } from '../src/app/services/view.service';
import { generateState } from '../src/app/StateGenerator';
import { environment } from '../src/environments/environment';
import bomTemplate from '../src/templates/bom.html';
import headerTemplate from '../src/templates/header.html';
import leftNavigationTemplate from '../src/templates/left-navigation.html';
import projectAndDesignTemplate from '../src/templates/project-and-design.html';
import versionTemplate from '../src/templates/version.html';
import { BomController } from './Controllers/bom-controller';
import { ProjectAndDesignController } from './Controllers/project-and-design-controller';
import { IRootControllerScope, RootController } from './Controllers/root-controller';
import {
    AddNewAnchorDirective as ControlAddNewAnchorDirective
} from './Controls/AddNewAnchor/AddNewAnchor';
import { AlertDirective as ControlAlertDirective } from './Controls/Alert/Alert';
import {
    ApplicationSettingsDirective as ControlApplicationSettingsDirective
} from './Controls/ApplicationSettings/ApplicationSettings';
import { ArchiveDirective as ControlArchiveDirective } from './Controls/Archive/Archive';
import {
    BomDetailsDirective as ControlBomDetailsDirective
} from './Controls/BomDetails/BomDetails';
import { ButtonDirective as ControlButtonDirective } from './Controls/Button/Button';
import { CheckboxDirective as ControlCheckboxDirective } from './Controls/Checkbox/Checkbox';
import {
    ConceptualPictureDirective as ControlConceptualPictureDirective
} from './Controls/ConceptualPicture/ConceptualPicture';
import {
    ConfirmChangeDirective as ControlConfirmChangeDirective
} from './Controls/ConfirmChange/ConfirmChange';
import {
    ConnectionsGridDirective as ControlConnectionsGridDirective
} from './Controls/ConnectionsGrid/ConnectionsGrid';
import {
    ContactHiltiDirective as ControlContactHiltiDirective
} from './Controls/ContactHilti/ContactHilti';
import { ControlTooltipDirective } from './Controls/ControlTooltip/ControlTooltip';
import ControlTooltipTemplate from './Controls/ControlTooltip/ControlTooltip.html';
import {
    CreateNewBomDirective as ControlCreateNewBomDirective
} from './Controls/CreateNewBom/CreateNewBom';
import {
    DesignFileInfoDirective as ControlDesignFileInfoDirective
} from './Controls/DesignFileInfo/DesignFileInfo';
import { DropdownDirective as ControlDropdownDirective } from './Controls/Dropdown/Dropdown';
import {
    DropdownHeaderDirective as ControlDropdownHeaderDirective
} from './Controls/DropdownHeader/DropdownHeader';
import {
    DropdownItemDirective as ControlDropdownItemDirective
} from './Controls/DropdownItem/DropdownItem';
import { GridBomDirective as ControlGridBomDirective } from './Controls/GridBom/GridBom';
import {
    GridBomDetailsDirective as ControlGridBomDetailsDirective
} from './Controls/GridBomDetails/GridBomDetails';
import { GroupDirective as ControlGroupDirective } from './Controls/Group/Group';
import {
    ButtonDirective as ControlHiltiStyledButtonDirective
} from './Controls/HiltiStyled/Button/Button';
import {
    LicenseComparisonDirective as ControlHiltiStyledLicenseComparisonDirective
} from './Controls/HiltiStyled/LicenseComparison/LicenseComparison';
import {
    LicenseComparisonTableDirective as ControlHiltiStyledLicenseComparisonTableDirective
} from './Controls/HiltiStyled/LicenseComparisonTable/LicenseComparisonTable';
import {
    TrialBannerDirective as ControlHiltiStyledTrialBannerDirective
} from './Controls/HiltiStyled/TrialBanner/TrialBanner';
import { LoadingDirective as ControlLoadingDirective } from './Controls/Loading/Loading';
import { ModalDirective as ControlModalDirective } from './Controls/Modal/Modal';
import { PopupDirective as ControlPopupDirective } from './Controls/Popup/Popup';
import {
    RadioButtonDirective as ControlRadioButtonDirective
} from './Controls/RadioButton/RadioButton';
import { RegionDirective as ControlRegionDirective } from './Controls/Region/Region';
import {
    SelectRegionLanguageDirective as ControlSelectRegionLanguageDirective
} from './Controls/SelectRegionLanguage/SelectRegionLanguage';
import {
    ShareProjectDirective as ControlShareProjectDirective
} from './Controls/ShareProject/ShareProject';
import {
    ShortcutIconPopupDirective as ControlShortcutIconPopupDirective
} from './Controls/ShortcutIconPopup/ShortcutIconPopup';
import { SupportDirective as ControlSupportDirective } from './Controls/Support/Support';
import { TextBoxDirective as ControlTextBoxDirective } from './Controls/TextBox/TextBox';
import {
    UnauthorizedAccessDirective as ControlUnauthorizedAccessDirective
} from './Controls/UnauthorizedAccess/UnauthorizedAccess';
import {
    UserAgreementDirective as ControlUserAgreementDirective
} from './Controls/UserAgreement/UserAgreement';
import {
    UserAgreementPrivacyDirective as ControlUserAgreementPrivacyDirective
} from './Controls/UserAgreementPrivacy/UserAgreementPrivacy';
import {
    UserAgreementSettingsDirective as ControlUserAgreementSettingsDirective
} from './Controls/UserAgreementSettings/UserAgreementSettings';
import {
    UserSettingsDirective as ControlUserSettingsDirective
} from './Controls/UserSettings/UserSettings';
import { ChangeDirective } from './Directives/change';
import { CheckboxDirective, CheckboxDisabledDirective } from './Directives/checkbox';
import { FocusOutDirective } from './Directives/focusOut';
import { i18nDirective } from './Directives/i18n';
import { RadioDirective, RadioDisabledDirective } from './Directives/radio';
import { StepperDecrementDirective, StepperIncrementDirective } from './Directives/stepper';
import { TextSanitizeDirective } from './Directives/textSanitize';
import { UpdateOnEnter } from './Directives/updateOnEnter';
import { AuthenticationService } from './Services/authentication-service';
import { BomService } from './Services/bom-service';
import { BrowserService } from './Services/browser-service';
import { DataService } from './Services/data-service';
import { DocumentService } from './Services/document-service';
import { FavoritesService } from './Services/favorites-service';
import { HttpInterceptor } from './Services/http-interceptor-service';
import { IdService } from './Services/id-service';
import { Ignore } from './Services/ignore';
import { ImportService } from './Services/import-service';
import { LicenseService } from './Services/license-service';
import { LoadingService } from './Services/loading-service';
import { ModalService } from './Services/modal-service';
import { NumberService } from './Services/number-service';
import { ProductInformationService } from './Services/product-information.service';
import { PromiseService } from './Services/promise-service';
import { RegionOrderService } from './Services/region-order-service';
import { TrackingService } from './Services/tracking-service';
import { UnitService } from './Services/unit-service';
import { UserService } from './Services/user-service';
import { UserSettingsService } from './Services/user-settings-service';

interface IRouteChangeRejection {
    redirectTo: string;
    redirectToExternal: string;
    params?: any;
    logoff?: boolean;
}

interface IRouteChangeLocation {
    loadedTemplateUrl: string;
}

const authenticationResolver = [
    '$q',
    'user',
    '$location',
    'localization',
    'sessionStorage',
    '$route', (
        $q: ng.IQService,
        user: UserService,
        $location: ng.ILocationService,
        localization: LocalizationService,
        sessionStorage: SessionStorageService,
        $route: ng.route.IRouteService
    ) => {
        user.storeRouteParameters($route.current.params);

        // if user is not authenticated redirect to login view
        if (!user.isAuthenticated) {
            if (environment.authentication == 'local') {
                const redirectUrl = new URL(window.location.origin + urlPath.authenticationCallback);
                redirectUrl.searchParams.append('code', 'local');
                redirectUrl.searchParams.append('state', storageKey.loginState);
                window.location.href = redirectUrl.toString();
            }
            else {
                const defer = $q.defer<never>();

                if (sessionStorage.get(storageKey.loginState) == null || sessionStorage.get(storageKey.loginState) == '') {
                    sessionStorage.set(storageKey.loginState, generateState());
                }

                const buildUrl = async () => {
                    const getPkceAsync = async () => {
                        return new Promise<{verifier: string, challenge: string}>((resolve) => {
                            getPkce(43, (error, { verifier, challenge }) => {
                              if (error) {
                                  console.error(error.message);
                                  throw error;
                              }
                              resolve({ verifier, challenge });
                            });
                          });
                    };

                    const loginUrl = new URL(environment.externalAuthenticationUrl + environment.externalAuthorize);
                    loginUrl.searchParams.append('client_id', environment.externalClientId);
                    loginUrl.searchParams.append('redirect_uri', window.location.origin + urlPath.authenticationCallback);
                    loginUrl.searchParams.append('response_type', 'code');
                    loginUrl.searchParams.append('scope', 'HC.Request.AllScopes');
                    loginUrl.searchParams.append('state', sessionStorage.get(storageKey.loginState));

                    const language = getLanguage(localization.selectedLanguage ?? navigator.language);
                    loginUrl.searchParams.append('lang', language);

                    const countryCode = getCountryCode($location.search());
                    if (countryCode) {
                        loginUrl.searchParams.append('country', countryCode.toUpperCase());
                    }

                    if (environment.authentication == 'oauth2') {
                        const { verifier, challenge } = await getPkceAsync();
                        sessionStorage.set('code_verifier', verifier);
                        loginUrl.searchParams.append('code_challenge', challenge);
                        loginUrl.searchParams.append('code_challenge_method', 'S256');
                    }

                    return loginUrl.toString();
                };

                const getLanguage = (locale: string) => {
                    if (environment.externalLanguage) {
                        return environment.externalLanguage;
                    }

                    if (
                        locale == null
                        || locale == ''
                        || /[\d_]/g.test(locale)
                        || (locale.match(/-/g) || []).length > 1
                    ) {
                        return environment.defaultLanguage;
                    }

                    return locale;
                };

                const getCountryCode = (parameters: any): string => {
                    // Country can be set in environment
                    if (environment.externalCountry) {
                        return environment.externalCountry;
                    }

                    // Country can be forced by adding it to the url https://profisengineering.hilti.com/?country=en
                    // this is used by Hilti when they link to PE from other sites
                    if (parameters.country) {
                        return parameters.country;
                    }

                    // Do not set country
                    return null;
                };

                buildUrl().then(loginUrl => {
                    const reject: IRouteChangeRejection = {
                        redirectTo: null,
                        redirectToExternal: loginUrl
                    };
                    defer.reject(reject);
                });

                return defer.promise;
            }

        }

        return $q.when();
    }];

// Drop document id from url.
const designIdPathResolver = ['$q', '$route', ($q: ng.IQService, $route: ng.route.IRouteService) => {
    if ($route.current.params.guid != null) {
        return $q.reject({
            redirectTo: urlPath.projectAndDesign,
            redirectToExternal: null
        } as IRouteChangeRejection);
    }
    else {
        return $q.when();
    }
}];

const initialDataResolver = ['data', 'user', (data: DataService, user: UserService) => {
    if (!user.isAuthenticated) {
        throw new Error('Unauthenticated');
    }
    return data.loadInitialData();
}];

const orderedResolvers = (...resolvers: any[]) => {
    if (resolvers == null || resolvers.length == 0) {
        throw new Error('No resolvers.');
    }

    return {
        ordered: ['$injector', ($injector: ng.auto.IInjectorService) => {
            const invoke = (resolver: any) => $injector.invoke(resolver) as ng.IPromise<void>;

            let promise = invoke(resolvers[0]);
            for (const resolver of resolvers.slice(1)) {
                promise = promise.then(() => invoke(resolver));
            }

            return promise;
        }]
    };
};

const logoutResolver = [
    '$q',
    '$location',
    'localization',
    'authentication',
    'user',
    'modal', (
        $q: ng.IQService,
        $location: ng.ILocationService,
        localization: LocalizationService,
        authentication: AuthenticationService,
        user: UserService,
        modal: ModalService
    ) => {
        const search = $location.search();

        let redirectToUrl: string = null;
        let redirectToExternalUrl: string = null;
        let params = null;
        let logoff = false;

        if (search.type != null && search.type == 'invalidate') {
            // logout: internal
            const accessToken = user.authentication.accessToken;

            let language = (localization.selectedLanguage != undefined && localization.selectedLanguage != '')
                ? localization.selectedLanguage
                : environment.defaultLanguage;

            if (environment.externalLanguage) {
                language = environment.externalLanguage;
            }

            redirectToExternalUrl = `${environment.externalAuthenticationUrl}${environment.externalLogout}`;
            params = {
                client_id: environment.externalClientId,
                lang: language,
                access_token: accessToken
            };

            if (environment.authentication == 'oauth1') {
                (params as any).redirect_uri = `${window.location.origin}${urlPath.logout}`;
            }
            else if (environment.authentication == 'oauth2') {
                (params as any).logout_uri = `${window.location.origin}${urlPath.logout}`;
            }

            logoff = true;
        }
        else {
            // logout: redirected by SSO service

            // invalidate user
            authentication.logout();
            // redirect to project and design path, which will redirect back to login page
            redirectToUrl = urlPath.projectAndDesign;

            $location.search('');
        }

        const promise = $q.defer<void>();
        const reject: IRouteChangeRejection = {
            redirectTo: redirectToUrl,
            redirectToExternal: redirectToExternalUrl,
            params,
            logoff
        };

        if (!logoff) {
            // Reject immediatelly
            promise.reject(reject);
        }
        else {
            // Notify about user logging out, reject promise afterwards
            authentication.loggingOut()
                .catch(_ => { })
                .finally(() => {
                    promise.reject(reject);
                });
        }

        return promise.promise;
    }];

/**
 * Initialize angular module, controllers, directives, factories and services
 */
angular
    // module
    .module(moduleName.main, [
        ngRoute,
        ngSanitize,
        uiBootstrapTooltip,
        uiBootstrapModal,
        uiBootstrapButtons,
        uiBootstrapDropdown,
        ngSortable,
        'vs-repeat'])
    .config([
        '$httpProvider',
        '$routeProvider',
        '$locationProvider',
        '$uibTooltipProvider',
        '$compileProvider',
        '$qProvider', (
            $httpProvider: ng.IHttpProvider,
            $routeProvider: ng.route.IRouteProvider,
            $locationProvider: ng.ILocationProvider,
            $uibTooltipProvider: ng.ui.bootstrap.ITooltipProvider,
            $compileProvider: ng.ICompileProvider,
            $qProvider: ng.IQProvider
        ) => {
            // set html5 mode (to remove # from url)
            $locationProvider.html5Mode(true);
            // clear default content type from header
            delete $httpProvider.defaults.headers.common['Content-Type'];
            // Remove the header used to identify ajax call  that would prevent CORS from working
            delete $httpProvider.defaults.headers.common['X-Requested-With'];
            // define the header content type
            $httpProvider.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
            // initialize http interceptor service
            $httpProvider.interceptors.push(HttpInterceptor.Factory);

            //
            // routes
            //
            $routeProvider.when(urlPath.projectAndDesignPathParams, {
                controller: controllerName.projectAndDesign,
                templateUrl: templateName.projectAndDesign,
                controllerAs: 'ctrl',
                resolve: orderedResolvers(authenticationResolver, designIdPathResolver, initialDataResolver)
            });

            $routeProvider.when(urlPath.bom, {
                controller: controllerName.bom,
                templateUrl: templateName.bom,
                controllerAs: 'ctrl',
                resolve: orderedResolvers(authenticationResolver, initialDataResolver)
            });

            $routeProvider.when('/version', {
                templateUrl: templateName.version,
                resolve: orderedResolvers(authenticationResolver, initialDataResolver)
            });

            $routeProvider.when(urlPath.authenticationCallback, {
                controller: () => { },
                resolve: {
                    accessTokenResolver: [
                        '$q',
                        'user',
                        '$location',
                        'authentication',
                        'data',
                        'logger',
                        'tracking',
                        'sessionStorage', (
                            $q: ng.IQService,
                            user: UserService,
                            $location: ng.ILocationService,
                            authentication: AuthenticationService,
                            data: DataService,
                            logger: LoggerService,
                            tracking: TrackingService,
                            sessionStorage: SessionStorageService
                        ) => {
                            if (user.isAuthenticated) {
                                const reject: IRouteChangeRejection = {
                                    redirectTo: urlPath.projectAndDesign,
                                    redirectToExternal: null
                                };

                                return $q.reject(reject);
                            }

                            if (environment.authentication == 'oauth2') {
                                if (sessionStorage.get<string>('code_verifier') == null) {
                                    // code_verifier can be null in rare cases, one example is when user clicks on login url in registration email (because login url redirect directly to callback, without going to our first page).
                                    // In that case we have to restart the whole login procedure to generate new code_verifier.

                                    user.invalidateAuthentication();

                                    const reject: IRouteChangeRejection = {
                                        redirectTo: urlPath.projectAndDesign,
                                        redirectToExternal: null
                                    };

                                    return $q.reject(reject);
                                }
                            }

                            const code = $location.search().code;

                            if (code == null) {
                                logger.log('Missing authentication code!', LogType.error);
                                data.forceHideLoading = true;
                                return $q.when();
                            }

                            return authentication.getToken(code)
                                .then((response) => authentication.ensureLicense(response))
                                .then((response) => {
                                    if (authentication.login(response)) {
                                        return data.loadInitialData()
                                            .then(() => {
                                                tracking.trackOnAppOpened();

                                                const reject: IRouteChangeRejection = {
                                                    redirectTo: urlPath.projectAndDesign,
                                                    redirectToExternal: null
                                                };

                                                return $q.reject(reject);
                                            });
                                    }

                                    logger.log('Authentication failed!', LogType.error);
                                    data.forceHideLoading = true;
                                    return $q.when();
                                });
                        }]
                }
            });

            $routeProvider.when(urlPath.logout, {
                resolve: {
                    logoutResolver
                }
            });

            $routeProvider.otherwise({
                redirectTo: urlPath.projectAndDesign
            });

            $routeProvider.when(urlPath.unauthenticated, {
                resolve: {
                    unauthenticatedResolver: ['$q', ($q: ng.IQService) => {
                        const reject: IRouteChangeRejection = {
                            redirectTo: urlPath.projectAndDesign,
                            redirectToExternal: null
                        };

                        return $q.reject(reject);
                    }]
                }
            });

            // tooltip config
            $uibTooltipProvider.options({
                animation: false,
                appendToBody: true,
                popupDelay: 500
            });
        }])

    // controllers
    .controller(controllerName.root, RootController)
    .controller(controllerName.projectAndDesign, ProjectAndDesignController)
    .controller(controllerName.bom, BomController)

    // controls
    .directive('agtButton', [() => new ControlButtonDirective()])
    .directive('agtCheckbox', [() => new ControlCheckboxDirective()])
    .directive('agtRadioButton', [() => new ControlRadioButtonDirective()])
    .directive('agtRegion', [() => new ControlRegionDirective()])
    .directive('agtTextBox', [() => new ControlTextBoxDirective()])
    .directive('agtDropdown', [() => new ControlDropdownDirective()])
    .directive('agtDropdownItem', [() => new ControlDropdownItemDirective()])
    .directive('agtDropdownHeader', [() => new ControlDropdownHeaderDirective()])
    .directive('agtLoading', [() => new ControlLoadingDirective()])
    .directive('agtModal', [() => new ControlModalDirective()])
    .directive('agtPopup', [() => new ControlPopupDirective()])
    .directive('agtAlert', [() => new ControlAlertDirective()])
    .directive('agtGroup', [() => new ControlGroupDirective()])
    .directive('agtConfirmChange', [() => new ControlConfirmChangeDirective()])
    .directive('agtCreateNewBom', [() => new ControlCreateNewBomDirective()])
    .directive('agtGridBom', [() => new ControlGridBomDirective()])
    .directive('agtApplicationSettings', [() => new ControlApplicationSettingsDirective()])
    .directive('agtDesignFileInfo', [() => new ControlDesignFileInfoDirective()])
    .directive('agtBomDetails', [() => new ControlBomDetailsDirective()])
    .directive('agtGridBomDetails', [() => new ControlGridBomDetailsDirective()])
    .directive('agtUserSettings', [() => new ControlUserSettingsDirective()])
    .directive('agtAddNewAnchor', [() => new ControlAddNewAnchorDirective()])
    .directive('agtConceptualPicture', [() => new ControlConceptualPictureDirective()])
    .directive('agtUserAgreement', [() => new ControlUserAgreementDirective()])
    .directive('agtUserAgreementPrivacy', [() => new ControlUserAgreementPrivacyDirective()])
    .directive('agtUserAgreementSettings', [() => new ControlUserAgreementSettingsDirective()])
    .directive('agtSelectRegionLanguage', [() => new ControlSelectRegionLanguageDirective()])
    .directive('agtConnectionsGrid', [() => new ControlConnectionsGridDirective()])
    .directive('agtArchive', [() => new ControlArchiveDirective()])
    .directive('agtShareProject', [() => new ControlShareProjectDirective()])
    .directive('agtContactHilti', [() => new ControlContactHiltiDirective()])
    .directive('agtSupport', [() => new ControlSupportDirective()])
    .directive('agtUnauthorizedAccess', [() => new ControlUnauthorizedAccessDirective()])
    .directive('agtHiltiStyledButton', [() => new ControlHiltiStyledButtonDirective()])
    .directive('agtHiltiStyledTrialBanner', [() => new ControlHiltiStyledTrialBannerDirective()])
    .directive('agtHiltiStyledLicenseComparison', [() => new ControlHiltiStyledLicenseComparisonDirective()])
    .directive('agtHiltiStyledLicenseComparisonTable', [() => new ControlHiltiStyledLicenseComparisonTableDirective()])
    .directive('agtShortcutIconPopup', [() => new ControlShortcutIconPopupDirective()])
    .directive('appCollapse', downgradeComponent({ component: CollapseComponent}))
    .directive('appLabel', downgradeComponent({ component: LabelComponent}))
    .directive('appCheckboxButton', downgradeComponent({ component: CheckboxButtonComponent }))
    .directive('appContentTooltip', downgradeComponent({ component: ContentTooltipComponent}))
    .directive('appControlTitle', downgradeComponent({ component: ControlTitleComponent}))
    .directive('appDropdown', downgradeComponent({ component: DropdownComponent}))
    .directive('appLoading', downgradeComponent({ component: LoadingComponent}))
    .directive('appNumericTextBox', downgradeComponent({ component: NumericTextBoxComponent}))
    .directive('appTextBox', downgradeComponent({ component: TextBoxComponent}))
    .directive('appRadioButton', downgradeComponent({ component: RadioButtonComponent}))
    .directive('appSection', downgradeComponent({ component: SectionComponent}))
    .directive('appVersion', downgradeComponent({ component: VersionComponent}))

    // directives
    .directive(directiveName.i18n, i18nDirective.Factory())
    .directive(directiveName.focusOut, FocusOutDirective.Factory())
    .directive(directiveName.change, ChangeDirective.Factory())
    .directive(directiveName.textSanitize, TextSanitizeDirective.Factory())
    .directive(directiveName.checkbox, CheckboxDirective.Factory())
    .directive(directiveName.checkboxDisabled, CheckboxDisabledDirective.Factory())
    .directive(directiveName.radio, RadioDirective.Factory())
    .directive(directiveName.radioDisabled, RadioDisabledDirective.Factory())
    .directive(directiveName.stepperIncrement, StepperIncrementDirective.Factory())
    .directive(directiveName.stepperDecrement, StepperDecrementDirective.Factory())
    .directive(directiveName.updateOnEnter, UpdateOnEnter.Factory())
    .directive(directiveName.controlTooltip, ControlTooltipDirective.Factory())

    // services
    .service(serviceName.authentication, AuthenticationService)
    .service(serviceName.user, UserService)
    .service(serviceName.favorites, FavoritesService)
    .service(serviceName.userSettings, UserSettingsService)
    .service(serviceName.guid, GuidService)
    .service(serviceName.id, IdService)
    .service(serviceName.data, DataService)
    .service(serviceName.loading, LoadingService)
    .service(serviceName.modal, ModalService)
    .service(serviceName.promise, PromiseService)
    .service(serviceName.regionOrder, RegionOrderService)
    .service(serviceName.browser, BrowserService)
    .service(serviceName.number, NumberService)
    .service(serviceName.unit, UnitService)
    .service(serviceName.document, DocumentService)
    .service(serviceName.tracking, TrackingService)
    .service(serviceName.license, LicenseService)
    .service(serviceName.import, ImportService)
    .service(serviceName.bom, BomService)
    .service(serviceName.productinformation, ProductInformationService)

    // ignore service so we can add other values into constructors
    .service('ignore', Ignore)

    // Angular services
    .factory(serviceName.apiService, downgradeInjectable(ApiService))
    .factory(serviceName.appStorage, downgradeInjectable(AppStorageService))
    .factory(serviceName.clipboard, downgradeInjectable(ClipboardService))
    .factory(serviceName.componentProviderService, downgradeInjectable(ComponentProviderService))
    .factory(serviceName.launchDarklyService, downgradeInjectable(LaunchDarklyService))
    .factory(serviceName.logger, downgradeInjectable(LoggerService))
    .factory(serviceName.sessionStorage, downgradeInjectable(SessionStorageService))
    .factory(serviceName.view, downgradeInjectable(ViewService))
    .factory(serviceName.appConfig, downgradeInjectable(AppConfigService))
    .factory(serviceName.changes, downgradeInjectable(ChangesService))
    .factory(serviceName.codeList, downgradeInjectable(CodeListService))
    .factory(serviceName.versionDetailsService, downgradeInjectable(VersionDetailsService))
    .factory(serviceName.applicationVersionsService, downgradeInjectable(ApplicationVersionsService))
    .factory(serviceName.authService, downgradeInjectable(AuthService))
    .factory(serviceName.localization, downgradeInjectable(LocalizationService))
    .factory(serviceName.dateTime, downgradeInjectable(DateTimeService))
    .factory(serviceName.featureVisibilityService, downgradeInjectable(FeatureVisibilityService))
    .factory(serviceName.errorHandler, downgradeInjectable(ErrorHandlerService))

    // run
    .run([
        '$rootScope',
        '$location',
        'user',
        'view',
        '$window',
        '$templateCache', (
            $rootScope: IRootControllerScope,
            $location: ng.ILocationService,
            user: UserService,
            view: ViewService,
            $window: ng.IWindowService,
            $templateCache: ng.ITemplateCacheService
        ) => {

            // set authentication
            user.authenticatedFromStorage();

            // on route rejection redirect if needed
            $rootScope.$on(
                '$routeChangeError', (
                    angularEvent: any,
                    current: IRouteChangeLocation,
                    previous: IRouteChangeLocation,
                    rejection: IRouteChangeRejection
                ) => {
                if (rejection.redirectTo != null && rejection.redirectTo != '') {
                    $location.search('');

                    if (rejection.params != null) {
                        $location.path(rejection.redirectTo).search(rejection.params);
                    }
                    else {
                        $location.path(rejection.redirectTo);
                    }
                }
                else if (rejection.redirectToExternal != null && rejection.redirectToExternal != '') {
                    if (rejection.logoff != null && rejection.logoff) {
                        const logoffForm = document.getElementById('logoffForm') as HTMLFormElement;

                        logoffForm.action = rejection.redirectToExternal;
                        (document.getElementById('client_id') as HTMLInputElement).value = rejection.params.client_id;
                        (document.getElementById('lang') as HTMLInputElement).value = rejection.params.lang;
                        (document.getElementById('access_token') as HTMLInputElement).value = rejection.params.access_token;
                        (document.getElementById('redirect_uri') as HTMLInputElement).value = rejection.params.redirect_uri;
                        (document.getElementById('logout_uri') as HTMLInputElement).value = rejection.params.logout_uri;

                        logoffForm.submit();
                    }
                    else {
                        $window.location.href = rejection.redirectToExternal;
                    }
                }
            });

            // on route change
            $rootScope.$on('$routeChangeSuccess', () => {
                view.viewLoaded = true;
            });

            // template cache
            $templateCache.put(templateName.projectAndDesign, projectAndDesignTemplate);
            $templateCache.put(templateName.bom, bomTemplate);
            $templateCache.put(templateName.header, headerTemplate);
            $templateCache.put(templateName.leftNavigation, leftNavigationTemplate);
            $templateCache.put('ControlTooltip.html', ControlTooltipTemplate);
            $templateCache.put(templateName.version, versionTemplate);
        }]);

// application title
document.title = environment.solutionName;

// Google Tag Manager
// the GTM code (ID) differs between the environments
if (environment.useGoogleTagManager) {
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${environment.gtmCode}');`;
    document.head.appendChild(script);
}
