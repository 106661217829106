import { ModalService } from '../../../Services/modal-service';
import { UserSettingsService } from '../../../Services/user-settings-service';
import { control } from '../../controls';
import { BaseModalController } from '../../Modal/BaseModal';
import { Modal, ModalController, ModalDirective } from '../../Modal/Modal';
import { LicenseComparisonTable } from '../LicenseComparisonTable/LicenseComparisonTable';
import template from './LicenseComparison.html';

export class LicenseComparisonModalController extends BaseModalController<LicenseComparison> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'modal',
        'control'
    ];

    public licenseComparisonTableControl: LicenseComparisonTable;

    public submitted: boolean;

    private $scope: ng.IScope;
    private userSettings: UserSettingsService;
    private modal: ModalService;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        modal: ModalService,
        control: LicenseComparison
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.modal = modal;

            this.$scope.$on('modal.closing', (event) => {
                if (!this.submitted) {
                    event.preventDefault();
                }
            });

            this.licenseComparisonTableControl = new LicenseComparisonTable();
        })(this.$onInit);
    }

    public buyNow() {
        this.submitted = true;
        this.$uibModalInstance.close();
        this.modal.trialBanner.open();

        const getProfis3Url = this.userSettings.getProfis3Url();

        if (getProfis3Url != null) {
            window.open(getProfis3Url, '_blank');
        }
    }

    public close() {
        this.submitted = true;
        this.$uibModalInstance.close();
    }

}

export interface ILicenseComparisonConstructor {

}

@control('HiltiStyledLicenseComparison')
export class LicenseComparison extends Modal<LicenseComparison> {
    constructor(ctor?: ILicenseComparisonConstructor) {
        super({
            template,
            templateUrl: 'HiltiStyledLicenseComparison.html',
            controller: LicenseComparisonModalController,
            windowClass: 'base-modal license-comparison-modal',
            size: 'full-screen' as any,
            ...ctor
        });
    }
}

class LicenseComparisonController extends ModalController<LicenseComparison> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $uibModal: ng.ui.bootstrap.IModalService) {
        super(
            LicenseComparison,
            $scope,
            $element,
            $attrs,
            $uibModal);
    }
}

export class LicenseComparisonDirective extends ModalDirective {
    constructor() {
        super(LicenseComparisonController, LicenseComparison);
    }
}
