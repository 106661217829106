import {
    UnitEntity
} from '../../generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Codelists';
import { CodeList } from './codeList';

export class Unit extends CodeList {

    public static fromService(codeList: UnitEntity) {
        return new Unit({
            id: codeList.UnitId,
            name: codeList.Name
        });
    }

    public cloneDeep(): Unit {
        return super.cloneDeep() as Unit;
    }
}
