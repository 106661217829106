import { urlPath } from '../../../src/app/ModuleConstants';
import { UserService } from '../../Services/user-service';
import { control } from '../controls';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective } from '../Modal/Modal';
import template from './UnauthorizedAccess.html';

export class UnauthorizedAccessModalController extends BaseModalController<UnauthorizedAccess> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'user',
        'control',
        '$location'
    ];

    private $scope: ng.IScope;
    private user: UserService;
    private $location: ng.ILocationService;
    private loggedOut: boolean;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        user: UserService,
        control: UnauthorizedAccess,
        $location: ng.ILocationService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.user = user;
            this.$location = $location;
            this.loggedOut = false;

            // don't close the modal
            this.$scope.$on('modal.closing', (event, result) => event.preventDefault());
        })(this.$onInit);
    }

    public logOut() {
        if (!this.loggedOut) {
            this.loggedOut = true;

            this.$location.path(urlPath.logout).search('type', 'invalidate');
        }
    }
}

export interface IUnauthorizedAccessConstructor {
}

@control('UnauthorizedAccess')
export class UnauthorizedAccess extends Modal<UnauthorizedAccess> {
    constructor(ctor?: IUnauthorizedAccessConstructor) {
        super({
            template,
            templateUrl: 'UnauthorizedAccess.html',
            controller: UnauthorizedAccessModalController,
            windowClass: 'default-modal unauthorized-access-modal',
            ...ctor
        });
    }
}

class UnauthorizedAccessController extends ModalController<UnauthorizedAccess> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(UnauthorizedAccess, $scope, $element, $attrs, $uibModal);
    }
}

export class UnauthorizedAccessDirective extends ModalDirective {
    constructor() {
        super(UnauthorizedAccessController, UnauthorizedAccess);
    }
}
