import {
    RegionLicenseFeature
} from '../../../../src/app/entities/codeLists/region-license-feature';
import {
    LicenseSuiteType
} from '../../../../src/app/generated-modules/Hilti.PE.Purchaser.Integrations.PEWebServices.Entities';
import { CodeListService, ProjectCodeList } from '../../../../src/app/services/code-list.service';
import { UserSettingsService } from '../../../Services/user-settings-service';
import {
    control, ControlDirective, IWebControl, IWebControlConstructor, WebControl, WebControlController
} from '../../controls';
import template from './LicenseComparisonTable.html';

interface LicenseSuite {
    Type: LicenseSuiteType;
    Features: string[];
}

export interface ILicenseComparisonTableConstructor extends IWebControlConstructor {
}

@control('HiltiStyledLicenseComparisonTable')
export class LicenseComparisonTable extends WebControl implements IWebControl {
    constructor(licenseComparisonTable?: ILicenseComparisonTableConstructor) {
        super(licenseComparisonTable);
    }
}

class LicenseComparisonTableController extends WebControlController<LicenseComparisonTable> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse',
        'codeList',
        'userSettings'
    ];

    public allFeatures: string[] = null;

    private codeList: CodeListService;
    private userSettings: UserSettingsService;
    private standardLicenseSuite: LicenseSuite = null;
    private premiumLicenseSuite: LicenseSuite = null;

    constructor(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $compile: ng.ICompileService,
        $parse: ng.IParseService,
        codeList: CodeListService,
        userSettings: UserSettingsService
    ) {
        super(
            LicenseComparisonTable,
            $scope,
            $element,
            $attrs,
            $compile,
            $parse
        );

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.codeList = codeList;
            this.userSettings = userSettings;

            const licenseSuiteFeatures = this.codeList
                .codelist[ProjectCodeList.RegionLicenseFeatures] as RegionLicenseFeature[];

            const premiumFeatures = this.filterLicenseFeatures(
                licenseSuiteFeatures,
                LicenseSuiteType.Premium);
            const standardFeatures = this.filterLicenseFeatures(
                licenseSuiteFeatures,
                LicenseSuiteType.Standard);

            this.premiumLicenseSuite = {
                Type: LicenseSuiteType.Premium,
                Features: premiumFeatures
            };
            this.standardLicenseSuite = {
                Type: LicenseSuiteType.Standard,
                Features: standardFeatures
            };

            this.allFeatures = [];
            premiumFeatures.forEach(element => this.allFeatures.push(element));
            standardFeatures.forEach(element => this.allFeatures.push(element));

            // Remove duplicate feature values.
            this.allFeatures = this.allFeatures.filter((element, index, self) => {
                return index === self.indexOf(element);
            });
        })(this.$onInit);
    }

    public isPremiumLicenseFeature(feature: string): boolean {
        if (this.premiumLicenseSuite == null ||
            this.premiumLicenseSuite.Features == null) {
            return false;
        }
        else if (this.premiumLicenseSuite.Features.filter(x => x == feature).length > 0) {
            return true;
        }

        return false;
    }

    public isStandardLicenseFeature(feature: string): boolean {
        if (this.standardLicenseSuite == null ||
            this.standardLicenseSuite.Features == null) {
            return false;
        }
        else if (this.standardLicenseSuite.Features.filter(x => x == feature).length > 0) {
            return true;
        }

        return false;
    }

    private filterLicenseFeatures(
        licenseSuiteFeatures: RegionLicenseFeature[],
        licenseSuiteType: LicenseSuiteType): string[] {
        // First check if features were defined for this region.
        let result = licenseSuiteFeatures
            .filter((element) =>
                element.regionId == this.userSettings.settings.user.general.regionId.value &&
                element.licenseSuiteType == licenseSuiteType)
            .map((element) => element.translationKey);

        // If they weren't, take default features.
        if (result.length == 0) {
            result = licenseSuiteFeatures
                .filter((element) =>
                    element.regionId == null &&
                    element.licenseSuiteType == licenseSuiteType)
                .map((element) => element.translationKey);
        }

        return result;
    }
}

export class LicenseComparisonTableDirective extends ControlDirective {
    constructor() {
        super(LicenseComparisonTableController, LicenseComparisonTable, template);
    }
}
