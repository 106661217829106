import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

const color1 = '#fff';
const color2 = '#fafafa';
const color3 = '#d7cebe';
const color4 = '#524f53';
const color5 = '#d2051e';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnChanges {
    @Input()
    public width = 32;

    @Input()
    public height = 8;

    @Input()
    public duration = 1;

    @Input()
    public color: string;

    public delay = 0.1;

    public color1 = color1;
    public color2 = color2;
    public color3 = color3;
    public color4 = color4;
    public color5 = color5;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.duration != null) {
            this.delay = (this.duration ?? 1) / 10;
        }

        if (changes.color != null) {
            this.color1 = this.color ?? color1;
            this.color2 = this.color ?? color2;
            this.color3 = this.color ?? color3;
            this.color4 = this.color ?? color4;
            this.color5 = this.color ?? color5;
        }
    }
}
