import cloneDeep from 'lodash-es/cloneDeep';

import { ChangesService } from '../services/changes.service';
import { LoggerService, LogMessage } from '../services/logger.service';
import { Change } from './change';

const noCollapseProperty = '__noCollapse';

export interface ITrackChangesConstructor {
    ignoreUndefined?: boolean;
    collapse?: boolean;
    obj?: Object;
    shallowChanges?: boolean;

    changesService: ChangesService;
    loggerService: LoggerService;
}

export class TrackChanges {
    public changes: Change[];

    private obj: Object;
    private previousObj: Object;
    private ignoreUndefined: boolean;
    private collapse: boolean;
    private shallowChanges: boolean;

    private changesService: ChangesService;
    private logger: LoggerService;

    constructor(
        trackChanges?: ITrackChangesConstructor
    ) {
        if (trackChanges != null) {
            this.changesService = trackChanges.changesService;
            this.logger = trackChanges.loggerService;

            this.obj = trackChanges.obj;
            this.ignoreUndefined = trackChanges.ignoreUndefined;
            this.collapse = trackChanges.collapse;
            this.shallowChanges = trackChanges.shallowChanges;
        }

        this.previousObj = cloneDeep(this.obj);
        this.changes = [];
    }

    public observe() {
        if (this.obj != null) {
            const compareChanges = this.shallowChanges ? this.changesService.getShallowChanges(this.previousObj, this.obj, this.ignoreUndefined) : this.changesService.getDeepChanges(this.previousObj, this.obj, this.ignoreUndefined);
            const currentChanges = Object.values(compareChanges);

            this.changes = this.changes.concat(currentChanges);
            this.previousObj = cloneDeep(this.obj);

            // collapse
            if (this.collapse) {
                const changes = this.changes.slice();
                this.changes = [];

                // find latest
                const reverseChanges = changes.slice().reverse();
                for (const change of changes) {
                    if ((change as any)[noCollapseProperty] || reverseChanges.find((c) => c.name == change.name) === change) {
                        this.changes.push(change);
                    }
                }

                // change old value
                for (let i = 0; i < this.changes.length; i++) {
                    const change = this.changes[i];
                    const previousChange = this.changes.find((c, index) => index < i && c.name == change.name);

                    if (previousChange != null) {
                        change.oldValue = previousChange.newValue;
                    }
                    else {
                        change.oldValue = changes.find((c) => c.name == change.name).oldValue;
                    }
                }

                // remove changes with the same old and new value
                this.changes = this.changes.filter((c) => (c as any)[noCollapseProperty] || c.oldValue !== c.newValue);
            }
        }
    }

    public clear() {
        this.changes = [];
        this.previousObj = cloneDeep(this.obj);
    }

    public set(obj: Object) {
        this.obj = obj;
    }

    public setOriginalProperty(property: string, value: any) {
        if (this.previousObj == null) {
            this.previousObj = {};
        }

        this.previousObj[property] = value;
    }

    public print(name: string, ...args: any[]) {
        this.logger.logGroup(new LogMessage({
            message: name,
            args
        }), this.changes.map((change) => new LogMessage({
            message: `${change.name != null && change.name != '' ? `${change.name}: ` : ''}%o => %o`,
            args: [change.oldValue, change.newValue]
        })));
    }

    public addChange(change: Change, noCollapse?: boolean) {
        this.changes.push(change);

        (change as any)[noCollapseProperty] = noCollapse;
    }
}
