import orderBy from 'lodash-es/orderBy';
import sortBy from 'lodash-es/sortBy';

import { UnitGroup } from '../../src/app/generated-modules/Hilti.PE.Purchaser.Common.Units';
import {
    BillOfMaterialDetailsEntity, BillOfMaterialEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.BillOfMaterial';
import {
    AnchorType
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Enums';
import { GuidService } from '../../src/app/guid.service';
import { urlPath } from '../../src/app/ModuleConstants';
import { PropertyMetaData } from '../../src/app/properties/properties';
import { LocalizationService } from '../../src/app/services/localization.service';
import { environment } from '../../src/environments/environment';
import { ApplicationSettings } from '../Controls/ApplicationSettings/ApplicationSettings';
import { ContactHilti } from '../Controls/ContactHilti/ContactHilti';
import { Bind } from '../Controls/controls';
import { ShortcutIconPopup } from '../Controls/ShortcutIconPopup/ShortcutIconPopup';
import { UserAgreementSettings } from '../Controls/UserAgreementSettings/UserAgreementSettings';
import { UserSettings } from '../Controls/UserSettings/UserSettings';
import { IStepperOptions, stepperDelay } from '../Directives/stepper';
import { AuthenticationService } from '../Services/authentication-service';
import { BomService } from '../Services/bom-service';
import { CodeListService } from '../../src/app/services/code-list.service';
import { DateTimeService } from '../../src/app/services/date-time.service';
import { DocumentService } from '../Services/document-service';
import { LoadingService } from '../Services/loading-service';
import { LoggerService, LogType } from '../../src/app/services/logger.service';
import { ModalService } from '../Services/modal-service';
import { NumberService } from '../Services/number-service';
import { ProductInformationService } from '../Services/product-information.service';
import { TrackingService } from '../Services/tracking-service';
import { UnitService } from '../Services/unit-service';
import { UserService } from '../Services/user-service';
import { UserSettingsService } from '../Services/user-settings-service';
import { IRootControllerScope } from './root-controller';

export const ArticleNumberNotFound = 'NumberNotFound';

export interface ILeftNavigationItem {
    id: string;
    image: string;
    tooltip: string;
    click: () => void;
    bottomSeparator?: boolean;
}

interface Pagination {
    currentPage: number;
    pageSize: number;
    step: number;
    show: { [key: string]: boolean };
    interval: number;
    delayedFunction: number;
}

enum PaginationDirection {
    up,
    down
}

const PaginationPageSize = 10;
const PaginationPageStep = 1;
const PaginationButtonDelay = 500;
const PaginationStepperDelay = 70;

export class BomController {

    public static $inject = [
        '$scope',
        'logger',
        '$location',
        'user',
        'authentication',
        'guid',
        '$timeout',
        'loading',
        'modal',
        'codeList',
        '$q',
        '$http',
        'document',
        'localization',
        'userSettings',
        'unit',
        'bom',
        'dateTime',
        'number',
        'tracking',
        'productInformation'
    ];

    public static readonly orderAmountMin = 0;
    public static readonly orderAmountMax = 1000000;

    public applicationSettings: ApplicationSettings;
    public userSettingsModal: UserSettings;
    public userAgreementSettingsModal: UserAgreementSettings;
    public leftNavigation: ILeftNavigationItem[];
    public leftNavigationSelectedButton: ILeftNavigationItem;
    public boms: BillOfMaterialEntity[];
    public selectedBom: BillOfMaterialEntity;
    public bomsPagination: Pagination;
    public stepperOptions: IStepperOptions;
    public contactHilti: ContactHilti;
    public user: UserService;
    public shortcutIconPopup: ShortcutIconPopup;

    private $scope: IRootControllerScope;
    private logger: LoggerService;
    private $location: ng.ILocationService;
    private authentication: AuthenticationService;
    private guid: GuidService;
    private $timeout: ng.ITimeoutService;
    private $q: ng.IQService;
    private $http: ng.IHttpService;
    private loading: LoadingService;
    private modal: ModalService;
    private codeList: CodeListService;
    private localization: LocalizationService;
    private document: DocumentService;
    private userSettings: UserSettingsService;
    private unit: UnitService;
    private bomService: BomService;
    private dateTime: DateTimeService;
    private numberService: NumberService;
    private tracking: TrackingService;
    private productInformation: ProductInformationService;

    /**
     * Initializes a new instance of the BomController class.
     */
    constructor(
        $scope: IRootControllerScope,
        logger: LoggerService,
        $location: ng.ILocationService,
        user: UserService,
        authentication: AuthenticationService,
        guid: GuidService,
        $timeout: ng.ITimeoutService,
        loading: LoadingService,
        modal: ModalService,
        codeList: CodeListService,
        $q: ng.IQService,
        $http: ng.IHttpService,
        document: DocumentService,
        localization: LocalizationService,
        userSettings: UserSettingsService,
        unit: UnitService,
        bom: BomService,
        dateTime: DateTimeService,
        numberService: NumberService,
        tracking: TrackingService,
        productInformation: ProductInformationService
    ) {
        this.$scope = $scope;
        this.logger = logger;
        this.$location = $location;
        this.user = user;
        this.authentication = authentication;
        this.guid = guid;
        this.$timeout = $timeout;
        this.$q = $q;
        this.$http = $http;
        this.loading = loading;
        this.modal = modal;
        this.codeList = codeList;
        this.localization = localization;
        this.document = document;
        this.userSettings = userSettings;
        this.unit = unit;
        this.bomService = bom;
        this.dateTime = dateTime;
        this.numberService = numberService;
        this.tracking = tracking;
        this.productInformation = productInformation;

        this.logger.log('BomController::ctor', LogType.debug);

        this.init();
    }

    public get selectedBomDetails() {
        const allBomDetails = this.bomService.model[PropertyMetaData.BOM_BOMDetailsList.id] as BillOfMaterialDetailsEntity[];
        const selectedBomDetails = this.selectedBom == null ? [] : allBomDetails.filter(details => details.BomId === this.selectedBom.BomId);

        // sort by name
        selectedBomDetails.sort((a, b) => {
            const aName = this.getProductName(a);
            const bName = this.getProductName(b);

            if (aName == null || bName == null) {
                return 0;
            }
            else if (aName.toLowerCase() < bName.toLowerCase()) {
                return -1;
            }
            else if (aName.toLowerCase() > bName.toLowerCase()) {
                return 1;
            }
            return 0;
        });

        return selectedBomDetails;
    }

    public get includedText() {
        if (this.selectedBom != null) {
            const sortedDesigns = orderBy(this.selectedBom.BomDesigns, ['ProjectName', 'DesignName'], ['desc', 'desc']);

            return sortedDesigns
                .map(design => `${design.ProjectName || this.localization.getLocalizedString('Agito.Hilti.Purchaser.Bom.UnknownInclusion')} / ${design.DesignName || this.localization.getLocalizedString('Agito.Hilti.Purchaser.Bom.UnknownInclusion')}`)
                .join(', ');
        }
        else {
            return '';
        }
    }

    public get selectedBomRemarks() {
        return this.selectedBom == null ? '' : this.selectedBom.Remark;
    }

    public get hasBomRows() {
        return this.boms != null && this.boms.length > 0;
    }

    public get paginatedBoms() {
        return this.boms.filter(bom => this.bomsPagination.show[bom.BomId]);
    }

    public get userName() {
        if (this.user.isExternalRussianUser) {
            return this.userSettings.ccmsUserSettings != null
                ? this.userSettings.ccmsUserSettings.FullName
                : '';
        }

        return this.userSettings.settings.user.general.name.value?.trim()
            ? this.userSettings.settings.user.general.name.value
            : this.user.authentication.userName;
    }

    public get showManageHiltiAccount() {
        const link = this.getManageHiltiAccountUrl();
        return link != null && link != '';
    }

    public translate(key: string) {
        return this.localization.getLocalizedString(key);
    }

    public openUserSettings() {
        this.userSettingsModal.open();
    }

    public openManageHiltiAccount() {
        const link = this.getManageHiltiAccountUrl();
        window.open(link, '_blank');
    }

    public openUserAgreementSettings() {
        this.userAgreementSettingsModal.open();
    }

    public openHiltiDataPrivacyUrl() {
        const hiltiDataPrivacyUrl = this.userSettings.getHiltiDataPrivacyUrl();
        if (hiltiDataPrivacyUrl != null) {
            window.open(hiltiDataPrivacyUrl, '_blank');
        }
    }

    public openApplicationSettings() {
        this.applicationSettings.open();
    }

    public openSupportLink() {
        this.modal.support.open();
    }

    public openShortcutIconPopup() {
        this.shortcutIconPopup.open();
    }

    public navigate(path: string) {
        this.$location.path(path);
    }

    public logout() {
        this.$location.path(urlPath.logout).search('type', 'invalidate');
    }

    public paginationStartMoveContinously(direction: PaginationDirection) {
        document.onmouseup = (ev: Event) => {
            this.paginationStopMoveContinously();
        };

        this.bomsPagination.delayedFunction = window.setTimeout(() => {
            this.bomsPagination.interval = window.setInterval(() => {
                this.$scope.$apply(() => {
                    this.paginationMove(direction);
                    return;
                });
            }, PaginationStepperDelay);
        }, PaginationButtonDelay);
    }

    public paginationStopMoveContinously() {
        if (this.bomsPagination.delayedFunction) {
            window.clearTimeout(this.bomsPagination.delayedFunction);
            this.bomsPagination.delayedFunction = null;
        }

        if (this.bomsPagination.interval) {
            window.clearInterval(this.bomsPagination.interval);
            this.bomsPagination.interval = null;
        }

        document.onmouseup = () => { };
    }

    public paginationMove(direction: PaginationDirection) {
        if (!this.showPagination()) {
            return;
        }

        if (direction == PaginationDirection.up && !this.disabledPagination(PaginationDirection.up)) {
            this.bomsPagination.currentPage -= this.bomsPagination.step;
            this.updatePaginationItems();
        }
        else if (direction == PaginationDirection.down && !this.disabledPagination(PaginationDirection.down)) {
            this.bomsPagination.currentPage += this.bomsPagination.step;
            this.updatePaginationItems();
        }
    }

    public showPagination() {
        return this.boms.length > 0 && this.boms.length > PaginationPageSize;
    }

    public disabledPagination(direction: PaginationDirection) {
        if (direction == PaginationDirection.down) {
            return this.bomsPagination.currentPage + this.bomsPagination.pageSize >= this.boms.length ? true : false;
        }
        else if (direction == PaginationDirection.up) {
            return this.bomsPagination.currentPage <= 0 ? true : false;
        }

        return true;
    }

    public paginationMoveToItem(item: BillOfMaterialEntity) {
        const currentPage = this.bomsPagination.currentPage;
        const pageSize = this.bomsPagination.pageSize - 1;

        // find where item is on the list
        let position = 0;
        stopFindPosition:
        for (const bom of this.boms) {
            if (bom.BomId == item.BomId) {
                break stopFindPosition;
            }
            position++;
        }

        // find min and max visible pagination item
        const minVisible = currentPage;
        const maxVisible = currentPage + pageSize;

        // if item is already between min and max visible do nothing
        if (position >= minVisible && position <= maxVisible) {
            return;
        }

        // if item is below max visible scroll down enough to make it visible
        if (position > maxVisible) {
            this.bomsPagination.currentPage = position - pageSize; // set current page so that item will be the last visible
            return;
        }

        // if item is above min visible scroll up enough to make it visible
        if (position < minVisible) {
            this.bomsPagination.currentPage = position; // set current page so that item will be the first visible
            return;
        }
    }

    public getProductName(details: BillOfMaterialDetailsEntity) {
        return details.AnchorName;
    }

    public getLengthOrSize(details: BillOfMaterialDetailsEntity) {
        if (details.AnchorType == null) {
            return this.unit.formatInternalValueAsDefault(details.LengthOrSize, UnitGroup.Length);
        }

        switch (details.AnchorType) {
            case AnchorType.Insert:
                return this.unit.formatInternalValueAsDefault(details.LengthOrSize, UnitGroup.Length);
            case AnchorType.Capsule:
            case AnchorType.Mortar:
                return this.unit.formatInternalValueAsDefault(details.LengthOrSize, UnitGroup.Volume);
            case AnchorType.SieveSleeve:
                return '';
            default:
                throw new Error('Unknown anchor type');
        }
    }

    public getArticleNumber(details: BillOfMaterialDetailsEntity) {
        if (details.ArticleNumber == null || details.ArticleNumber == '' || details.ArticleNumber == ArticleNumberNotFound) {
            return null;
        }

        return `${details.ArticleNumber}`;
    }

    public getTotalInProject(details: BillOfMaterialDetailsEntity) {
        return this.unit.formatNumber(details.TotalInProject);
    }

    public bindOrderAmount(details: BillOfMaterialDetailsEntity) {
        return (value: string) => {
            // setter
            if (value !== undefined) {
                let count = this.unit.parseNumber(value);

                if (count != null && !Number.isNaN(count)) {
                    // round to a whole number
                    count = this.numberService.round(count);

                    // min max
                    if (count < BomController.orderAmountMin) {
                        count = BomController.orderAmountMin;
                    }

                    if (count > BomController.orderAmountMax) {
                        count = BomController.orderAmountMax;
                    }

                    details.OrderAmount = count;
                }
            }

            // getter
            return this.unit.formatNumber(details.OrderAmount);
        };
    }

    public transferOnline(bom: BillOfMaterialEntity) {
        this.bomService.placeOrder(bom.BomId);
    }

    public print(bom: BillOfMaterialEntity) {
        const bomDetails = this.bomService.model[PropertyMetaData.BOM_BOMDetailsList.id] as BillOfMaterialDetailsEntity[];
        const selectedBomDetails = bomDetails.filter(details => details.BomId === bom.BomId);

        this.bomService.printBom(selectedBomDetails);
    }

    public exportExcel(bom: BillOfMaterialEntity) {
        const bomDetails = this.bomService.model[PropertyMetaData.BOM_BOMDetailsList.id] as BillOfMaterialDetailsEntity[];
        const selectedBomDetails = bomDetails.filter(details => details.BomId === bom.BomId);

        let createdDate: string;
        if (bom.CreatedDate instanceof Date) {
            createdDate = this.localization.moment(bom.CreatedDate).format('lll').replace(':', '-');
        }
        else {
            createdDate = this.localization.moment(new Date(bom.CreatedDate)).format('lll').replace(':', '-');
        }

        this.bomService.exportToExcel(selectedBomDetails, bom.BomName + ' ' + createdDate);
    }

    /**
     * Initialize main controller
     */
    private init() {
        this.logger.log('BomController::init', LogType.debug);

        this.$scope['localization'] = this.localization;
        this.$scope['dateTime'] = this.dateTime;
        this.$scope['PropertyMetaData'] = PropertyMetaData;

        this.stepperOptions = {
            delay: stepperDelay,
            min: BomController.orderAmountMin,
            max: BomController.orderAmountMax
        };

        this.applicationSettings = new ApplicationSettings({
            unitLength: new Bind(`ctrl.user.project.model[${PropertyMetaData.Option_UnitLength.id}]`),
            unitVolume: new Bind(`ctrl.user.project.model[${PropertyMetaData.Option_UnitVolume.id}]`),
            unitArea: new Bind(`ctrl.user.project.model[${PropertyMetaData.Option_UnitArea.id}]`),
            languageLCID: new Bind(`ctrl.user.project.model[${PropertyMetaData.Option_LanguageLCID.id}]`),
            regionId: new Bind(`ctrl.user.project.model[${PropertyMetaData.Option_Region.id}]`),
            loading: new Bind(`ctrl.user.project.loading`)
        });

        this.userSettingsModal = new UserSettings();
        this.userAgreementSettingsModal = new UserAgreementSettings();
        this.contactHilti = new ContactHilti();
        this.shortcutIconPopup = new ShortcutIconPopup();

        this.initLeftNavigation();
        this.refreshBoms();
        this.initPagination();
    }

    private initLeftNavigation() {
        this.leftNavigation = [
            {
                id: 'home',
                image: 'sprite-loading-logo-small',
                bottomSeparator: true,
                tooltip: 'Agito.Hilti.Purchaser.Navigation.Home',
                click: () => {
                    this.navigate(urlPath.projectAndDesign);
                }
            },
            {
                id: 'bom',
                image: 'sprite-bom',
                tooltip: 'Agito.Hilti.Purchaser.Navigation.BOM',
                click: () => {
                    this.navigate(urlPath.bom);
                }
            },
        ];

        this.leftNavigationSelectedButton = this.leftNavigation[1];
    }

    private refreshBoms() {
        if (this.bomService.purchaserDataEntity == null || this.bomService.purchaserDataEntity.Boms == null) {
            this.boms = [];
            this.selectedBom = null;
        }
        else {
            this.boms = sortBy(this.bomService.purchaserDataEntity.Boms, bom => bom.CreatedDate).reverse();

            if (this.selectedBom != null) {
                this.selectedBom = this.boms.find(bom => bom.BomId === this.selectedBom.BomId);
            }

            if (this.selectedBom == null && this.boms.length > 0) {
                this.selectedBom = this.boms[0];
            }
        }
    }

    private updatePaginationItems() {
        if (this.bomsPagination == null) {
            return;
        }

        let counter = 0;
        let begin = this.bomsPagination.currentPage * this.bomsPagination.step;

        if (begin + this.bomsPagination.pageSize > this.boms.length) {
            begin = Math.max(0, this.boms.length - this.bomsPagination.pageSize);
        }

        const end = begin + this.bomsPagination.pageSize;


        const updateShows = (item: BillOfMaterialEntity) => {
            if (counter >= begin && counter < end) {
                this.bomsPagination.show[item.BomId] = true;
            }
            else {
                this.bomsPagination.show[item.BomId] = false;
            }
            counter++;
        };

        this.boms.forEach((item) => {
            updateShows(item);
        });
    }

    private initPagination() {
        this.$scope['PaginationDirection'] = PaginationDirection;
        this.bomsPagination = ({
            pageSize: PaginationPageSize,
            currentPage: 0,
            step: PaginationPageStep,
            show: {},
            delayedFunction: null,
            interval: null
        } as Pagination);
        this.updatePaginationItems();
    }

    private getManageHiltiAccountUrl() {
        return this.productInformation.regionLinksForUser()?.ManageAccountLink;
    }
}
