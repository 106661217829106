export class FocusOutDirective implements ng.IDirective {
    public restrict = 'A';

    public static Factory() {
        return () => new FocusOutDirective();
    }

    public link: ng.IDirectiveLinkFn = ($scope, $element, attributes) => {
        const focusOut = (event: FocusEvent) => {
            $scope.$eval(attributes['agtFocusOut'], { $event: event });
        };

        $element[0].addEventListener('blur', focusOut, true);

        $scope.$on('$destroy', () => {
            $element[0].removeEventListener('blur', focusOut, true);
        });
    }
}
