<app-control-title
    [focusControlId]="id"
    [title]="title"
    [tooltip]="tooltip">
</app-control-title>

<div [id]="id" class="checkbox-container">
    <label class="checkbox"
        *ngFor="let item of items"
        [ngbTooltip]="ngbTooltipTemplate(item.tooltip, itemTooltipTemplate)"
        [disableTooltip]="item.tooltipTarget != null && item.tooltipTarget != tooltipTargetEnum.Item">
        <ng-template #itemTooltipTemplate>
            <app-content-tooltip [tooltip]="item.tooltip"></app-content-tooltip>
        </ng-template>

        <span class="checkbox-wrapper">
            <input class="checkbox-input" [attr.id]="item.id ? item.id : null" [disabled]="item.disabled || disabled"
                [checked]="selectedValues?.has(item.value)" (change)="onChange(item)" type="checkbox">
            <span class="checkbox-button"></span>
            <span class="checkbox-label" [attr.id]="item.id ? item.id + '-label': null">{{item.text}}</span>
        </span>

        <span class="checkbox-description" *ngIf="item.description">{{item.description}}</span>
    </label>
</div>
