import {
    control, ControlDirective, ControlProperty, method, property, WebControl, WebControlController
} from '../controls';
import { Tooltip } from '../ControlTooltip/ControlTooltip';
import { IRegionControl, IRegionControlConstructor } from '../Region/Region';
import { RegionControlSize } from '../Region/RegionControlSize';
import template from './Button.html';

export enum ButtonSize {
    normal,
    full
}

export enum ButtonType {
    default,
    primary,
    ghost,
    link
}

export interface IButtonConstructor extends IRegionControlConstructor {
    text?: string | ControlProperty;
    image?: string | ControlProperty;
    buttonSize?: ButtonSize | ControlProperty;
    buttonType?: ButtonType | ControlProperty;
}

@control('Button')
export class Button extends WebControl implements IRegionControl {

    public static click = 'click';

    @property()
    public title: string;

    @property()
    public size: RegionControlSize;

    @property()
    public tooltip: Tooltip;

    @property()
    public text: string;

    @property()
    public image: string;

    @property()
    public buttonSize: ButtonSize;

    @property()
    public buttonType: ButtonType;
    constructor(button?: IButtonConstructor) {
        super(button);
    }

    @method()
    public focus() { }
}

class ButtonController extends WebControlController<Button> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse'
    ];

    public title: string;
    public size: RegionControlSize;
    public tooltip: Tooltip;
    public text: string;
    public image: string;
    public buttonSize: ButtonSize;
    public buttonType: ButtonType;

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $compile: ng.ICompileService, $parse: ng.IParseService) {
        super(Button, $scope, $element, $attrs, $compile, $parse);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            // constants
            this.$scope['RegionControlSize'] = RegionControlSize;
            this.$scope['ButtonSize'] = ButtonSize;
        })(this.$onInit);
    }

    public get buttonClassObject() {
        return {
            'size-full': this.buttonSize == ButtonSize.full,
            ['button-' + ButtonType[this.buttonType || ButtonType.default]]: true
        };
    }

    public focus() {
        this.element.querySelector<HTMLButtonElement>('.button-element').focus();
    }

    public onClick() {
        this.trigger(Button.click);
    }
}

export class ButtonDirective extends ControlDirective {
    constructor() {
        super(ButtonController, Button, template);
    }
}
