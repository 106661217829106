

import { environment } from '../../src/environments/environment';
import {
  RegionLinks
} from '../../src/app/generated-modules/Hilti.PE.ProductInformationService.Shared.Entities';
import { IRequestConfig } from './http-interceptor-service';
import { LoggerService } from '../../src/app/services/logger.service';
import { IUserSettingsService } from './user-settings-service';

export class ProductInformationService {
  public static $inject = [
    '$q',
    'userSettings',
    '$http',
    'logger',
  ];

  private regionLinks: RegionLinks[];

  private $q: ng.IQService;
  private userSettingsService: IUserSettingsService;
  private $http: ng.IHttpService;
  private logger: LoggerService;


  constructor(
    $q: ng.IQService,
    userSettingsService: IUserSettingsService,
    $http: ng.IHttpService,
    logger: LoggerService) {
    this.$q = $q;
    this.userSettingsService = userSettingsService;
    this.$http = $http;
    this.logger = logger;
  }

  public regionLinksForUser(regionId?: number): RegionLinks {
    const id = regionId ?? this.userSettingsService.settings.user.general.regionId.value;
    const regionLinks = this.regionLinks.find(links => links.RegionId == id);
    return regionLinks;
  }

  public loadRegionLinksFromService(options?: IRequestConfig) {
    const url = `${environment.productInformationServiceUrl}public/RegionLinks/GetRegionLinks`;
    const defer = this.$q.defer<void>();

    return this.$http.get<RegionLinks[]>(url, options).then((response) => {
      this.regionLinks = response.data;

      defer.resolve();
    })
      .catch((response) => {
        this.logger.logServiceError(response, 'PurchaserApplication', 'GetRegionLinks');

        defer.reject();
      });
  }
}
