export class ChangeDirective implements ng.IDirective {
    public restrict = 'A';

    public static Factory() {
        return () => new ChangeDirective();
    }

    public link: ng.IDirectiveLinkFn = ($scope, element, attributes) => {
        const onChangeFn = () => $scope.$applyAsync(() => $scope.$eval(attributes['agtChange']));

        element.on('change', onChangeFn);

        $scope.$on('$destroy', () => {
            element.off('change', onChangeFn);
        });
    }
}
