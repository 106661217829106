import '../../App/application';

import { HttpClientModule } from '@angular/common/http';
import { DoBootstrap, NgModule, NgZone, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { NgbTooltip, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { modalServiceProvider } from '../../App/ajs-upgraded-providers';

import { AppRoutingModule } from './app-routing.module';
import { CheckboxButtonComponent } from './components/checkbox-button/checkbox-button.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { ContentTooltipComponent } from './components/content-tooltip/content-tooltip.component';
import { ControlTitleComponent } from './components/control-title/control-title.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { LabelComponent } from './components/label/label.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NumericTextBoxComponent } from './components/numeric-text-box/numeric-text-box.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { SectionComponent } from './components/section/section.component';
import { TextBoxComponent } from './components/text-box/text-box.component';
import { VersionComponent } from './components/version/version.component';
import { L10nDirective } from './directives/l10n.directive';
import { httpInterceptorProviders } from './http-interceptors';
import { moduleName } from './ModuleConstants';
import { L10nPipe } from './pipes/l10n.pipe';
import { ComponentProviderService, DeclarationType } from './services/component-provider.service';

// When changing this list also change the DeclarationType enum in src\app\services\component-provider.service.ts.
// This declarations list and the DeclarationType enum in component-provider.service.ts *MUST* be the same!!!
// Order in declarations and component-provider.service.ts MUST be the same!!!
// Also, the entry in this list **should be the same as the component's class name (no aliases!)**!
const declarations = [
    L10nDirective,
    L10nPipe,
    CollapseComponent,
    LabelComponent,
    ContentTooltipComponent,
    CheckboxButtonComponent,
    ControlTitleComponent,
    DropdownComponent,
    LoadingComponent,
    NumericTextBoxComponent,
    TextBoxComponent,
    RadioButtonComponent,
    SectionComponent,
    VersionComponent
];
export const moduleComponentDeclarations = declarations.map((val, index) => [index, val.name] as Record<number, string>);

const componentDeclarations = Object.fromEntries(declarations.map((val, index) => [index, val])) as Record<DeclarationType, Type<object>>;

@NgModule({
    declarations,
    imports: [
        BrowserModule,
        AppRoutingModule,
        UpgradeModule,
        HttpClientModule,
        NgbTooltipModule,
        FormsModule
    ],
    providers: [
        httpInterceptorProviders,
        // AngularJS-to-Angular
        modalServiceProvider
    ]
})
export class AppModule implements DoBootstrap {
    constructor(
        private upgrade: UpgradeModule,
        private ngZone: NgZone,
        ngbTooltipConfig: NgbTooltipConfig,
        componentProvider: ComponentProviderService
    ) {
        this.configureTooltip(ngbTooltipConfig);

        componentProvider.register(componentDeclarations);
    }

    ngDoBootstrap() {
        this.upgrade.bootstrap(document.body, [moduleName.main], { strictDi: true });
    }

    private configureTooltip(config: NgbTooltipConfig) {
        config.container = 'body';
        config.triggers = 'hover';
        config.openDelay = 500;

        // XXX: BUDQBP-23907: fix tooltip position bug
        // might get broken when we update angular bootstrap
        const baseOpen = NgbTooltip.prototype.open;
        const ngZone = this.ngZone;

        NgbTooltip.prototype.open = function(...args: unknown[]) {
            baseOpen.apply(this, args);

            ngZone.run(() => {});
        };
    }
}
