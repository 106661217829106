import angular from 'angular';

import { ModalValue } from '../../../src/app/entities/modal-helper';
import { LocalizationService } from '../../../src/app/services/localization.service';
import { control } from '../controls';
import { BaseModalController } from '../Modal/BaseModal';
import {
    IModalConstructor, Modal, ModalController, ModalDirective, modalProperty
} from '../Modal/Modal';
import template from './Popup.html';

export interface IPopupColumn {
    title?: string;
    property: string;
}

export class PopupModalController<TValue extends Object> extends BaseModalController<Popup<TValue>> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        '$timeout',
        'localization',
        'control'
    ];

    private $scope: ng.IScope;
    private $timeout: ng.ITimeoutService;
    private localization: LocalizationService;

    private resizeGridBodyFn: () => void;

    constructor($uibModalInstance: ng.ui.bootstrap.IModalServiceInstance, $scope: ng.IScope, $timeout: ng.ITimeoutService, localization: LocalizationService, control: Popup<TValue>) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.$scope['localization'] = this.localization = localization;
            this.$timeout = $timeout;

            this.$scope.$applyAsync(() => {
                this.resizeGridBody();
                this.resizeGridBodyFn = this.resizeGridBody.bind(this);
                angular.element(window).on('resize', this.resizeGridBodyFn);
            });

            this.$scope.$on('modal.closing', (event: ng.IAngularEvent, result?: any) => {
                if (this.resizeGridBodyFn != null) {
                    angular.element(window).off('resize', this.resizeGridBodyFn);
                    this.resizeGridBodyFn = null;
                }
            });

            this.$timeout(() => {
                this.scrollToSelected();
            });
        })(this.$onInit);
    }

    public get title() {
        return this.control.popupTitle;
    }

    public get width() {
        return (this.control.columns != null ? 100 / (this.control.columns.length + 1) : 100) + '%';
    }

    public get info() {
        return this.control.info;
    }

    public close() {
        this.$uibModalInstance.close();
    }

    public select(item: Object) {
        const value = item[this.control.valueProperty || 'value'];

        this.control.selectedValue = value;

        if (this.control.onSelect != null) {
            this.control.onSelect(value);
        }

        this.close();
    }

    public isOdd(item: Object) {
        const index = this.control.items.indexOf(item);

        if (index < 0) {
            return false;
        }

        return index % 2 != 0;
    }

    public isEven(item: Object) {
        const index = this.control.items.indexOf(item);

        if (index < 0) {
            return false;
        }

        return index % 2 == 0;
    }

    private resizeGridBody() {
        const gridBodyElement = document.querySelector<HTMLElement>(`.${this.control.popupClass} .table-body`);

        let space = window.outerHeight - 160; // 60 = modal-dialog margin  2 = modal-content border  48 = modal-header height+margin  50 = table-header height
        space -= 5; // just in case, if ie has some brilliant height calculation ideas

        if (space < 50) {
            space = 50;
        }

        gridBodyElement.style.maxHeight = space + 'px';
    }

    private scrollToSelected() {
        if (this.control.items.length == 0) {
            return;
        }

        const selectedIndex = this.control.items.findIndex(item => this.control.selectedValue == item[this.control.valueProperty || 'value']);
        const previousIndex = selectedIndex - 1 > 0 ? selectedIndex - 1 : 0;

        const gridBodyElement = document.querySelector(`.${this.control.popupClass} .table-body`);

        gridBodyElement.scrollTop = 50 * previousIndex;
    }
}

export interface IPopupConstructor<TValue extends Object> extends IModalConstructor<Popup<TValue>> {
    popupTitle?: string | ModalValue<string>;
    columns?: IPopupColumn[] | ModalValue<IPopupColumn[]>;
    items?: Object[] | ModalValue<Object[]>;
    selectedValue?: TValue | ModalValue<TValue>;
    valueProperty?: string | ModalValue<string>;
    onSelect?: (value: TValue) => void;
    popupClass?: string | ModalValue<string>;
    info?: () => void | ModalValue<() => void>;
}

@control('Popup')
export class Popup<TValue extends Object> extends Modal<Popup<TValue>> {
    @modalProperty()
    public popupTitle: string;

    @modalProperty()
    public columns: IPopupColumn[];

    @modalProperty()
    public items: Object[];

    @modalProperty()
    public selectedValue: TValue;

    @modalProperty()
    public valueProperty: string;

    @modalProperty()
    public onSelect: (value: TValue) => void;

    @modalProperty()
    public popupClass: string;

    @modalProperty()
    public info: () => void;

    constructor(ctor?: IPopupConstructor<TValue>) {
        super({
            template,
            templateUrl: 'popup-modal-template.html',
            controller: PopupModalController,
            windowClass: 'default-modal popup-modal',
            size: 'lg',
            noAnimation: true,
            ...ctor
        });
    }

    public setWindowClass(windowClass: string) {
        this.popupClass = windowClass != null ? windowClass : '';
        this.windowClass = `default-modal popup-modal ${this.popupClass}`;
    }
}

export class PopupController<TValue extends Object> extends ModalController<Popup<TValue>> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(Popup, $scope, $element, $attrs, $uibModal);
    }
}

export class PopupDirective extends ModalDirective {
    constructor() {
        super(PopupController, Popup);
    }
}
