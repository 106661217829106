<app-text-box
    class="input-textbox"
    [id]="id"
    [title]="title"
    [type]="type"
    [(value)]="displayValue"
    [placeholder]="displayPlaceholder()"
    [disabled]="disabled"
    [tooltip]="tooltip"
    (blur)="onUserChange($event)"
    (isValid)="isValid?.emit($event)"
    [submitted]="submitted"
    (submittedChange)="submittedChange?.emit($event)"
></app-text-box>

<div class="stepper">
    <button class="stepper-button increment button" tabindex="-1" type="button" (mousedown)="debounce(0)"
        (mouseup)="stopDebounce()" (click)="increment()" placement="top" [ngbTooltip]="incrementTooltip()"
        [disabled]="disabled">
        +
    </button>
    <button class="stepper-button decrement button" tabindex="-1" type="button" (mousedown)="debounce(1)"
        (mouseup)="stopDebounce()" (click)="decrement()" placement="top" [ngbTooltip]="decrementTooltip()"
        [disabled]="disabled">
        -
    </button>
</div>
