import intersection from 'lodash-es/intersection';
import some from 'lodash-es/some';

import { ModalValue } from '../../../src/app/entities/modal-helper';
import { UnitGroup } from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Common.Units';
import {
    AnchorFilterDataResponse
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data';
import {
    ArticleEntity, FastenerFamiliesEntity
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Anchor';
import {
    DesignEntity, ManualDesignEntity
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Design';
import { replace } from '../../../src/app/helpers/string-helper';
import { CodeListService } from '../../../src/app/services/code-list.service';
import { LocalizationService } from '../../../src/app/services/localization.service';
import { LoggerService } from '../../../src/app/services/logger.service';
import { environment } from '../../../src/environments/environment';
import { Project } from '../../Entities/Project';
import { BrowserService } from '../../Services/browser-service';
import { DocumentService } from '../../Services/document-service';
import { NumberService } from '../../Services/number-service';
import { UnitService } from '../../Services/unit-service';
import { UserSettingsService } from '../../Services/user-settings-service';
import { Bind, control, Translation, WebControlVisibility } from '../controls';
import { Dropdown } from '../Dropdown/Dropdown';
import { DropdownItem } from '../DropdownItem/DropdownItem';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective, modalProperty } from '../Modal/Modal';
import { ModelUpdate, TextBox } from '../TextBox/TextBox';
import template from './AddNewAnchor.html';

export class AddNewAnchorModalController extends BaseModalController<AddNewAnchor> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'localization',
        'control',
        '$http',
        '$q',
        'logger',
        'codeList',
        'userSettings',
        'unit',
        'number',
        'browser',
        'document'
    ];

    public designNameTextBox: TextBox;
    public numberOfAnchorsTextBox: TextBox;
    public fastenerFamilyDropdown: Dropdown<FastenerFamiliesEntity>;
    public insertDropdown: Dropdown<ArticleEntity>;
    public mortarDropdown: Dropdown<ArticleEntity>;
    public capsuleDropdown: Dropdown<ArticleEntity>;

    private pendingSave: boolean;
    private submitted: boolean;

    private $scope: ng.IScope;
    private localization: LocalizationService;
    private $http: ng.IHttpService;
    private $q: ng.IQService;
    private logger: LoggerService;
    private codeList: CodeListService;
    private userSettings: UserSettingsService;
    private unitService: UnitService;
    private number: NumberService;
    private browser: BrowserService;
    private document: DocumentService;

    private onSelectedChangeCall: boolean;
    private fastenerFamilyChanged: boolean;

    private fastenerFamilies: FastenerFamiliesEntity[];
    private inserts: ArticleEntity[];
    private mortars: ArticleEntity[];
    private capsules: ArticleEntity[];

    private selectedFastenerFamily: FastenerFamiliesEntity;
    private selectedInsert: ArticleEntity;
    private selectedMortar: ArticleEntity;
    private selectedCapsule: ArticleEntity;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        localization: LocalizationService,
        control: AddNewAnchor,
        $http: ng.IHttpService,
        $q: ng.IQService,
        logger: LoggerService,
        codeList: CodeListService,
        userSettings: UserSettingsService,
        unitService: UnitService,
        number: NumberService,
        browser: BrowserService,
        document: DocumentService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.$scope['localization'] = this.localization = localization;
            this.$http = $http;
            this.$q = $q;
            this.logger = logger;
            this.codeList = codeList;
            this.userSettings = userSettings;
            this.unitService = unitService;
            this.number = number;
            this.browser = browser;
            this.document = document;

            // don't close the modal if save is pending
            this.$scope.$on('modal.closing', (event) => {
                if (this.pendingSave) {
                    event.preventDefault();
                }
            });

            // controls
            this.designNameTextBox = new TextBox({
                required: true,
                disabled: false,
                title: new Translation('Agito.Hilti.Purchaser.AddNewAnchor.DesignName'),
                model: 'NewDesign',
            });

            const defaultAnchorsNo = 1;
            this.numberOfAnchorsTextBox = new TextBox({
                required: true,
                disabled: false,
                title: new Translation('Agito.Hilti.Purchaser.AddNewAnchor.NumberOfAnchors'),
                model: defaultAnchorsNo,
                stepper: true,
                update: ModelUpdate.blur,
                events: {
                    [TextBox.blurChange]: this.onNumberOfAnchorsChange.bind(this)
                }
            });

            // call BL and get the fastener families, inserts, mortars
            this.getFilteredAnchorData(null);

            this.fastenerFamilyDropdown = new Dropdown<FastenerFamiliesEntity>({
                disabled: new Bind('ctrl.submitted'),
                required: true,
                id: 'fastener-family-dropdown',
                title: new Translation('Agito.Hilti.Purchaser.AddNewAnchor.FastenerFamily'),
                selectedValue: new Bind(`ctrl.selectedFastenerFamily`),
            });

            this.insertDropdown = new Dropdown<ArticleEntity>({
                disabled: new Bind('ctrl.submitted'),
                required: true,
                id: 'insert-dropdown',
                title: new Translation('Agito.Hilti.Purchaser.AddNewAnchor.Insert'),
                selectedValue: new Bind(`ctrl.selectedInsert`),
            });

            this.mortarDropdown = new Dropdown<ArticleEntity>({
                disabled: new Bind('ctrl.submitted'),
                required: false,
                id: 'mortar-dropdown',
                title: new Translation('Agito.Hilti.Purchaser.AddNewAnchor.Mortar'),
                selectedValue: new Bind(`ctrl.selectedMortar`),
                visibility: WebControlVisibility.hidden
            });

            this.capsuleDropdown = new Dropdown<ArticleEntity>({
                disabled: new Bind('ctrl.submitted'),
                required: false,
                id: 'capsule-dropdown',
                title: new Translation('Agito.Hilti.Purchaser.AddNewAnchor.Capsule'),
                selectedValue: new Bind(`ctrl.selectedCapsule`),
                visibility: WebControlVisibility.hidden
            });

            // focus design name text box
            this.$uibModalInstance.rendered
                .then(() => {
                    const input = window.document.querySelector<HTMLInputElement>('.add-new-anchor-modal .design-name-text-box .input');

                    input.focus();
                });

            this.$scope.$watch('ctrl.selectedFastenerFamily', this.onSelectedFastenerFamilyChange.bind(this));
            this.$scope.$watch('ctrl.selectedInsert', this.onSelectedInsertChange.bind(this));

            this.$scope.$on('$destroy', () => {
                this.numberOfAnchorsTextBox.off(TextBox.blurChange, this.onNumberOfAnchorsChange.bind(this));
            });
        })(this.$onInit);
    }

    public get project() {
        return this.control.project;
    }

    private get form() {
        return this.$scope['form'] as ng.IFormController;
    }

    public getFilteredAnchorData(fastenerFamiliesName: string) {
        this.submitted = true;
        const url = `${environment.purchaserApplicationWebServiceUrl}GetFilteredAnchors`;
        const params = {
            regionId: this.userSettings.settings.user.general.regionId.value,
            fastenerFamilyName: fastenerFamiliesName
        };

        this.$http.post<AnchorFilterDataResponse>(url, params)
            .then((response) => {
                const yearText = this.localization.getLocalizedString('Agito.Hilti.Purchaser.Anchor.Years');

                this.fastenerFamilies = response.data.FastenerFamilies;
                this.inserts = response.data.Inserts;
                this.mortars = response.data.Mortars;
                this.capsules = response.data.Capsules;
                this.selectedFastenerFamily = this.fastenerFamilies.find((family) => family.FastenerFamilyName == response.data.FastenerFamilyName);
                this.selectedInsert = this.inserts != null && this.inserts.length > 0 ? this.inserts[0] : null;
                this.selectedMortar = this.mortars != null && this.mortars.length > 0 ? this.mortars[0] : null;
                this.selectedCapsule = this.capsules != null && this.capsules.length > 0 ? this.capsules[0] : null;

                this.fastenerFamilyDropdown.items = this.fastenerFamilies.map((item) =>
                    new DropdownItem<FastenerFamiliesEntity>({
                        value: item,
                        text: replace(item.FastenerFamilyName, '{years}', yearText)
                    })
                );

                this.insertDropdown.items = this.inserts.map((item) =>
                    new DropdownItem<ArticleEntity>({
                        value: item,
                        text: this.formatInsertName(item)
                    })
                );

                this.mortarDropdown.items = this.mortars.map((item) =>
                    new DropdownItem<ArticleEntity>({
                        value: item,
                        text: this.unitService.formatInternalValueAsDefault(item.Volume, UnitGroup.Volume) + ' (' + item.ArticleNumber + ')'
                    })
                );
                this.mortarDropdown.visibility = this.mortars.length > 0 ? WebControlVisibility.visible : WebControlVisibility.hidden;

                this.capsuleDropdown.items = this.capsules.map((item) =>
                    new DropdownItem<ArticleEntity>({
                        value: item,
                        text: this.unitService.formatInternalValueAsDefault(item.Volume, UnitGroup.Volume) + ' (' + item.ArticleNumber + ')'
                    })
                );
                this.capsuleDropdown.visibility = this.capsules.length > 0 ? WebControlVisibility.visible : WebControlVisibility.hidden;

                this.onSelectedChangeCall = true;
                this.fastenerFamilyChanged = true;
                this.submitted = false;
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'PurchaserApplication', 'GetFilteredAnchors');
            });
    }

    public getFilteredMortarData(fastenerFamiliesName: string, fastenerName: string) {
        this.submitted = true;
        const url = `${environment.purchaserApplicationWebServiceUrl}GetFilteredMortars`;

        const params = {
            regionId: this.userSettings.settings.user.general.regionId.value,
            fastenerFamilyName: fastenerFamiliesName,
            fastenerName
        };

        this.$http.post<AnchorFilterDataResponse>(url, params)
            .then((response) => {
                this.mortars = response.data.Mortars;
                this.selectedMortar = this.mortars != null && this.mortars.length > 0 ? this.mortars[0] : null;

                this.mortarDropdown.items = this.mortars.map((item) =>
                    new DropdownItem<ArticleEntity>({
                        value: item,
                        text: this.unitService.formatInternalValueAsDefault(item.Volume, UnitGroup.Volume) + ' (' + item.ArticleNumber + ')'
                    })
                );

                this.mortarDropdown.visibility = this.mortars.length > 0 ? WebControlVisibility.visible : WebControlVisibility.hidden;
                this.submitted = false;
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'PurchaserApplication', 'getFilteredMortarData');
            });
    }

    public save() {
        if (this.submitted || !this.form.$valid) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        const familyIds: number[] = this.selectedFastenerFamily.FastenerFamilyIds;
        const insertIds: number[] = (this.selectedInsert != null && this.selectedInsert.ArticleNumber != 'NumberNotFound') ? this.selectedInsert.FastenerFamilyIds : this.selectedFastenerFamily.FastenerFamilyIds; // not setting to [] for purpuses of intersection later on
        const mortarIds: number[] = (this.selectedMortar != null) ? this.selectedMortar.FastenerFamilyIds : this.selectedFastenerFamily.FastenerFamilyIds; // not setting to [] for purpuses of intersection later on
        const capsulIds: number[] = (this.selectedCapsule != null) ? this.selectedCapsule.FastenerFamilyIds : this.selectedFastenerFamily.FastenerFamilyIds; // not setting to [] for purpuses of intersection later on

        const familyID = intersection(familyIds, insertIds, mortarIds, capsulIds)[0];

        if (familyID == null || familyID == 0) {
            throw new Error('Fastener family not found!');
        }

        const parentProject = this.project.parentId != null
            ? this.document.findProjectById(this.project.parentId)
            : this.project;

        let projectWithSubIds = parentProject.subProjects != null
            ? Object.values(parentProject.subProjects).map(subProject => subProject.id)
            : [];
        projectWithSubIds = projectWithSubIds.concat(parentProject.id);

        const manualDesign = {
            RegionId: this.userSettings.settings.user.general.regionId.value,
            ProjectId: this.project.id,
            ProjectWithSubIds: projectWithSubIds,
            DesignName: this.designNameTextBox.model,
            AnchorsInDesign: this.unitService.parseNumber(this.numberOfAnchorsTextBox.rawModel),
            FastenerFamilyId: familyID,
            ArticleNumberMech: this.selectedInsert.ArticleNumber,
            ArticleNumberChem: this.selectedMortar != null ? this.selectedMortar.ArticleNumber : (this.selectedCapsule != null ? this.selectedCapsule.ArticleNumber : null)
        } as ManualDesignEntity;

        this.loadIfNotLoaded()
            .then(() => {
                return this.project.importNewAnchorToPurchaserDataEntity(manualDesign)
                    .finally(() => {
                        this.pendingSave = false;
                    })
                    .then(() => {
                        this.close();
                    })
                    .catch(() => {
                        this.submitted = false;
                    });
            })
            .catch(() => {
                this.pendingSave = false;
                this.close();
            });
    }

    public close() {
        this.$uibModalInstance.close();
    }

    private formatInsertName(insert: ArticleEntity): string {
        if (insert.ArticleNumber == 'NumberNotFound') {
            return this.localization.getLocalizedString('Agito.Hilti.Purchaser.Grid.Column.ArticleNumber.ContactHilti');
        }

        let alternativeInsertName = '';
        let alternativeInsertNumber = '';
        let insertNameSeparator = '; ';

        const alternativeInsertExists = some(insert.ArticleNumberAlternative) && some(insert.ArticleNameAlternative);
        if (alternativeInsertExists) {
            alternativeInsertName = `${insert.ArticleNameAlternative}${insertNameSeparator}`;
            alternativeInsertNumber = `${insertNameSeparator}${insert.ArticleNumberAlternative}`;
            insertNameSeparator = ' or ';
        }

        const insertName = `${insert.FastenerName}${insertNameSeparator}${alternativeInsertName}`;
        const insertArticleNumber = `${insert.ArticleNumber}${alternativeInsertNumber}`;

        return `${insertName}${this.unitService.formatInternalValueAsDefault(insert.InsertLength, UnitGroup.Length)} (${insertArticleNumber})`;
    }

    private loadIfNotLoaded() {
        if (this.project.purchaserDataEntity == null || this.project.purchaserDataEntity.Options == null) {
            return this.project.loadPurchaserDataEntityForProject(null, true);
        } else {
            return this.$q.when<DesignEntity[]>(null);
        }
    }

    private onSelectedFastenerFamilyChange() {
        if (!this.onSelectedChangeCall) {
            this.getFilteredAnchorData(this.selectedFastenerFamily !== undefined ? this.selectedFastenerFamily.FastenerFamilyName : null);
        } else {
            this.onSelectedChangeCall = false;
        }
    }

    private onSelectedInsertChange() {
        if (this.selectedFastenerFamily !== undefined && this.selectedInsert !== undefined && !this.fastenerFamilyChanged) {
            this.getFilteredMortarData(this.selectedFastenerFamily.FastenerFamilyName, this.selectedInsert.FastenerName);
        }
        else {
            this.fastenerFamilyChanged = false;
        }
    }

    private onNumberOfAnchorsChange() {
        if (this.numberOfAnchorsTextBox.rawModel !== undefined) {
            let count = this.unitService.parseNumber(this.numberOfAnchorsTextBox.rawModel);

            if (count != null && !Number.isNaN(count)) {
                // round to a whole number
                count = this.number.round(count);

                // min value
                if (count < 1) {
                    count = 1;
                }

                if (count > 1000) {
                    count = 1000;
                }

                this.numberOfAnchorsTextBox.model = this.unitService.formatNumber(count);
            }
            else if (count != null) {
                this.numberOfAnchorsTextBox.rawModel = this.unitService.formatNumber(1);
                this.numberOfAnchorsTextBox.model = this.unitService.formatNumber(1);
            }
        }
    }
}

export interface IAddNewAnchorConstructor {
    project?: Project | ModalValue<Project>;
}

@control('AddNewAnchor')
export class AddNewAnchor extends Modal<AddNewAnchor> {

    @modalProperty()
    public project: Project;

    constructor(ctor?: IAddNewAnchorConstructor) {
        super({
            template,
            templateUrl: 'AddNewAnchor.html',
            controller: AddNewAnchorModalController,
            windowClass: 'default-modal add-new-anchor-modal',
            size: 'lg',
            ...ctor
        });
    }
}

class AddNewAnchorController extends ModalController<AddNewAnchor> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(AddNewAnchor, $scope, $element, $attrs, $uibModal);
    }
}

export class AddNewAnchorDirective extends ModalDirective {
    constructor() {
        super(AddNewAnchorController, AddNewAnchor);
    }
}
