export class UserSettingsValue<TValue> {
    public value: TValue;

    constructor(value: TValue) {
        this.value = value;
    }
}

/**
 * Class storing all the user settings of the
 */
export class UserSettings {
    //#endregion

    //#region Properties

    // USER SETTINGS
    public countryContactEmail: string;

    public user: {
        // general
        general: {
            name: UserSettingsValue<string>;
            languageId: UserSettingsValue<number>;
            regionId: UserSettingsValue<number>;
        }
        // company details
        companyDetails: {
            companyName: UserSettingsValue<string>;
            address: UserSettingsValue<string>;
            phoneNumber: UserSettingsValue<string>;
            email: UserSettingsValue<string>;
            fax: UserSettingsValue<string>;
            website: UserSettingsValue<string>;
        }
        // units
        units: {
            lengthId: UserSettingsValue<number>;
            volumeId: UserSettingsValue<number>;
            areaId: UserSettingsValue<number>;
        }
    };

    // APPLICATION SETTINGS
    public application: {
        general: {
            userAgreement: UserSettingsValue<boolean>;
            userPrivacy: UserSettingsValue<boolean>;
        }
    };

    /**
     * Stay on standard license (HNA)
     */
    public stayOnStandardLicense: UserSettingsValue<boolean>;

    //#region Constructor
    constructor() {
        this.user = {
            general: {
                name: new UserSettingsValue(null),
                languageId: new UserSettingsValue(null),
                regionId: new UserSettingsValue(null)
            },

            companyDetails: {
                companyName: new UserSettingsValue(null),
                address: new UserSettingsValue(null),
                phoneNumber: new UserSettingsValue(null),
                email: new UserSettingsValue(null),
                fax: new UserSettingsValue(null),
                website: new UserSettingsValue(null)
            },

            units: {
                lengthId: new UserSettingsValue(null),
                volumeId: new UserSettingsValue(null),
                areaId: new UserSettingsValue(null)
            }
        };

        this.application = {
            general: {
                userAgreement: new UserSettingsValue(null),
                userPrivacy: new UserSettingsValue(null)
            }
        };

        this.stayOnStandardLicense = new UserSettingsValue(false);
    }

    //#endregion

}
