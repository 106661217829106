import isEqual from 'lodash-es/isEqual';

import { getCodeListTextDeps } from '../../../src/app/entities/codeLists/codeList';
import { ILabelProps } from '../../../src/app/entities/controls/control-props';
import {
    Unit, UnitGroup
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Common.Units';
import {
    BillOfMaterialDetailsEntity
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.BillOfMaterial';
import {
    AnchorType
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Enums';
import { LocalizationService } from '../../../src/app/services/localization.service';
import { CodeListService, ProjectCodeList } from '../../../src/app/services/code-list.service';
import { DateTimeService } from '../../../src/app/services/date-time.service';
import { DocumentService } from '../../Services/document-service';
import { ModalService } from '../../Services/modal-service';
import { UnitService } from '../../Services/unit-service';
import { UserSettingsService } from '../../Services/user-settings-service';
import {
    control, ControlDirective, ControlProperty, IWebControlConstructor, property, WebControl,
    WebControlController
} from '../controls';
import template from './GridBomDetails.html';

export interface IGridBomDetailsConstructor extends IWebControlConstructor {
    items?: BillOfMaterialDetailsEntity[] | ControlProperty;
}

interface IBomTextDisplay {
    value: string;
    raw: string;
    maxLength: number;
    required: boolean;
}

interface IBomDateDisplay {
    label: ILabelProps;
    raw: Date;
}

interface IBomLabelDisplay {
    numberValue: number;
    textValue: string;
    visibility: boolean;
}

interface IBomDetailsDisplay {
    item: BillOfMaterialDetailsEntity;
    product: IBomLabelDisplay;
    lengthOrSize: IBomLabelDisplay;
    articleNumber: IBomLabelDisplay;
    calculatedAmount: IBomLabelDisplay;
    orderAmount: number;
    orderAmountRaw: number;
}

@control('GridBomDetails')
export class GridBomDetails extends WebControl {

    @property()
    public gridOptions: Object;

    @property()
    public items: BillOfMaterialDetailsEntity[];
    constructor(items?: IGridBomDetailsConstructor) {
        super(items);
    }
}

export class GridBomDetailsController extends WebControlController<GridBomDetails> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse',
        'localization',
        'document',
        'modal',
        '$window',
        'dateTime',
        'userSettings',
        'unit',
        'codeList'
    ];

    public gridOptions: Object;
    public items: BillOfMaterialDetailsEntity[];
    private structuredItems: IBomDetailsDisplay[];
    private localization: LocalizationService;
    private document: DocumentService;
    private modal: ModalService;
    private dateTime: DateTimeService;
    private $window: ng.IWindowService;
    private gridApi: any;
    private userSettings: UserSettingsService;
    private unitService: UnitService;
    private codeList: CodeListService;
    private unitLengthLabel: string;
    private unitVolumeLabel: string;

    constructor(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $compile: ng.ICompileService,
        $parse: ng.IParseService,
        localization: LocalizationService,
        document: DocumentService,
        modal: ModalService,
        $window: ng.IWindowService,
        dateTime: DateTimeService,
        userSettings: UserSettingsService,
        unitService: UnitService,
        codeList: CodeListService
    ) {
        super(GridBomDetails, $scope, $element, $attrs, $compile, $parse);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.localization = localization;
            this.$scope = $scope;
            this.document = document;
            this.modal = modal;
            this.$window = $window;
            this.dateTime = dateTime;
            this.userSettings = userSettings;
            this.unitService = unitService;
            this.codeList = codeList;

            this.getUnitNames();

            this.initGrid();

            const localizationChangeSubscription = this.localization.localizationChange.subscribe(() => {
                const translations = [
                    this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBomDetails.Column.Product'),
                    this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBomDetails.Column.LengthOrSize'),
                    this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBomDetails.Column.ArticleNumber'),
                    this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBomDetails.Column.TotalInProject'),
                    this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBomDetails.Column.OrderAmount')
                ];

                for (let i = 0; i < translations.length; i++) {
                    this.gridApi.grid.options.columnDefs[i]['displayName'] = translations[i];
                }
                this.gridApi.core.notifyDataChange('column');
            });

            $scope.$on('$destroy', () => {
                localizationChangeSubscription.unsubscribe();
            });
        })(this.$onInit);
    }

    private formatLength(value: number) {
        value = +value.toString().replace(',', '.').split(' ').join('');
        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Length);
        const userUnit = this.userSettings.settings.user.units.lengthId.value as Unit;
        const userValue = this.unitService.convertUnitValueArgsToUnit(value, defaultUnit, userUnit);

        return this.unitService.formatNumber(userValue, this.unitService.getPrecision(userUnit));
    }

    private formatVolume(value: number) {
        const tmpValue = parseInt(value.toString().replace(',', ''));
        if (tmpValue > 999) {
            value = +value.toString().replace(',', '').split(' ').join('');
        } else {
            value = +value.toString().replace(',', '.').split(' ').join('');
        }
        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Volume);
        const userUnit = this.userSettings.settings.user.units.volumeId.value as Unit;
        const userValue = this.unitService.convertUnitValueArgsToUnit(value, defaultUnit, userUnit);

        return this.unitService.formatNumber(userValue, this.unitService.getPrecision(userUnit));
    }

    private getUnitNames() {
        const codeListDeps = getCodeListTextDeps(this.localization);

        this.unitLengthLabel = this.codeList.codelist[ProjectCodeList.UnitLength].find((item) => item.id == this.userSettings.settings.user.units.lengthId.value).getTranslatedNameText(codeListDeps);
        this.unitVolumeLabel = this.codeList.codelist[ProjectCodeList.UnitVolume].find((item) => item.id == this.userSettings.settings.user.units.volumeId.value).getTranslatedNameText(codeListDeps);
    }

    private structureItems() {
        this.structuredItems = [];
        this.items = this.items || [];
        this.items.forEach((item) => {
            let lengthOrSize: string = null;
            if (item.AnchorType == null || item.AnchorType == AnchorType.Insert) {
                lengthOrSize = this.formatLength(item.LengthOrSize) + this.unitLengthLabel;
            }
            else if (item.AnchorType == AnchorType.Mortar || item.AnchorType == AnchorType.Capsule) {
                lengthOrSize = this.formatVolume(item.LengthOrSize) + this.unitVolumeLabel;
 }
            else if (item.AnchorType == AnchorType.SieveSleeve) {
                lengthOrSize = '';
 }
            else {
                throw new Error('Unknown AnchorType.');
 }

            this.structuredItems.push({
                item,
                product: {
                    textValue: item.AnchorName
                } as IBomLabelDisplay,
                lengthOrSize: {
                    textValue: lengthOrSize
                } as IBomLabelDisplay,
                articleNumber: {
                    textValue: item.ArticleNumber == 'NumberNotFound' ? this.localization.getLocalizedString('Agito.Hilti.Purchaser.Grid.Column.ArticleNumber.ContactHilti') : item.ArticleNumber
                } as IBomLabelDisplay,
                calculatedAmount: {
                    numberValue: item.TotalInProject != null ? item.TotalInProject : 0,
                    textValue: item.TotalInProject != null ? item.TotalInProject.toString() : 0
                } as IBomLabelDisplay,
                orderAmount: item.OrderAmount,
                orderAmountRaw: item.OrderAmount,

                rowKeyDown: ($event: any, row: any, type: string) => { this.rowKeyDown($event, row, type); }
            } as IBomDetailsDisplay);
        });
    }

    private initGrid() {
        this.structureItems();
        this.gridOptions = {
            enableSorting: true,
            paginationPageSize: 10,
            enablePaginationControls: true,
            paginationPageSizes: [],
            rowHeight: 36,
            columnDefs: [
                // product: label
                {
                    displayName: this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBomDetails.Column.Product'),
                    name: 'product',
                    field: 'product',
                    enableSorting: true,
                    enableHiding: false,
                    sortingAlgorithm: this.sortLabel,
                    width: '*',
                    minWidth: 80,
                    cellClass: 'grid-cell grid-cell-label',
                    cellTemplate: '<div class=\'row\'><div class=\'col-xs-12\'>{{row.entity.product.textValue}}</div></div>'
                },
                // lengthOrSize: label
                {
                    displayName: this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBomDetails.Column.LengthOrSize'),
                    name: 'lengthOrSize',
                    field: 'lengthOrSize',
                    enableSorting: true,
                    enableHiding: false,
                    sortingAlgorithm: this.sortLabel,
                    width: '*',
                    minWidth: 80,
                    cellClass: 'grid-cell grid-cell-label',
                    cellTemplate: '<div class=\'row\'><div class=\'col-xs-12\'>{{row.entity.lengthOrSize.textValue}}</div></div>'
                },
                // articleNumber: label
                {
                    displayName: this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBomDetails.Column.ArticleNumber'),
                    name: 'articleNumber',
                    field: 'articleNumber',
                    enableSorting: true,
                    enableHiding: false,
                    sortingAlgorithm: this.sortLabel,
                    width: '*',
                    minWidth: 80,
                    cellClass: 'grid-cell grid-cell-label',
                    cellTemplate: '<div class=\'row\'><div class=\'col-xs-12\'>{{row.entity.articleNumber.textValue}}</div></div>'
                },
                // calculatedAmount: label
                {
                    displayName: this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBomDetails.Column.TotalInProject'),
                    name: 'calculatedAmount',
                    field: 'calculatedAmount',
                    enableSorting: true,
                    enableHiding: false,
                    sortingAlgorithm: this.sortLabel,
                    width: '*',
                    minWidth: 80,
                    cellClass: 'grid-cell-numeric grid-cell-numeric-label',
                    cellTemplate: '<div class=\'row\'><div class=\'col-xs-12\'>{{row.entity.calculatedAmount.textValue}}</div></div>'
                },
                // orderAmount; textbox[int]
                {
                    displayName: this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBomDetails.Column.OrderAmount'),
                    name: 'orderAmount',
                    field: 'orderAmount',
                    enableSorting: true,
                    enableHiding: false,
                    sortingAlgorithm: this.sortNumericTextbox,
                    width: '*',
                    minWidth: 100,
                    cellClass: 'grid-cell-numeric grid-input',
                    cellTemplate: '<input ng-blur=\'row.entity.orderAmount = row.entity.orderAmountRaw\' ng-keydown="row.entity.rowKeyDown($event, row, \'orderAmount\')" style=\'height:36px;padding-right:6px;\' ng-model=\'row.entity.orderAmountRaw\' / >'
                },

            ],
            data: [],
            enableHorizontalScrollbar: 2,
            enableVerticalScrollbar: 2,
            onRegisterApi: (api: any) => {
                this.gridApi = api;
            },
        };
        this.$scope.$watch(() => this.items, this.onItemChange.bind(this), true);
    }

    private rowKeyDown($event: any, row: any, type: string) {
        if ($event.which === 13) { // enter pressed
            switch (type) {
                case 'orderAmount':
                    row.entity.orderAmount = row.entity.orderAmountRaw;
                    break;
            }
        }
    }

    private onItemChange() {
        // remove watches
        for (const itm of this.structuredItems as IBomDetailsDisplay[]) {
            // this.$scope.$watch(() => itm.purchased.checked, () => { });
            this.$scope.$watch(() => itm.orderAmount, () => { });
            this.$scope.$watch(() => itm.orderAmountRaw, () => { });
        }

        // Restructure items into controls
        this.structureItems();

        // add watches
        for (const itm of this.structuredItems as IBomDetailsDisplay[]) {
            this.$scope.$watch(() => itm.orderAmount, (aNew: number, aOld: number) => {
                if (!isEqual(aNew, aOld)) {
                    this.applyChanges(itm);
                }
            });
            this.$scope.$watch(() => itm.orderAmountRaw, (aNew: number, aOld: number) => {
                if (!isEqual(aNew, aOld)) {
                    if (aNew !== null && aNew !== undefined) {
                        itm.orderAmountRaw = this.numberFilter(aNew, aOld);
                    }
                }
            });
        }

        // Change data in the grid control
        this.gridApi.grid.options.data = this.structuredItems;
    }

    private numberFilter(aNew: number, aOld: number) {
        let returnValue = aNew;
        const limit = Math.pow(2, 31) - 1;
        aOld = aOld != undefined && aOld != null ? aOld : 0;

        if (isNaN(aNew)) {
            returnValue = parseFloat(aOld.toString());
        }

        if (aNew > limit) {
            returnValue = parseFloat(aOld.toString());
        }

        if (aNew.toString().indexOf('.') > -1
            || aNew.toString().indexOf(',') > -1) {
            returnValue = parseFloat(aOld.toString());
        }

        return parseFloat(returnValue.toString().trim());
    }

    private applyChanges(bomRow: IBomDetailsDisplay) {
        // Write back to the source item's list
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].BomDetailsId === bomRow.item.BomDetailsId) {
                this.items[i].OrderAmount = bomRow.orderAmount;
            }
        }
    }

    private sortLabel(a: IBomLabelDisplay, b: IBomLabelDisplay) {
        if (a.textValue === b.textValue) return 1;
        if (a.textValue < b.textValue) return -1;
        return 1;
    }

    private sortNumericTextbox(a: number, b: number) {
        const tmp_a = isNaN(a) || a === null ? Number.MIN_SAFE_INTEGER : a;
        const tmp_b = isNaN(b) || b === null ? Number.MIN_SAFE_INTEGER : b;
        if (tmp_a == tmp_b) return 0;
        if (tmp_a < tmp_b) return -1;
        return 1;
    }
}

export class GridBomDetailsDirective extends ControlDirective {
    constructor() {
        super(GridBomDetailsController, GridBomDetails, template);
    }
}
