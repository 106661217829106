import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { VersionDetailsService } from '../../services/version-details.service';

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
    public suiteVersion = '';
    public suiteBuildVersion = '';
    public cluster = '';


    constructor(
        private versionDetailsService: VersionDetailsService
    ) { }

    public async ngOnInit() {
        this.suiteVersion = environment.applicationVersion;

        const clusterData = await this.versionDetailsService.getClusterDetails();
        this.cluster = clusterData.cluster;

        this.suiteBuildVersion = environment.version;
    }
}
