

import { Language } from '../../src/app/entities/codeLists/language';
import {
    DesignType
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.PEDependencies';
import {
    PurchaserCodeLists
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Codelists';
import {
    InitialDataEntity
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Display';
import {
    RegionHub
} from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Enums';
import { ApplicationVersionsService } from '../../src/app/services/application-versions.service';
import { LocalizationService } from '../../src/app/services/localization.service';
import { environment } from '../../src/environments/environment';
import { BomService } from './bom-service';
import { CodeListService, ProjectCodeList } from '../../src/app/services/code-list.service';
import { DocumentService } from './document-service';
import { FavoritesService } from './favorites-service';
import { IRequestConfig } from './http-interceptor-service';
import { LaunchDarklyService } from '../../src/app/services/launch-darkly.service';
import { LicenseService } from './license-service';
import { LoggerService } from '../../src/app/services/logger.service';
import { ModalService } from './modal-service';
import { ProductInformationService } from './product-information.service';
import { RegionOrderService } from './region-order-service';
import { UserEvent, UserService } from './user-service';
import { IUserSettingsService, UserSettingModel } from './user-settings-service';

export class DataService {
    public static $inject = [
        '$q',
        'codeList',
        'user',
        'regionOrder',
        'document',
        'localization',
        'userSettings',
        'modal',
        'license',
        'bom',
        '$http',
        'logger',
        'productInformation',
        'launchDarklyService',
        'applicationVersionsService'
    ];

    public initialDataLoaded: boolean;
    public forceHideLoading: boolean;

    private loadInitialDataPromise: ng.IPromise<void>;

    private $q: ng.IQService;
    private codeList: CodeListService;
    private user: UserService;
    private regionOrder: RegionOrderService;
    private document: DocumentService;
    private localization: LocalizationService;
    private userSettings: IUserSettingsService;
    private modal: ModalService;
    private license: LicenseService;
    private bom: BomService;
    private $http: ng.IHttpService;
    private logger: LoggerService;
    private productInformation: ProductInformationService;
    private launchDarklyService: LaunchDarklyService;
    private applicationVersionsService: ApplicationVersionsService;

    constructor(
        $q: ng.IQService,
        codeList: CodeListService,
        user: UserService,
        regionOrder: RegionOrderService,
        document: DocumentService,
        localization: LocalizationService,
        userSettings: IUserSettingsService,
        modal: ModalService,
        license: LicenseService,
        bom: BomService,
        $http: ng.IHttpService,
        logger: LoggerService,
        productInformation: ProductInformationService,
        launchDarklyService: LaunchDarklyService,
        applicationVersionsService: ApplicationVersionsService
    ) {
        this.$q = $q;
        this.codeList = codeList;
        this.user = user;
        this.regionOrder = regionOrder;
        this.document = document;
        this.localization = localization;
        this.userSettings = userSettings;
        this.modal = modal;
        this.license = license;
        this.bom = bom;
        this.$http = $http;
        this.logger = logger;
        this.productInformation = productInformation;
        this.launchDarklyService = launchDarklyService;
        this.applicationVersionsService = applicationVersionsService;

        this.initialDataLoaded = false;
        this.forceHideLoading = false;
    }

    public loadInitialData() {
        // already called
        if (this.loadInitialDataPromise != null) {
            return this.loadInitialDataPromise;
        }

        // we are authenticated
        if (this.user.isAuthenticated) {
            return this.loadInitialDataPromise = this.loadInitialDataInternal();
        }

        // not authenticated wait for authentication event
        const defer = this.$q.defer<void>();
        this.user.one(UserEvent.authenticated, () => {
            this.loadInitialDataInternal()
                .then(() => {
                    defer.resolve();
                })
                .catch(() => {
                    defer.reject();
                });
        });
        return this.loadInitialDataPromise = defer.promise;
    }

    private getInitialDataInternal(config?: IRequestConfig): ng.IPromise<void> {
        let url = `${environment.purchaserApplicationWebServiceUrl}GetInitialData`;

        // Pseudo translate
        if (environment.translate) {
            url += '?pseudoTranslate=true';
        }

        const _promise = this.$q.defer<void>();

        this.$http.get<InitialDataEntity>(url, config || ({} as IRequestConfig))
            .then(async (response) => {
                const dataEntity = response.data;

                // init codelists
                this.codeList.initialize(dataEntity.PurchaserCodeLists || ({} as PurchaserCodeLists));

                // init translations
                this.localization.initialize(dataEntity.Translations);

                // init user settings
                this.userSettings.initialize((dataEntity.UserSettings as any as UserSettingModel[]) || [], dataEntity.CountryContactEmail, dataEntity.CcmsUserData);

                // application version
                await this.applicationVersionsService.setApplicationVersion();

                // init document service results
                // filter out unsupported designs
                if (dataEntity.Documents?.Documents) {
                    const supportedDesigns = [DesignType.Concrete.toString(), DesignType.Handrail.toString(), DesignType.Masonry.toString(), DesignType.MetalDeck.toString()];
                    const filteredOutDocuments = Object.fromEntries(Object.entries(dataEntity.Documents.Documents)
                        .filter(([k, v]) => supportedDesigns.includes(v.Metadata['designType'])));
                    dataEntity.Documents.Documents = filteredOutDocuments;
                }

                this.document.initialize(dataEntity.Documents || { Projects: {}, Documents: {} }, dataEntity.ProjectArchive || [], dataEntity.NumberOfManualDesigns as { [key: string]: number });

                // init userlicense
                this.license.initialize(dataEntity.License);
                this.checkIfLicenseParsingFailed();

                // set back end application version
                this.user.setBackendApplicationVersion(dataEntity.ApplicationVersion);

                _promise.resolve();
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'DataService', 'getInitialDataInternal');

                _promise.reject(response);
            });

        return _promise.promise;
    }

    private loadInitialDataInternal() {
        return this.getInitialDataInternal({ ignoreErrors: true } as IRequestConfig)
            .then(() => {
                if (this.userSettings.settings.user.general.regionId.value == null ||
                    this.userSettings.settings.user.general.languageId.value == null) {
                    // open the modal for region and language selection
                    return this.modal.selectRegionLanguage.open()[0].result.result;
                }

                else {
                    const language = (this.codeList.codelist[ProjectCodeList.Language] as Language[]).find(language => language.id == this.userSettings.settings.user.general.languageId.value);
                    return this.localization.getTranslations(language.culture);
                }
            })
            .then(() => {
                if (this.userSettings.settings.application.general.userAgreement.value == null) {
                    // open the modal for user agreement
                    const regionLanguage = this.userSettings.getRegionLanguage();
                    let dataPrivacyUrl = '';
                    let userAgreementUrl = '';

                    if (regionLanguage != null) {
                        dataPrivacyUrl = regionLanguage.hiltiDataPrivacyUrl;
                        userAgreementUrl = regionLanguage.userAgreement;
                    }

                    return this.modal.userAgreement.open(dataPrivacyUrl, userAgreementUrl)[0].result.closed;
                }
                return this.$q.when();
            })
            .then(() => {
                if (this.userSettings.settings.application.general.userPrivacy.value == null) {

                    const region = this.userSettings.getRegionById(this.userSettings.settings.user.general.regionId.value);

                    if (region && region.hubId == RegionHub.W1_HNA.valueOf()) {
                        this.userSettings.settings.application.general.userPrivacy.value = true;
                    }
                    else {
                        // open the modal for user privacy for non HNA
                        return this.modal.userAgreementPrivacy.open()[0].result.closed;
                    }

                }
                return this.$q.when();
            })
            .then(() => {
                // for trial user or trial expired users we have to display trial banner
                if (this.license.isTrial() || this.license.isTrialExpired()) {
                    this.license.displayTrialInformation = true;
                }

                return this.$q.when();
            })
            .then(() => {
                const regionId = this.userSettings.settings.user.general.regionId.value;
                return this.bom.loadBomData(regionId);
            })
            .then(() => {
                return this.productInformation.loadRegionLinksFromService({ ignoreErrors: true });
            })
            .then(() => this.regionOrder.initialize())
            .then(() => this.launchDarklyService.initialize(this.user.authentication.userId, this.user.authentication.userName) /* load feature flags first */)
            .then(() => {
                this.initialDataLoaded = true;
            })
            .catch<void>((response) => {
                this.loadInitialDataPromise = null;
                this.forceHideLoading = true;

                return this.$q.reject(response);
            });
    }

    private checkIfLicenseParsingFailed() {
        if (this.license.isLicenseParsingFailed()) {
            this.modal.alert.openLicenseError()
        }
    }
}
