import {
    Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';

import { randomString } from '../../helpers/random';
import { NgbTooltipValue } from '../content-tooltip/content-tooltip.component';

export const enum CollapseLook {
  Section             = 'Section',
  SectionBox          = 'SectionBox',
}

@Component({
    selector: 'app-section',
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit, OnChanges {
    public isSectionBoxStyled = false;

    @Input()
    public id = randomString(8);

    @Input()
    public title = '';

    @Input()
    public look = CollapseLook.Section;

    @Input()
    public collapsed = false;

    @Input()
    public collapseTooltip?: NgbTooltipValue;

    @Output()
    public collapsedChange = new EventEmitter<boolean>();

    constructor(private elementRef: ElementRef<HTMLElement>) { }

    public ngOnInit() {
        this.collapsed = false;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['look'] != null) {
            this.isSectionBoxStyled = this.look == CollapseLook.SectionBox;
        }
    }

    public toggleCollapsed() {
        this.collapsed = !this.collapsed;

        this.collapsedChange.emit(this.collapsed);
    }
}
