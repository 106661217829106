export function triggerChangeEvent(element: Element) {
    let event: Event;

    if (typeof Event == 'function') {
        event = new Event('change');
    }
    else {
        // IE
        event = document.createEvent('HTMLEvents');
        event.initEvent('change', false, true);
    }

    element.dispatchEvent(event);
}

export function triggerInputEvent(element: Element) {
    let event: Event;

    if (typeof Event == 'function') {
        event = new Event('input', { bubbles: true, cancelable: true });
    }
    else {
        // IE
        event = document.createEvent('Event');
        event.initEvent('input', true, true);
    }

    element.dispatchEvent(event);
}

export function triggerWindowResizeEvent() {
    let event: Event;

    if (typeof Event == 'function') {
        event = new Event('resize');
    }
    else {
        // IE
        event = document.createEvent('UIEvents');
        event['initUIEvent']('resize', true, false, window, 0);
    }

    window.dispatchEvent(event);
}

export function isElementVisible(element: HTMLElement) {
    return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
}
