import { LocalizationService } from '../../src/app/services/localization.service';

export class TextSanitizeDirective implements ng.IDirective {
    public restrict = 'A';

    private localization: LocalizationService;

    constructor(localization: LocalizationService) {
        this.localization = localization;
    }

    public static Factory() {
        const directive = (localization: LocalizationService) => new TextSanitizeDirective(localization);

        directive.$inject = ['localization'];

        return directive;
    }

    public link: ng.IDirectiveLinkFn = ($scope, element, attributes): void => {
        attributes.$observe('agtTextSanitize', () => {
            const text = attributes['agtTextSanitize'];

            element.html(this.localization.sanitizeText(text, LocalizationService.SubSup));
        });
    }
}
