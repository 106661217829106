import { LocalizationService } from '../../../src/app/services/localization.service';
import { control, ControlDirective, WebControl, WebControlController } from '../controls';
import template from './ContactHilti.html';

export interface IContactHiltiConstructor {
}

@control('ContactHilti')
export class ContactHilti extends WebControl {
    constructor(contactHilti?: IContactHiltiConstructor) {
        super(contactHilti);
    }
}

export class ContactHiltiDirective extends ControlDirective {
    constructor() {
        super(ContactHiltiController, ContactHilti, template);
    }
}

class ContactHiltiController extends WebControlController<ContactHilti> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse',
        'localization'
    ];

    private localization: LocalizationService;

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $compile: ng.ICompileService, $parse: ng.IParseService, localization: LocalizationService) {
        super(ContactHilti, $scope, $element, $attrs, $compile, $parse);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.localization = localization;
        })(this.$onInit);
    }
}
