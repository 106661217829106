export interface IChangeConstructor {
    name?: string;
    oldValue?: any;
    newValue?: any;
}

export interface IChange<TValue> {
    name: string;
    oldValue: TValue;
    newValue: TValue;
}

export class Change implements IChange<any> {
    public name: string;
    public oldValue: any;
    public newValue: any;

    constructor(change?: IChangeConstructor) {
        if (change != null) {
            this.name = change.name;
            this.oldValue = change.oldValue;
            this.newValue = change.newValue;
        }
    }
}
