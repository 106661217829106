import { Pipe, PipeTransform } from '@angular/core';

import { LocalizationService } from '../services/localization.service';

@Pipe({
    name: 'l10n'
})
export class L10nPipe implements PipeTransform {
    constructor(
        private localizationService: LocalizationService
    ) { }

    /**
     * Only by key. Tags and other arguments must not be added because this function must be fast.
     *
     * If you need tags, create a cache value manually and don't use this pipe.
     */
    public transform(key: string): string {
        return this.localizationService.getLocalizedString(key);
    }
}
