import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';
import startCase from 'lodash-es/startCase';

import {
    getCodeListTextDeps, ICodeListTextDeps
} from '../../../src/app/entities/codeLists/codeList';
import { Language } from '../../../src/app/entities/codeLists/language';
import { Region } from '../../../src/app/entities/codeLists/region';
import { Unit as CodeListUnit } from '../../../src/app/entities/codeLists/unit';
import { Unit } from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Common.Units';
import { LocalizationService } from '../../../src/app/services/localization.service';
import { environment } from '../../../src/environments/environment';
import { BomService } from '../../Services/bom-service';
import { CodeListService, ProjectCodeList } from '../../../src/app/services/code-list.service';
import { UserSettingsService } from '../../Services/user-settings-service';
import { Bind, control, ControlProperty, property, Translation } from '../controls';
import { Dropdown } from '../Dropdown/Dropdown';
import { DropdownItem } from '../DropdownItem/DropdownItem';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective } from '../Modal/Modal';
import template from './ApplicationSettings.html';

export class ApplicationSettingsModalController extends BaseModalController<ApplicationSettings> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'localization',
        'control',
        'codeList',
        'userSettings',
        'bom',
    ];
    public submitted: boolean;

    // controls
    public regionDropdown: Dropdown<Region>;
    public languageDropdown: Dropdown<Language>;
    public unitLengthDropdown: Dropdown<CodeListUnit>;
    public unitVolumeDropdown: Dropdown<CodeListUnit>;
    public unitAreaDropdown: Dropdown<CodeListUnit>;

    private pendingSave: boolean;
    private $scope: ng.IScope;
    private localization: LocalizationService;
    private codelists: CodeListService;
    private userSettings: UserSettingsService;
    private bomService: BomService;

    // selected settings
    private selectedRegion: Region;
    private selectedLanguage: Language;
    private selectedUnitLength: CodeListUnit;
    private selectedUnitVolume: CodeListUnit;
    private selectedUnitArea: CodeListUnit;

    private codeListDeps: ICodeListTextDeps;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        localization: LocalizationService,
        control: ApplicationSettings,
        codeList: CodeListService,
        userSettings: UserSettingsService,
        bomService: BomService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.$scope['localization'] = this.localization = localization;
            this.codelists = codeList;
            this.userSettings = userSettings;
            this.bomService = bomService;

            this.codeListDeps = getCodeListTextDeps(this.localization);

            // don't close the modal if save is pending
            this.$scope.$on('modal.closing', (event) => {
                if (this.pendingSave) {
                    event.preventDefault();
                }
            });

            this.initSettingDropdown();
        })(this.$onInit);
    }

    private get form() {
        return this.$scope['form'] as ng.IFormController;
    }

    public save() {
        if (this.submitted || !this.form.$valid) {
            this.submitted = false;
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        this.userSettings.settings.user.general.languageId.value = this.selectedLanguage.id;
        this.userSettings.settings.user.general.regionId.value = this.selectedRegion.id;
        this.userSettings.settings.user.units.lengthId.value = this.selectedUnitLength.id;
        this.userSettings.settings.user.units.volumeId.value = this.selectedUnitVolume.id;
        this.userSettings.settings.user.units.areaId.value = this.selectedUnitArea.id;
        this.userSettings.save().then(() => {
            // Change language and update translations
            const c = this.codelists.codelist[ProjectCodeList.Language].find((item) => { item.id == this.userSettings.settings.user.general.languageId.value; });
            this.localization.getTranslations((this.codelists.codelist[ProjectCodeList.Language] as Language[]).find((item) => item.id == this.userSettings.settings.user.general.languageId.value).culture);

            // Update BOM properties
            this.bomService.updatePurchaserDataEntityOptions(true);

            if (this.control.unitLength !== this.selectedUnitLength.id
                || this.control.unitVolume !== this.selectedUnitVolume.id
                || this.control.unitArea !== this.selectedUnitArea.id
                || this.control.regionId !== this.selectedRegion.id
                || this.control.languageLCID !== this.selectedLanguage.id) {
                this.$scope.$watch(() => this.control.loading, ((a: boolean, b: boolean) => {
                    if (a === false) {
                        // write back to the model
                        this.control.unitLength = this.selectedUnitLength.id;
                        this.control.unitVolume = this.selectedUnitVolume.id;
                        this.control.unitArea = this.selectedUnitArea.id;
                        this.control.languageLCID = this.selectedLanguage.id;
                        this.control.regionId = this.selectedRegion.id;

                        this.pendingSave = false;
                        this.close();
                    }
                    else if (a != true) {
                        this.pendingSave = false;
                        this.close();
                    }
                }).bind(this));
            }
            else {
                this.pendingSave = false;
                this.close();
            }
        })
            .catch(() => {
                this.pendingSave = false;
                this.close();
            });
    }

    public close() {
        this.$uibModalInstance.close();
    }

    private initUserSettings() {
        if (environment.translate) {
            // pseudo language
            this.selectedLanguage = (this.codelists.codelist[ProjectCodeList.Language] as Language[]).find((item) => item.culture == LocalizationService.pseudoLanguage);
        }
        else {
            this.selectedLanguage = (this.codelists.codelist[ProjectCodeList.Language] as Language[]).find((item) => item.id == this.userSettings.settings.user.general.languageId.value);
        }

        this.selectedRegion = (this.codelists.codelist[ProjectCodeList.Region] as Region[]).find((item) => item.id == this.userSettings.settings.user.general.regionId.value);
        this.selectedUnitLength = (this.codelists.codelist[ProjectCodeList.UnitLength] as CodeListUnit[]).find((item) => item.id == this.userSettings.settings.user.units.lengthId.value);
        this.selectedUnitVolume = (this.codelists.codelist[ProjectCodeList.UnitVolume] as CodeListUnit[]).find((item) => item.id == this.userSettings.settings.user.units.volumeId.value);
        this.selectedUnitArea = (this.codelists.codelist[ProjectCodeList.UnitArea] as CodeListUnit[]).find((item) => item.id == this.userSettings.settings.user.units.areaId.value);
    }

    private createUnitDropdown(name: string, selectedValue: Bind, type: number) {
        return new Dropdown<CodeListUnit>({
            disabled: new Bind('ctrl.submitted'),
            required: true,
            id: `application-settings-unit-${name}`,
            title: new Translation(`Agito.Hilti.Purchaser.ApplicationSettings.${startCase(name)}Unit`),
            items: this.codelists.codelist[type].map((item) =>
                new DropdownItem<CodeListUnit>({
                    value: item,
                    text: item.getTranslatedNameText(this.codeListDeps)
                })
            ),
            selectedValue
        });
    }

    private initSettingDropdown() {
        this.initUserSettings();

        this.regionDropdown = new Dropdown<Region>({
            disabled: new Bind('ctrl.submitted'),
            required: true,
            id: 'application-settings-region',
            title: new Translation('Agito.Hilti.Purchaser.ApplicationSettings.Region'),
            items: sortBy(this.codelists.codelist[ProjectCodeList.Region], (item) => item.getTranslatedNameText(this.codeListDeps)).map((item) =>
                new DropdownItem<Region>({
                    value: item,
                    text: item.getTranslatedNameText(this.codeListDeps)
                })
            ),
            selectedValue: new Bind(`ctrl.selectedRegion`)
        });



        const languageCodeList = this.codelists.codelist[ProjectCodeList.Language] as Language[];
        let languageItems: DropdownItem<Language>[] = null;

        // filter languages by pseudo language and translate
        if (environment.translate) {
            const pseudoLanguageCodeList = languageCodeList.find(languageCodeListItem => languageCodeListItem.culture == LocalizationService.pseudoLanguage);

            languageItems = [new DropdownItem({
                value: pseudoLanguageCodeList,
                text: 'Pseudo'
            })];
        }
        else {
            const nonPseudoLanguageCodeList = languageCodeList.filter(languageCodeListItem => languageCodeListItem.culture != LocalizationService.pseudoLanguage);

            languageItems = nonPseudoLanguageCodeList.map(languageCodeListItem => new DropdownItem({
                value: languageCodeListItem,
                text: languageCodeListItem.getTranslatedNameText(this.codeListDeps)
            }));
        }

        // sort languages
        languageItems = sortBy(languageItems, languageItem => (languageItem.text || '').toLowerCase());

        this.languageDropdown = new Dropdown<Language>({
            disabled: new Bind('ctrl.submitted || ctrl.appConfig.translate'),
            required: true,
            id: 'application-settings-language',
            title: new Translation('Agito.Hilti.Purchaser.ApplicationSettings.Language'),
            items: languageItems,
            selectedValue: new Bind(`ctrl.selectedLanguage`)
        });

        this.unitLengthDropdown = this.createUnitDropdown(
            'length',
            new Bind(`ctrl.selectedUnitLength`),
            ProjectCodeList.UnitLength
        );

        this.unitVolumeDropdown = this.createUnitDropdown(
            'volume',
            new Bind(`ctrl.selectedUnitVolume`),
            ProjectCodeList.UnitVolume
        );

        this.unitAreaDropdown = this.createUnitDropdown(
            'area',
            new Bind(`ctrl.selectedUnitArea`),
            ProjectCodeList.UnitArea
        );

        this.$scope.$watch(() => this.regionDropdown.selectedValue, (this.onRegionChange).bind(this));
    }

    private onRegionChange(newItem: Region, oldItem: Region) {
        if (newItem !== undefined && newItem !== null
            && !isEqual(newItem, oldItem)) {
            // select default units for slected region
            this.selectedUnitLength = (this.codelists.codelist[ProjectCodeList.UnitLength] as CodeListUnit[]).find((item) => item.id === newItem.defaultUnitLength);
            this.selectedUnitVolume = (this.codelists.codelist[ProjectCodeList.UnitVolume] as CodeListUnit[]).find((item) => item.id === newItem.defaultUnitVolume);
            this.selectedUnitArea = (this.codelists.codelist[ProjectCodeList.UnitArea] as CodeListUnit[]).find((item) => item.id === newItem.defaultUnitArea);
        }
    }
}

export interface IApplicationSettingsConstructor {
    unitLength: Unit | ControlProperty;
    unitVolume: Unit | ControlProperty;
    unitArea: Unit | ControlProperty;
    languageLCID: number | ControlProperty;
    regionId: number | ControlProperty;
    loading: boolean | ControlProperty;
}

@control('ApplicationSettings')
export class ApplicationSettings extends Modal<ApplicationSettings> {

    @property()
    public unitLength: Unit;

    @property()
    public unitVolume: Unit;

    @property()
    public unitArea: Unit;

    @property()
    public languageLCID: number;

    @property()
    public regionId: number;

    @property()
    public loading: boolean;

    constructor(ctor: IApplicationSettingsConstructor) {
        super({
            template,
            templateUrl: 'ApplicationSettings.html',
            controller: ApplicationSettingsModalController,
            windowClass: 'default-modal application-settings-modal',
            size: 'lg',
            ...ctor
        });
    }
}

class ApplicationSettingsController extends ModalController<ApplicationSettings> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal',
    ];

    constructor($scope: ng.IScope,
                $element: ng.IAugmentedJQuery,
                $attrs: ng.IAttributes,
                $uibModal: ng.ui.bootstrap.IModalService) {
        super(ApplicationSettings, $scope, $element, $attrs, $uibModal);
    }
}

export class ApplicationSettingsDirective extends ModalDirective {
    constructor() {
        super(ApplicationSettingsController, ApplicationSettings);
    }
}
