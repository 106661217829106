import isEqual from 'lodash-es/isEqual';

import { ILabelProps } from '../../../src/app/entities/controls/control-props';
import {
    BillOfMaterialDetailsEntity, BillOfMaterialEntity
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.BillOfMaterial';
import { PropertyMetaData } from '../../../src/app/properties/properties';
import { LocalizationService } from '../../../src/app/services/localization.service';
import { Project } from '../../Entities/Project';
import { BomService } from '../../Services/bom-service';
import { DateTimeService } from '../../../src/app/services/date-time.service';
import { DocumentService } from '../../Services/document-service';
import { ModalService } from '../../Services/modal-service';
import { BomDetails } from '../BomDetails/BomDetails';
import { Checkbox } from '../Checkbox/Checkbox';
import {
    control, ControlDirective, ControlProperty, IWebControlConstructor, property, WebControl,
    WebControlController
} from '../controls';
import { ModalTranslation } from '../Modal/BaseModal';
import template from './GridBom.html';

export interface IGridBomConstructor extends IWebControlConstructor {
    items?: BillOfMaterialEntity[] | ControlProperty;
}

interface IBomTextDisplay {
    value: string;
    raw: string;
    maxLength: number;
    required: boolean;
}

interface IBomDateDisplay {
    label: ILabelProps;
    raw: Date;
}

interface IBomLabelDisplay {
    numberValue: number;
    textValue: string;
    visibility: boolean;
}

interface IBomDisplay {
    item: BillOfMaterialEntity;
    fileName: IBomLabelDisplay;
    created: IBomDateDisplay;
    remark: IBomTextDisplay;
    archive: {
        archive: (a: BillOfMaterialEntity) => void
    };
    showBomDetails: (bomId: number, bomName: string, createdDate: string) => void;
}

@control('GridBom')
export class GridBom extends WebControl {

    @property()
    public gridOptions: Object;

    @property()
    public items: BillOfMaterialEntity[];

    @property()
    public project: Project;
    constructor(items?: IGridBomConstructor) {
        super(items);
    }
}

export class GridBomController extends WebControlController<GridBom> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse',
        'localization',
        'document',
        'modal',
        '$window',
        'dateTime',
        'bom'
    ];

    public gridOptions: Object;
    public items: BillOfMaterialEntity[];
    public project: Project;
    public bomDetailsModalCtrl: BomDetails;
    private structuredItems: IBomDisplay[];
    private localization: LocalizationService;
    private document: DocumentService;
    private modal: ModalService;
    private dateTime: DateTimeService;
    private $window: ng.IWindowService;
    private bomService: BomService;
    private gridApi: any;

    constructor(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $compile: ng.ICompileService,
        $parse: ng.IParseService,
        localization: LocalizationService,
        document: DocumentService,
        modal: ModalService,
        $window: ng.IWindowService,
        dateTime: DateTimeService,
        bom: BomService
    ) {
        super(GridBom, $scope, $element, $attrs, $compile, $parse);

        this.$onInit = (($onInit) => () => {
            $onInit?.();
            this.localization = localization;
            this.$scope = $scope;
            this.document = document;
            this.modal = modal;
            this.$window = $window;
            this.dateTime = dateTime;
            this.bomService = bom;

            this.initGrid();

            const localizationChangeSubscription = this.localization.localizationChange.subscribe(() => {
                const translations = [
                    this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBom.Column.FileName'),
                    this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBom.Column.Remark'),
                    this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBom.Column.Created'),
                    this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBom.Column.Archived')
                ];

                for (let i = 0; i < translations.length; i++) {
                    this.gridApi.grid.options.columnDefs[i]['displayName'] = translations[i];
                }
                this.gridApi.core.notifyDataChange('column');
            });

            $scope.$on('$destroy', () => {
                localizationChangeSubscription.unsubscribe();
            });

            this.bomDetailsModalCtrl = new BomDetails({});
        })(this.$onInit);
    }

    private exportCSV(item: BillOfMaterialEntity) {

    }

    private onConfirmArchiveBom(bomId: number) {
        this.modal.confirmChange.close();
        this.bomService.archiveBom(bomId);
    }

    private onCancelArchiveBom() {
        this.modal.confirmChange.close();
    }

    private archive(item: BillOfMaterialEntity) {
        this.modal.confirmChange.open('ArchiveBom',
            new ModalTranslation('Agito.Hilti.Purchaser.ArchiveBom.Title'),
            new ModalTranslation('Agito.Hilti.Purchaser.ArchiveBom.Message'),
            new ModalTranslation('Agito.Hilti.Purchaser.Yes'),
            new ModalTranslation('Agito.Hilti.Purchaser.No'),
            () => this.onConfirmArchiveBom(item.BomId),
            (...args: any[]) => this.onCancelArchiveBom.apply(this, args));
    }

    private placeOrder(item: BillOfMaterialEntity) {
        if (item.Purchased) {
            return;
        }

        this.bomService.placeOrder(item.BomId);
    }

    private structureItems() {
        this.structuredItems = [];
        this.items = this.items || [];
        this.items.forEach((item) => {
            this.structuredItems.push({
                item,
                fileName: {
                    textValue: item.BomName
                } as IBomLabelDisplay,
                created: {
                    label: { text: this.localization.moment(item.CreatedDate).format('lll') } as ILabelProps,
                    raw: item.CreatedDate
                } as IBomDateDisplay,
                remark: {
                    value: item.Remark,
                    raw: item.Remark,
                    maxLength: 500,
                    required: false
                } as IBomTextDisplay,
                archive: {
                    archive: (this.archive).bind(this)
                },

                rowKeyDown: ($event: any, row: any, type: string) => { this.rowKeyDown($event, row, type); },
                showBomDetails: (bomId: number, bomName: string, createdDate: string) => { this.bomDetailsModal(bomId, bomName, createdDate); }
            } as IBomDisplay);
        });
    }

    private initGrid() {
        this.structureItems();
        this.gridOptions = {
            enableSorting: true,
            paginationPageSize: 20,
            enablePaginationControls: true,
            paginationPageSizes: [],
            rowHeight: 60,
            columnDefs: [
                // fileName: label
                {
                    displayName: this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBom.Column.FileName'),
                    name: 'fileName',
                    field: 'fileName',
                    enableSorting: true,
                    enableHiding: false,
                    sortingAlgorithm: this.sortVersionName,
                    width: '*',
                    minWidth: 80,
                    cellClass: 'grid-cell grid-cell-btn',
                    cellTemplate: '<div class=\'btn btn-dashed\' ng-click=\'row.entity.showBomDetails(row.entity.item.BomId, row.entity.item.BomName, row.entity.created.label.text)\'>{{row.entity.fileName.textValue}}</div>'
                },
                // created: label
                {
                    displayName: this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBom.Column.Created'),
                    name: 'created',
                    field: 'created',
                    enableColumnMenu: true,
                    enableSorting: true,
                    enableHiding: false,
                    sortingAlgorithm: this.sortDate,
                    width: '*',
                    minWidth: 200,
                    cellClass: 'grid-cell grid-cell-label',
                    cellTemplate: '<agt-label agt-control=\'row.entity.created.label\' />'
                },
                // remark: textarea
                {
                    displayName: this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBom.Column.Remark'),
                    name: 'remark',
                    field: 'remark',
                    enableColumnMenu: false,
                    enableSorting: false,
                    enableHiding: false,
                    width: '*',
                    minWidth: 400,
                    cellClass: 'grid-cell',
                    cellTemplate: '<textarea ng-required=\'{{row.entity.remark.required}}\' ng-attr-maxlength=\'{{row.entity.remark.maxLength}}\' ng-blur=\'row.entity.remark.value = row.entity.remark.raw\' ng-keydown="row.entity.rowKeyDown($event, row, \'remark\')" ng-model=\'row.entity.remark.raw\'></textarea>'
                },
                // Archive: button
                {
                    displayName: this.localization.getLocalizedString('Agito.Hilti.Purchaser.GridBom.Column.Archived'),
                    name: 'archive',
                    field: 'archive',
                    enableSorting: false,
                    enableHiding: false,
                    enableColumnMenu: false,
                    width: '*',
                    minWidth: 120,
                    cellClass: 'grid-cell grid-cell-btn',
                    cellTemplate: '<div class=\'btn btn-dashed\' ng-click=\'row.entity.archive.archive(row.entity.item)\' i18n=\'Agito.Hilti.Purchaser.GridBom.Column.Archived.Cell.Archive\'></div>'
                },
            ],
            data: [],
            enableHorizontalScrollbar: 2,
            enableVerticalScrollbar: 2,
            onRegisterApi: (api: any) => {
                this.gridApi = api;
            },
        };
        this.$scope.$watch(() => this.items, this.onItemChange.bind(this), true);
    }

    private rowKeyDown($event: any, row: any, type: string) {
        if ($event.which === 13) { // enter pressed
            switch (type) {
                case 'remark':
                    row.entity.remark.value = row.entity.remark.raw;
                    break;
            }
        }
    }

    private bomDetailsModal(bomId: number, bomName: string, createdDate: string) {
        const bomDetails = this.project.model[PropertyMetaData.BOM_BOMDetailsList.id] as BillOfMaterialDetailsEntity[];

        this.bomDetailsModalCtrl.setProject(this.project);
        this.bomDetailsModalCtrl.setBomDetails(bomDetails.filter((bomDetail) => bomDetail.BomId == bomId));
        this.bomDetailsModalCtrl.setBomName(bomName);
        this.bomDetailsModalCtrl.setBomCreatedDate(createdDate);
        this.bomDetailsModalCtrl.setBomId(bomId);

        this.bomDetailsModalCtrl.open();
    }

    private onItemChange() {
        // remove watches
        for (const itm of this.structuredItems as IBomDisplay[]) {
            this.$scope.$watch(() => itm.remark.value, () => { });
        }

        // Restructure items into controls
        this.structureItems();

        // add watches
        for (const itm of this.structuredItems as IBomDisplay[]) {
            this.$scope.$watch(() => itm.remark.value, (aNew: string, aOld: string) => {
                if (!isEqual(aNew, aOld)) {
                    if (itm.remark.required === true
                        && (aNew === undefined || aNew === '' || aNew === null)) {
                        itm.remark.raw = aOld;
                    }

                    this.applyChanges(itm);
                }
            });
        }

        // Change data in the grid control
        this.gridApi.grid.options.data = this.structuredItems;
    }

    private applyChanges(bomRow: IBomDisplay) {
        // Write back to the source item's list
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].BomId === bomRow.item.BomId) {
                this.items[i].Remark = bomRow.remark.raw;
            }
        }
    }

    private sortDate(a: IBomDateDisplay, b: IBomDateDisplay) {
        const tmp_a = a.raw.valueOf();
        const tmp_b = b.raw.valueOf();

        if (tmp_a === tmp_b) return 0;
        if (tmp_a < tmp_b) return -1;
        return 1;
    }

    private sortVersionName(a: IBomTextDisplay, b: IBomTextDisplay) {
        if (a.value === b.value) return 1;
        if (a.value < b.value) return -1;
        return 1;
    }

    private sortCheckbox(a: Checkbox<boolean>, b: Checkbox<boolean>) {
        const tmp_a = a.checked ? 1 : 0;
        const tmp_b = b.checked ? 1 : 0;
        if (tmp_a == tmp_b) return 0;
        if (tmp_a < tmp_b) return -1;
        return 1;
    }
}

export class GridBomDirective extends ControlDirective {
    constructor() {
        super(GridBomController, GridBom, template);

    }
}
