import {
    Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild
} from '@angular/core';

import { randomString } from '../../helpers/random';
import { LocalizationService } from '../../services/localization.service';
import { Tooltip } from '../content-tooltip/content-tooltip.component';

export interface DropdownProps<TValue = number> {
    id?: string;
    title?: string;
    items?: DropdownItem<TValue>[];
    disabled?: boolean;
    selectedValue?: TValue;
    tooltip?: Tooltip;
}

export interface DropdownItem<TValue> {
    value: TValue;
    text: string;
}

const keyUp = 'Up';
const keyArrowUp = 'ArrowUp';
const keyDown = 'Down';
const keyArrowDown = 'ArrowDown';
const keyEnter = 'Enter';
const keyTab = 'Tab';
const keySpace = ' ';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent<TValue> implements OnInit {

    @Input()
    public id = randomString(8);

    @Input()
    public title: string;

    @Input()
    public items: DropdownItem<TValue>[];

    @Input()
    public disabled: boolean = false;

    @Input()
    public selectedValue: TValue;

    @Output()
    public selectedValueChange = new EventEmitter<TValue>();

    @Input()
    public tooltip: Tooltip;

    @ViewChild('controlContainer')
    controlContainerElement: ElementRef;

    @ViewChild('dropdownItems')
    dropdownItemsElement: ElementRef;

    @ViewChild('dropdownButton')
    buttonElement: ElementRef;

    private opened: boolean;
    private selectedItem: DropdownItem<TValue>;
    private searchText: string;
    private searchTimeout: number;

    constructor(private localizationService: LocalizationService) { }

    public get isOpen() {
        return this.opened;
    }

    public get text() {
        let text: string = '';

        if (this.selectedValue != null && this.items != null) {
            text = this.items.find(item => item.value == this.selectedValue).text;
        }
        else {
            text = this.localizationService.getLocalizedString('Agito.Hilti.Profis3.Dropdown.NoneSelected');
        }

        return text;
    }

    ngOnInit(): void {
        this.opened = false;

        if (this.selectedValue != null && this.items != null) {
            this.selectedItem = this.items.find(item => item.value == this.selectedValue);
        }
    }

    @HostListener("document:click", ['$event.target'])
    clickedOut(target: HTMLElement) {
        let controlContainer = this.controlContainerElement.nativeElement as HTMLElement;
        if (!controlContainer.contains(target) && this.opened) {
            this.opened = false;
            this.searchTimeout = null;
        }
    }

    public itemSelected(item: DropdownItem<TValue>) {
        if (this.selectedItem) {
            return item.value == this.selectedItem.value;
        }

        return false;
    }

    public onClick(event: MouseEvent) {
        if (this.items != null && this.items.length > 0) {
            this.opened = !this.opened;

            if (this.opened) {
                this.searchText = '';
                this.selectedItem = this.items.find(item => item.value == this.selectedValue);

                setTimeout(() => this.scrollIntoView(this.selectedValue), 100);
            }
        }
    }

    public onKeyPress(event: KeyboardEvent) {
        if (this.opened) {
            event.preventDefault();
            const eventKey = event.key;

            if (this.searchTimeout != null) {
                window.clearTimeout(this.searchTimeout);
            }

            this.searchTimeout = window.setTimeout(() => {
                this.searchTimeout = null;
                this.searchText = '';
            }, 1000);

            this.searchText += eventKey;

            const itemToSelect = this.items.find(item => item.text.substr(0, this.searchText.length).toLowerCase() == this.searchText.toLowerCase());

            if (itemToSelect != null) {
                this.selectedItem = itemToSelect;
                this.scrollIntoView(this.selectedItem.value);
            }
        }
    }

    public onKeyDown(event: KeyboardEvent) {
        if (this.opened) {
            const eventCode = event.key;
            if (eventCode == keyEnter || eventCode == keyTab || eventCode == keySpace) {
                event.preventDefault();

                // set selected value
                const selectedItem = this.selectedItem;
                if (selectedItem != null) {
                    this.selectedValue = selectedItem.value;
                    this.selectedValueChange.emit(selectedItem.value);
                }

                this.opened = false;
            }
            else if (eventCode == keyDown || eventCode == keyArrowDown) {
                event.preventDefault();

                const selectedIndex = this.items.findIndex(item => item.value == this.selectedItem.value);

                if (selectedIndex != -1) {
                    if (selectedIndex < this.items.length - 1) {
                        const nextItem = this.items[selectedIndex + 1];

                        this.selectedItem = nextItem;

                        this.scrollIntoView(nextItem.value);
                    }
                }
                // select first if none is selected
                else if (this.items != null && this.items.length > 0) {
                    this.selectedItem = this.items[0];
                    this.scrollIntoView(this.selectedItem.value);
                }
            }
            else if (eventCode == keyUp || eventCode == keyArrowUp) {
                event.preventDefault();

                const selectedIndex = this.items.findIndex(item => item.value == this.selectedItem.value);

                if (selectedIndex != -1) {
                    if (selectedIndex > 0) {

                        const previousItem = this.items[selectedIndex - 1];

                        this.selectedItem = previousItem;

                        this.scrollIntoView(previousItem.value);
                    }
                }
                // select first if none is selected
                else if (this.items != null && this.items.length > 0) {
                    this.selectedItem = this.items[0];
                    this.scrollIntoView(this.selectedItem.value);
                }
            }
        }
    }

    public onItemClick(item: TValue) {
        this.selectedValue = item;
        this.selectedValueChange.emit(this.selectedValue);

        this.opened = !this.opened;
    }

    private scrollIntoView(value: TValue) {
        const offset = 2;

        const selectedIndex = this.items.findIndex(i => i.value === value);

        if (selectedIndex != -1) {
            let dropdownItemsHTMLElement = this.dropdownItemsElement.nativeElement as HTMLElement;

            const selectedElement = dropdownItemsHTMLElement.querySelectorAll('.dropdown-item')[selectedIndex] as HTMLElement;
            const dropdownFullHeight = dropdownItemsHTMLElement.scrollHeight;
            const dropdownHeight = dropdownItemsHTMLElement.offsetHeight;

            if (dropdownFullHeight > dropdownHeight) {
                const selectedElementHeight = selectedElement.offsetHeight;
                const dropdownTop = dropdownItemsHTMLElement.scrollTop;
                const dropdownBottom = dropdownItemsHTMLElement.scrollTop + dropdownHeight;

                if (selectedElement.offsetTop < dropdownTop) {
                    dropdownItemsHTMLElement.scrollTop = selectedElement.offsetTop - offset;
                }
                else if (selectedElement.offsetTop + selectedElementHeight + offset * 2 > dropdownBottom) {
                    dropdownItemsHTMLElement.scrollTop = selectedElement.offsetTop - dropdownHeight + selectedElementHeight + offset * 2;
                }
            }
        }
    }
}
