import { GuidService } from '../../../src/app/guid.service';
import {
    control, ControlDirective, ControlProperty, property, WebControl, WebControlController
} from '../controls';
import { Tooltip } from '../ControlTooltip/ControlTooltip';
import { IRegionControl, IRegionControlConstructor } from '../Region/Region';
import { RegionControlSize } from '../Region/RegionControlSize';
import template from './Checkbox.html';

export interface ICheckboxConstructor<TValue> extends IRegionControlConstructor {
    text?: string | ControlProperty;
    value?: TValue | ControlProperty;
    checked?: boolean | ControlProperty;
}

@control('Checkbox')
export class Checkbox<TValue> extends WebControl implements IRegionControl {

    @property()
    public title: string;

    @property()
    public size: RegionControlSize;

    @property()
    public tooltip: Tooltip;

    @property()
    public text: string;

    @property()
    public value: TValue;

    @property()
    public checked: boolean;
    constructor(checkbox?: ICheckboxConstructor<TValue>) {
        super(checkbox);
    }
}

class CheckboxController<TValue> extends WebControlController<Checkbox<TValue>> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse',
        'guid'
    ];

    public title: string;
    public size: RegionControlSize;
    public tooltip: Tooltip;
    public text: string;
    public value: TValue;
    public checked: boolean;

    private guid: GuidService;

    constructor(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $compile: ng.ICompileService,
        $parse: ng.IParseService,
        guid: GuidService
    ) {
        super(Checkbox, $scope, $element, $attrs, $compile, $parse);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.guid = guid;

            // constants
            this.$scope['RegionControlSize'] = RegionControlSize;
        })(this.$onInit);
    }

    public focus() {
        this.element.querySelector<HTMLInputElement>('.checkbox-input').focus();
    }
}

export class CheckboxDirective extends ControlDirective {
    constructor() {
        super(CheckboxController, Checkbox, template);
    }
}
