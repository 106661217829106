import {
    control, Control, ControlController, ControlDirective, IControlConstructor
} from '../controls';
import template from './Loading.html';

export interface ILoadingConstructor extends IControlConstructor { }

@control('Loading')
export class Loading extends Control {
    constructor(loading?: ILoadingConstructor) {
        super(loading);
    }
}

class LoadingController extends ControlController<Loading> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes) {
        super(Loading, $scope, $element, $attrs);
    }
}

export class LoadingDirective extends ControlDirective {
    constructor() {
        super(LoadingController, Loading, template);

        this.transclude = true;
    }
}
