import { Design } from '../../../App/Entities/Design';

/**
* External design meta-data constructor.
*/
export interface IDesignExternalMetaDataConstructor {
    id?: string;
    name?: string;
    region?: number;
    standard?: number;
    designMethod?: number;
    designType?: number;
}

/**
 * Holding information about design meta-data that is duplicated in the document service meta data, for efficient (fast) access.
 */
export class DesignExternalMetaData {
    public id: string;
    public name: string;
    public region: number;
    public standard: number;
    public designMethod: number;
    public designType: number;

    public constructor(desingExternalMetaDataConstructor?: IDesignExternalMetaDataConstructor) {
        if (desingExternalMetaDataConstructor != undefined && desingExternalMetaDataConstructor != null) {
            this.id = desingExternalMetaDataConstructor.id;
            this.name = desingExternalMetaDataConstructor.name;

            this.region = desingExternalMetaDataConstructor.region;
            this.standard = desingExternalMetaDataConstructor.standard;
            this.designMethod = desingExternalMetaDataConstructor.designMethod;
            this.designType = desingExternalMetaDataConstructor.designType;
        }
    }

    /**
     * Read the design meta-data from design entity for storage in document service
     * @param design
     */
    public static getMetaDataFromDesign(design: Design): DesignExternalMetaData {
        const ret: DesignExternalMetaData = new DesignExternalMetaData();

        ret.id = design.id;
        ret.name = design.designName;
        ret.region = design.region.id;

        return ret;
    }
}
