import { Injectable } from '@angular/core';

import { CodeList } from '../entities/codeLists/codeList';
import { Language } from '../entities/codeLists/language';
import { Region } from '../entities/codeLists/region';
import { RegionLanguage } from '../entities/codeLists/region-language';
import { RegionLicenseFeature } from '../entities/codeLists/region-license-feature';
import { TrialBannerFeature } from '../entities/codeLists/trial-banner-feature';
import { Unit } from '../entities/codeLists/unit';
import { WorkingCondition } from '../entities/codeLists/working-condition';
import { WorldArea } from '../entities/codeLists/world-area';
import {
    PurchaserCodeLists
} from '../generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Codelists';

/** Code list types for project */
export enum ProjectCodeList {
    Region,
    DesignStandard,
    DesignType,
    ReportPaperSize,
    ReportType,
    Language,
    ReportLanguage,
    UnitLength,
    UnitArea,
    UnitStress,
    UnitForce,
    UnitMoment,
    UnitTemperature,
    WorkingCondition,
    UnitVolume,
    RegionLanguage,
    UnitForcePerLength,
    UnitTime,
    WorldArea,
    RegionLicenseFeatures,
    TrialBannerFeature
}

@Injectable({
    providedIn: 'root'
})
export class CodeListService {
    public codelist: { [codeList: number]: CodeList[] };

    public initialize(data: PurchaserCodeLists) {
        this.codelist = {
            [ProjectCodeList.UnitLength]: data.LengthUnits.map((codeList) => Unit.fromService(codeList)),
            [ProjectCodeList.UnitVolume]: data.VolumeUnits.map((codeList) => Unit.fromService(codeList)),
            [ProjectCodeList.UnitArea]: data.AreaUnits.map((codeList) => Unit.fromService(codeList)),
            [ProjectCodeList.WorkingCondition]: data.WorkingConditions.map((codeList) => WorkingCondition.fromService(codeList)),
            [ProjectCodeList.Region]: data.Regions.map((codeList) => Region.fromService(codeList)),
            [ProjectCodeList.Language]: data.Languages.map((codeList) => Language.fromService(codeList)),
            [ProjectCodeList.RegionLanguage]: data.RegionLanguages.map((codeList) => RegionLanguage.fromService(codeList)),
            [ProjectCodeList.WorldArea]: data.WorldAreas.map((codeList) => WorldArea.fromService(codeList)),
            [ProjectCodeList.RegionLicenseFeatures]: data.RegionLicenseFeatures.map((codeList) => RegionLicenseFeature.fromService(codeList)),
            [ProjectCodeList.TrialBannerFeature]: data.TrialBannerFeatures.map((codeList) => TrialBannerFeature.fromService(codeList))
        };
    }
}
