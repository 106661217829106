import { LocalizationService } from '../../src/app/services/localization.service';
import { EventObject } from '../Entities/EventObject';
import { DataService } from './data-service';
import { UserService } from './user-service';
import { ViewService } from '../../src/app/services/view.service';

const rootScopeName = 'loadingService';

export enum LoadingEvent {
    loaded
}

export class LoadingService extends EventObject<LoadingEvent> {
    public static $inject = [
        '$rootScope',
        'view',
        'localization',
        'user',
        'data'
    ];

    public loaded: boolean;
    public showLoadingScreen: boolean;

    private _internalLoaded: boolean;

    private $rootScope: ng.IRootScopeService;
    private view: ViewService;
    private localization: LocalizationService;
    private user: UserService;
    private data: DataService;

    constructor(
        $rootScope: ng.IRootScopeService,
        view: ViewService,
        localization: LocalizationService,
        user: UserService,
        data: DataService
    ) {
        super();

        this.$rootScope = $rootScope;
        this.view = view;
        this.localization = localization;
        this.user = user;
        this.data = data;

        this._internalLoaded = false;

        this.$rootScope[rootScopeName] = this;

        // loaded event
        this.$rootScope.$watch(`${rootScopeName}.internalLoaded`, (loaded, oldLoaded) => {
            if (loaded !== oldLoaded && loaded) {
                this.loaded = true;
                this.trigger(LoadingEvent.loaded);
            }
        });
    }
    private get internalLoaded() {
        if (this._internalLoaded) {
            return true;
        }

        return this._internalLoaded = this.view.viewLoaded && this.localization.translationsLoaded && (!this.user.isAuthenticated || this.data.initialDataLoaded);
    }

    public onLoaded(fn: () => void) {
        this.on(LoadingEvent.loaded, fn);
    }
}
