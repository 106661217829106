import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { ApiOptions, ApiService } from './api.service';

export interface ApplicationVersion {
    Version?: string;
}


@Injectable({
    providedIn: 'root'
})
export class ApplicationVersionsService {
    private serviceApplicationVersion?: string;
    private serviceApplicationVersionLoaded = false;

    private environmentApplicationVersion: string;


    constructor(
        private apiService: ApiService,
        private titleService: Title
    ) {
        this.environmentApplicationVersion = environment.applicationVersion;
    }


    public async getApplicationVersionFromService(forceUpdate = false, apiOptions?: ApiOptions) {
        if (this.serviceApplicationVersionLoaded && !forceUpdate) {
            return Promise.resolve();
        }

        try {
            const httpRequest = new HttpRequest('GET', `${environment.productInformationServiceUrl}public/ApplicationVersions/QuantityCalculator`);

            const result = await this.apiService.request<ApplicationVersion>(httpRequest, apiOptions);
            const version = (result?.Version ?? '').trim();

            if (version != '') {
                this.serviceApplicationVersion = version;
            }
            else {
                this.serviceApplicationVersion = undefined;
            }
            this.serviceApplicationVersionLoaded = true;
        }
        catch (error) {
            console.error(error);
        }
    }

    public async setApplicationVersion(forceUpdate = false, apiOptions?: ApiOptions) {
        await this.getApplicationVersionFromService(forceUpdate, apiOptions);
        this.setApplicationVersionInternal(this.serviceApplicationVersion);
        this.updatePageTitle();
    }


    private setApplicationVersionInternal(applicationVersion: string) {
        if (applicationVersion) {
            environment.applicationVersion = applicationVersion;
        }
        else {
            environment.applicationVersion = this.environmentApplicationVersion;
        }

        this.serviceApplicationVersionLoaded = true;
    }

    private updatePageTitle() {
        let title = environment.solutionName;

        if (this.serviceApplicationVersionLoaded) {
            title = `${title} - ${environment.applicationVersion}`;
        }

        this.titleService.setTitle(title);
    }
}
