import { LocalizationService } from '../../../src/app/services/localization.service';
import { control } from '../controls';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective } from '../Modal/Modal';
import template from './ConceptualPicture.html';

export class ConceptualPictureModalController extends BaseModalController<ConceptualPicture> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'localization',
        'control',
    ];

    public image: string;

    private $scope: ng.IScope;
    private localization: LocalizationService;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        localization: LocalizationService,
        control: ConceptualPicture
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.$scope['localization'] = this.localization = localization;

            this.image = this.control.image;

            // focus ok button
            this.$uibModalInstance.rendered
                .then(() => document.querySelector<HTMLButtonElement>('.conceptual-picture-modal #conceptual-picture-close-button').focus());
        })(this.$onInit);
    }

    private get form() {
        return this.$scope['form'] as ng.IFormController;
    }

    public save() {
        this.$uibModalInstance.close();
    }

    public close() {
        this.$uibModalInstance.close();
    }
}

export interface IConceptualPictureConstructor {
    image?: string;
}

@control('ConceptualPicture')
export class ConceptualPicture extends Modal<ConceptualPicture> {

    public image: string;

    constructor(ctor?: IConceptualPictureConstructor) {
        super({
            template,
            templateUrl: 'ConceptualPicture.html',
            controller: ConceptualPictureModalController,
            windowClass: 'default-modal conceptual-picture-modal',
            size: 'lg',
            ...ctor
        });

        if (ctor != null) {
            this.image = ctor.image;
        }
    }

    public setImage(image: string) {
        this.image = image;
    }
}

class ConceptualPictureController extends ModalController<ConceptualPicture> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal',
    ];

    constructor($scope: ng.IScope,
                $element: ng.IAugmentedJQuery,
                $attrs: ng.IAttributes,
                $uibModal: ng.ui.bootstrap.IModalService) {
        super(ConceptualPicture, $scope, $element, $attrs, $uibModal);
    }
}

export class ConceptualPictureDirective extends ModalDirective {
    constructor() {
        super(ConceptualPictureController, ConceptualPicture);
    }
}
