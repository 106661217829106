<ng-template #tooltipTemplate>
    <app-content-tooltip [tooltip]="tooltip"></app-content-tooltip>
</ng-template>

<div id="{{id}}" class="control label-control">
    <div class="control-header" *ngIf="title">
        <label
            class="control-label"
            [ngbTooltip]="ngbTooltipTemplate(tooltip, tooltipTemplate)">
            {{title}}
        </label>
    </div>

    <p class="text-label" [hidden]="textLines?.length < 1" *ngFor="let t of textLines">{{t}}</p>
</div>