import cloneDeep from 'lodash-es/cloneDeep';

import { Unit } from '../../generated-modules/Hilti.PE.Purchaser.Common.Units';
import {
    RegionEntity
} from '../../generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Codelists';
import { CodeList, ICodeListConstructor } from './codeList';

export type RegionLinks = { [id: string]: IRegionLink };

export interface IRegionLink {
    id: string;
    url: string;
    nameResourceKey: string;
}

export interface IRegionConstructor extends ICodeListConstructor {
    countryCode?: string;
    defaultDesignStandardId?: number;
    defaultUnitLength?: Unit;
    defaultUnitArea?: Unit;
    defaultUnitStress?: Unit;
    defaultUnitForce?: Unit;
    defaultUnitMoment?: Unit;
    defaultUnitTemperature?: Unit;
    defaultUnitVolume?: Unit;
    worldAreaId?: number;
    links?: RegionLinks;
    contactUrl?: string;
    supportPhone: string;
    supportHours: string;
    image: string;
    kbUrl: string;
    hubId: number;
    hiltiDataConsentUrl: string;
}

export class Region extends CodeList {
    public countryCode: string;
    public defaultDesignStandardId: number;
    public defaultUnitLength: Unit;
    public defaultUnitArea: Unit;
    public defaultUnitStress: Unit;
    public defaultUnitForce: Unit;
    public defaultUnitMoment: Unit;
    public defaultUnitTemperature: Unit;
    public defaultUnitVolume: Unit;
    public worldAreaId: number;
    public links: RegionLinks;
    public contactUrl: string;
    public supportPhone: string;
    public supportHours: string;
    public image: string;
    public kbUrl: string;
    public hubId: number;
    public hiltiDataConsentUrl: string;

    constructor(codeList?: IRegionConstructor) {
        super(codeList);

        if (codeList != null) {
            this.links = codeList.links;
            this.countryCode = codeList.countryCode;
            this.defaultDesignStandardId = codeList.defaultDesignStandardId;
            this.defaultUnitLength = codeList.defaultUnitLength;
            this.defaultUnitArea = codeList.defaultUnitArea;
            this.defaultUnitStress = codeList.defaultUnitStress;
            this.defaultUnitForce = codeList.defaultUnitForce;
            this.defaultUnitMoment = codeList.defaultUnitMoment;
            this.defaultUnitTemperature = codeList.defaultUnitTemperature;
            this.defaultUnitVolume = codeList.defaultUnitVolume;
            this.contactUrl = codeList.contactUrl;
            this.supportPhone = codeList.supportPhone;
            this.supportHours = codeList.supportHours;
            this.worldAreaId = codeList.worldAreaId;
            this.image = codeList.image;
            this.kbUrl = codeList.kbUrl;
            this.hubId = codeList.hubId;
            this.hiltiDataConsentUrl = codeList.hiltiDataConsentUrl;
        }
    }

    public static fromService(codeList: RegionEntity) {
        return new Region({
            links: Region.parseLinksFromService(codeList),
            id: codeList.RegionId,
            nameResourceKey: codeList.DisplayName,
            countryCode: codeList.CountryCode,
            defaultDesignStandardId: codeList.DefaultDesignStandard != null ? codeList.DefaultDesignStandard.DesignStandardId : null,
            defaultUnitLength: codeList.DefaultUnitLength,
            defaultUnitArea: codeList.DefaultUnitArea,
            defaultUnitStress: codeList.DefaultUnitStress,
            defaultUnitForce: codeList.DefaultUnitForce,
            defaultUnitMoment: codeList.DefaultUnitMoment,
            defaultUnitTemperature: codeList.DefaultUnitTemperature,
            defaultUnitVolume: codeList.DefaultUnitVolume,
            contactUrl: codeList.ContactUrl,
            supportPhone: codeList.SupportPhone,
            supportHours: codeList.SupportHours,
            worldAreaId: codeList.WorldAreaId,
            image: codeList.Image != null && codeList.Image != '' ? 'sprite-' + codeList.Image : null,
            kbUrl: codeList.KBUrl,
            hubId: codeList.HubId,
            hiltiDataConsentUrl: codeList.HiltiDataConsentUrl
        });
    }

    private static parseLinksFromService({ Links: serviceLinks, HubId: hubId }: RegionEntity) {
        const links: RegionLinks = {};

        if (serviceLinks == null) {
            return links;
        }

        // Add link that contains an actual link/url.
        if (serviceLinks['GetPurchaserUrl'] != null) {
            links['GetPurchaserUrl'] = {
                id: 'GetPurchaserUrl',
                nameResourceKey: `Agito.Hilti.Purchaser.Region.Link.GetPurchaserUrl`,
                url: serviceLinks['GetPurchaserUrl']
            };
        }

        return links;
    }

    public cloneDeep() {
        const codeList: Region = super.cloneDeep() as Region;

        codeList.links = cloneDeep(this.links);
        codeList.countryCode = this.countryCode;
        codeList.defaultDesignStandardId = this.defaultDesignStandardId;
        codeList.defaultUnitLength = this.defaultUnitLength;
        codeList.defaultUnitArea = this.defaultUnitArea;
        codeList.defaultUnitStress = this.defaultUnitStress;
        codeList.defaultUnitForce = this.defaultUnitForce;
        codeList.defaultUnitMoment = this.defaultUnitMoment;
        codeList.defaultUnitTemperature = this.defaultUnitTemperature;
        codeList.defaultUnitVolume = this.defaultUnitVolume;
        codeList.contactUrl = this.contactUrl;
        codeList.supportPhone = this.supportPhone;
        codeList.supportHours = this.supportHours;
        codeList.worldAreaId = this.worldAreaId;
        codeList.image = this.image;
        codeList.kbUrl = this.kbUrl;
        codeList.hubId = this.hubId;
        codeList.hiltiDataConsentUrl = this.hiltiDataConsentUrl;
        return codeList;
    }
}
