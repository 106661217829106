import { Component, EventEmitter, Input, Output } from '@angular/core';

import { randomString } from '../../helpers/random';
import { ngbTooltipTemplate, Tooltip } from '../content-tooltip/content-tooltip.component';

export interface RadioButtonProps<TValue = number> {
    id?: string;
    title?: string;
    selectedValue?: TValue;
    items?: RadioButtonItem<TValue>[];
    disabled?: boolean;
    tooltip?: Tooltip;
}

export interface RadioButtonItem<TValue> {
    value: TValue;
    text: string;
    id?: string;
    disabled?: boolean;
    tooltip?: Tooltip;
}

@Component({
    selector: 'app-radio-button',
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent<TValue> {
    @Input()
    public id = randomString(8);

    @Input()
    public title: string;

    @Input()
    public selectedValue: TValue;

    @Output()
    public selectedValueChange = new EventEmitter<TValue>();

    @Input()
    public items: RadioButtonItem<TValue>[];

    @Input()
    public disabled: boolean = false;

    @Input()
    public tooltip: Tooltip;

    public radioName = randomString(8);
    public ngbTooltipTemplate = ngbTooltipTemplate;

    public onSelected(item: RadioButtonItem<TValue>) {
        this.selectedValue = item.value;
        this.selectedValueChange.emit(item.value);
    }
}
