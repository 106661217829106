import { QueryStringHelper } from '../app/query-string';

import type schema from './environment.local.json';

export type Environment = typeof schema & {
    authenticationRequired: string[];
    baseUrl: string;
    translate: boolean;
};

const qsHelper = new QueryStringHelper(window.location.search);

export const environment: Environment = getEnvironmentSettings();

environment.baseUrl = document.getElementsByTagName('base')[0].href;
environment.translate = qsHelper.getBoolean('translate');
environment.authenticationRequired = [
    environment.baseplateApplicationWebServiceUrl,
    environment.baseplateCalculationWebServiceUrl,
    environment.documentWebServiceUrl,
    environment.productInformationServiceUrl,
    environment.purchaserApplicationWebServiceUrl,
    environment.supportServiceUrl,
    environment.trackingWebServiceUrl,
    environment.translationsWebServiceUrl,
    environment.userSettingsWebServiceUrl
].filter(x => x);

/**
 * Get environment settings and update service version (v1 or v2). Required for blue green deployment.
 * Supported environments
 * - Ireland: dev
 * - China: stage and prod
 */
function getEnvironmentSettings(): Environment {
    const host = window.location.host;
    const v1Hosts: string[] = ['quantitycalculator-d.hilti.com', 'quantitycalculator-qa.hilti.com', 'quantitycalculator-q.hilti.com', 'quantitycalculator.hilti.com', 'quantitycalculator-q.hilti.cn', 'quantitycalculator.hilti.cn', 'quantitycalculator-s.hilti.com', 'quantitycalculator-hf.hilti.com'];
    const v2Hosts: string[] = ['quantitycalculator-d2.hilti.com', 'quantitycalculator-qa2.hilti.com', 'quantitycalculator-q2.hilti.com', 'quantitycalculator2.hilti.com', 'quantitycalculator-q2.hilti.cn', 'quantitycalculator2.hilti.cn', 'quantitycalculator-s2.hilti.com', 'quantitycalculator-hf2.hilti.com'];

    let serviceVersion = '';
    if (v1Hosts.some(x => x == host)) {
        serviceVersion = 'v1';
    }
    else if (v2Hosts.some(x => x == host)) {
        serviceVersion = 'v2';
    }

    const env: Environment = (window as any).environment;
    if (serviceVersion != '') {
        env.baseplateApplicationWebServiceUrl = formatServiceVersion(env.baseplateApplicationWebServiceUrl, serviceVersion);
        env.baseplateCalculationWebServiceUrl = formatServiceVersion(env.baseplateCalculationWebServiceUrl, serviceVersion);
        env.documentWebServiceUrl = formatServiceVersion(env.documentWebServiceUrl, serviceVersion);
        env.productInformationServiceUrl = formatServiceVersion(env.productInformationServiceUrl, serviceVersion);
        env.purchaserApplicationWebServiceUrl = formatServiceVersion(env.purchaserApplicationWebServiceUrl, serviceVersion);
        env.supportServiceUrl = formatServiceVersion(env.supportServiceUrl, serviceVersion);
        env.trackingWebServiceUrl = formatServiceVersion(env.trackingWebServiceUrl, serviceVersion);
        env.translationsWebServiceUrl = formatServiceVersion(env.translationsWebServiceUrl, serviceVersion);
        env.userSettingsWebServiceUrl = formatServiceVersion(env.userSettingsWebServiceUrl, serviceVersion);
    }

    return env ?? {} as Environment;
}

function formatServiceVersion(url: string, serviceVersion: string) {
    return format(url, '{service_version}', serviceVersion);
}

function format(value: string, searchValue: string, replaceValue: string) {
    if (value == null) {
        return null;
    }

    return value.replace(searchValue, replaceValue);
}
