import {
    RegionLanguageEntity
} from '../../generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Codelists';
import { CodeList, ICodeListConstructor } from './codeList';

export interface IRegionLanguageConstructor extends ICodeListConstructor {
    regionId?: number;
    lcid?: number;
    hiltiDataPrivacyUrl?: string;
    userAgreement?: string;
    regionStructureId?: number;
    dataSubjectRightsUrl?: string;
    regionLanguageId: number;
    defaultForRegion?: boolean;
}

export class RegionLanguage extends CodeList {
    public regionId: number;
    public lcid: number;
    public hiltiDataPrivacyUrl: string;
    public userAgreement: string;
    public regionStructureId: number;
    public dataSubjectRightsUrl: string;
    public regionLanguageId: number;
    public defaultForRegion: boolean;

    constructor(codeList?: IRegionLanguageConstructor) {
        super(codeList);

        if (codeList != null) {
            this.regionId = codeList.regionId;
            this.lcid = codeList.lcid;
            this.hiltiDataPrivacyUrl = codeList.hiltiDataPrivacyUrl;
            this.userAgreement = codeList.userAgreement;
            this.regionStructureId = codeList.regionStructureId;
            this.dataSubjectRightsUrl = codeList.dataSubjectRightsUrl;
            this.regionLanguageId = codeList.regionLanguageId;
            this.defaultForRegion = codeList.defaultForRegion;
        }
    }

    public static fromService(codeList: RegionLanguageEntity) {
        return new RegionLanguage({
            id: codeList.RegionLanguageId,
            name: codeList.Name,
            image: codeList.Image != null && codeList.Image != '' ? `sprite-${codeList.Image}` : null,
            regionId: codeList.RegionId,
            lcid: codeList.LCID,
            hiltiDataPrivacyUrl: codeList.HiltiDataPrivacyUrl,
            userAgreement: codeList.UserAgreement,
            regionStructureId: codeList.RegionStructureId,
            dataSubjectRightsUrl: codeList.DataSubjectRightsUrl,
            regionLanguageId: codeList.RegionLanguageId,
            defaultForRegion: codeList.DefaultForRegion
        });
    }

    public cloneDeep() {
        const codeList: RegionLanguage = super.cloneDeep() as RegionLanguage;

        codeList.regionId = this.regionId;
        codeList.lcid = this.lcid;
        codeList.hiltiDataPrivacyUrl = this.hiltiDataPrivacyUrl;
        codeList.userAgreement = this.userAgreement;
        codeList.regionStructureId = this.regionStructureId;
        codeList.dataSubjectRightsUrl = this.dataSubjectRightsUrl;
        codeList.regionLanguageId = this.regionLanguageId;
        codeList.defaultForRegion = this.defaultForRegion;

        return codeList;
    }
}
