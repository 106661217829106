import cloneDeep from 'lodash-es/cloneDeep';

import { Injectable } from '@angular/core';
import { AppStorageService } from './app-storage.service';
import { SessionStorageService } from './session-storage.service';
import { environment } from '../../environments/environment';


export interface ISubscriptionInfo {
    AuthorizationEntryList: {
        City: string;
        ContactFirstName: string;
        ContactIDs: string;
        ContactLastName: string;
        ContactOriginID: string;
        Country: string;
        CustomerID: string;
        CustomerName: string;
        CustomerOriginID: string;
        Licenses: string;
        PostalCode: string;
        Roles: string;
        Scopes: string;
        SecondaryName: string;
        Street: string;
    }[];
    ClientID: string;
    Country: string;
    CountryOfResidence: string;
    CustomerID: string;
    LogonID: string;
    UID: string;
}

export interface IAuthentication {
    accessToken: string;
    customerId: string;
    customerOriginId: string;
    license: string;
    userName: string;
    contactId: string;
    userId: string;
    countryOfResidence?: string;
    country?: string;
    subscription_info: ISubscriptionInfo;
}

export const sharedAuthenticationKey = 'sharedAuthentication';
export const authenticationStorageKey = 'authentication';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private authenticationData: IAuthentication;


    constructor(
        private appStorage: AppStorageService,
        private sessionStorage: SessionStorageService
    ) { }


    public get authentication() {
        return this.authenticationData;
    }

    public get isAuthenticated() {
        return this.authentication != null &&
            typeof this.authentication == 'object' &&
            !this.isNullOrEmpty(this.authentication.accessToken);
    }

    public get isExternalRussianUser() {
        return environment.russianDataProtectionRegulationEnabled && !this.isInternalHiltiUser() && this.authentication.subscription_info.CountryOfResidence == 'RU';
    }


    public setAuthentication(authentication: IAuthentication, remember?: boolean) {
        this.authenticationData = cloneDeep(authentication);

        // save to session
        this.sessionStorage.set(authenticationStorageKey, authentication);

        if (remember) {
            this.appStorage.set(authenticationStorageKey, authentication);
        }
        else {
            this.appStorage.remove(authenticationStorageKey);
        }

        this.appStorage.set(sharedAuthenticationKey, authentication);
        this.appStorage.remove(sharedAuthenticationKey);
    }

    public tryAuthenticateFromStorage() {
        this.authenticationData = this.sessionStorage.get<IAuthentication>(authenticationStorageKey) || this.appStorage.get<IAuthentication>(authenticationStorageKey);
    }

    public invalidateAuthentication() {
        this.authenticationData = null;

        this.sessionStorage.remove(authenticationStorageKey);
        this.appStorage.remove(authenticationStorageKey);
    }

    public isInternalHiltiUser() {
        const userName = this.authentication.userName.toLowerCase();

        // that's how hilti cloud filters them
        return userName.indexOf('@hilti.com') > 0;
    }


    private isNullOrEmpty(value: string) {
        return value == null || value == '';
    }
}
