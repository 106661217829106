import { LocalizationService } from '../../../src/app/services/localization.service';
import {
    control, ControlDirective, ControlProperty, IWebControl, IWebControlConstructor, property,
    WebControl, WebControlController, WebControlVisibility
} from '../controls';
import { Tooltip } from '../ControlTooltip/ControlTooltip';
import template from './Region.html';
import { RegionControlSize } from './RegionControlSize';

interface IFavoriteWatch {
    last: boolean;
}

export enum FavoriteChangeAction {
    Add,
    Remove,
    Change
}

export class FavoriteChange {
    public region: Region;
    public action: FavoriteChangeAction;
    public userChange: boolean;

    constructor(region: Region, action: FavoriteChangeAction, userChange?: boolean) {
        this.region = region;
        this.action = action;
        this.userChange = userChange != null ? userChange : false;
    }
}

export interface IRegionControl extends IWebControl {
    title: string;
    size: RegionControlSize;
    tooltip: Tooltip;
}

export interface IRegionControlConstructor extends IWebControlConstructor {
    title?: string | ControlProperty;
    size?: RegionControlSize | ControlProperty;
    tooltip?: Tooltip | ControlProperty;
}

export interface IRegionConstructor extends IWebControlConstructor {
    title?: string | ControlProperty;
    favorite?: boolean | ControlProperty;
    collapsed?: boolean | ControlProperty;
    controls?: IRegionControl[] | ControlProperty;
    hideOnEmpty?: boolean | ControlProperty;
}

@control('Region')
export class Region extends WebControl {
    public static favoriteChange = 'favoriteChange';

    @property()
    public title: string;

    @property()
    public favorite: boolean;

    @property()
    public collapsed: boolean;

    @property()
    public controls: IRegionControl[];

    @property()
    public hideTooltips: boolean;

    @property()
    public hideOnEmpty: boolean;

    @property()
    public menuHidden: boolean;

    constructor(region?: IRegionConstructor) {
        super(region);
    }
}

class RegionController extends WebControlController<Region> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse',
        'localization'
    ];

    public title: string;
    public favorite: boolean;
    public collapsed: boolean;
    public controls: IRegionControl[];
    public hideTooltips: boolean;
    public hideOnEmpty: boolean;
    public menuHidden: boolean;

    private favoriteWatch: IFavoriteWatch;
    private disposed: boolean;
    private destroyed: boolean;
    private controlWatches: Function[];

    private localization: LocalizationService;

    constructor(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $compile: ng.ICompileService,
        $parse: ng.IParseService,
        localization: LocalizationService
    ) {
        super(Region, $scope, $element, $attrs, $compile, $parse);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.localization = localization;

            this.bindControls(this.element.querySelector('.controls'), 'ctrl.controls');

            this.$scope.$watch('ctrl.favorite', () => {
                this.trigger(Region.favoriteChange, [new FavoriteChange(this.control, FavoriteChangeAction.Change, false)]);
            });

            this.controlWatches = [];

            this.$scope.$watch('ctrl.hideOnEmpty', this.hideOnEmptyWatch.bind(this));
            this.$scope.$watchCollection('ctrl.controls', this.controlsWatch.bind(this));

            this.favoriteWatch = this.$scope['$$watchers'][0];

            // dispose event
            this.onDisposed(this.dispose.bind(this));
        })(this.$onInit);
    }

    public get tooltipIsOpen() {
        return this.hideTooltips ? false : undefined;
    }

    public set tooltipIsOpen(value) { }

    public favoriteClick() {
        this.favorite = !this.favorite;
        this.favoriteWatch.last = this.favorite;

        this.trigger(Region.favoriteChange, [new FavoriteChange(this.control, FavoriteChangeAction.Change, true)]);
    }

    private dispose() {
        if (!this.disposed) {
            this.disposed = true;

            for (const control of this.controls || []) {
                control.dispose();
            }

            this.$scope.$destroy();
        }
    }

    private controlsWatch(controls: IRegionControl[], oldControls: IRegionControl[]) {
        // remove control watches
        for (const controlWatch of this.controlWatches) {
            controlWatch();
        }

        this.controlWatches = [];

        // watch control visibility
        if (this.controls != null) {
            for (const control of this.controls) {
                this.controlWatches.push(this.$scope.$watch(() => control.visibility, this.controlVisibilityWatch.bind(this)));
            }
        }
    }

    private controlVisibilityWatch() {
        this.setVisibility();
    }

    private setVisibility() {
        if (this.hideOnEmpty) {
            // hide if all contained controls are hidden, or if there are only labels
            if (this.controls == null || this.controls.every((control) => control.visibility != WebControlVisibility.visible)) {
                this.visibility = WebControlVisibility.hidden;
            }
            else {
                this.visibility = WebControlVisibility.visible;
            }
        }
    }

    private hideOnEmptyWatch() {
        this.setVisibility();
    }
}

export class RegionDirective extends ControlDirective {
    constructor() {
        super(RegionController, Region, template);

        this.transclude = true;
    }
}
