import debounce from 'lodash-es/debounce';
import isEqual from 'lodash-es/isEqual';

import { GuidService } from '../../src/app/guid.service';
import { environment } from '../../src/environments/environment';
import { MenuType } from '../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Display.Enums';
import { LoggerService } from '../../src/app/services/logger.service';
import { UserService } from './user-service';

const updateDelay = 500;

interface IFavoritesInfo {
    menuType: MenuType;
    favorites: number[];
    dbFavorites: number[];
    getPromise: ng.IPromise<number[]>;
    setDefer: ng.IDeferred<number[]>;
    updating: boolean;
    updateId: string;
}

export class FavoritesService {
    public static $inject = [
        '$http',
        'user',
        '$q',
        'logger',
        'guid',
        '$rootScope'
    ];

    private $http: ng.IHttpService;
    private user: UserService;
    private $q: ng.IQService;
    private logger: LoggerService;
    private guid: GuidService;
    private $rootScope: ng.IRootScopeService;

    private info: { [menuType: number]: IFavoritesInfo } = {};
    private debounceSave: (menuType: MenuType, favorites: number[], updateId: string) => void;

    constructor(
        $http: ng.IHttpService,
        user: UserService,
        $q: ng.IQService,
        logger: LoggerService,
        guid: GuidService,
        $rootScope: ng.IRootScopeService
    ) {
        this.$http = $http;
        this.user = user;
        this.$q = $q;
        this.logger = logger;
        this.guid = guid;
        this.$rootScope = $rootScope;

        this.debounceSave = debounce((...args: any[]) => { this.$rootScope.$apply(() => { this.saveInternal.apply(this, args); }); }, updateDelay);
    }

    public get updating() {
        return Object.values(this.info).some(info => info.updating);
    }

    public get(menuType: MenuType) {
        const info = this.getInfo(menuType);

        if (info.favorites != null) {
            return this.$q.when(info.favorites);
        }

        if (info.getPromise != null) {
            return info.getPromise;
        }

        const url = `${environment.purchaserApplicationWebServiceUrl}GetUserFavorites`;
        const params = {
            menuId: menuType
        };

        return info.getPromise = this.$http.get<number[]>(url, { params })
            .then((response) => {
                info.dbFavorites = response.data;
                info.favorites = response.data;

                return info.favorites;
            })
            .catch<number[]>((response) => {
                this.logger.logServiceError(response, 'FavoritesService', 'get');

                info.getPromise = null;

                return this.$q.reject(response);
            });
    }

    public update(menuType: MenuType, favorites: number[]) {
        favorites = favorites || [];

        const info = this.getInfo(menuType);

        info.favorites = favorites;
        info.updating = true;
        info.updateId = this.guid.new();

        const promise = info.setDefer.promise;

        this.debounceSave(menuType, favorites, info.updateId);

        return promise;
    }

    private getInfo(menuType: MenuType) {
        let info = this.info[menuType];

        if (info == null) {
            info = this.info[menuType] = {
                menuType,
                dbFavorites: null,
                favorites: null,
                getPromise: null,
                setDefer: this.$q.defer<number[]>(),
                updateId: null,
                updating: false
            };
        }

        return info;
    }

    private saveToDatabase(menuType: MenuType, favorites: number[]) {
        if (!this.user.isAuthenticated) {
            throw new Error('Unauthenticated');
        }

        const info = this.getInfo(menuType);
        const url = `${environment.purchaserApplicationWebServiceUrl}SaveToFavorites`;
        const data = {
            menuId: menuType,
            favoriteTabRegionIds: favorites
        };

        // don't update if favorites didn't change
        if (isEqual(favorites, info.dbFavorites || [])) {
            return this.$q.when();
        }

        return this.$http.post<boolean>(url, data)
            .then<void>((response) => {
                if (response.data !== true) {
                    return this.$q.reject(response);
                }

                info.dbFavorites = favorites;

                return undefined;
            })
            .catch<void>((response) => {
                this.logger.logServiceError(response, 'FavoritesService', 'saveToDatabase');

                return this.$q.reject(response);
            });
    }

    private saveInternal(menuType: MenuType, favorites: number[], updateId: string) {
        const info = this.getInfo(menuType);

        this.saveToDatabase(menuType, favorites)
            .then(() => {
                info.setDefer.resolve(favorites);
            })
            .catch((response) => {
                info.setDefer.reject(response);
            })
            .then(() => {
                // remove updating flag
                if (info.updateId == updateId) {
                    info.updating = false;
                }

                info.setDefer = this.$q.defer<number[]>();
            });
    }
}
