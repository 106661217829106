import {
    TrialBannerFeatureEntity
} from '../../generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Codelists';
import { CodeList, ICodeListConstructor } from './codeList';

export interface ITrialBannerFeatureConstructor extends ICodeListConstructor {
    regions: number[];
}

export class TrialBannerFeature extends CodeList {
    public regions: number[];

    constructor(codeList?: ITrialBannerFeatureConstructor) {
        super(codeList);

        if (codeList != null) {
            this.regions = codeList.regions;
        }
    }

    public static fromService(codeList: TrialBannerFeatureEntity) {
        return new TrialBannerFeature({
            id: codeList.Id,
            name: codeList.Name,
            regions: codeList.Regions
        });
    }

    public cloneDeep() {
        const codeList: TrialBannerFeature = super.cloneDeep() as TrialBannerFeature;

        codeList.regions = this.regions;

        return codeList;
    }
}
