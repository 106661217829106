import cloneDeep from 'lodash-es/cloneDeep';

import { Language } from '../../src/app/entities/codeLists/language';
import { Region } from '../../src/app/entities/codeLists/region';
import { RegionLanguage } from '../../src/app/entities/codeLists/region-language';
import { UserSettings, UserSettingsValue } from '../../src/app/entities/user-settings';
import { CcmsUserSettings } from '../../src/app/generated-modules/Hilti.PE.CcmsLibrary.Entities';
import { AppStorageService } from '../../src/app/services/app-storage.service';
import { LocalizationService } from '../../src/app/services/localization.service';
import { environment } from '../../src/environments/environment';
import { BrowserService } from './browser-service';
import { CodeListService, ProjectCodeList } from '../../src/app/services/code-list.service';
import { FeatureVisibilityService } from '../../src/app/services/feature-visibility.service';
import { LoggerService } from '../../src/app/services/logger.service';
import { UserService } from './user-service';

export interface UserSettingModel {
    Key: string;
    Value: string;
}

// #region IUserSettingsService
/**
 * User setting service.
 * Responsibilities:
 * - Hold local version of user settings.
 * - Load user settings from remote service
 * - Handle updates to user settings.
 * - Update reduction when updating setting values. Only changed values are sent to the server.
 */
export interface IUserSettingsService {

    /**
     * The actual setting to read from or to load.
     * Don't use if property 'settingsLoaded' is set to false. In that case call method 'get()' first and than wait for promise fulfilment.
     */
    settings: UserSettings;

    /**
     * The ccms setting user data.
     */
    ccmsUserSettings: CcmsUserSettings;

    /**
     * false - if settings not loaned jet, true if read.
     */
    settingsLoaded: boolean;

    /**
     * Initializes service with initial data. This method is only invoked by getInitialDataInternal.
     */
    initialize(data: UserSettingModel[], countryContactEmail: string, ccmsData?: CcmsUserSettings): void;

    /**
     * Writes changes made to settings back to the user settings service.
     */
    save(): ng.IPromise<void>;

    getRegionById(regionId: number): Region;
    getRegionByCountryCode(countryCode: string): Region;
    getProfis3Url(): string;
    getRegionLanguage(regionId?: number, languageId?: number): RegionLanguage;
    getHiltiDataPrivacyUrl(): string;
    getActiveRegion(checkSettings?: boolean): Region;
}
// #endregion

// #region BaseUserSettingsService
/**
 * Base implementation of functionality common for local and actual implementation.
 */
export abstract class BaseUserSettingsService implements IUserSettingsService {

    public settingsLoaded: boolean;

    protected $q: ng.IQService;
    protected logger: LoggerService;
    protected browser: BrowserService;
    protected codeList: CodeListService;
    protected user: UserService;
    protected appStorage: AppStorageService;
    protected featureVisibilityService: FeatureVisibilityService;

    protected _settings: UserSettings;

    protected _ccmsUserSettings: CcmsUserSettings;

    constructor($q: ng.IQService,
        logger: LoggerService,
        browser: BrowserService,
        codeList: CodeListService,
        user: UserService,
        appStorage: AppStorageService,
        featureVisibilityService: FeatureVisibilityService) {

        this.$q = $q;
        this.logger = logger;
        this.browser = browser;
        this.codeList = codeList;
        this.user = user;
        this.appStorage = appStorage;
        this.featureVisibilityService = featureVisibilityService;

        this.settingsLoaded = false;
        this._settings = null;
    }
    public get settings(): UserSettings {
        if (this.settingsLoaded != true) {
            throw new Error('Settings are not loaded!');
        }

        return this._settings;
    }
    public get ccmsUserSettings(): CcmsUserSettings {
        if (this.settingsLoaded != true) {
            throw new Error('Settings are not loaded!');
        }

        return this._ccmsUserSettings;
    }

    public save(): ng.IPromise<void> {
        if (this.settingsLoaded == false) {
            throw new Error('Calling save on user settings service before the service has been initiated!');
        }

        return this.writeToService();
    }

    public abstract initialize(data: UserSettingModel[], countryContactEmail: string, ccmsData?: CcmsUserSettings): void;

    public getRegionById(regionId: number): Region {
        const regionCodeList = this.codeList.codelist[ProjectCodeList.Region] as Region[];
        const result = regionCodeList.find(region => region.id == regionId);

        return result;
    }

    public getRegionByCountryCode(countryCode: string): Region {
        if (countryCode == null || countryCode == '') {
            return null;
        }
        else if (this.codeList.codelist == null) {
            return null;
        }

        countryCode = countryCode.toLowerCase();

        const regionCodeList = this.codeList.codelist[ProjectCodeList.Region] as Region[];

        const result = regionCodeList.find(region => region.countryCode.toLowerCase() == countryCode);

        return result;
    }

    public getRegionLanguage(
        regionId = this.settings.user.general.regionId.value,
        languageId = this.settings.user.general.languageId.value) {
        const regionLanguageCodeList = this.codeList.codelist[ProjectCodeList.RegionLanguage] as RegionLanguage[];

        let regionLanguage = regionLanguageCodeList.find((cl) => cl.regionId == regionId && cl.lcid == languageId);

        // if region language combo does not exist, get the default one for region
        if (regionLanguage == null) {
            regionLanguage = regionLanguageCodeList.find((cl) => cl.regionId == regionId && cl.defaultForRegion);
        }

        return regionLanguage;
    }

    public getProfis3Url(): string {
        const selectedRegion = (this.codeList.codelist[ProjectCodeList.Region] as Region[]).find((item) => item.id == this.settings.user.general.regionId.value);
        const getProfis3Url = selectedRegion.links['GetPurchaserUrl'].url;

        return getProfis3Url;
    }

    public getHiltiDataPrivacyUrl(): string {
        return this.getRegionLanguage().hiltiDataPrivacyUrl;
    }

    public getActiveRegion(checkSettings: boolean = true): Region {
        // First check token "country of residence" property
        let region = this.getRegionByCountryCode(
            this.user.authentication.subscription_info.CountryOfResidence);

        if (region == null) {
            // Then check by "country" property.
            if (this.user.authentication.subscription_info.AuthorizationEntryList[0].Country != null) {
                region = this.getRegionByCountryCode(
                    this.user.authentication.subscription_info.AuthorizationEntryList[0].Country);
            }

            // Finally check by regionId in user settings, if they are available.
            if (checkSettings &&
                region == null &&
                this.settingsLoaded) {
                region = this.getRegionById(
                    this.settings.user.general.regionId.value);
            }
        }

        return region;
    }

    protected isTrue(input: string) {
        if (input == null) {
            return null;
        }

        return input.toLowerCase() === 'true';
    }

    /**
     * Just set default values for non existing values or ones not read from the service, so that
     * those are the predefined values if user never goes to change settings or is opening the application the first time.
     */
    protected setDefaultsForEmptyOrNonExitingValues(): void {
        const languageCodeList = this.codeList.codelist[ProjectCodeList.Language] as Language[];
        const userExistingLanguage = languageCodeList.find((language) => language.id === this._settings.user.general.languageId.value);

        // If the user's language is not set or the user's language is not supported (does not exist in the language code list), set it to default one
        if (this._settings.user.general.languageId.value == null || userExistingLanguage === undefined) {
            const languageDefaultValue = languageCodeList
                .find((language) => language.culture == (environment.translate ? LocalizationService.pseudoLanguage : environment.defaultLanguage));

            if (languageDefaultValue == null) {
                throw new Error('Language not found.');
            }

            this._settings.user.general.languageId.value = languageDefaultValue.id;
        }

        if (this._settings.user.general.name.value == null) {
            this._settings.user.general.name.value = '/';
        }
    }

    protected abstract writeToService(): ng.IPromise<void>;

    protected logServiceRequest(fnName: string, ...args: any[]) {
        this.logger.logServiceRequest(
            'user-settings-service',
            fnName,
            ...args);
    }

    protected logServiceResponse(fnName: string, ...args: any[]) {
        this.logger.logServiceResponse(
            'user-settings-service',
            fnName,
            ...args);
    }

    private normalizeCulure(culture: string) {
        culture = culture.length == 2 ? `${culture}-${culture}` : culture;
        culture = culture.toUpperCase();

        return culture;
    }

    private cultureEqual(culture1: string, culture2: string) {
        const culture1Normalized = this.normalizeCulure(culture1);
        const culture1NormalizedShort = culture1Normalized.substring(0, 2);
        const culture2Normalized = this.normalizeCulure(culture2);
        const culture2NormalizedShort = culture2Normalized.substring(0, 2);

        if (culture1Normalized == culture2Normalized) {
            return true;
        }

        if (culture1NormalizedShort == culture2NormalizedShort) {
            return true;
        }

        return false;
    }

    private getLanguagebyId(id: number) {
        const languageCodeList = this.codeList.codelist[ProjectCodeList.Language] as Language[];
        return languageCodeList.find((r) => r.id == id);
    }
}

// #region UserSettingsService
/**
 * An implementation actually talking to service.
 * Look at IUserSettingsService methods for comments.
 */
export class UserSettingsService extends BaseUserSettingsService implements IUserSettingsService {
    public static $inject = [
        '$http',
        'user',
        '$q',
        'logger',
        'browser',
        'codeList',
        'appStorage',
        'featureVisibilityService'
    ];

    private $http: ng.IHttpService;

    // store original values here, so that we can only send values that actually changed during update and thus enable server work reduction
    private originalValues: UserSettings;

    constructor(
        $http: ng.IHttpService,
        user: UserService,
        $q: ng.IQService,
        logger: LoggerService,
        browser: BrowserService,
        codeList: CodeListService,
        appStorage: AppStorageService,
        featureVisibilityService: FeatureVisibilityService
    ) {
        super($q, logger, browser, codeList, user, appStorage, featureVisibilityService);

        this.$http = $http;
        this.user = user;
    }

    public initialize(data: UserSettingModel[], countryContactEmail: string, ccmsData?: CcmsUserSettings) {
        const rSettings = this.parseServiceResponse(data);

        // make a clone to enable service work reduction
        this.originalValues = cloneDeep(rSettings);
        this.copySettingsValues(this.originalValues, rSettings);

        this._settings = rSettings;
        this._ccmsUserSettings = ccmsData;
        this.setDefaultsForEmptyOrNonExitingValues();
        this._settings.countryContactEmail = countryContactEmail;
        this.settingsLoaded = true;
    }

    public getLanguage(): Language {
        const languageCodeList = this.codeList.codelist[ProjectCodeList.Language] as Language[];
        return languageCodeList.find((item) => item.id == this.settings.user.general.languageId.value);
    }

    public getRegion(): Region {
        const regionCodeList = this.codeList.codelist[ProjectCodeList.Region] as Region[];
        return regionCodeList.find((item) => item.id == this.settings.user.general.regionId.value);
    }

    protected parseServiceResponse(data: UserSettingModel[]) {
        const rSettings = new UserSettings();

        this.readKeysFromResponse(data, rSettings);

        if (environment.translate) {
            rSettings.user.general.languageId.value = LocalizationService.pseudoLanguageLcid;
        }

        // set defaults
        rSettings.user.units.lengthId.value = rSettings.user.units.lengthId.value === undefined || rSettings.user.units.lengthId.value === null ? 100 : rSettings.user.units.lengthId.value;
        rSettings.user.units.volumeId.value = rSettings.user.units.volumeId.value === undefined || rSettings.user.units.volumeId.value === null ? 1100 : rSettings.user.units.volumeId.value;
        rSettings.user.units.areaId.value = rSettings.user.units.areaId.value === undefined || rSettings.user.units.areaId.value === null ? 200 : rSettings.user.units.areaId.value;

        // make a clone to enable service work reduction
        this.originalValues = cloneDeep(rSettings);
        this.copySettingsValues(this.originalValues, rSettings);
        return rSettings;
    }

    //#region writeToService
    protected writeToService(): ng.IPromise<void> {

        // validation
        if (this.settingsLoaded != true) {
            throw new Error('Updating setting values before they are loaded is not allowed!');
        }
        if (this.settings == null) {
            throw new Error('Missing setting values!');
        }
        if (this.originalValues == null) {
            throw new Error('Missing original values!');
        }

        // create send object
        const data: { settings: UserSettingModel[] } = { settings: [] };

        this.writeSettings(data.settings, this._settings, this.originalValues);

        const url = `${environment.userSettingsWebServiceUrl}user-settings/settings`;

        this.logServiceRequest('writeToService', url, data);
        return this.$http.put(url, data, this.getHttpConfig())
            .then(response => {
                this.logServiceResponse('writeToService', response);

                this.copySettingsValues(this.originalValues, this._settings);

                return;
            }).
            catch(e => {
                this.logger.logServiceError(e, 'user-settings-service', 'writeToService');

                // revert changes
                this.copySettingsValues(this._settings, this.originalValues);

                return this.$q.reject();
            });
    }

    private tryParseJson(userVal: UserSettingsValue<any>, str: string) {
        try {
            userVal.value = JSON.parse(str);
        }
        catch (e) {
            return false;
        }
        return true;
    }

    private readKeysFromResponse(data: UserSettingModel[], settingsData: UserSettings, key_apendx = 'purchaser') {
        for (const key in settingsData) {
            if ((settingsData[key] instanceof UserSettingsValue)) {
                if (data.some((x) => x.Key == `${key_apendx}_${key}`)) {
                    this.tryParseJson(settingsData[key], (data.find((x) => x.Key == `${key_apendx}_${key}`)).Value);
                }
            }
            else if (Object.keys(settingsData[key]).length > 0) {
                this.readKeysFromResponse(data, settingsData[key], `${key_apendx}_${key}`);
            }
        }
    }
    // #endregion

    private writeSettings(
        dataSettings: UserSettingModel[],
        settingsData: UserSettings,
        origSettingsData: UserSettings,
        key_apendx = 'purchaser') {
        for (const key in settingsData) {
            const dataValue = JSON.stringify(settingsData[key].value);
            if (settingsData[key] instanceof UserSettingsValue) {
                if (dataValue != JSON.stringify(origSettingsData[key].value)) {
                    dataSettings.push(
                        {
                            Key: `${key_apendx}_${key}`,
                            Value: dataValue
                        });
                }
            }
            else if (Object.keys(settingsData[key]).length > 0) {
                this.writeSettings(
                    dataSettings,
                    settingsData[key],
                    origSettingsData[key],
                    `${key_apendx}_${key}`);
            }
        }
    }

    private copySettingsValues(cloneData: UserSettings, data: UserSettings) {
        for (const key in data) {
            if (data[key] instanceof UserSettingsValue) {
                cloneData[key] = cloneDeep(data[key]);
            }
            else if (Object.keys(data[key]).length > 0) {
                this.copySettingsValues(cloneData[key], data[key]);
            }
        }
    }

    private getHttpConfig() {
        if (!this.user.isAuthenticated) {
            throw new Error('Unauthenticated');
        }

        // implement any further special stuff here

        return {} as ng.IRequestShortcutConfig;
    }
}
