import { Alert } from '../Controls/Alert/Alert';
import { ConfirmChange } from '../Controls/ConfirmChange/ConfirmChange';
import { LicenseComparison } from '../Controls/HiltiStyled/LicenseComparison/LicenseComparison';
import { TrialBanner } from '../Controls/HiltiStyled/TrialBanner/TrialBanner';
import { ModalTranslation } from '../Controls/Modal/BaseModal';
import { SelectRegionLanguage } from '../Controls/SelectRegionLanguage/SelectRegionLanguage';
import { Support } from '../Controls/Support/Support';
import { UnauthorizedAccess } from '../Controls/UnauthorizedAccess/UnauthorizedAccess';
import { UserAgreement } from '../Controls/UserAgreement/UserAgreement';
import { UserAgreementPrivacy } from '../Controls/UserAgreementPrivacy/UserAgreementPrivacy';

export class ModalService {
    private _alert: Alert;
    private _confirmChange: ConfirmChange;
    private _userAgreement: UserAgreement;
    private _userAgreementPrivacy: UserAgreementPrivacy;
    private _selectRegionLanguage: SelectRegionLanguage;
    private _support: Support;
    private _unauthorizedAccess: UnauthorizedAccess;
    private _trialBanner: TrialBanner;
    private _licenseComparison: LicenseComparison;

    public get alert() {
        if (this._alert == null) {
            this._alert = new Alert();
        }

        return this._alert;
    }

    public get confirmChange() {
        if (this._confirmChange == null) {
            this._confirmChange = new ConfirmChange();
        }

        return this._confirmChange;
    }

    public get userAgreement() {
        if (this._userAgreement == null) {
            this._userAgreement = new UserAgreement();
        }

        return this._userAgreement;
    }

    public get userAgreementPrivacy() {
        if (this._userAgreementPrivacy == null) {
            this._userAgreementPrivacy = new UserAgreementPrivacy();
        }

        return this._userAgreementPrivacy;
    }

    public get selectRegionLanguage() {
        if (this._selectRegionLanguage == null) {
            this._selectRegionLanguage = new SelectRegionLanguage();
        }

        return this._selectRegionLanguage;
    }

    public get support() {
        if (this._support == null) {
            this._support = new Support();
        }

        return this._support;
    }

    public get unauthorizedAccess() {
        if (this._unauthorizedAccess == null) {
            this._unauthorizedAccess = new UnauthorizedAccess();
        }

        return this._unauthorizedAccess;
    }

    public get trialBanner() {
        if (this._trialBanner == null) {
            this._trialBanner = new TrialBanner();
        }

        return this._trialBanner;
    }

    public get licenseComparison() {
        if (this._licenseComparison == null) {
            this._licenseComparison = new LicenseComparison();
        }

        return this._licenseComparison;
    }

    public showMaxDesignsLimitModal() {
        this.confirmChange.open('max-designs-limit-reached-popup',
            new ModalTranslation('Agito.Hilti.Purchaser.ProjectAndDesing.Alerts.MaximumDesignsLimitReached.Title'),
            new ModalTranslation('Agito.Hilti.Purchaser.ProjectAndDesing.Alerts.MaximumDesignsLimitReached.Description'),
            null,
            new ModalTranslation('Agito.Hilti.Purchaser.ControlTooltip.Ok'),
            null,
            () => {
                this.confirmChange.close(false);
            });
    }
}
