export class PromiseService {
    public isResolved<TValue>(promise: ng.IPromise<TValue>) {
        return (promise as any)['$$state']['status'] === 1;
    }

    public isTimeoutResolved(promise: number | ng.IPromise<any>) {
        if (promise == null || (promise != null && typeof promise == 'number')) {
            return false;
        }

        return this.isResolved(promise as ng.IPromise<any>);
    }

    public isResponseTimeoutResolved(response: ng.IHttpResponse<any>) {
        return response != null && response.config != null && this.isTimeoutResolved(response.config.timeout);
    }
}
