import { IValidatorContext, Validator, WebControlVisibility } from '../../controls';
import { TextBoxController } from '../TextBox';

export interface RequiredContext extends IValidatorContext<TextBoxController> {
    watch: Function;
}

export class Required extends Validator<TextBoxController, RequiredContext> {
    public add(context: RequiredContext) {
        // refresh validation
        setTimeout(() => context.$scope.$applyAsync(() => {
            context.watch = context.$scope.$watchGroup(['ctrl.required', 'ctrl.rawModel', 'ctrl.disabled', 'ctrl.visibility', 'ctrl.validateDisabled'], () => {
                context.ngModel.$validate();
            });
        }));
    }

    public remove(context: RequiredContext) {
        // remove watch
        context.watch();
    }

    public isValid(context: RequiredContext) {
        return context.control.visibility != WebControlVisibility.visible ||
            !context.control.required ||
            !context.control.validateDisabled && context.control.disabled ||
            context.control.rawModel != null && context.control.rawModel != '';
    }
}
