import angular from 'angular';

import { ModalValue } from '../../../src/app/entities/modal-helper';
import { BrowserService } from '../../Services/browser-service';
import { Ignore } from '../../Services/ignore';
import {
    control, Control, ControlController, ControlDirective, IControl, IControlConstructor, method,
    MethodResult, property
} from '../controls';
import { BaseModalController } from './BaseModal';

const priority = 1000;

export interface IModalConstructor<TControl> extends IControlConstructor {
    template?: string;
    templateUrl?: string;
    controller?: string | (new (...args: any[]) => BaseModalController<TControl>);
    windowClass?: string;
    size?: 'sm' | 'lg' | 'xl';
    modalControl?: TControl;
    noAnimation?: boolean;
}

@control('Modal')
export class Modal<TControl> extends Control {

    @property()
    public windowClass: string;

    @property()
    public size: 'sm' | 'lg' | 'xl';

    @property()
    public modalControl: TControl;

    @property()
    public noAnimation: boolean;

    @property()
    private template: string;

    @property()
    private templateUrl: string;

    @property()
    private controller: string | (new (...args: any[]) => BaseModalController<TControl>);

    constructor(modal?: IModalConstructor<TControl>) {
        super(modal);

        this.createProperties(modal, this.getModalProperties());
    }

    @method()
    public open(): MethodResult<Modal<TControl>, IModalOpend>[] { return null; }

    @method()
    public close(result?: any) { }

    @method()
    public dismiss(reason?: any) { }

    public configProperty(property: string, config: ModalValue<any>) {
        this[`${property}Getter`] = config.getter;
        this[`${property}Setter`] = config.setter;
    }

    public configPropertyAsValue(property: string, value?: any) {
        this[`${property}Getter`] = null;
        this[`${property}Setter`] = null;

        if (value != undefined) {
            this[`${property}`] = value;
        }
    }

    private createProperties(ctorObj: Object, properties: string[]) {
        if (ctorObj != null) {
            for (const property of properties) {
                this.createProperty(ctorObj, property);
            }
        }
    }

    private createProperty(ctorObj: Object, property: string) {
        const value = ctorObj[property];
        const getterName = `${property}Getter`;
        const setterName = `${property}Setter`;
        const valueName = `_${property}`;

        if (value instanceof ModalValue) {
            const modalValue = value as ModalValue<any>;

            this[getterName] = modalValue.getter;
            this[setterName] = modalValue.setter;
        }
        else {
            this[getterName] = () => {
                return this[valueName];
            };

            this[setterName] = (value: any) => {
                this[valueName] = value;
            };

            this[valueName] = value;
        }

        Object.defineProperty(this, property, {
            get: () => {
                if (this[getterName] != null) {
                    return this[getterName]();
                }

                return this[valueName];
            },
            set: (value) => {
                if (this[setterName] != null) {
                    this[setterName](value);
                }
                else {
                    this[valueName] = value;
                }
            },
            enumerable: true,
            configurable: true
        });
    }

    private getModalProperties() {
        const metadataKeys: string[] = Reflect.getMetadataKeys(this.constructor.prototype);
        const properties: string[] = [];

        for (const key of metadataKeys) {
            if (key.startsWith('modalProperty:')) {
                properties.push(key.substring('modalProperty:'.length));
            }
        }

        return properties;
    }
}

export interface IModalOpend {
    rendered: ng.IPromise<any>;
    closed: ng.IPromise<any>;
    result: ng.IPromise<any>;
}

export class ModalController<TControl extends Modal<TControl>> extends ControlController<TControl> {
    public static $inject = [
        'ignore',
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    public template: string;
    public templateUrl: string;
    public controller: string | (new (...args: any[]) => BaseModalController<TControl>);
    public windowClass: string;
    public size: 'sm' | 'lg' | 'xl';
    public modalControl: TControl;
    public noAnimation: boolean;

    protected $compile: ng.ICompileService;
    protected browser: BrowserService;

    private modalInstance: ng.ui.bootstrap.IModalServiceInstance;

    private $uibModal: ng.ui.bootstrap.IModalService;

    constructor(control: new (ctor?: Object) => Modal<TControl>, $scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super((control instanceof Ignore ? Modal : control) as any, $scope, $element, $attrs);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$compile = this.$injector.get<ng.ICompileService>('$compile');
            this.$uibModal = $uibModal;
            this.browser = this.$injector.get<BrowserService>('browser');

            // content
            const content = this.$element[0].innerHTML;
            this.$element.contents().remove();

            if (this.templateUrl == null && this.template == null) {
                this.template = content;
            }

            // compile other directives
            this.$compile(this.$element, null, priority)(this.$scope.$parent);

            this.$scope.$on('$destroy', this.destroy.bind(this));
        })(this.$onInit);
    }

    public open() {
        this.close();

        const hasController = this.controller != null;

        this.modalInstance = this.$uibModal.open({
            animation: this.noAnimation !== true,
            bindToController: hasController ? true : undefined,
            controller: hasController ? this.controller : undefined,
            controllerAs: hasController ? 'ctrl' : undefined,
            template: this.template,
            templateUrl: this.templateUrl,
            backdrop: 'static',
            windowClass: this.windowClass,
            size: this.size,
            resolve: {
                control: () => {
                    return this.modalControl || this.control;
                }
            }
        });

        this.modalInstance.rendered = this.modalInstance.rendered.then((args) => {
            // IE: remove tabindex attribute since it interferes with the dropdown control
            document.querySelector('.modal').removeAttribute('tabindex');

            return args;
        });

        // suppress 'Possibly unhandled rejection' for result
        this.modalInstance.result.catch(angular.noop);

        return {
            rendered: this.modalInstance.rendered,
            closed: this.modalInstance.closed,
            result: this.modalInstance.result
        } as IModalOpend;
    }

    public close(result?: any) {
        if (this.modalInstance != null) {
            this.modalInstance.close(result);

            this.modalInstance = null;
        }
    }

    public dismiss(reason?: any) {
        if (this.modalInstance != null) {
            this.modalInstance.dismiss(reason);

            this.modalInstance = null;
        }
    }

    private destroy() {
        this.close('destroy');
    }
}

export class ModalDirective extends ControlDirective {
    constructor(controller?: new (...args: any[]) => ControlController<any>, control?: new (ctor?: Object) => IControl) {
        super(controller || ModalController, control || Modal, null);

        this.templateUrl = null;
        this.terminal = true;
        this.priority = priority;
    }
}

export function modalProperty(): PropertyDecorator {
    return (target: Object, propertyKey: string) => {
        Reflect.defineMetadata(`modalProperty:${propertyKey}`, null, target);
    };
}
