import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GuidService {
    public new() {
        return `${this.s4() + this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}-${this.s4() + this.s4() + this.s4()}`;
    }

    public removeHyphens(guid: string) {
        if (!guid) {
            return null;
        }

        return guid.replace(/-/g, '');
    }

    private s4() {
        // tslint:disable-next-line:no-bitwise
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
}
