<div class="bar" [style.width.px]="width" [style.height.px]="height">
    <div class="fill fill-1" [style.background-color]="color1" [style.border-color]="color != null ? color : '#fafafa'"
        [style.animation-duration.s]="duration" [style.animation-delay.s]="0 * delay"></div>
    <div class="space"></div>
    <div class="fill fill-2" [style.background-color]="color2" [style.border-color]="color2"
        [style.animation-duration.s]="duration" [style.animation-delay.s]="1 * delay"></div>
    <div class="space"></div>
    <div class="fill fill-3" [style.background-color]="color3" [style.border-color]="color3"
        [style.animation-duration.s]="duration" [style.animation-delay.s]="2 * delay"></div>
    <div class="space"></div>
    <div class="fill fill-4" [style.background-color]="color4" [style.border-color]="color4"
        [style.animation-duration.s]="duration" [style.animation-delay.s]="3 * delay"></div>
    <div class="space"></div>
    <div class="fill fill-5" [style.background-color]="color5" [style.border-color]="color5"
        [style.animation-duration.s]="duration" [style.animation-delay.s]="4 * delay"></div>
</div>
