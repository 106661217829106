import {
    TrialBannerFeature as TrialBannerFeatureCodeList
} from '../../../../src/app/entities/codeLists/trial-banner-feature';
import {
    TrialBannerFeature
} from '../../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Enums';
import { replace } from '../../../../src/app/helpers/string-helper';
import { urlPath } from '../../../../src/app/ModuleConstants';
import { LocalizationService } from '../../../../src/app/services/localization.service';
import { CodeListService, ProjectCodeList } from '../../../../src/app/services/code-list.service';
import { LicenseService } from '../../../Services/license-service';
import { ModalService } from '../../../Services/modal-service';
import { UserSettingsService } from '../../../Services/user-settings-service';
import { control } from '../../controls';
import { BaseModalController } from '../../Modal/BaseModal';
import { Modal, ModalController, ModalDirective } from '../../Modal/Modal';
import template from './TrialBanner.html';

export class TrialBannerModalController extends BaseModalController<TrialBanner> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        '$location',
        'localization',
        'control',
        'license',
        'userSettings',
        'modal',
        'keylight',
        'codeList'
    ];

    public submitted: boolean;

    public showLicenseComparisonButton = true;

    private $scope: ng.IScope;
    private localization: LocalizationService;
    private $location: ng.ILocationService;
    private license: LicenseService;
    private userSettings: UserSettingsService;
    private modal: ModalService;
    private codeList: CodeListService;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        $location: ng.ILocationService,
        localization: LocalizationService,
        control: TrialBanner,
        license: LicenseService,
        userSettings: UserSettingsService,
        modal: ModalService,
        codeList: CodeListService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.$location = $location;
            this.$scope['localization'] = this.localization = localization;
            this.license = license;
            this.userSettings = userSettings;
            this.modal = modal;
            this.codeList = codeList;

            this.showLicenseComparisonButton = this.setShowLicenseComparisonButton();

            this.$scope.$on('modal.closing', (event) => {
                if (!this.submitted && this.license.isTrialExpired()) {
                    event.preventDefault();
                }
            });
        })(this.$onInit);
    }

    public get getSectionTitle() {
        if (this.license.isTrialExpired()) {
            return this.localization.getLocalizedString('Agito.Hilti.Purchaser.TrialExpired.Section.Title');
        }
        else {
            const tranlation = this.localization.getLocalizedString('Agito.Hilti.Purchaser.Trial.Section.Title');
            return replace(tranlation, '{number}', this.license.getRemainingDays().toString());
        }
    }

    public get trialBannerInfoText() {
        // Trial expiration information text
        if (this.license.isTrialExpired()) {
            return 'Agito.Hilti.Purchaser.TrialExpired.Info';
        }
        else {
            // Trial license information text
            if (this.showLicenseComparisonButton) {
                return 'Agito.Hilti.Purchaser.Trial.Info';
            }
            else {
                return 'Agito.Hilti.Purchaser.Trial.Info.NoLink';
            }
        }
    }

    public close() {
        this.$uibModalInstance.close();
    }

    public logout() {
        this.$location.path(urlPath.logout)
            .search('type', 'invalidate');
    }

    public purchase() {
        const getProfis3Url = this.userSettings.getProfis3Url();

        if (getProfis3Url != null) {
            window.open(getProfis3Url, '_blank');
        }
    }

    public licenseCompare() {
        this.submitted = true;

        this.modal.licenseComparison.open();

        this.$uibModalInstance.close();
    }

    private setShowLicenseComparisonButton(): boolean {
        const region = this.userSettings.getActiveRegion();

        // Get the regions that should not display the comparison button
        const trialBannerFeatures = this.codeList.codelist[ProjectCodeList.TrialBannerFeature] as TrialBannerFeatureCodeList[];
        const noLicenseComparisonButtonRegions = trialBannerFeatures.find((f) => f.id === TrialBannerFeature.NoLicenseComparisonButton).regions;

        // If user is from a country that should not display the comparison button, hide it
        if (noLicenseComparisonButtonRegions.some((r) => r === region.id)) {
            return false;
        }

        return true;
    }
}

export interface ITrialBannerConstructor {
}

@control('HiltiStyledTrialBanner')
export class TrialBanner extends Modal<TrialBanner> {
    constructor(ctor?: ITrialBannerConstructor) {
        super({
            template,
            templateUrl: 'HiltiStyledTrialBanner.html',
            controller: TrialBannerModalController,
            windowClass: 'base-modal trial-banner-modal',
            size: 'full-screen' as any,
            ...ctor
        });
    }
}

class TrialBannerController extends ModalController<TrialBanner> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(TrialBanner, $scope, $element, $attrs, $uibModal);
    }
}

export class TrialBannerDirective extends ModalDirective {
    constructor() {
        super(TrialBannerController, TrialBanner);
    }
}
