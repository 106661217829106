import angular from 'angular';

import { triggerChangeEvent } from '../../src/app/helpers/htmlHelper';

const keyEnter = 13;
const keySpace = 32;

export class RadioDirective implements ng.IDirective {
    public restrict = 'A';

    public static Factory() {
        return () => new RadioDirective();
    }

    public link: ng.IDirectiveLinkFn = ($scope, $element, $attrs) => {
        const element = $element[0];

        const isElementButton = element.classList.contains('radio-button');

        const radioButton = isElementButton ? element : element.querySelector('.radio-button');
        const radioLabel = isElementButton ? null : element.querySelector('.radio-label');
        const buttonInput = radioButton.querySelector('input');

        const radioButtonClick = () => {
            if (!buttonInput.disabled) {
                $scope.$apply(() => {
                    this.selectInput($scope, buttonInput);
                });
            }
        };

        const radioButtonKeyPress = (event: JQueryKeyEventObject) => {
            const eventCode = event.which || event.keyCode || event.charCode;

            if (!buttonInput.disabled && (eventCode == keyEnter || eventCode == keySpace)) {
                $scope.$apply(() => {
                    this.selectInput($scope, buttonInput);

                    event.preventDefault();
                });
            }
        };

        const radioLabelClick = () => {
            if (!buttonInput.disabled) {
                $scope.$apply(() => {
                    this.selectInput($scope, buttonInput);
                });
            }
        };

        const buttonInputClick = (event: JQueryEventObject) => {
            event.stopPropagation();
        };

        radioButton.addEventListener('click', radioButtonClick, false);
        radioButton.addEventListener('keypress', radioButtonKeyPress, false);
        radioLabel?.addEventListener('click', radioLabelClick, false);
        buttonInput.addEventListener('click', buttonInputClick, false);

        $scope.$on('$destroy', () => {
            radioButton.removeEventListener('click', radioButtonClick, false);
            radioButton.removeEventListener('keypress', radioButtonKeyPress, false);
            radioLabel?.removeEventListener('click', radioLabelClick, false);
            buttonInput.removeEventListener('click', buttonInputClick, false);
        });
    }

    private selectInput($scope: ng.IScope, input: HTMLInputElement) {
        const ngModel = angular.element(input).controller('ngModel') as ng.INgModelController;

        const val = input.getAttribute('ng-value') != null ? $scope.$eval(input.getAttribute('ng-value')) : input.getAttribute('value');

        ngModel.$setViewValue(val);
        ngModel.$commitViewValue();
        ngModel.$setTouched();

        input.click();
        triggerChangeEvent(input);
        input.focus();
    }
}

export class RadioDisabledDirective implements ng.IDirective {
    public restrict = 'A';

    public static Factory() {
        return () => new RadioDisabledDirective();
    }

    public link = ($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes) => {
        const element = $element[0];

        const isElementButton = element.classList.contains('radio-button');

        const radioButton = isElementButton ? element : element.querySelector('.radio-button');
        const buttonInput = radioButton.querySelector('input');

        $scope.$watch($attrs['agtRadioDisabled'], (disabled) => {
            if (disabled) {
                element.classList.add('disabled');
                radioButton.setAttribute('tabindex', '-1');
                buttonInput.setAttribute('disabled', 'disabled');
            }
            else {
                element.classList.remove('disabled');
                radioButton.setAttribute('tabindex', '0');
                buttonInput.removeAttribute('disabled');
            }
        });
    }
}
