import { Component, Input, TemplateRef } from '@angular/core';

import { LocalizationService } from '../../services/localization.service';

export interface ContentTooltip {
  title?: string;
  content?: string;
}

export type NgbTooltipValue = string | TemplateRef<void>;
export type Tooltip = NgbTooltipValue | ContentTooltip;

export function isContentTooltip(value: unknown): value is ContentTooltip {
  // tslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return typeof value == 'object' && value != null && 'title' in value && 'content' in value;
}

export function ngbTooltipTemplate(tooltip: Tooltip | undefined, tooltipTemplate: TemplateRef<void>): NgbTooltipValue {
  if (tooltip == undefined) {
      return '';
  }

  if (isContentTooltip(tooltip)) {
      return tooltipTemplate;
  }

  return tooltip;
}

@Component({
    selector: 'app-content-tooltip',
    templateUrl: './content-tooltip.component.html',
    styleUrls: ['./content-tooltip.component.scss']
})
export class ContentTooltipComponent {
    public isContentTooltip = false;
    public title?: string;
    public content?: string;


    constructor(
        private localizationService: LocalizationService
    ) { }


    @Input()
    public set tooltip(tooltip: Tooltip) {
        if (tooltip != null) {
            // tslint:disable-next-line: no-conditional-assignment
            if (this.isContentTooltip = isContentTooltip(tooltip)) {
                this.title = this.localizationService.sanitizeText(tooltip.title ?? '', LocalizationService.SubSup);
                this.content = this.localizationService.sanitizeText(tooltip.content ?? '', LocalizationService.SubSup);
            }
        }
    }
}
