import { Tooltip } from '../../../../App/Controls/ControlTooltip/ControlTooltip';
import { LocalizationService } from '../../services/localization.service';

export interface ICodeListTextDeps {
    localizationService?: LocalizationService;
}

export function getCodeListTextDeps(
    localizationService?: LocalizationService,
) {
    return {
        localizationService,
    } as ICodeListTextDeps;
}

export interface ICodeListConstructor {
    id?: number;
    name?: string;
    nameResourceKey?: string;
    image?: string;
    groupResourceKey?: string;
    tooltip?: Tooltip;
    tooltipDisplayKey?: string;
    tooltipTitleDisplayKey?: string;
}

export abstract class CodeList {
    public id: number;
    public name: string;
    public nameResourceKey: string;
    public image: string;
    public groupResourceKey: string;
    public tooltip: Tooltip;
    public tooltipDisplayKey: string;
    public tooltipTitleDisplayKey: string;

    constructor(
        codeList?: ICodeListConstructor
    ) {
        if (codeList != null) {
            this.id = codeList.id;
            this.name = codeList.name;
            this.nameResourceKey = codeList.nameResourceKey;
            this.image = codeList.image;
            this.groupResourceKey = codeList.groupResourceKey;
            this.tooltip = codeList.tooltip;
            this.tooltipDisplayKey = codeList.tooltipDisplayKey;
            this.tooltipTitleDisplayKey = codeList.tooltipTitleDisplayKey;
        }
    }

    public getTranslatedNameText(codeListDeps: ICodeListTextDeps) {
        if (this.name != null && this.name != '') {
            return this.name;
        }

        if (this.nameResourceKey != null && this.nameResourceKey != '') {
            return codeListDeps.localizationService.getLocalizedString(this.nameResourceKey);
        }

        return null;
    }

    public cloneDeep() {
        const constructor: new (codeList?: ICodeListConstructor) => CodeList = this.constructor as any;

        return new constructor({
            id: this.id,
            name: this.name,
            nameResourceKey: this.nameResourceKey,
            image: this.image,
            groupResourceKey: this.groupResourceKey,
            tooltip: this.tooltip.cloneDeep(),
            tooltipDisplayKey: this.tooltipDisplayKey,
            tooltipTitleDisplayKey: this.tooltipTitleDisplayKey
        });
    }
}
