import groupBy from 'lodash-es/groupBy';
import uniqWith from 'lodash-es/uniqWith';

import { ModalValue } from '../../../src/app/entities/modal-helper';
import { UnitGroup } from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Common.Units';
import {
    BillOfMaterialDesignEntity, BillOfMaterialDetailsEntity, BillOfMaterialEntity
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.BillOfMaterial';
import {
    DesignEntity
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Design';
import {
    AnchorType
} from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Data.Enums';
import { urlPath } from '../../../src/app/ModuleConstants';
import { PropertyMetaData } from '../../../src/app/properties/properties';
import { DateTimeService } from '../../../src/app/services/date-time.service';
import { LocalizationService } from '../../../src/app/services/localization.service';
import { ArticleNumberNotFound, BomController } from '../../Controllers/bom-controller';
import { Project } from '../../Entities/Project';
import { BomService } from '../../Services/bom-service';
import { BrowserService } from '../../Services/browser-service';
import { DocumentService, ProjectType } from '../../Services/document-service';
import { NumberService } from '../../Services/number-service';
import { UnitService } from '../../Services/unit-service';
import { ContactHilti } from '../ContactHilti/ContactHilti';
import { Bind, control, Translation } from '../controls';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective, modalProperty } from '../Modal/Modal';
import { TextBox } from '../TextBox/TextBox';
import template from './CreateNewBom.html';

interface ITableRow {
    articleNumber: string;
    calculatedAmount: number;
    orderAmount: number;
    anchorType: AnchorType;
    anchorFamilyName: string;
    anchorName: string;
    articleQuantity: number;
}

export const MAX_INT32_VALUE = 0x7FFFFFFF;

export class CreateNewBomModalController extends BaseModalController<CreateNewBom> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        '$location',
        'localization',
        'control',
        'document',
        'dateTime',
        'unit',
        'number',
        'bom',
        'browser',
    ];

    public nameTextBox: TextBox;
    public remarksTextBox: TextBox;

    public contactHilti: ContactHilti;

    public tableItems: ITableRow[];

    private createdDate: Date;

    private pending: {
        save: boolean;
        excel: boolean;
        order: boolean;
    };

    private $scope: ng.IScope;
    private $location: ng.ILocationService;
    private localization: LocalizationService;
    private document: DocumentService;
    private dateTime: DateTimeService;
    private unit: UnitService;
    private number: NumberService;
    private bomService: BomService;
    private browser: BrowserService;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        $location: ng.ILocationService,
        localization: LocalizationService,
        control: CreateNewBom,
        document: DocumentService,
        dateTime: DateTimeService,
        unit: UnitService,
        number: NumberService,
        bom: BomService,
        browser: BrowserService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.$location = $location;
            this.$scope['localization'] = this.localization = localization;
            this.browser = browser;

            this.document = document;
            this.dateTime = dateTime;
            this.unit = unit;
            this.number = number;
            this.bomService = bom;

            this.createdDate = new Date();

            this.tableItems = [];

            if (this.includedConnections != null && this.includedConnections.length > 0) {
                const articleNumberConnections = this.includedConnections.filter(connection => connection.SelectedAnchor.ArticleNumber != ArticleNumberNotFound);
                const noArticleNumberConnections = this.includedConnections.filter(connection => connection.SelectedAnchor.ArticleNumber == ArticleNumberNotFound);

                const articleNumberGroup = groupBy(articleNumberConnections, connection => connection.SelectedAnchor.ArticleNumber);

                // article number group
                for (const articleNumber in articleNumberGroup) {
                    const connections = articleNumberGroup[articleNumber];
                    if (connections.length > 0) {
                        const sum = connections.map(connection => connection.ComputedAmount != null ? connection.ComputedAmount : 0).reduce((a, b) => a + b);

                        this.tableItems.push({
                            anchorFamilyName: connections[0].SelectedAnchor.AnchorFamilyName,
                            anchorName: connections[0].SelectedAnchor.AnchorName,
                            anchorType: connections[0].AnchorType,
                            articleNumber,
                            articleQuantity: connections[0].SelectedAnchor.ArticleQuantity,
                            calculatedAmount: sum,
                            orderAmount: sum
                        });
                    }
                }

                // no article number (connections that do not have article number)
                // those should not be grouped
                for (const connection of noArticleNumberConnections) {
                    this.tableItems.push({
                        anchorFamilyName: connection.SelectedAnchor.AnchorFamilyName,
                        anchorName: connection.SelectedAnchor.AnchorName,
                        anchorType: connection.AnchorType,
                        articleNumber: ArticleNumberNotFound,
                        articleQuantity: connection.SelectedAnchor.ArticleQuantity,
                        calculatedAmount: connection.ComputedAmount,
                        orderAmount: connection.ComputedAmount
                    });
                }

                // sort by name
                this.tableItems.sort((a, b) => {
                    if (a.anchorName == null || b.anchorName == null) {
                        return 0;
                    }
                    else if (a.anchorName.toLowerCase() < b.anchorName.toLowerCase()) {
                        return -1;
                    }
                    else if (a.anchorName.toLowerCase() > b.anchorName.toLowerCase()) {
                        return 1;
                    }

                    return 0;
                });
            }

            this.pending = { save: false, excel: false, order: false };


            // don't close the modal if save is pending
            this.$scope.$on('modal.closing', (event) => {
                if (this.pending.save || this.pending.excel || this.pending.order) {
                    event.preventDefault();
                }
            });

            // controls
            this.nameTextBox = new TextBox({
                required: true,
                disabled: new Bind('ctrl.submitted'),
                title: new Translation('Agito.Hilti.Purchaser.CreateNewBom.Name'),
                model: this.createBomName(this.project)
            });

            this.remarksTextBox = new TextBox({
                required: false,
                disabled: new Bind('ctrl.submitted'),
                title: new Translation('Agito.Hilti.Purchaser.CreateNewBom.Remark'),
                model: null,
                attrMax: 500
            });

            this.contactHilti = new ContactHilti();

            // focus remarks text box
            this.$uibModalInstance.rendered
                .then(() => {
                    const input = window.document.querySelector<HTMLInputElement>('.create-new-bom-modal .name-text-box .input');

                    input.focus();
                });
        })(this.$onInit);
    }

    public get project() {
        return this.control.project;
    }

    public get bomDetails() {
        return this.bomService.model[PropertyMetaData.BOM_BOMDetailsList.id] as BillOfMaterialDetailsEntity[];
    }

    public get submitted() {
        return this.pending.save || this.pending.excel || this.pending.order;
    }

    public get modalTitle() {
        return this.localization.getLocalizedString('Agito.Hilti.Purchaser.CreateNewBom.Title') + ' / ' + this.dateTime.format(this.createdDate);
    }

    public get includedConnections() {
        return this.control.connectionDetails.filter(c => c.IsIncluded);
    }

    public get createNewBomItem() {
        const uniqueConnections = uniqWith(this.includedConnections, (c1: DesignEntity, c2: DesignEntity) => c1.ProjectId == c2.ProjectId && c1.DesignId == c2.DesignId);
        const bomDesigns = uniqueConnections.map(c => {
            const project = Object.values(this.document.projects).find(p => p.id == c.ProjectId);
            return {
                DesignName: c.DesignName,
                ProjectName: (project == null || project.projectType == ProjectType.draft ? this.localization.getLocalizedString('Agito.Hilti.Purchaser.Documents.SpecialProject.draft') : project.name)
            } as BillOfMaterialDesignEntity;
        });

        return {
            BomName: this.nameTextBox.model,
            Remark: this.remarksTextBox.model,
            CreatedDate: this.dateTime.toMicrosoftFormat(this.createdDate) as any,
            BomDesigns: bomDesigns
        } as BillOfMaterialEntity;
    }

    public get createNewBomItemDetails() {
        return this.tableItems.map(t => {
            return {
                TotalInProject: t.calculatedAmount,
                OrderAmount: t.orderAmount,
                AnchorType: t.anchorType,
                ArticleNumber: t.articleNumber,
                LengthOrSize: t.articleQuantity,
                AnchorFamilyName: t.anchorFamilyName,
                AnchorName: t.anchorName,
            } as BillOfMaterialDetailsEntity;
        });
    }

    private get form() {
        return this.$scope['form'] as ng.IFormController;
    }

    public translate(key: string) {
        return this.localization.getLocalizedString(key);
    }

    public buildProductSize(row: ITableRow) {
        if (row.anchorType == null || row.anchorType == AnchorType.Insert) {
            return this.unit.formatInternalValueAsDefault(this.unit.parseNumber(row.articleQuantity as any as string), UnitGroup.Length);
        }
        else if (row.anchorType == AnchorType.Mortar || row.anchorType == AnchorType.Capsule) {
            return this.unit.formatInternalValueAsDefault(this.unit.parseNumber(row.articleQuantity as any as string), UnitGroup.Volume);
 }
        else if (row.anchorType == AnchorType.SieveSleeve) {
            return '';
 }
        else {
            throw new Error('Unknown AnchorType.');
 }
    }

    public save() {
        if (this.submitted || !this.form.$valid) {
            return;
        }

        const newBom = this.createNewBomItem;
        this.pending.save = true;

        this.bomService.addNewBom(this.createNewBomItem, this.createNewBomItemDetails, this.project.purchaserDataEntity)
            .finally(() => {
                this.pending.save = false;
            })
            .then(() => {
                this.close();
                this.$location.path(urlPath.bom);
                return undefined;
            });

    }

    public exportToExcel() {
        if (this.submitted || !this.form.$valid) {
            return;
        }

        this.pending.excel = true;
        this.bomService.addNewBom(this.createNewBomItem, this.createNewBomItemDetails, this.project.purchaserDataEntity)
            .then((bomId: number) => {
                const createdDate = this.createdDate instanceof Date
                    ? this.localization.moment(this.createdDate).format('lll').replace(':', '-')
                    : (this.createdDate != null && typeof this.createdDate == 'number')
                        ? this.localization.moment(new Date(this.createdDate)).format('lll').replace(':', '-')
                        : this.createdDate;

                this.bomService.exportToExcel(this.bomDetails.filter(b => b.BomId == bomId), this.nameTextBox.model + ' ' + createdDate);

                this.pending.excel = false;

                this.$location.path(urlPath.bom);
                this.close();
            })
            .catch(() => {
                this.pending.excel = false;
            });
    }

    public placeOrderOnHol() {
        if (this.submitted || !this.form.$valid) {
            return;
        }

        this.pending.order = true;
        this.bomService.addNewBom(this.createNewBomItem, this.createNewBomItemDetails, this.project.purchaserDataEntity)
            .then((bomId: number) => {
                this.bomService.placeOrder(bomId);

                this.pending.order = false;

                this.$location.path(urlPath.bom);
                this.close();
            })
            .catch(() => {
                this.pending.order = false;
            });
    }

    public close() {
        this.$uibModalInstance.close();
    }

    public print() {
        this.bomService.printBom(this.createNewBomItemDetails);
    }

    public bindCount(row: ITableRow) {
        return (value: string) => {
            // setter
            if (value !== undefined) {
                let count = this.unit.parseNumber(value);

                if (count != null && !Number.isNaN(count)) {
                    // round to a whole number
                    count = this.number.round(count);

                    // min value
                    if (count < BomController.orderAmountMin) {
                        count = BomController.orderAmountMin;
                    }

                    if (count > BomController.orderAmountMax) {
                        count = BomController.orderAmountMax;
                    }

                    row.orderAmount = count;
                }
            }

            // getter
            return this.unit.formatNumber(row.orderAmount);
        };
    }

    private createBomName(project: Project) {
        return project.projectType != ProjectType.draft ? project.name : this.localization.getLocalizedString('Agito.Hilti.Purchaser.Documents.SpecialProject.draft');
    }

    private hasArticleNumber(articleNumber: string) {
        if (articleNumber == ArticleNumberNotFound) {
            return false;
        }

        return true;
    }
}

export interface ICreateNewBomConstructor {
    project?: Project | ModalValue<Project>;
    connectionDetails?: DesignEntity[];
}

@control('CreateNewBom')
export class CreateNewBom extends Modal<CreateNewBom> {

    @modalProperty()
    public project: Project;

    @modalProperty()
    public connectionDetails: DesignEntity[];

    constructor(ctor?: ICreateNewBomConstructor) {
        super({
            template,
            templateUrl: 'CreateNewBom.html',
            controller: CreateNewBomModalController,
            windowClass: 'default-modal create-new-bom-modal',
            size: 'lg',
            ...ctor
        });
    }
}

class CreateNewBomController extends ModalController<CreateNewBom> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(CreateNewBom, $scope, $element, $attrs, $uibModal);
    }
}

export class CreateNewBomDirective extends ModalDirective {
    constructor() {
        super(CreateNewBomController, CreateNewBom);
    }
}
