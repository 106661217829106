import { Subscription } from 'rxjs';

import {
    AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Optional, Output,
    SimpleChanges, ViewChild
} from '@angular/core';
import { NgForm, NgModel, ValidatorFn } from '@angular/forms';

import { randomString } from '../../helpers/random';
import { Tooltip } from '../content-tooltip/content-tooltip.component';

export type TextBoxType = 'text' | 'number' | 'password';

export interface TextBoxProps {
    id?: string;
    title?: string;
    value?: string;
    blur?: (value: string) => void;
    placeholder?: string;
    disabled?: boolean;
    minLength?: number;
    maxLength?: number;
    type?: TextBoxType;
    validators?: ValidatorFn[];
    tooltip?: Tooltip;
    isValid?: boolean;
    submitted?: boolean;
}

@Component({
    selector: 'app-text-box',
    templateUrl: './text-box.component.html',
    styleUrls: ['./text-box.component.scss']
})
export class TextBoxComponent implements AfterViewInit, OnChanges, OnDestroy {

    @Input()
    public id = randomString(8);

    @Input()
    public title: string;

    @Input()
    public value = '';

    @Output()
    public valueChange = new EventEmitter<string>();

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-native
    public blur = new EventEmitter<string>();

    @Input()
    public placeholder = '';

    @Input()
    public disabled = false;

    @Input()
    public minLength = 0;

    @Input()
    public maxLength = 524288;

    @Input()
    public type: TextBoxType = 'text';

    @Input()
    public validators: ValidatorFn[];

    @Input()
    public tooltip: Tooltip;

    @Output()
    public isValid = new EventEmitter<boolean>();

    @Input()
    public submitted = false;

    @Output()
    public submittedChange = new EventEmitter<boolean>();

    @ViewChild('ngModel')
    public ngModel: NgModel;

    private form: NgForm;
    private ngSubmitSubscription: Subscription;

    constructor(@Optional() form: NgForm) {
        this.form = form;
    }

    public ngAfterViewInit(): void {
        // add control to the form and sync submitted
        if (this.form != null) {
            this.form.addControl(this.ngModel);
            this.ngSubmitSubscription = this.form.ngSubmit.subscribe(() => {
                if (this.submitted != this.form.submitted) {
                    this.submitted = this.form.submitted;
                    this.submittedChange.emit(this.submitted);
                }
            });
        }

        // sync isValid
        this.ngModel.statusChanges.subscribe(() => {
            this.isValid.emit(this.ngModel.disabled || this.ngModel.valid);
        });

        this.updateValidators();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['validators']?.firstChange === false) {
            this.updateValidators();
        }
    }

    public ngOnDestroy(): void {
        // remove control from form and unsubscribe from ngSubmit
        if (this.form != null) {
            this.form.removeControl(this.ngModel);
            this.ngSubmitSubscription?.unsubscribe();
        }
    }

    public ngModelChange(value: string) {
        this.value = value;
        this.valueChange.emit(value);
    }

    private updateValidators() {
        this.ngModel.control.setValidators(this.validators ?? []);
        this.ngModel.control.updateValueAndValidity();
    }
}
