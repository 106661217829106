import { HttpResponseBase } from '@angular/common/http';
import angular from 'angular';
import escape from 'lodash-es/escape';
import { IAlertConstructor, IAlertParameters } from '../../../src/app/entities/alert';

import { ErrorDetails, IApplicationError } from '../../../src/app/entities/application-error';
import { Region } from '../../../src/app/entities/codeLists/region';
import { ModalValue } from '../../../src/app/entities/modal-helper';
import { urlPath } from '../../../src/app/ModuleConstants';
import { AppConfigService } from '../../../src/app/services/app-config.service';
import { CodeListService, ProjectCodeList } from '../../../src/app/services/code-list.service';
import { ModalService } from '../../Services/modal-service';
import { UserSettingsService } from '../../Services/user-settings-service';
import { control } from '../controls';
import { BaseModalController, ModalTranslation } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective, modalProperty } from '../Modal/Modal';
import template from './Alert.html';

export class AlertModalController extends BaseModalController<Alert> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'control',
        'modal',
        '$timeout'
    ];

    private $scope: ng.IScope;
    private modal: ModalService;
    private $timeout: ng.ITimeoutService;
    private $location: ng.ILocationService;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        control: Alert,
        modal: ModalService,
        $timeout: ng.ITimeoutService,
        $location: ng.ILocationService,
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.modal = modal;
            this.$timeout = $timeout;
            this.$location = $location;
        })(this.$onInit);
    }

    public get title() {
        return this.control.title != null && this.control.title != '' ? escape(this.control.title) : '&nbsp;';
    }

    public get message() {
        let message = this.control.message;

        if (message == null || message == '') {
            message = '&nbsp;';
        }
        else if (message.startsWith('<html>') && message.endsWith('</html>')) {
            message = message.substring('<html>'.length);
            message = message.substring(0, message.length - '</html>'.length);
        }
        else {
            message = escape(message);
        }

        return message;
    }

    public close() {
        this.$uibModalInstance.close();
    }

    public openSupport() {
        this.$timeout(() => this.close())
            .then(() => this.modal.support.open(this.control.applicationError))
            .catch((err: any) => console.error(err));
    }

    public logout() {
        this.$location.path(urlPath.logout).search('type', 'invalidate');
    }
}

@control('Alert')
export class Alert extends Modal<Alert> {
    private static _$injector: ng.auto.IInjectorService;

    private static _appConfig: AppConfigService;

    private static _codeList: CodeListService;

    private static _userSettings: UserSettingsService;

    @modalProperty()
    public title: string;

    @modalProperty()
    public message: string;

    @modalProperty()
    public applicationError: IApplicationError;

    @modalProperty()
    public alertParameters: IAlertParameters;

    @modalProperty()
    public showSupportButton: boolean;

    @modalProperty()
    public showLogoutButton: boolean;

    constructor(ctor?: IAlertConstructor) {
        super({
            template,
            templateUrl: 'Alert.html',
            controller: AlertModalController,
            windowClass: 'default-modal alert-modal',
            ...ctor
        });
    }
    protected static getInjector() {
        if (Alert._$injector == null) {
            Alert._$injector = angular.element(document.body).injector();
        }

        return Alert._$injector;
    }
    protected static getAppConfig() {
        if (Alert._appConfig == null) {
            Alert._appConfig = Alert.getInjector().get<AppConfigService>('appConfig');
        }

        return Alert._appConfig;
    }
    protected static getCodeList() {
        if (Alert._codeList == null) {
            Alert._codeList = Alert.getInjector().get<CodeListService>('codeList');
        }

        return Alert._codeList;
    }
    protected static getUserSettings() {
        if (Alert._userSettings == null) {
            Alert._userSettings = Alert.getInjector().get<UserSettingsService>('userSettings');
        }

        return Alert._userSettings;
    }

    public open(alertParameters?: IAlertParameters | ModalValue<IAlertParameters>) {
        if (alertParameters != null) {
            if (alertParameters instanceof ModalValue) {
                this.configProperty('alertParameters', alertParameters);
            }
            else {
                this.configPropertyAsValue('alertParameters', alertParameters);
            }
        }

        this.validateParameters();

        return super.open();
    }

    // public openServiceError(response: ng.IHttpResponse<any>) {
    public openServiceError(applicationError: IApplicationError) {
        const defaultTitle = (new ModalTranslation('Agito.Hilti.Purchaser.ServerErrorAlert.Title', 'ERROR')).getter();
        const defaultMessage = (new ModalTranslation('Agito.Hilti.Purchaser.ServerErrorAlert.Message', 'Please try again later. If the problem persists contact us at')).getter();

        const region = (Alert.getCodeList().codelist[ProjectCodeList.Region] as Region[]).find(region => region.id == Alert.getUserSettings().settings.user.general.regionId.value);

        let contactUrl = null as string;
        if (region != null) {
            contactUrl = region.contactUrl;
        }
        contactUrl = (contactUrl == null || contactUrl == '') ? 'info@info' : contactUrl.replace('mailto:', '');

        const title = defaultTitle;
        let message = escape(defaultMessage) + ' <span class="nowrap">' + escape(contactUrl) + '.</span>';

        let description = '';
        if (applicationError.response?.config != null) {
            description = this.getDescriptionFromResponse(applicationError.response);
        }
        else if (applicationError.httpResponse != null) {
            description = this.getDescriptionFromHttpResponse(applicationError.httpResponse);
        }

        // if there is additional description, prepend it to message
        if (description != null && description != '') {
            message = escape(description + '\n\n') + message;
        }
        message = '<html>' + message + '</html>';

        const param: IAlertParameters = {
            title,
            message,
            applicationError
        };

        this.open(param);
    }

    public openLicenseError() {
        const title = (new ModalTranslation('Agito.Hilti.Purchaser.LicenseErrorAlert.Title', 'UNKNOWN LICENSE')).getter();
        let message = (new ModalTranslation('Agito.Hilti.Purchaser.LicenseErrorAlert.Message', 'Something went wrong with identifying your license type. Your are currently using standard license, if you think you should have a different type of license, please try to re-login in a few minutes or contact Hilti.')).getter();
        message = '<html>' + message + '</html>';

        const param: IAlertParameters = {
            title,
            message,
            showSupportButton: false,
            showLogoutButton: true
        };

        this.open(param);
    }

    private validateParameters() {
        if (this.alertParameters != null) {
            this.validateAlertParameters();
        }

        if (this.applicationError?.response != null) {
            this.validateApplicationErrorResponse();
        }

        if (this.applicationError?.httpResponse != null) {
            this.validateApplicationErrorHttpResponse();
        }
    }

    private validateAlertParameters() {
        if (this.alertParameters.title != null) {
            if (this.alertParameters.title instanceof ModalValue) {
                this.configProperty('title', this.alertParameters.title);
            }
            else {
                this.configPropertyAsValue('title', this.alertParameters.title.toUpperCase());
            }
        }

        if (this.alertParameters.message != null) {
            if (this.alertParameters.message instanceof ModalValue) {
                this.configProperty('message', this.alertParameters.message);
            }
            else {
                this.configPropertyAsValue('message', this.alertParameters.message);
            }
        }

        if (this.alertParameters.showSupportButton != null) {
            if (this.alertParameters.showSupportButton instanceof ModalValue) {
                this.configProperty('showSupportButton', this.alertParameters.showSupportButton);
            }
            else {
                this.configPropertyAsValue('showSupportButton', this.alertParameters.showSupportButton);
            }
        }
        else {
            // Show support button by default
            this.configPropertyAsValue('showSupportButton', true);
        }

        if (this.alertParameters.showLogoutButton != null) {
            if (this.alertParameters.showLogoutButton instanceof ModalValue) {
                this.configProperty('showLogoutButton', this.alertParameters.showLogoutButton);
            }
            else {
                this.configPropertyAsValue('showLogoutButton', this.alertParameters.showLogoutButton);
            }
        }
        else {
            // Hide logout button by default
            this.configPropertyAsValue('showLogoutButton', false);
        }

        if (this.alertParameters.applicationError != null) {
            if (this.alertParameters.applicationError instanceof ModalValue) {
                this.configProperty('applicationError', this.alertParameters.applicationError);
            }
            else {
                this.configPropertyAsValue('applicationError', this.alertParameters.applicationError);
            }
        }
    }

    private validateApplicationErrorResponse() {
        if (this.applicationError.errors == null) {
            this.applicationError.errors = [];
        }

        if (this.applicationError.response instanceof Error) {
            if (this.applicationError.errors.length == 0) {
                this.applicationError.errors.push({
                    errorMessage: this.applicationError.response.message,
                    errorStackTrace: this.applicationError.response.stack
                } as ErrorDetails);
            }
        }

        const httpResponse = this.applicationError.response;
        if (
            this.applicationError.endPointUrl == null
            && httpResponse.config?.url != null
            && httpResponse.config.url != ''
        ) {
            this.applicationError.endPointUrl = httpResponse.config.url;
        }

        if (this.applicationError.responsePayload == null && this.applicationError.response != null) {
            this.applicationError.responsePayload = this.applicationError.response;
        }
    }

    private validateApplicationErrorHttpResponse() {
        if (this.applicationError.errors == null) {
            this.applicationError.errors = [];
        }

        if (this.applicationError.httpResponse instanceof Error) {
            if (this.applicationError.errors.length == 0) {
                const error = this.applicationError.httpResponse;
                this.applicationError.errors.push({
                    errorMessage: error.message,
                    errorStackTrace: error.stack
                } as ErrorDetails);
            }
        }

        if (this.applicationError.endPointUrl == null
            && this.applicationError.httpResponse.url != null
            && this.applicationError.httpResponse.url != '') {
                this.applicationError.endPointUrl = this.applicationError.httpResponse.url;
        }

        if (this.applicationError.responsePayload == null && this.applicationError.response != null) {
            this.applicationError.responsePayload = this.applicationError.response;
        }
    }

    private getDescriptionFromResponse(response: angular.IHttpResponse<any>) {
        let description = '';

        // service failed statement
        let serviceDescription: string;
        if (response.config.url != null && response.config.url != '') {
            serviceDescription = (new ModalTranslation('Agito.Hilti.Purchaser.ServerErrorAlert.ServiceDescription')).getter()
                                .replace('{service}', Alert.getAppConfig().getServiceName(response.config.url));
        }

        if (serviceDescription != null && serviceDescription != '') {
            description += serviceDescription;
        }

        // error description statement
        let errorDescription: string;
        if (response.status === -1) { // request was not send at all - probably there is no internet connection
            errorDescription = (new ModalTranslation('Agito.Hilti.Purchaser.ServerErrorAlert.NoConnectionDescription')).getter();
        }
        else if (response.status === 503 || response.status === 408) { // temporarily unavailable or request timed out - probably server is overloaded
            errorDescription = (new ModalTranslation('Agito.Hilti.Purchaser.ServerErrorAlert.ServerOverloadDescription')).getter();
        }
        else if (response.data?.Message != null
                && typeof response.data.Message == 'string'
                && response.data.Message != '') { // message was returned, let display it
            errorDescription = (new ModalTranslation('Agito.Hilti.Purchaser.ServerErrorAlert.ErrorDescription')).getter()
                                .replace('{error}', response.data.Message);
        }

        if (errorDescription != null && errorDescription != '') {
            description += description == '' ? errorDescription : ' ' + errorDescription;
        }

        return description;
    }

    private getDescriptionFromHttpResponse(response: HttpResponseBase) {
        let description = '';

        // service failed statement
        let serviceDescription: string;
        if (response.url != null && response.url != '') {
            serviceDescription = (new ModalTranslation('Agito.Hilti.Purchaser.ServerErrorAlert.ServiceDescription')).getter()
                                .replace('{service}', Alert.getAppConfig().getServiceName(response.url));
        }

        if (serviceDescription != null && serviceDescription != '') {
            description += serviceDescription;
        }

        // error description statement
        let errorDescription: string;
        if (response.status === -1) { // request was not send at all - probably there is no internet connection
            errorDescription = (new ModalTranslation('Agito.Hilti.Purchaser.ServerErrorAlert.NoConnectionDescription')).getter();
        }
        else if (response.status === 503 || response.status === 408) { // temporarily unavailable or request timed out - probably server is overloaded
            errorDescription = (new ModalTranslation('Agito.Hilti.Purchaser.ServerErrorAlert.ServerOverloadDescription')).getter();
        }
        else if (response instanceof Error
            && response.message != null
            && typeof response.message == 'string'
            && response.message != ''
        ) {
            // message was returned, let display it
            errorDescription = (new ModalTranslation('Agito.Hilti.Purchaser.ServerErrorAlert.ErrorDescription')).getter()
                                .replace('{error}', response.message);
        }

        if (errorDescription != null && errorDescription != '') {
            description += description == '' ? errorDescription : ' ' + errorDescription;
        }

        return description;
    }
}

class AlertController extends ModalController<Alert> {
    public static readonly $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(Alert, $scope, $element, $attrs, $uibModal);
    }
}

export class AlertDirective extends ModalDirective {
    constructor() {
        super(AlertController, Alert);
    }
}
