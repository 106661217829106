import angular from 'angular';

import { triggerChangeEvent } from '../../src/app/helpers/htmlHelper';

const keyEnter = 13;
const keySpace = 32;

export class CheckboxDirective implements ng.IDirective {
    public restrict = 'A';

    public static Factory() {
        return () => new CheckboxDirective();
    }

    public link: ng.IDirectiveLinkFn = ($scope, $element, $attrs) => {
        const element = $element[0];
        const isElementButton = element.classList.contains('checkbox');

        const checkboxButton = isElementButton ? element : element.querySelector('.checkbox-button');
        const checkboxLabel = isElementButton ? null : element.querySelector('.checkbox-label');
        const buttonInput = checkboxButton.querySelector('input');

        const trueValue = $attrs.ngTrueValue !== undefined ? $attrs.ngTrueValue : true;
        const falseValue = $attrs.ngFalseValue !== undefined ? $attrs.ngFalseValue : false;

        const checkboxButtonClick = () => {
            if (!buttonInput.disabled) {
                $scope.$apply(() => {
                    this.toggleInput(buttonInput, trueValue, falseValue);
                });
            }
        };

        const checkboxButtonKeyPress = (event: JQueryKeyEventObject) => {
            const eventCode = event.which || event.keyCode || event.charCode;

            if (!buttonInput.disabled && (eventCode == keyEnter || eventCode == keySpace)) {
                $scope.$apply(() => {
                    this.toggleInput(buttonInput, trueValue, falseValue);

                    event.preventDefault();
                });
            }
        };

        const checkboxLabelClick = () => {
            if (!buttonInput.disabled) {
                $scope.$apply(() => {
                    this.toggleInput(buttonInput, trueValue, falseValue);
                });
            }
        };

        const buttonInputClick = (event: JQueryEventObject) => {
            event.stopPropagation();
        };

        checkboxButton.addEventListener('click', checkboxButtonClick, false);
        checkboxButton.addEventListener('keypress', checkboxButtonKeyPress, false);
        checkboxLabel?.addEventListener('click', checkboxLabelClick, false);
        buttonInput.addEventListener('click', buttonInputClick, false);

        $scope.$on('$destroy', () => {
            checkboxButton.removeEventListener('click', checkboxButtonClick, false);
            checkboxButton.removeEventListener('keypress', checkboxButtonKeyPress, false);
            checkboxLabel?.removeEventListener('click', checkboxLabelClick, false);
            buttonInput.removeEventListener('click', buttonInputClick, false);
        });
    }

    private toggleInput(input: HTMLInputElement, trueValue: any, falseValue: any) {
        const ngModel = angular.element(input).controller('ngModel') as ng.INgModelController;

        ngModel.$setViewValue(input.checked ? falseValue : trueValue);
        ngModel.$commitViewValue();
        ngModel.$setTouched();

        input.click();
        triggerChangeEvent(input);
        input.focus();
    }
}

export class CheckboxDisabledDirective implements ng.IDirective {
    public restrict = 'A';

    public static Factory() {
        return () => new CheckboxDisabledDirective();
    }

    public link = ($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes) => {
        const element = $element[0];

        const isElementButton = element.classList.contains('checkbox');

        const checkboxButton = isElementButton ? element : element.querySelector('.checkbox-button');
        const buttonInput = checkboxButton.querySelector('input');

        $scope.$watch($attrs.agtCheckboxDisabled, (disabled) => {
            if (disabled) {
                element.classList.add('disabled');
                checkboxButton.setAttribute('tabindex', '-1');
                buttonInput.setAttribute('disabled', 'disabled');
            }
            else {
                element.classList.remove('disabled');
                checkboxButton.setAttribute('tabindex', '0');
                buttonInput.removeAttribute('disabled');
            }
        });
    }
}
