export class IdGenerator {
    private id: number;

    constructor() {
        this.id = 1;
    }

    /** Generate a new id. */
    public next() {
        return this.id++;
    }
}

export class IdService {
    private generators: { [name: string]: IdGenerator };

    constructor() {
        this.generators = {};
    }

    /** Create or get an id generator. */
    public get(name: string) {
        let generator = this.generators[name];

        if (generator == null) {
            generator = this.generators[name] = new IdGenerator();
        }

        return generator;
    }
}
