import { Region } from '../../src/app/entities/codeLists/region';
import { authenticationStorageKey, sharedAuthenticationKey } from '../../src/app/services/auth.service';
import { LocalizationService } from '../../src/app/services/localization.service';
import { CodeListService, ProjectCodeList } from '../../src/app/services/code-list.service';
import { DataService } from '../Services/data-service';
import { FavoritesService } from '../Services/favorites-service';
import { LicenseService } from '../Services/license-service';
import { LoadingService } from '../Services/loading-service';
import { LoggerService, LogType } from '../../src/app/services/logger.service';
import { ModalService } from '../Services/modal-service';
import { UserService } from '../Services/user-service';
import { UserSettingsService } from '../Services/user-settings-service';
import { ViewService } from '../../src/app/services/view.service';

/**
* Root controller specific scope
**/
export interface IRootControllerScope extends ng.IRootScopeService {
    localization: LocalizationService;
}

export interface IGeneralErrorMsg {
    title1: string;
    title2: string;
    content: string;
}

/**
* Root controller class.
**/
export class RootController {

    public static $inject = [
        '$scope',
        'logger',
        'localization',
        'codeList',
        'user',
        '$q',
        'favorites',
        'data',
        'view',
        'loading',
        'modal',
        'license',
        'userSettings',
        'codeList'
    ];

    public modal: ModalService;
    public license: LicenseService;
    public localization: LocalizationService;
    public data: DataService;

    private $scope: IRootControllerScope;
    private logger: LoggerService;
    private codeList: CodeListService;
    private user: UserService;
    private $q: ng.IQService;
    private favorites: FavoritesService;
    private view: ViewService;
    private loading: LoadingService;
    private userSettings: UserSettingsService;

    private errorMsgTranslations: IGeneralErrorMsg;

    /**
    * Initializes a new instance of the RootController class.
    **/
    constructor(
        $scope: IRootControllerScope,
        logger: LoggerService,
        localization: LocalizationService,
        codeList: CodeListService,
        user: UserService,
        $q: ng.IQService,
        favorites: FavoritesService,
        data: DataService,
        view: ViewService,
        loading: LoadingService,
        modal: ModalService,
        license: LicenseService,
        userSettings: UserSettingsService
    ) {
        this.$scope = $scope;
        this.logger = logger;
        this.localization = localization;
        this.codeList = codeList;
        this.user = user;
        this.$q = $q;
        this.favorites = favorites;
        this.data = data;
        this.view = view;
        this.loading = loading;
        this.modal = modal;
        this.license = license;
        this.userSettings = userSettings;

        this.errorMsgTranslations = this.setGeneralErrorTranslations();
        this.logger.log('RootController::ctor', LogType.debug);
        this.Init();
    }

    public get errMsgTitle1() {
        return this.errorMsgTranslations.title1;
    }

    public get errMsgTitle2() {
        return this.errorMsgTranslations.title2;
    }

    public get errMsgContent() {
        return this.errorMsgTranslations.content;
    }

    public openGetProfis3Url() {
        const selectedRegion = (this.codeList.codelist[ProjectCodeList.Region] as Region[]).find((item) => item.id == this.userSettings.settings.user.general.regionId.value);
        const getProfis3Url = selectedRegion.links['GetPurchaserUrl'];

        window.open(getProfis3Url.url, '_blank');
    }

    public hideLoading() {
        return this.loading.loaded && !this.loading.showLoadingScreen;
    }

    /**
    * Initialize root controller data
    **/
    private Init() {
        this.logger.log('RootController::init', LogType.debug);
        this.$scope.localization = this.localization;

        window.addEventListener('storage', (event) => {
            if (event.key === sharedAuthenticationKey) {
                // if this tab needs data
                if (event.newValue) {
                    const currentAuthentication = JSON.parse(sessionStorage.getItem(authenticationStorageKey));
                    const newAuthentication = JSON.parse(event.newValue);
                    if (currentAuthentication['userId'] == newAuthentication['userId']) {
                        sessionStorage.setItem(authenticationStorageKey, event.newValue);
                        this.user.authenticatedFromStorage();
                    }
                }
            }
        });
    }

    private setGeneralErrorTranslations() {
        if (this.localization.translationsLoaded) {
            return {
                title1: this.localization.getLocalizedString('Agito.Hilti.Purchaser.GeneralError.Title1', LocalizationService.PBrB),
                title2: this.localization.getLocalizedString('Agito.Hilti.Purchaser.GeneralError.Title2', LocalizationService.PBrB),
                content: this.localization.getLocalizedString('Agito.Hilti.Purchaser.GeneralError.Content', LocalizationService.PBrB),
            } as IGeneralErrorMsg;
        }
        else {
            return {
                title1: 'SORRY',
                title2: 'SOMETHING WENT VERY WRONG',
                content: '<p>It appears a critical error appeared on our side. We apologize and ensure that we are working on it.</p><p>We will be back as soon as possible. Therefore pls try again in a moment or contact us in case you have any questions or need help.</p><p>profis@hilti.com</p>'
            } as IGeneralErrorMsg;
        }
    }
}
