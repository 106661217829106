import { LocalizationService } from '../../../src/app/services/localization.service';
import { control } from '../controls';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective } from '../Modal/Modal';
import template from './ShortcutIconPopup.html';

export class ShortcutIconPopupModalController extends BaseModalController<ShortcutIconPopup> {
    public static $inject = [
        '$uibModalInstance',
        'localization',
        'control',
        '$scope'
    ];

    private localization: LocalizationService;
    private $scope: ng.IScope;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        localization: LocalizationService,
        control: ShortcutIconPopup,
        $scope: ng.IScope
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.localization = localization;
            this.$scope = $scope;

        })(this.$onInit);
    }

    public close() {
        this.$uibModalInstance.close();
    }
}

export interface IShortcutIconPopupConstructor {

}

@control('ShortcutIconPopup')
export class ShortcutIconPopup extends Modal<ShortcutIconPopup> {

    constructor(ctor?: IShortcutIconPopupConstructor) {
        super({
            template,
            templateUrl: 'ShortcutIconPopup.html',
            controller: ShortcutIconPopupModalController,
            windowClass: 'default-modal shortcut-icon-popup-modal',
            ...ctor
        });
    }
}

class ShortcutIconPopupController extends ModalController<ShortcutIconPopup> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(ShortcutIconPopup, $scope, $element, $attrs, $uibModal);
    }
}

export class ShortcutIconPopupDirective extends ModalDirective {
    constructor() {
        super(ShortcutIconPopupController, ShortcutIconPopup);
    }
}
