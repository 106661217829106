import {
    WorldAreaEntity
} from '../../generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Codelists';
import { CodeList, ICodeListConstructor } from './codeList';

export interface IWorldAreaConstructor extends ICodeListConstructor {

}

export class WorldArea extends CodeList {
    constructor(codeList?: IWorldAreaConstructor) {
        super(codeList);
    }

    public static fromService(codeList: WorldAreaEntity) {
        return new WorldArea({
            id: codeList.WorldAreaId,
            nameResourceKey: codeList.DisplayName
        });
    }

    public cloneDeep() {
       const codeList = super.cloneDeep() as WorldArea;

       return codeList;
    }

}
