import { Injectable } from '@angular/core';
import { LaunchDarklyService } from './launch-darkly.service';

export type FeatureFlag = '';

@Injectable({
    providedIn: 'root'
})
export class FeatureVisibilityService {
    private featureStore: Partial<Record<FeatureFlag, boolean>> = {};

    constructor(
        private launchDarklyService: LaunchDarklyService
    ) { }

    public isFeatureEnabled(feature: FeatureFlag): boolean {
        return (this.featureStore[feature] ?? false) || this.launchDarklyService.isFeatureEnabled(feature);
    }
}
