import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    public getServiceName(url: string) {
        if (url.startsWith(environment.purchaserApplicationWebServiceUrl)) {
            return 'purchaser-application-service';
        }

        if (url.startsWith(environment.translationsWebServiceUrl)) {
            return 'translations-service';
        }

        if (url.startsWith(environment.trackingWebServiceUrl)) {
            return 'tracking-service';
        }

        if (url.startsWith(environment.documentWebServiceUrl)) {
            return 'document-service';
        }

        if (url.startsWith(environment.userSettingsWebServiceUrl)) {
            return 'user-settings-service';
        }

        if (url.startsWith(environment.externalAuthenticationUrl)) {
            return 'authentication-service';
        }

        if (url.startsWith(environment.baseplateCalculationWebServiceUrl)) {
            return 'baseplate-calculation-service';
        }

        if (url.startsWith(environment.supportServiceUrl)) {
            return 'support-service';
        }

        if (url.startsWith(environment.productInformationServiceUrl)) {
            return 'product-information-service';
        }

        return 'unknown service';
    }
}
