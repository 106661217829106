<div class="version-details-view">
    <div class="content">
        <div class="version-suite">
            <p class="title-strike">
                Suite Version
            </p>

            <div class="version-details-content">Version: {{ suiteVersion }} (Build: {{ suiteBuildVersion }})</div>

            <div class="version-details-content">Cluster: {{ cluster }}</div>
        </div>
    </div>
</div>
