import angular from 'angular';
import { ModalValue } from '../../../src/app/entities/modal-helper';

import { LocalizationService } from '../../../src/app/services/localization.service';

export abstract class BaseModalController<TControl> implements ng.IController {
    public static $inject = [
        '$uibModalInstance',
        'control'
    ];

    public $onInit: () => void;

    protected $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance;
    protected control: TControl;

    constructor($uibModalInstance: ng.ui.bootstrap.IModalServiceInstance, control: TControl) {
        this.$uibModalInstance = $uibModalInstance;
        this.control = control;
    }
}

export class ModalTranslation extends ModalValue<string> {
    private static _localization: LocalizationService;

    constructor(key: string, defaultString?: string) {
        super({
            getter: () => ModalTranslation.getLocalization().getLocalizedString(key) || defaultString
        });
    }

    private static getLocalization() {
        if (ModalTranslation._localization == null) {
            const $injector: ng.auto.IInjectorService = angular.element(document.body).injector();

            ModalTranslation._localization = $injector.get<LocalizationService>('localization');
        }

        return ModalTranslation._localization;
    }
}
