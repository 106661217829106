import { LDClient, initialize } from 'launchdarkly-js-client-sdk';
import { environment } from '../../environments/environment';
import { FeatureFlag } from './feature-visibility.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LaunchDarklyService {
    private client: LDClient;
    private initialized = false;
    private initializing = false;

    private cache: Record<string, unknown> = {};


    constructor() {
        this.beforeunload = this.beforeunload.bind(this);

        window.addEventListener('beforeunload', this.beforeunload, false);
    }


    public get isInitialized() {
        return this.initialized;
    }

    public async initialize(userId: string, userName: string): Promise<void> {
        if (this.initialized) {
            throw new Error('already initialized');
        }

        if (this.initializing) {
            throw new Error('already initializing');
        }

        if (userId == null) {
            throw new Error('userId not provided');
        }

        if (userName == null) {
            throw new Error('userName not provided');
        }

        this.initializing = true;
        try {
            if (environment.launchDarklyClientSideId) {
                this.client = initialize(environment.launchDarklyClientSideId, {
                    kind: 'user',
                    key: userId,
                    name: userName
                });

                await this.client.waitForInitialization();
            }
        }
        catch {
            // if launch darkly fails to initialize use default values
            // error should already be logged by launch darkly
            this.client = null;
        }
        finally {
            this.initializing = false;
        }

        this.initialized = true;
    }

    public isFeatureEnabled(feature: FeatureFlag): boolean {
        return this.variation(`P_QC_${feature}`, false);
    }

    private variation<T>(key: string, defaultValue: T): T {
        if (!this.initialized) {
            throw new Error('FeatureFlagsService not initialized');
        }

        if (this.client == null) {
            return defaultValue;
        }

        if (!this.cache.hasOwnProperty(key)) {
            this.cache[key] = this.client.variation(key, defaultValue);
        }

        return this.cache[key] as T;
    }

    private beforeunload() {
        window.removeEventListener('beforeunload', this.beforeunload, false);

        if (this.client != null) {
            this.client.close();
            this.client = null;
        }
    }
}
