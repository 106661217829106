import { Injectable } from '@angular/core';

import { ModalService } from '../../../App/Services/modal-service';
import { IAlertParameters } from '../entities/alert';
import { IApplicationError } from '../entities/application-error';
import { LocalizationService } from './localization.service';

export const enum ModalDialogType {
    project,
    subproject
}

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    constructor(
        private localization: LocalizationService,
        private modal: ModalService
    ) { }


    public showProjectArchivedModal(applicationError?: IApplicationError) {
        const param: IAlertParameters = {
            applicationError,
            title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.ProjectArchived.Title'),
            message: this.localization.getLocalizedString('Agito.Hilti.Purchaser.ProjectArchived.Message')
        };
        this.modal.alert.open(param);
    }

    public showProjectContentChangedModal(applicationError?: IApplicationError): void {
        const param: IAlertParameters = {
            applicationError,
            title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.DocumentService.Alerts.ProjectContentChanged.Title'),
            message: this.localization.getLocalizedString('Agito.Hilti.Purchaser.DocumentService.Alerts.ProjectContentChanged.Message')
        };
        this.modal.alert.open(param);
    }

    public showExistingDesignNameModal(applicationError?: IApplicationError) {
        const param: IAlertParameters = {
            applicationError,
            title: this.localization.getLocalizedString('Agito.Hilti.Purchaser.Naming.DesignNameExists.Title'),
            message: this.localization.getLocalizedString('Agito.Hilti.Purchaser.Naming.DesignNameExists.Message')
        };
        this.modal.alert.open(param);
    }

    public showExistingProjectNameModal(type: ModalDialogType, applicationError?: IApplicationError) {
        let title = null;
        let message = null;

        switch (type) {
            case ModalDialogType.project:
                title = this.localization.getLocalizedString('Agito.Hilti.Purchaser.Naming.ProjectNameExists.Title');
                message = this.localization.getLocalizedString('Agito.Hilti.Purchaser.Naming.ProjectNameExists.Message');
                break;

            case ModalDialogType.subproject:
                title = this.localization.getLocalizedString('Agito.Hilti.Purchaser.Naming.SubprojectNameExists.Title');
                message = this.localization.getLocalizedString('Agito.Hilti.Purchaser.Naming.SubprojectNameExists.Message');
                break;
        }

        const param: IAlertParameters = {
            applicationError,
            title,
            message
        };
        this.modal.alert.open(param);
    }
}
