import 'reflect-metadata';

import angular from 'angular';
import camelCase from 'lodash-es/camelCase';
import difference from 'lodash-es/difference';
import escape from 'lodash-es/escape';
import kebabCase from 'lodash-es/kebabCase';
import sortBy from 'lodash-es/sortBy';

import { GuidService } from '../../src/app/guid.service';
import { LocalizationService } from '../../src/app/services/localization.service';
import { EventObject } from '../Entities/EventObject';
import { IDisposable } from '../../src/app/entities/disposable';
import { IdGenerator, IdService } from '../Services/id-service';

/** Saves all the compiled template link functions. */
class CompileCache {
    private _templates: { [template: string]: ng.ITemplateLinkingFunction } = {};

    /** Get the compiled template link function. */
    public get(template: string) {
        if (this._templates[template] == null) {
            const $injector: ng.auto.IInjectorService = angular.element(document.body).injector();
            const $compile = $injector.get<ng.ICompileService>('$compile');

            this._templates[template] = $compile(template);
        }

        return this._templates[template];
    }
}

/** All the compiled template link functions. */
const _compileCache = new CompileCache();

/** All the control values that use setters and getters. */
const _controlValues: WeakMap<IControl, { [property: string]: Ref<any> }> = new WeakMap();

/** Event handler for the event functions like on, off ... */
export type EventHandler<TControl extends IControl = IControl> = (eventArgs?: Object, controlController?: ControlController<TControl>, control?: TControl) => void;

/** Angular service injector. */
let _injector: ng.auto.IInjectorService;

/** Control id generator. */
let _controlIdGenerator: IdGenerator;

/**
 * Get the angular injector.
 */
function getInjector() {
    if (_injector == null) {
        _injector = angular.element(document.body).injector();
    }

    return _injector;
}

/**
 * Gets the control id generator.
 */
function getControlIdGenerator() {
    if (_controlIdGenerator == null) {
        _controlIdGenerator = getInjector().get<IdService>('id').get('control');
    }

    return _controlIdGenerator;
}

/**
 * Generates an new control id in the form of 'agt-<id_number>'.
 */
function newControlId() {
    return `agt-${getControlIdGenerator().next()}`;
}

/** Property metadata interface. */
export interface IPropertyMetadata {
    /** Name for the property that will be used to generate the template. Might not be the same as the property name on the object. */
    name: string;

    /** Expression for the property that will be used to generate the template.  */
    expression: string;

    /** Reference name for the property that will be used to generate the template. */
    refName: string;

    /** Reference expression for the property that will be used to generate the template. */
    refExpression: string;
}

/** Property metadata constructor. */
export interface IPropertyMetadataConstructor {
    /** Name for the property that will be used to generate the template. Might not be the same as the property name on the object. */
    name?: string;

    /** Expression for the property that will be used to generate the template.  */
    expression?: string;

    /** Reference name for the property that will be used to generate the template. */
    refName?: string;

    /** Reference expression for the property that will be used to generate the template. */
    refExpression?: string;
}

/** Property metadata. */
export class PropertyMetadata implements IPropertyMetadata {
    /** Name for the property that will be used to generate the template. Might not be the same as the property name on the object. */
    public name: string;

    /** Expression for the property that will be used to generate the template.  */
    public expression: string;

    /** Reference name for the property that will be used to generate the template. */
    public refName: string;

    /** Reference expression for the property that will be used to generate the template. */
    public refExpression: string;

    constructor(propertyMetadata?: IPropertyMetadataConstructor) {
        if (propertyMetadata != null) {
            this.name = propertyMetadata.name;
            this.expression = propertyMetadata.expression;
            this.refName = propertyMetadata.refName;
            this.refExpression = propertyMetadata.refExpression;
        }
    }
}

/** Method metadata interface. */
export interface IMethodMetadata {
    /** The original function defined on the class. */
    fn: Function;
}

/** Method metadata constructor. */
export interface IMethodMetadataConstructor {
    /** The original function defined on the class. */
    fn?: Function;
}

/** Method metadata. */
export class MethodMetadata implements IMethodMetadata {
    /** The original function defined on the class. */
    public fn: Function;

    constructor(methodMetadata?: IMethodMetadataConstructor) {
        if (methodMetadata != null) {
            this.fn = methodMetadata.fn;
        }
    }
}

/** Control interface. */
export interface IControl extends IDisposable {
    /** The link function that is used to create a directive. */
    link: ng.ITemplateLinkingFunction;

    /** Outer scope of the control. */
    $scope: ng.IScope;

    /** Event proxy that is used to communicate with the directive. If event proxy is not provided one is created. */
    eventProxy: EventProxy;

    /** Gets the instance bind definition for the specified property. If none is found null is retuned. */
    getInstanceBind(property: string): Bind;

    /** Calls a handler when the specified event is triggered. */
    on(events: string, handler: EventHandler): void;

    /** Removes handlers for the specified event. */
    off(events: string, handler?: EventHandler): void;
}

/** Control constructor. */
export interface IControlConstructor {
    /** Events that can be provider when creating a control. After a control is created events can be added with the on function. */
    events?: { [event: string]: EventHandler };
}

/** Control. */
export abstract class Control implements IControl {

    /** The created event. */
    public static created = 'created';

    /** The destroyed event. */
    public static destroyed = 'destroyed';

    /** The disposed event. */
    public static disposed = 'disposed';

    // FILIP TEMP: fix, one control can have multiple directives
    /** Outer scope of the control. */
    public $scope: ng.IScope;

    /** Event proxy that is used to communicate with the directive. If event proxy is not provided one is created. */
    @property()
    public eventProxy: EventProxy;
    /** The link function that is used to create a directive. */
    private _link: ng.ITemplateLinkingFunction;

    /** All instance bind definitions for this control. */
    private _instanceBinds: { [name: string]: Bind } = {};

    /** The control constructor. */
    private _controlConstructor: IControlConstructor;

    private _$injector: ng.auto.IInjectorService;

    private _$timeout: ng.ITimeoutService;

    private _$rootScope: ng.IRootScopeService;

    private _guid: GuidService;

    constructor(control?: IControlConstructor) {
        this._controlConstructor = control;

        this.initMethodProxy();
        this.initEventProxy();
        this.setValues(control);
    }

    /** The link function that is used to create a directive. */
    public get link() {
        if (this._link == null) {
            const template = this.createTemplate(this._controlConstructor);

            this._link = _compileCache.get(template);
        }

        return this._link;
    }
    protected get $injector() {
        if (this._$injector == null) {
            this._$injector = angular.element(document.body).injector();
        }

        return this._$injector;
    }
    protected get $timeout() {
        if (this._$timeout == null) {
            this._$timeout = this.$injector.get<ng.ITimeoutService>('$timeout');
        }

        return this._$timeout;
    }
    protected get $rootScope() {
        if (this._$rootScope == null) {
            this._$rootScope = this.$injector.get<ng.IRootScopeService>('$rootScope');
        }

        return this._$rootScope;
    }
    protected get guid() {
        if (this._guid == null) {
            this._guid = this.$injector.get<GuidService>('guid');
        }

        return this._guid;
    }

    /** Gets the instance bind definition for the specified property. If none is found null is retuned. */
    public getInstanceBind(property: string) {
        return this._instanceBinds[property];
    }

    /** Calls a handler when the specified event is triggered. */
    public on<TControl extends IControl = IControl>(events: string, handler: EventHandler<TControl>) {
        if (this.eventProxy != null) {
            this.eventProxy.on(events, handler);
        }
    }

    public one<TControl extends IControl = IControl>(events: string, handler: EventHandler<TControl>) {
        if (this.eventProxy != null) {
            this.eventProxy.one(events, handler);
        }
    }

    /** Removes handlers for the specified event. */
    public off<TControl extends IControl = IControl>(events: string, handler?: EventHandler<TControl>) {
        if (this.eventProxy != null) {
            this.eventProxy.off(events, handler);
        }
    }

    public trigger(event: string, eventArgs?: Object) {
        if (this.eventProxy != null) {
            this.eventProxy.trigger(event, eventArgs, null, this);
        }
    }

    public onCreated<TControl extends IControl>(fn: (controlController?: ControlController<TControl>, control?: TControl) => void) {
        this.on<TControl>(Control.created, (args, controlController, control) => { fn(controlController, control); });
    }

    public onDestroyed<TControl extends IControl>(fn: (controlController?: ControlController<TControl>, control?: TControl) => void) {
        this.on(Control.destroyed, (args: Object, controlController: ControlController<TControl>, control: TControl) => { fn(controlController, control); });
    }

    public onDisposed<TControl extends IControl>(fn: (controlController?: ControlController<TControl>, control?: TControl) => void) {
        this.one(Control.disposed, (args: Object, controlController: ControlController<TControl>, control: TControl) => { fn(controlController, control); });
    }

    public dispose() {
        this.triggerAsync(Control.disposed);
    }

    protected triggerAsync(event: string, eventArgs?: Object) {
        if (this.eventProxy != null) {
            this.eventProxy.triggerAsync(event, eventArgs, null, this);
        }
    }

    /** Sets the property value if not set and if it's posible. If scope is not yet set Bind property can't be set. */
    protected setDefaultValue(property: string, value: Object) {
        if (this.getInstanceBind(property) == null && (this as any)[property] == null) {
            this[property] = value;
        }
    }

    /** Sets the property value if not set and if it's posible. If scope is not yet set Bind property can't be set. For value function fn is evaluated if needed. */
    protected setDefaultValueByFunction(property: string, fn: () => any) {
        if (this.getInstanceBind(property) == null && this[property] == null) {
            this[property] = fn();
        }
    }

    /** Set values for the provided properties. */
    private setValues(values: { [name: string]: any }) {
        if (values != null) {
            const propertiesMetadata = getPropertiesMetadata(this.constructor.prototype);
            const methodsMetadata = getMethodsMetadata(this.constructor.prototype);

            // properties
            for (const valueName in values) {
                const value = values[valueName];
                const isProperty = propertiesMetadata.hasOwnProperty(valueName);
                const isMethod = methodsMetadata.hasOwnProperty(valueName);
                const propertyName = isProperty ? valueName : isMethod ? `${valueName}MethodProxy` : null;

                if (propertyName != null) {
                    if (!(value instanceof Bind)) {
                        const control = this as any;

                        control[propertyName] = value;
                    } else {
                        const propertyBind: Bind = value;

                        this._instanceBinds[propertyName] = propertyBind;
                    }
                }
            }

            // events
            const events = values['events'];
            if (events != null) {
                for (const eventName in events) {
                    const eventHandler = events[eventName];

                    this.eventProxy.on(eventName, eventHandler);
                }
            }
        }
    }

    /** Creates a template from the provided values. */
    private createTemplate(propertyValues: { [name: string]: any }): string {
        const target = this.constructor.prototype;
        const name = Reflect.getMetadata('name', target);
        const directiveName = `agt-${kebabCase(name)}`;

        const properties: IProperty[] = [];
        const propertiesMetadata = getPropertiesMetadata(target);

        for (const key in propertiesMetadata) {
            const propertyName = key as string;
            const propertyMetadata = propertiesMetadata[propertyName];
            const propertyValue = propertyValues != null ? propertyValues[propertyName] : null;

            if (propertyValue != null && propertyValue instanceof Bind) {
                const bindProperty: Bind = propertyValue;

                if (bindProperty.ref) {
                    properties.push(new Property({ name: propertyMetadata.refName, expression: bindProperty.expression }));
                }
                else {
                    properties.push(new Property({ name: propertyMetadata.name, expression: bindProperty.expression }));
                }
            }
        }

        // control
        properties.push(new Property({ name: 'agt-control', expression: 'control' }));

        return createTemplate(directiveName, properties);
    }

    /** Creates method proxies if needed. */
    private initMethodProxy() {
        const metadata = getMethodsMetadata(this.constructor.prototype);

        for (const key in metadata) {
            const methodName: string = key;
            const methodProxyName = `${methodName}MethodProxy`;

            if (this[methodProxyName] == null) {
                this[methodProxyName] = new MethodProxy();
            }
        }
    }

    /** Creates an event proxy if needed. */
    private initEventProxy() {
        if (this.eventProxy == null) {
            this.eventProxy = new EventProxy();
        }
    }
}

/** Bind class used to connect a property with expression. */
export class Bind {
    /** The expression. */
    private _expression: string;

    /** Is the expression for the reference value. */
    private _ref: boolean;

    constructor(expression: string, ref?: boolean) {
        this._expression = expression;

        if (ref === true) {
            this._ref = true;
        }
        else {
            this._ref = false;
        }
    }

    /** The expression. */
    public get expression() {
        return this._expression;
    }

    /** Is the expression for the reference value. */
    public get ref() {
        return this._ref;
    }
}

/** Shortcut for bind. */
export class Translation extends Bind {
    private _key: string;

    constructor(key: string) {
        super(`localization.getLocalizedString('${key}')`);

        this._key = key;
    }

    public get key() {
        return this._key;
    }
}

/** Ignore class to specify that the property should not be bound. */
export class Ignore {

}

/** Type to be used when defining constructor interfaces for the control. */
export declare type ControlProperty = Bind | Ignore;

/**
 * Control decorator.
 * @param name The name of the control.
 */
export function control(name: string): ClassDecorator {
    return <TFunction extends Function>(target: TFunction) => {
        Reflect.defineMetadata('name', name, target.prototype);

        return target;
    };
}

/**
 * Creates a property as a reference.
 * @param target The target where the property will be created.
 * @param propertyKey The property name.
 */
function createRefProperty(target: Object, propertyKey: string) {
    const getter = function(this: IControl) {
        const control = this;
        const instanceBind = control.getInstanceBind(propertyKey);

        if (instanceBind != null) {
            if (control.$scope == null) {
                return undefined;
            }

            const $parse: ng.IParseService = angular.element(document.body).injector().get('$parse');
            const model = $parse(instanceBind.ref ? `${instanceBind.expression}.value` : instanceBind.expression);

            return model(control.$scope);
        } else {
            const ref = getRefValue(_controlValues, control, propertyKey);

            return ref.value;
        }
    };

    const setter = function(this: IControl, setValue: any) {
        const control = this;
        const instanceBind = control.getInstanceBind(propertyKey);

        if (instanceBind != null) {
            if (control.$scope == null) {
                throw new Error('Scope not set. Can\'t use bind property.');
            }

            const $parse: ng.IParseService = angular.element(document.body).injector().get('$parse');
            const model = $parse(instanceBind.ref ? `${instanceBind.expression}.value` : instanceBind.expression);

            if (typeof model.assign == 'function') {
                model.assign(control.$scope, setValue);
            }
        } else {
            const ref = getRefValue(_controlValues, control, propertyKey);

            ref.value = setValue;
        }
    };

    Object.defineProperty(target, propertyKey, {
        get: getter,
        set: setter,
        enumerable: true,
        configurable: true
    });

    // create ref property
    Object.defineProperty(target, `${propertyKey}Ref`, {
        get(this: IControl) {
            const control = this;
            const instanceBind = control.getInstanceBind(propertyKey);

            if (instanceBind != null) {
                if (instanceBind.ref) {
                    const $parse: ng.IParseService = angular.element(document.body).injector().get('$parse');
                    const model = $parse(instanceBind.expression);

                    return model(control.$scope);
                }

                throw new Error('Can\'t use Ref with instance bind ref == false.');
            }

            return getRefValue(_controlValues, control, propertyKey);
        },
        enumerable: true,
        configurable: true
    });
}

/**
 * Control method decorator. It redefines the method to call the directive.
 */
export function method() {
    return (target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<Function>) => {
        Reflect.defineMetadata(`method:${propertyKey}`, new MethodMetadata({ fn: descriptor.value as any }), target);

        // method proxy property
        const propertyName = `${propertyKey}MethodProxy`;
        createRefProperty(target, propertyName);

        // call method proxy method
        descriptor.value = function(this: IControl, ...args: any[]) {
            const control = this;
            const methodProxy: MethodProxy<(...args: any[]) => any, any> = control[propertyName];

            if (!(methodProxy instanceof MethodProxy)) {
                throw new Error('Not a MethodProxy.');
            }

            return methodProxy.fn.apply(methodProxy, args);
        };

        // property metadata
        Reflect.defineMetadata(`property:${propertyName}`, new PropertyMetadata({
            name: `agt-${kebabCase(propertyKey)}`,
            expression: `control.${propertyName}`,
            refName: `agt-${kebabCase(propertyKey)}-ref`,
            refExpression: `control.${propertyName}Ref`,
        }), target);
    };
}

/**
 * Control property decorator. It redefines the property to use Ref value if possible.
 * @param name Optional name for the property in directive.
 * @param expression Optional expression for the property in directive.
 */
export function property(name?: string, expression?: string): PropertyDecorator {
    return (target: Object, propertyKey: string) => {
        createRefProperty(target, propertyKey);

        // metadata
        Reflect.defineMetadata(`property:${propertyKey}`, new PropertyMetadata({
            name: name || `agt-${kebabCase(propertyKey)}`,
            expression: expression || `control.${propertyKey}`,
            refName: name != null && name != '' ? `${name}-ref` : `agt-${kebabCase(propertyKey)}-ref`,
            refExpression: expression || `control.${propertyKey}Ref`,
        }), target);
    };
}

/**
 * Gets the ref value. If the ref value is null it's created.
 * @param controlValues All the control values that are stored in properties.
 * @param control The control.
 * @param propertyKey The property name.
 */
function getRefValue(controlValues: WeakMap<IControl, { [property: string]: Ref<any> }>, control: IControl, propertyKey: string) {
    let currentControlValues = controlValues.get(control);

    if (currentControlValues == null) {
        currentControlValues = {};
        controlValues.set(control, currentControlValues);
    }

    if (currentControlValues[propertyKey] == null) {
        currentControlValues[propertyKey] = new Ref<any>();
    }

    return currentControlValues[propertyKey];
}

/** Property interface. */
export interface IProperty {
    /** Name for the property that will be used to generate the template. */
    name: string;

    /** Expression for the property that will be used to generate the template.  */
    expression: string;
}

/** Property constructor. */
export interface IPropertyConstructor {
    /** Name for the property that will be used to generate the template. */
    name?: string;

    /** Expression for the property that will be used to generate the template. */
    expression?: string;
}

/** Property. */
export class Property implements IProperty {
    /** Name for the property that will be used to generate the template. */
    public name: string;

    /** Expression for the property that will be used to generate the template. */
    public expression: string;

    constructor(property?: IPropertyConstructor) {
        if (property != null) {
            this.name = property.name;
            this.expression = property.expression;
        }
    }
}

/**
 * Creates a directive template from name and properties.
 * @param name The directive name.
 * @param properties The directive properties.
 */
export function createTemplate(name: string, properties?: IProperty[]) {
    properties = properties || [];

    const directiveName = kebabCase(name);
    const attributes: string[] = [];

    for (const property of properties) {
        const name = escape(property.name);
        const expression = escape(property.expression);

        attributes.push(`${name}="${expression}"`);
    }

    return `<${directiveName} ${attributes.join(' ')} />`;
}

/**
 * Gets the metadata for all the properties on the target.
 * @param target The target.
 */
function getPropertiesMetadata(target: any) {
    const properties: { [property: string]: IPropertyMetadata } = {};

    while (target != null) {
        const metadataKeys: string[] = Reflect.getMetadataKeys(target);

        for (const key of metadataKeys) {
            if (key.startsWith('property:')) {
                const propertyName = key.substring('property:'.length);
                const propertyMetadata: IPropertyMetadata = Reflect.getMetadata(key, target);

                if (properties[propertyName] == null) {
                    properties[propertyName] = propertyMetadata;
                }
            }
        }

        target = Object.getPrototypeOf(target.constructor.prototype);
    }

    return properties;
}

/**
 * Gets the metadata for all the methods on the target.
 * @param target The target.
 */
function getMethodsMetadata(target: any) {
    const methods: { [property: string]: IMethodMetadata } = {};

    while (target != null) {
        const metadataKeys: string[] = Reflect.getMetadataKeys(target);

        for (const key of metadataKeys) {
            if (key.startsWith('method:')) {
                const methodName = key.substring('method:'.length);
                const methodMetadata: IMethodMetadata = Reflect.getMetadata(key, target);

                if (methods[methodName] == null) {
                    methods[methodName] = methodMetadata;
                }
            }
        }

        target = Object.getPrototypeOf(target.constructor.prototype);
    }

    return methods;
}

/**
 * Destroys all the scopes and removes the controls from DOM.
 * @param controls The controls to destroy.
 */
function destroyControls(controls: HTMLElement[]) {
    for (const element of controls) {
        const $element = angular.element(element);
        const controlScope = $element[0].hasAttribute('ng-if') || $element[0].hasAttribute('data-ng-if') ? $element.scope().$parent : $element.scope(); // if ng-if is used a new parent scope is created so we destroy that one

        if (controlScope != null) {
            controlScope.$destroy();
        }

        // remove ng-if comments
        const prev = element.previousSibling;
        const next = element.nextSibling;

        if (prev != null && prev.nodeType == Node.COMMENT_NODE && prev.nodeValue.startsWith(' ngIf: ')) {
            prev.parentNode.removeChild(prev);
        }

        if (next != null && next.nodeType == Node.COMMENT_NODE && next.nodeValue.startsWith(' end ngIf: ')) {
            next.parentNode.removeChild(next);
        }

        // remove element
        $element.remove();
    }
}

/** Reference class. */
export class Ref<TType> {
    /** The value. */
    public value: TType;

    constructor(value?: TType) {
        this.value = value;
    }
}

/** Web control visibility. */
export enum WebControlVisibility {
    visible,
    hidden
}

/** Web control interface. */
export interface IWebControl extends IControl {
    /** The id of the control. */
    id: string;

    /** The visibility. */
    visibility: WebControlVisibility;

    /** The disabled. */
    disabled: boolean;

    /** Refreshes the binding of the control. */
    refreshBinding(): void;

    focus(): void;
}

/** Web control constructor. */
export interface IWebControlConstructor extends IControlConstructor {
    /** The id of the control. */
    id?: string | ControlProperty;

    /** The visibility. */
    visibility?: WebControlVisibility | ControlProperty;

    /** The disabled. */
    disabled?: boolean | ControlProperty;

    validators?: { [name: string]: IValidator<WebControlController<any>, any> } | ControlProperty;
}

/** Web control. */
export abstract class WebControl extends Control implements IWebControl {

    /** The id of the control. */
    @property()
    public id: string;

    /** The visibility. */
    @property()
    public visibility: WebControlVisibility;

    /** The disabled. */
    @property()
    public disabled: boolean;

    @property()
    public validators: { [name: string]: IValidator<WebControlController<any>, any> };
    constructor(webControl?: IWebControlConstructor) {
        super(webControl);

        this.setDefaultValueByFunction('id', newControlId);
        this.setDefaultValue('visibility', WebControlVisibility.visible);
        this.setDefaultValue('disabled', false);
    }

    /** Refreshes the binding of the control. */
    @method()
    public refreshBinding() { }

    @method()
    public focus() { }
}

/** Control controller. */
export abstract class ControlController<TControl extends IControl> implements ng.IController {
    public $onInit: () => void;

    /** Event proxy. */
    public eventProxy: EventProxy;

    /** Gets the control. */
    protected control: TControl;

    /** Angular scope. */
    protected $scope: ng.IScope;

    /** Controller element. */
    protected $element: ng.IAugmentedJQuery;

    protected element: HTMLElement;

    /** Angular attributes. */
    protected $attrs: ng.IAttributes;

    /** Angular injector. */
    protected $injector: ng.auto.IInjectorService;

    /** Angular parse. */
    protected $parse: ng.IParseService;

    /** Angular timeout. */
    protected $timeout: ng.ITimeoutService;

    /** Angular model. */
    protected ngModel: ng.INgModelController;

    /** All the properties that are connected to the control */
    private controlProperties: { [property: string]: void } = {};

    constructor(control: new (ctor?: Object) => TControl, $scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes) {
        this.$onInit = () => {
            this.$injector = angular.element(document.body).injector();

            this.$scope = $scope;
            this.$element = $element;
            this.element = $element?.[0];
            this.$attrs = $attrs;

            this.$parse = this.$injector.get<ng.IParseService>('$parse');
            this.$timeout = this.$injector.get<ng.ITimeoutService>('$timeout');

            // trigger destroy event
            this.$scope.$on('$destroy', () => {
                this.trigger(Control.destroyed);
            });

            if (this.control != null) {
                this.control.$scope = $scope.$parent;
            }

            this.createProperties(control);

            // trigger created event (we wait one tick so the inherited control constructor is done)
            if (this.hasEventListeners(Control.created)) {
                this.$scope.$evalAsync(() => { this.trigger(Control.created); });
            }
        };
    }

    /** Triggers the specified event. */
    public trigger(event: string, eventArgs?: Object) {
        if (this.eventProxy != null) {
            this.eventProxy.trigger(event, eventArgs, this, this.control);
        }
    }

    public triggerAsync(event: string, eventArgs?: Object) {
        if (this.eventProxy != null) {
            this.eventProxy.triggerAsync(event, eventArgs, this, this.control);
        }
    }

    /** Calls a handler when the specified event is triggered. */
    public on(events: string, handler: EventHandler<TControl>) {
        if (this.eventProxy != null) {
            this.eventProxy.on(events, handler);
        }
    }

    public one(events: string, handler: EventHandler<TControl>) {
        if (this.eventProxy != null) {
            this.eventProxy.one(events, handler);
        }
    }

    /** Removes handlers for the specified event. */
    public off(events: string, handler?: EventHandler<TControl>) {
        if (this.eventProxy != null) {
            this.eventProxy.off(events, handler);
        }
    }

    public onDisposed(fn: (controlController?: ControlController<TControl>, control?: TControl) => void) {
        this.one(Control.disposed, (args: Object, controlController: ControlController<TControl>, control: TControl) => { fn(controlController, control); });
    }

    protected ngModelSet(ngModel: ng.INgModelController) { }

    protected getValue<TValue>(property: string): TValue {
        if (this.controlProperties[property] !== undefined) {
            return this.control[property];
        }
        else {
            return this[`${property}Ref`].value;
        }
    }

    protected setValue<TValue>(property: string, value: TValue) {
        if (this.controlProperties[property] !== undefined) {
            this.control[property] = value;
        }
        else {
            this[`${property}Ref`].value = value;
        }
    }

    private hasEventListeners(event: string) {
        return this.eventProxy != null && this.eventProxy.hasEventListeners(event);
    }

    private _ngModelSet(ngModel: ng.INgModelController) {
        this.ngModel = ngModel;

        this.ngModelSet(ngModel);
    }

    /** Creates all the needed properties. */
    private createProperties(control: new (ctor?: Object) => TControl) {
        let metadataKeys: string[] = Reflect.getMetadataKeys(control.prototype);
        const hasControl = this.$attrs['control'] != null || this.control != null;

        // sort metadata keys (properties first)
        const properties = metadataKeys.filter((key) => key.startsWith('property:'));
        const methods = metadataKeys.filter((key) => key.startsWith('method:'));
        const other = metadataKeys.filter((key) => !key.startsWith('property:') && !key.startsWith('method:'));

        metadataKeys = properties.concat(methods).concat(other);

        for (const key of metadataKeys) {
            (() => {
                const propertyName = key.startsWith('property:') ? key.substring('property:'.length) : null;
                const methodName = key.startsWith('method:') ? key.substring('method:'.length) : null;
                const attrName = camelCase(`agt-${propertyName}`);
                const attrNameRef = camelCase(`agt-${propertyName}-ref`);

                if (propertyName != null) {
                    const propertyDescriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this), propertyName);
                    const getter: Function = propertyDescriptor != null ? propertyDescriptor.get : null;
                    const setter: Function = propertyDescriptor != null ? propertyDescriptor.set : null;

                    // bind to control property or directive property
                    if (hasControl && this.$attrs[attrName] == null && this.$attrs[attrNameRef] == null) {
                        this.controlProperties[propertyName] = null;

                        // create a getter and setter only if no getter and setter are specified
                        if (getter == null && setter == null) {
                            Object.defineProperty(this, propertyName, {
                                get: () => {
                                    return this.control[propertyName];
                                },
                                set(this: any, setValue) {
                                    if (this.control[propertyName] !== setValue) {
                                        this.control[propertyName] = setValue;
                                    }
                                },
                                enumerable: true,
                                configurable: true
                            });
                        }
                    }
                    else {
                        const refPropertyName = `${propertyName}Ref`;
                        const value = this[propertyName];

                        if (value != null && this[refPropertyName] != null) {
                            throw new Error('Can\'t set both value and ref.');
                        }

                        if (this[refPropertyName] == null) {
                            this[refPropertyName] = new Ref<any>();
                        }

                        // create a getter and setter only if no getter and setter are specified
                        if (getter == null && setter == null) {
                            Object.defineProperty(this, propertyName, {
                                get() {
                                    return this[refPropertyName].value;
                                },
                                set(setValue) {
                                    this[refPropertyName].value = setValue;
                                },
                                enumerable: true,
                                configurable: true
                            });
                        }

                        // set the value back if it was already set
                        if (value != null) {
                            this[refPropertyName].value = value;
                        }
                    }

                    // set event proxy if not bound
                    if (propertyName == 'eventProxy' && this[propertyName] == null) {
                        this[propertyName] = new EventProxy();
                    }
                }
                else if (methodName != null) {
                    const methodProxyName = `${hasControl ? 'control.' : ''}${methodName}MethodProxy`;
                    const expression = `ctrl.${methodProxyName}`;

                    const compiledExpression = this.$parse(expression);
                    let methodProxy = compiledExpression(this.$scope) as MethodProxy<(...args: any[]) => any, any>;

                    // set initial function
                    if (methodProxy == null) {
                        compiledExpression.assign(this.$scope, methodProxy = new MethodProxy<(...args: any[]) => any, any>());
                    }

                    this.setMethodProxyFunction(methodProxy, methodName);

                    // set method proxy
                    this.$scope.$watch<MethodProxy<(...args: any[]) => any, any>>(expression, (newMethodProxy) => {
                        this.setMethodProxyFunction(newMethodProxy, methodName);
                    });

                    // on destroy remove method proxy
                    this.$scope.$on('$destroy', () => {
                        this.removeMethodProxyFunction(compiledExpression(this.$scope) as MethodProxy<(...args: any[]) => any, any>);
                    });
                }
            })();
        }
    }

    private setMethodProxyFunction(methodProxy: MethodProxy<(...args: any[]) => any, any>, methodName: string) {
        this.removeMethodProxyFunction(methodProxy);

        if (methodProxy != null) {
            methodProxy.functions.push({
                controlController: this,
                control: this.control,
                fn: this[methodName]
            });
        }
    }

    private removeMethodProxyFunction(methodProxy: MethodProxy<(...args: any[]) => any, any>) {
        if (methodProxy != null) {
            methodProxy.functions = methodProxy.functions.filter(fn => fn.controlController !== this);
        }
    }
}

export interface IValidatorContext<TController extends WebControlController<any>> {
    control: TController;
    ngModel: ng.INgModelController;
    $scope: ng.IScope;
    name: string;
}

export interface IValidator<TController extends WebControlController<any>, TContext extends IValidatorContext<any>> {
    contexts: Map<TController, TContext>;

    add?: (context?: TContext) => void;
    remove?: (context?: TContext) => void;
    isValid: (context?: TContext) => boolean;
}

export abstract class Validator<TController extends WebControlController<any>, TContext extends IValidatorContext<any>> implements IValidator<TController, TContext> {
    public contexts: Map<TController, TContext>;

    private _$injector: ng.auto.IInjectorService;

    private _$q: ng.IQService;

    constructor() {
        this.contexts = new Map<TController, TContext>();
    }
    protected get $injector() {
        if (this._$injector == null) {
            this._$injector = angular.element(document.body).injector();
        }

        return this._$injector;
    }
    protected get $q() {
        if (this._$q == null) {
            this._$q = this.$injector.get<ng.IQService>('$q');
        }

        return this._$q;
    }

    public abstract add(context?: TContext): void;
    public abstract remove(context?: TContext): void;
    public abstract isValid(context?: TContext): boolean;

    protected refreshValidation() {
        this.contexts.forEach((context, control) => {
            context.ngModel.$validate();
        });
    }
}

/** Web control controller. */
export abstract class WebControlController<TControl extends IControl> extends ControlController<TControl> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse'
    ];

    /** Id of the control. */
    public id: string;

    /** The visibility. */
    public visibility: WebControlVisibility;

    /** The disabled. */
    public disabled: boolean;

    public validators: { [name: string]: IValidator<WebControlController<TControl>, any> };

    /** Angular compile. */
    protected $compile: ng.ICompileService;

    /** Angular timeout. */
    protected $timeout: ng.ITimeoutService;

    /** Angular Q service */
    protected $q: ng.IQService;

    constructor(control: new (ctor?: Object) => TControl, $scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $compile: ng.ICompileService, $parse: ng.IParseService) {
        super(control, $scope, $element, $attrs);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$compile = $compile;
            this.$timeout = this.$injector.get<ng.ITimeoutService>('$timeout');
            this.$q = this.$injector.get<ng.IQService>('$q');

            this.id = this.id || newControlId();
            this.visibility = this.visibility || WebControlVisibility.visible;
            this.disabled = this.disabled != null ? this.disabled : false;
            this.validators = this.validators != null ? this.validators : {};

            // constants
            this.$scope['WebControlVisibility'] = WebControlVisibility;
            this.$scope['isHidden'] = () => this.visibility == WebControlVisibility.hidden;
            this.$scope['localization'] = this.$injector.get<LocalizationService>('localization');

            // update validators on change
            const removeValidatorsWatch = this.$scope.$watchCollection<{ [name: string]: IValidator<WebControlController<TControl>, any> }>('ctrl.validators', (validators, oldValidators) => {
                if (this.ngModel == null) {
                    removeValidatorsWatch();

                    return;
                }

                this.updateValidators(validators, oldValidators);
            });
        })(this.$onInit);
    }

    /** Binds the controls to the DOM element. */
    protected bindControls(containerElement: ng.IAugmentedJQuery | Element, controlsExpression: string) {
        const container = angular.element(containerElement);

        const visibilityWatches: Function[] = [];
        const compiledExpression = this.$parse(controlsExpression);
        let hasToBeSorted = false;

        this.$scope.$watchCollection<IControl[]>(controlsExpression, (newValue) => {
            const controlElements = Array.from(container.children());

            // remove
            const removeControls = controlElements.filter((element) => {
                const control = this.getElementControl(element);

                return newValue == null || !newValue.some((newControl) => newControl === control);
            });

            destroyControls(removeControls);

            // add
            let controlsAdded = false;
            if (newValue != null) {
                for (const control of newValue) {
                    if (control != null && controlElements.filter((element) => this.getElementControl(element) === control).length == 0) {
                        control.$scope = this.$scope.$parent.$new();
                        control.$scope['isControlGeneratedScope'] = true;
                        control.$scope['control'] = control;

                        // create element
                        control.link(control.$scope, (controlElement) => {
                            container.append(controlElement);

                            controlsAdded = true;
                        });
                    }
                }
            }

            // sort
            this.sortControls(container, newValue);

            // add visibility watches
            for (const visibilityWatch of visibilityWatches) {
                visibilityWatch();
            }

            for (const control of newValue || []) {
                if (control != null && control instanceof WebControl) {
                    ((control: WebControl) => {
                        // we need to sort controls on visibility change
                        visibilityWatches.push(this.$scope.$watch<WebControlVisibility>(() => control.visibility, (visibility, oldVisibility) => {
                            if (visibility === oldVisibility) {
                                if (!hasToBeSorted) {
                                    this.$scope.$evalAsync(() => {
                                        const controls = compiledExpression(this.$scope) as IControl[];
                                        this.sortControls(container, controls);

                                        hasToBeSorted = false;
                                    });

                                    hasToBeSorted = true;
                                }
                            }
                        }));
                    })(control);
                }
            }

            // we have to also sort when control is compiled if a new one was added
            if (controlsAdded) {
                setTimeout(() => {
                    const controls = compiledExpression(this.$scope) as IControl[];

                    this.sortControls(container, controls);
                });
            }
        });
    }

    private getElementControl(element: Element) {
        const scope = angular.element(element).isolateScope();

        return scope != null ? scope['ctrl']['control'] : null;
    }

    private sortControls(container: ng.IAugmentedJQuery, controls: IControl[]) {
        if (controls == null || controls.length == 0) {
            return;
        }

        let unknownIndex = controls.length * 3;

        container.append(sortBy(container.contents(), (node: Node) => {
            if (node.nodeType == Node.COMMENT_NODE) {
                // comment node

                if (node.nodeValue.startsWith(' ngIf: ')) {
                    const index = controls.indexOf(this.getElementControl(this.nextElementSibling(node)));

                    return index != -1 ? index * 3 : unknownIndex++;
                }

                if (node.nodeValue.startsWith(' end ngIf: ')) {
                    const index = controls.indexOf(this.getElementControl(this.previousElementSibling(node)));

                    return index != -1 ? index * 3 + 2 : unknownIndex++;
                }
            }
            else if (node.nodeType == Node.ELEMENT_NODE) {
                // element node

                const index = controls.indexOf(this.getElementControl(node as Element));

                return index != -1 ? index * 3 + 1 : unknownIndex++;
            }

            return Number.MAX_SAFE_INTEGER;
        }));
    }

    private nextElementSibling(node: Node) {
        while ((node = node.nextSibling) != null) {
            if (node.nodeType == Node.ELEMENT_NODE) {
                return node as Element;
            }
        }

        return null;
    }

    private previousElementSibling(node: Node) {
        while ((node = node.previousSibling) != null) {
            if (node.nodeType == Node.ELEMENT_NODE) {
                return node as Element;
            }
        }

        return null;
    }

    private updateValidators(validators: { [name: string]: IValidator<WebControlController<TControl>, any> }, oldValidators: { [name: string]: IValidator<WebControlController<TControl>, any> }) {
        const init = validators === oldValidators;
        validators = validators || {};
        oldValidators = oldValidators || {};

        const addedValidatorKeys = difference(Object.keys(validators), Object.keys(oldValidators)) || [];
        const addedValidators = (init
            ? validators
            : Object.fromEntries(Object.entries(validators).filter(([key]) => addedValidatorKeys.includes(key)))
        ) as { [name: string]: IValidator<WebControlController<TControl>, any> };

        const removedValidatorKeys = difference(Object.keys(oldValidators), Object.keys(validators)) || [];
        const removedValidators = (init
            ? {}
            : Object.fromEntries(Object.entries(oldValidators || {}).filter(([key]) => removedValidatorKeys.includes(key)))
        ) as { [name: string]: IValidator<WebControlController<TControl>, any> };

        if (this.ngModel.$validators == null) {
            this.ngModel.$validators = {};
        }

        // add validators
        for (const key in addedValidators) {
            const name: string = key;
            const validator = addedValidators[name];

            ((name: string, validator: IValidator<WebControlController<TControl>, any>) => {
                validator.contexts = validator.contexts || new Map<WebControlController<TControl>, any>();

                const context: IValidatorContext<any> = {
                    control: this,
                    ngModel: this.ngModel,
                    $scope: this.$scope,
                    name
                };
                validator.contexts.set(this, context);

                validator.add(context);
                this.ngModel.$validators[name] = () => validator.isValid(context);
            })(name, validator);
        }

        // remove validators
        for (const key in removedValidators) {
            const name: string = key;
            const validator = removedValidators[name];

            ((name: string, validator: IValidator<WebControlController<TControl>, any>) => {
                validator.contexts = validator.contexts || new Map<WebControlController<TControl>, any>();
                const context = validator.contexts.get(this);

                validator.remove(context);

                delete this.ngModel.$validators[name];
                validator.contexts.delete(this);
            })(name, validator);
        }
    }
}

/** Control directive. */
export abstract class ControlDirective implements ng.IDirective {
    public restrict = 'E';
    public templateUrl: string;
    public controllerAs = 'ctrl';
    public transclude = false;
    public bindToController = true;
    public replace = true;
    public scope: { [attr: string]: string };
    public link: (...args: any[]) => any;
    public controller: new (...args: any[]) => ControlController<any>;
    public require: string[];
    public terminal: boolean;
    public priority: number;

    protected control: new (ctor?: Object) => IControl;
    protected $templateCache: ng.ITemplateCacheService;

    constructor(controller: new (...args: any[]) => ControlController<any>, control: new (ctor?: Object) => IControl, template: string) {
        this.controller = controller;
        this.control = control;

        const name = Reflect.getMetadata('name', this.control.prototype);

        this.require = [`agt${name}`, '?ngModel'];

        this.templateUrl = `${name}.html`;
        this.$templateCache = getInjector().get<ng.ITemplateCacheService>('$templateCache');

        if (template != null) {
            this.$templateCache.put(this.templateUrl, template);
        }

        this.scope = this.ctrlScope(this.control);

        // `this` method redirects
        this.link = (...args) => this.linkInternal.apply(this, args);
    }

    private linkInternal(scope: ng.IScope, instanceElement: ng.IAugmentedJQuery, instanceAttributes: ng.IAttributes, controllers: Object[], transclude: ng.ITranscludeFunction) {
        const name = Reflect.getMetadata('name', this.control.prototype);
        const controller = controllers[0] as ControlController<any>;
        const ngModel = controllers[1] as ng.INgModelController;

        controller['_ngModelSet'](ngModel);

        instanceAttributes.$set('agt-control-type', name);

        const instanceElementClone = instanceElement.clone();
        instanceElementClone.children().remove();

        scope['controlTemplate'] = instanceElementClone[0].outerHTML;
    }

    /** Creates all the bindings for the target. */
    private ctrlScope(target: any) {
        const bind: { [property: string]: string } = {};

        // properties
        let metadataKeys: string[] = Reflect.getMetadataKeys(target.prototype);
        for (const key of metadataKeys) {
            if (key.startsWith('property:')) {
                const propertyName = key.substring('property:'.length);

                bind[propertyName] = '=?' + camelCase(`agt-${propertyName}`);
                bind[`${propertyName}Ref`] = '=?' + camelCase(`agt-${propertyName}-ref`);
            }
        }

        // methods
        metadataKeys = Reflect.getMetadataKeys(target.prototype);
        for (const key of metadataKeys) {
            if (key.startsWith('method:')) {
                const methodName = key.substring('method:'.length);

                bind[`${methodName}MethodProxy`] = '=?' + camelCase(`agt-${methodName}`);
                bind[`${methodName}MethodProxyRef`] = '=?' + camelCase(`agt-${methodName}-ref`);
            }
        }

        // control
        bind['control'] = '=?agtControl';

        return bind;
    }
}

/** Method proxy. */
export class MethodProxy<TFunction extends Function, TControl extends IControl> {
    /** All the bound functions. */
    public functions: { controlController: ControlController<TControl>, control: TControl, fn: Function }[] = [];

    /** Call all the bound functions. */
    public fn: TFunction = ((...args: any[]) => {
        const methodResults: MethodResult<TControl, any>[] = [];

        for (const fn of this.functions) {
            methodResults.push(new MethodResult<TControl, any>(fn.controlController, fn.control, fn.fn.apply(fn.controlController, args)));
        }

        return methodResults;
    }) as any;
}

/** Method result. */
export class MethodResult<TControl extends IControl, TValue> {
    /** Control controller. */
    controlController: ControlController<TControl>;

    /** Control. */
    control: IControl;

    /** Result of the method. */
    result: TValue;

    constructor(controlController: ControlController<TControl>, control: TControl, result: TValue) {
        this.controlController = controlController;
        this.control = control;
        this.result = result;
    }
}

export class EventProxy {
    private eventObject: EventObject<string>;

    constructor() {
        this.eventObject = new EventObject<string>();
    }

    public on(events: string, handler: EventHandler) {
        const splitEvents = events.split(' ');

        this.eventObject.on(splitEvents, handler);
    }

    public one(events: string, handler: EventHandler) {
        const splitEvents = events.split(' ');

        this.eventObject.one(splitEvents, handler);
    }

    public off(events: string, handler?: EventHandler) {
        const splitEvents = events.split(' ');

        this.eventObject.off(splitEvents, handler);
    }

    public trigger<TControl extends IControl>(events: string, eventArgs?: Object, controlController?: ControlController<TControl>, control?: TControl) {
        const splitEvents = events.split(' ');

        this.eventObject.trigger(splitEvents, eventArgs, controlController, control);
    }

    public triggerAsync<TControl extends IControl>(events: string, eventArgs?: Object, controlController?: ControlController<TControl>, control?: TControl) {
        const splitEvents = events.split(' ');

        this.eventObject.triggerAsync(splitEvents, eventArgs, controlController, control);
    }

    public hasEventListeners(events: string) {
        const splitEvents = events.split(' ');

        return this.eventObject.hasEventListeners(splitEvents);
    }
}

export interface IPropertyChanged<TObject, TValue> {
    object: TObject;
    property: string;
    value: TValue;
    oldValue: TValue;
}
