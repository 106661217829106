import {
    WorkingConditionEntity
} from '../../generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Codelists';
import { CodeList } from './codeList';

export class WorkingCondition extends CodeList {

    public static fromService(codeList: WorkingConditionEntity) {
        return new WorkingCondition({
            id: codeList.WorkingConditionId,
            nameResourceKey: codeList.DisplayName
        });
    }

    public cloneDeep(): WorkingCondition {
        return super.cloneDeep() as WorkingCondition;
    }
}
