import { Component, EventEmitter, Input, Output } from '@angular/core';

import { randomString } from '../../helpers/random';
import { ngbTooltipTemplate, Tooltip } from '../content-tooltip/content-tooltip.component';

export const enum CheckboxLook {
    Normal = 'Normal',
}

export enum TooltipTarget {
    Item,
    InfoIcon,
}

export enum InfoIcon {
    None,
    Normal,
    Button,
}

export interface CheckboxButtonItem<TValue> {
    id?: string;
    value: TValue;
    text: string;
    description?: string;
    disabled?: boolean;
    tooltip?: Tooltip;
    tooltipTarget?: TooltipTarget;
}

export interface CheckboxButtonItem<TValue> {
    id?: string;
    value: TValue;
    text: string;
    description?: string;
    disabled?: boolean;
    tooltip?: Tooltip;
    tooltipTarget?: TooltipTarget;
    infoIcon?: InfoIcon;
}

@Component({
    selector: 'app-checkbox-button',
    templateUrl: './checkbox-button.component.html',
    styleUrls: ['./checkbox-button.component.scss']
})
export class CheckboxButtonComponent<TValue> {
    public tooltipTargetEnum = TooltipTarget;

    @Input()
    public id = randomString(8);

    @Input()
    public title: string;

    @Input()
    public look = CheckboxLook.Normal;

    @Input()
    public selectedValues: Set<TValue>;

    @Input()
    public items: CheckboxButtonItem<TValue>[];

    @Input()
    public disabled = false;

    @Input()
    public tooltip: Tooltip;

    @Output()
    public selectedValuesChange = new EventEmitter<Set<TValue>>();

    @Output()
    public itemToggle = new EventEmitter<TValue>();

    public ngbTooltipTemplate = ngbTooltipTemplate;


    public onChange(item: CheckboxButtonItem<TValue>) {
        this.selectedValues = this.selectedValues ?? new Set();

        if (this.selectedValues.has(item.value)) {
            this.selectedValues.delete(item.value);
        }
        else {
            this.selectedValues.add(item.value);
        }

        this.selectedValuesChange.emit(this.selectedValues);
        this.itemToggle.emit(item.value);
    }
}
