import {
    control, Control, ControlController, ControlDirective, ControlProperty, IControlConstructor,
    property
} from '../controls';
import template from './DropdownItem.html';

export interface IDropdownItemConstructor<TValue extends Object> extends IControlConstructor {
    id?: string | ControlProperty;
    value?: TValue | ControlProperty;
    text?: string | ControlProperty;
    image?: string | ControlProperty;
    selected?: boolean | ControlProperty;
    subItem?: boolean | ControlProperty;
}

@control('DropdownItem')
export class DropdownItem<TValue extends Object> extends Control {

    public static click = 'click';

    @property()
    public id: string;

    @property()
    public value: TValue;

    @property()
    public text: string;

    @property()
    public image: string;

    @property()
    public selected: boolean;

    @property()
    public subItem: boolean;
    constructor(dropdownItem?: IDropdownItemConstructor<TValue>) {
        super(dropdownItem);
    }
}

export class DropdownItemDirective extends ControlDirective {
    constructor() {
        super(DropdownItemController, DropdownItem, template);
    }
}

class DropdownItemController<TValue extends Object> extends ControlController<DropdownItem<TValue>> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs'
    ];

    public id: string;
    public value: TValue;
    public text: string;
    public image: string;
    public selected: boolean;
    public subItem: boolean;

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes) {
        super(DropdownItem, $scope, $element, $attrs);
    }

    public onClick() {
        this.selected = true;

        this.trigger(DropdownItem.click, this.value);
    }
}
