import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ClipboardService {
    public copy(value: string) {
        const input = document.createElement('textarea');
        input.style.width = '1px';
        input.style.height = '1px';
        input.style.margin = '0';
        input.style.padding = '0';
        input.style.border = 'none';
        input.style.background = 'transparent';

        input.value = value;

        document.body.appendChild(input);

        input.select();
        document.execCommand('copy');

        input.remove();
    }
}
