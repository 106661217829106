import angular from 'angular';
import debounce from 'lodash-es/debounce';

import { triggerChangeEvent, triggerInputEvent } from '../../src/app/helpers/htmlHelper';
import { UnitService } from '../Services/unit-service';

enum StepperType {
    increment,
    decrement
}

export interface IStepperOptions {
    delay?: number;
    min?: number;
    max?: number;
}

export const stepperDelay = 400;
const stepperButtonDelay = 500;
const stepperInterval = 70;

const stepperLink = (stepperType: StepperType, directiveName: string): ng.IDirectiveLinkFn => {
    return ($scope, $element, $attrs) => {
        const element = $element[0];

        const $injector: ng.auto.IInjectorService = angular.element(document.body).injector();
        const unit = $injector.get<UnitService>('unit');
        const options = ($attrs[directiveName] != null && $attrs[directiveName] != '' ? $scope.$eval($attrs[directiveName]) as IStepperOptions : {}) || {};

        const changeValue = (stepperInput: HTMLInputElement) => {
            $scope.$apply(() => {
                const ngModel = angular.element(stepperInput).controller('ngModel') as ng.INgModelController;

                ngModel.$setViewValue(stepperInput.value);
                ngModel.$commitViewValue();
                ngModel.$setTouched();

                triggerInputEvent(stepperInput);
                triggerChangeEvent(stepperInput);
                stepperInput.blur();
            });
        };

        const changeValueDebounce = options.delay != null && options.delay > 0 ? debounce((stepperInput: HTMLInputElement) => {
            changeValue(stepperInput);
        }, options.delay) : changeValue;


        const increment = () => {
            const stepperInput = element.closest('.stepper-container').querySelector('.stepper-input') as HTMLInputElement;
            const stepperValue = stepperInput.value;
            const defaultValue = stepperInput.getAttribute('agt-default-stepper-value') != null && stepperInput.getAttribute('agt-default-stepper-value') != ''
                ? $scope.$eval(stepperInput.getAttribute('agt-default-stepper-value'))
                : null;

            if (stepperValue != null && stepperValue != '') {
                let unitValue = unit.parseUnknownUnitValue(stepperValue);

                if (unitValue != null && !Number.isNaN(unitValue.value)) {
                    const stepValue = unit.incDecValueByUnit(unitValue.unit);
                    unitValue.value += stepperType == StepperType.increment ? stepValue : -(stepValue);

                    // min max
                    if (options.min != null || options.max != null) {
                        const internalUnitValue = unit.convertUnitValueToInternalUnitValue(unitValue);

                        if (options.min != null && internalUnitValue.value < options.min) {
                            internalUnitValue.value = options.min;
                        }

                        if (options.max != null && internalUnitValue.value > options.max) {
                            internalUnitValue.value = options.max;
                        }

                        unitValue = unit.convertUnitValueToUnit(internalUnitValue, unitValue.unit);
                    }

                    stepperInput.value = unit.formatUnitValue(unitValue);
                    changeValueDebounce(stepperInput);
                }
            }
            else if (defaultValue != null) {
                stepperInput.value = defaultValue;
                changeValueDebounce(stepperInput);
            }
        };

        let interval: number = null;
        let delayedFunction: number = null;

        const startIncrementContinously = () => {
            delayedFunction = window.setTimeout(() => {
                interval = window.setInterval(increment, stepperInterval);
            }, stepperButtonDelay);
        };

        const stoptIncrementContinously = () => {
            if (delayedFunction) {
                window.clearTimeout(delayedFunction);
                delayedFunction = null;
            }

            if (interval) {
                window.clearInterval(interval);
                interval = null;
            }
        };

        element.addEventListener('click', increment, false);
        element.addEventListener('mousedown', startIncrementContinously, false);
        element.addEventListener('mouseup', stoptIncrementContinously, false);
        element.addEventListener('mouseleave', stoptIncrementContinously, false);

        $scope.$on('$destroy', () => {
            element.removeEventListener('click', increment, false);
            element.removeEventListener('mousedown', startIncrementContinously, false);
            element.removeEventListener('mouseup', stoptIncrementContinously, false);
            element.removeEventListener('mouseleave', stoptIncrementContinously, false);
        });
    };
};

export class StepperIncrementDirective implements ng.IDirective {
    public restrict = 'A';

    public link: ng.IDirectiveLinkFn = stepperLink(StepperType.increment, 'agtStepperIncrement');

    public static Factory() {
        return () => new StepperIncrementDirective();
    }
}

export class StepperDecrementDirective implements ng.IDirective {
    public restrict = 'A';

    public link: ng.IDirectiveLinkFn = stepperLink(StepperType.decrement, 'agtStepperDecrement');

    public static Factory() {
        return () => new StepperDecrementDirective();
    }
}
