import { LoggerService, LogType } from '../../src/app/services/logger.service';

export class BrowserService {
    public static $inject = [
        '$q',
        'logger'
    ];

    private $q: ng.IQService;
    private logger: LoggerService;

    private padding = '=';
    private chrTable = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    private binTable = [
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63,
        52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, 0, -1, -1,
        -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
        15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1,
        -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
        41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1, -1, -1
    ];

    constructor(
        $q: ng.IQService,
        logger: LoggerService
    ) {
        this.$q = $q;
        this.logger = logger;
    }

    public encodeB64(str: string) {
        let result = '';
        const bytes = this.utf8Encode(str);
        let length = bytes.length, i = 0;

        // Convert every three bytes to 4 ascii characters.
        for (i = 0; i < (length - 2); i += 3) {
            result += this.chrTable[bytes[i] >> 2];
            result += this.chrTable[((bytes[i] & 0x03) << 4) + (bytes[i + 1] >> 4)];
            result += this.chrTable[((bytes[i + 1] & 0x0f) << 2) + (bytes[i + 2] >> 6)];
            result += this.chrTable[bytes[i + 2] & 0x3f];
        }

        // Convert the remaining 1 or 2 bytes, pad out to 4 characters.
        if (length % 3) {
            i = length - (length % 3);
            result += this.chrTable[bytes[i] >> 2];
            if ((length % 3) === 2) {
                result += this.chrTable[((bytes[i] & 0x03) << 4) + (bytes[i + 1] >> 4)];
                result += this.chrTable[(bytes[i + 1] & 0x0f) << 2];
                result += this.padding;
            } else {
                result += this.chrTable[(bytes[i] & 0x03) << 4];
                result += this.padding + this.padding;
            }
        }

        return result;
    }

    public decodeB64(data: string) {
        let value = 0, code = 0, idx = 0,
            bytes: number[] = [],
            leftbits = 0, // number of bits decoded, but yet to be appended
            leftdata = 0; // bits decoded, but yet to be appended

        // Convert one by one.
        for (idx = 0; idx < data.length; idx++) {
            code = data.charCodeAt(idx);
            value = this.binTable[code & 0x7F];

            if (-1 === value) {
                // Skip illegal characters and whitespace
                this.logger.log('BrowserService.decodeB64: Illegal characters (code=' + code + ') in position ' + idx, LogType.warn);
            } else {
                // Collect data into leftdata, update bitcount
                leftdata = (leftdata << 6) | value;
                leftbits += 6;

                // If we have 8 or more bits, append 8 bits to the result
                if (leftbits >= 8) {
                    leftbits -= 8;
                    // Append if not padding.
                    if (this.padding !== data.charAt(idx)) {
                        bytes.push((leftdata >> leftbits) & 0xFF);
                    }
                    leftdata &= (1 << leftbits) - 1;
                }
            }
        }

        // If there are any bits left, the base64 string was corrupted
        if (leftbits) {
            this.logger.log('BrowserService.decodeB64: Corrupted base64 string', LogType.error);
            return null;
        }

        return this.utf8Decode(bytes);
    }

    public downloadBlob(blob: Blob, fileName: string) {
        const url = URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        document.body.appendChild(anchor);

        anchor.href = url;
        anchor.style.display = 'none';
        anchor.setAttribute('download', fileName);
        anchor.click();

        anchor.remove();

        // wait a bit before revoking the url
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 5000);
    }

    public toBase64(val: Object) {
        if (val == null) {
            return this.$q.when<string>(null);
        }

        return this.$q.when(this.encodeB64(JSON.stringify(val)));
    }

    public fromBase64(value: string) {
        if (value == null) {
            return null;
        }

        return this.decodeB64(value);
    }

    public post(url: string, params?: { [key: string]: any }, newPage?: boolean) {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = url;
        form.style.display = 'none';

        if (newPage) {
            form.target = '_blank';
        }

        for (const paramKey in params || {}) {
            const paramValue = params[paramKey];

            const input = document.createElement('input');
            input.name = paramKey;
            input.value = paramValue;
            input.type = 'hidden';

            form.appendChild(input);
        }

        document.body.appendChild(form);

        form.submit();

        document.body.removeChild(form);
    }

    private utf8Encode(str: string) {
        const bytes: number[] = [];
        let offset = 0, length = 0, char = '';

        str = encodeURI(str);
        length = str.length;

        while (offset < length) {
            char = str[offset];
            offset += 1;

            if ('%' !== char) {
                bytes.push(char.charCodeAt(0));
            } else {
                char = str[offset] + str[offset + 1];
                bytes.push(parseInt(char, 16));
                offset += 2;
            }
        }

        return bytes;
    }

    private utf8Decode(bytes: number[]) {
        const chars: string[] = [];
        let offset = 0, length = bytes.length, c = 0, c2 = 0, c3 = 0;

        while (offset < length) {
            c = bytes[offset];
            c2 = bytes[offset + 1];
            c3 = bytes[offset + 2];

            if (128 > c) {
                chars.push(String.fromCharCode(c));
                offset += 1;
            } else if (191 < c && c < 224) {
                chars.push(String.fromCharCode(((c & 31) << 6) | (c2 & 63)));
                offset += 2;
            } else {
                chars.push(String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)));
                offset += 3;
            }
        }

        return chars.join('');
    }
}
