import { AppConfigService } from '../../../src/app/services/app-config.service';
import { TrackingService } from '../../Services/tracking-service';
import { UserSettingsService } from '../../Services/user-settings-service';
import { Checkbox } from '../Checkbox/Checkbox';
import { control } from '../controls';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective } from '../Modal/Modal';
import template from './UserAgreementSettings.html';

export class UserAgreementSettingsModalController extends BaseModalController<UserAgreementSettings> {
    public static readonly $inject = [
        '$uibModalInstance',
        '$scope',
        'control',
        'userSettings',
        'tracking'
    ];

    public diagnosticsAgreement: Checkbox<any>;

    public submitted: boolean;

    private $scope: ng.IScope;
    private userSettings: UserSettingsService;
    private tracking: TrackingService;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        control: UserAgreementSettings,
        userSettings: UserSettingsService,
        tracking: TrackingService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.userSettings = userSettings;
            this.tracking = tracking;

            this.diagnosticsAgreement = new Checkbox({
                text: '',
                checked: this.userSettings.settings.application.general.userPrivacy.value
            });

            this.$scope.$on('modal.closing', (event) => {
                if (this.submitted) {
                    event.preventDefault();
                }
            });
        })(this.$onInit);
    }


    public get marketingCommunicationPreferencesUrl() {
        return this.userSettings.getRegion()?.hiltiDataConsentUrl ?? '';
    }

    public get showMarketingCommunicationPreferences(){
        const url = this.marketingCommunicationPreferencesUrl;
        return url != undefined && url != '';
    }

    public close() {
        this.$uibModalInstance.close();
    }

    public save() {
        this.submitted = true;
        this.userSettings.settings.application.general.userPrivacy.value = this.diagnosticsAgreement.checked;

        this.userSettings.save()
            .finally(() => {
                this.tracking.HideMail(this.diagnosticsAgreement.checked);
                this.submitted = false;
                this.close();
            })
            .catch((pending) => {
                this.submitted = false;
            });
    }
}

export interface IUserAgreementSettingsConstructor {

}

@control('UserAgreementSettings')
export class UserAgreementSettings extends Modal<UserAgreementSettings> {

    constructor(ctor?: IUserAgreementSettingsConstructor) {
        super({
            template,
            templateUrl: 'UserAgreementSettings.html',
            controller: UserAgreementSettingsModalController,
            windowClass: 'default-modal settings-modal user-agreement-settings-modal',
            size: 'n' as any,
            ...ctor
        });
    }
}

class UserAgreementSettingsController extends ModalController<UserAgreementSettings> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(UserAgreementSettings, $scope, $element, $attrs, $uibModal);
    }
}

export class UserAgreementSettingsDirective extends ModalDirective {
    constructor() {
        super(UserAgreementSettingsController, UserAgreementSettings);
    }
}
