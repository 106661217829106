import { UIPropertyConfig } from '../generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Display';

export interface IPropertyMetaData {
    id: number;
    name: string;
}

const Design_DesignConnections: IPropertyMetaData = {
    id: 100,
    name: 'Design_DesignConnections'
};

const Design_AnchorMultiplier: IPropertyMetaData = {
    id: 101,
    name: 'Design_AnchorMultiplier'
};

const BOM_BOMList: IPropertyMetaData = {
    id: 200,
    name: 'BOM_BOMList'
};

const Option_UnitLength: IPropertyMetaData = {
    id: 301,
    name: 'Option_UnitLength'
};

const Option_UnitVolume: IPropertyMetaData = {
    id: 302,
    name: 'Option_UnitVolume'
};

const Option_LanguageLCID: IPropertyMetaData = {
    id: 303,
    name: 'Option_LanguageLCID'
};

const Option_Region: IPropertyMetaData = {
    id: 304,
    name: 'Option_Region'
};

const Option_UnitArea: IPropertyMetaData = {
    id: 305,
    name: 'Option_UnitArea'
};

const BOM_BOMDetailsList: IPropertyMetaData = {
    id: 400,
    name: 'BOM_BOMDetailsList'
};

const BOM_OrderAmount: IPropertyMetaData = {
    id: 401,
    name: 'BOM_OrderAmount'
};

const Project_WorkingCondition: IPropertyMetaData = {
    id: 500,
    name: 'Project_WorkingCondition'
};

const Project_WorkingConditionStatus: IPropertyMetaData = {
    id: 501,
    name: 'Project_WorkingConditionStatus'
};


export let PropertyMetaData = {
    getById: (id: number): IPropertyMetaData => {
        return (PropertyMetaData as any)[id];
    },

    Design_DesignConnections,
    100: Design_DesignConnections,
    Design_AnchorMultiplier,
    101: Design_AnchorMultiplier,
    BOM_BOMList,
    200: BOM_BOMList,
    Option_UnitLength,
    301: Option_UnitLength,
    Option_UnitVolume,
    302: Option_UnitVolume,
    Option_LanguageLCID,
    303: Option_LanguageLCID,
    Option_Region,
    304: Option_Region,
    Option_UnitArea,
    305: Option_UnitArea,
    BOM_BOMDetailsList,
    400: BOM_BOMDetailsList,
    BOM_OrderAmount,
    401: BOM_OrderAmount,
    Project_WorkingCondition,
    500: Project_WorkingCondition,
    Project_WorkingConditionStatus,
    501: Project_WorkingConditionStatus,
};

export interface UIProperties {
    Design_DesignConnections: UIPropertyConfig;
    Design_AnchorMultiplier: UIPropertyConfig;
    BOM_BOMList: UIPropertyConfig;
    Option_UnitLength: UIPropertyConfig;
    Option_UnitVolume: UIPropertyConfig;
    Option_LanguageLCID: UIPropertyConfig;
    Option_Region: UIPropertyConfig;
    Option_UnitArea: UIPropertyConfig;
    BOM_BOMDetailsList: UIPropertyConfig;
    BOM_OrderAmount: UIPropertyConfig;
    Project_WorkingCondition: UIPropertyConfig;
    Project_WorkingConditionStatus: UIPropertyConfig;
}
