import { Injectable } from '@angular/core';
import isEqual from 'lodash-es/isEqual';
import union from 'lodash-es/union';

import { Change } from '../entities/change';

@Injectable({
    providedIn: 'root'
})
export class ChangesService {
    public getDeepChanges(source: any, target: any, ignoreUndefined?: boolean) {
        return this.findChanges(null, source, target, ignoreUndefined);
    }

    public getShallowChanges(source: object, target: object, ignoreUndefined?: boolean) {
        const changes: { [property: string]: Change } = {};

        if (source == null || target == null) {
            return changes;
        }

        for (const key of union(Object.keys(source), Object.keys(target))) {
            const sourceValue = source[key];
            const targetValue = target[key];

            if ((!ignoreUndefined || sourceValue != null || targetValue != null) && !isEqual(sourceValue, targetValue)) {
                changes[key] = new Change({
                    name: key,
                    oldValue: sourceValue,
                    newValue: targetValue
                });
            }
        }

        return changes;
    }


    private findChanges(property: string, source: any, target: any, ignoreUndefined?: boolean) {
        const changes: { [property: string]: Change } = {};

        if (Array.isArray(source) && Array.isArray(target)) {
            this.findChangesArrays(changes, property, source, target, ignoreUndefined);
        }
        else if (this.isObject(source) && this.isObject(target)) {
            this.findChangesObjects(changes, property, source, target, ignoreUndefined);
        }
        else if (source !== target) {
            if (!ignoreUndefined || source != null || target != null) {
                changes[property] = new Change({
                    name: property,
                    oldValue: source,
                    newValue: target
                });
            }
        }

        return changes;
    }

    private findChangesArrays(changes: { [property: string]: Change; }, property: string, source: any[], target: any[], ignoreUndefined: boolean) {
        for (let i = 0; i < Math.max(source.length, target.length); i++) {
            const propertyNamePrefix = property != null && property != '' ? property : '';
            const arrayChanges = this.findChanges(`${propertyNamePrefix}[${i}]`, (i < source.length ? source[i] : undefined), (i < target.length ? target[i] : undefined), ignoreUndefined);

            for (const key in arrayChanges) {
                changes[key] = arrayChanges[key];
            }
        }
    }

    private findChangesObjects(changes: { [property: string]: Change; }, property: string, source: any, target: any, ignoreUndefined: boolean) {
        const objectProperties = union(Object.keys(source), Object.keys(target));

        for (const objectProperty of objectProperties) {
            const propertyNamePrefix = property != null && property != '' ? `${property}.` : '';
            const objectChanges = this.findChanges(`${propertyNamePrefix}${objectProperty}`, source[objectProperty], target[objectProperty], ignoreUndefined);

            for (const key in objectChanges) {
                changes[key] = objectChanges[key];
            }
        }
    }

    private isObject(value: any) {
        return value != null && typeof value == 'object' && !(value instanceof RegExp) && !(value instanceof Date);
    }
}
