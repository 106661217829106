import { storageKey, urlPath } from '../../src/app/ModuleConstants';
import { IAuthentication, ISubscriptionInfo } from '../../src/app/services/auth.service';
import { SessionStorageService } from '../../src/app/services/session-storage.service';
import { environment } from '../../src/environments/environment';
import { IRequestConfig } from './http-interceptor-service';
import { LoggerService } from '../../src/app/services/logger.service';
import { UserService } from './user-service';

interface ILogonResult {
    access_token: string;
    subscription_info: ISubscriptionInfo;
}

export class AuthenticationService {
    public static $inject = [
        '$http',
        '$q',
        'logger',
        'user',
        '$location',
        'sessionStorage'
    ];

    private $http: ng.IHttpService;
    private $q: ng.IQService;
    private logger: LoggerService;
    private user: UserService;
    private $location: ng.ILocationService;
    private sessionStorage: SessionStorageService;

    /**
     * Initializes a new instance of the AuthenticationService class.
     */
    constructor(
        $http: ng.IHttpService,
        $q: ng.IQService,
        logger: LoggerService,
        user: UserService,
        $location: ng.ILocationService,
        sessionStorage: SessionStorageService
    ) {
        this.$http = $http;
        this.$q = $q;
        this.logger = logger;
        this.user = user;
        this.$location = $location;
        this.sessionStorage = sessionStorage;
    }

    /**
     * Login to application
     */
    public login(authenticatinoData: ILogonResult) {
        const accessToken = authenticatinoData.access_token;
        const subscriptionInfo = authenticatinoData.subscription_info;
        const customerId: string = subscriptionInfo.AuthorizationEntryList[0].CustomerID;
        const customerOriginId: string = subscriptionInfo.AuthorizationEntryList[0].CustomerOriginID;
        const contactId: string = subscriptionInfo.AuthorizationEntryList[0].ContactIDs;
        const license = subscriptionInfo.AuthorizationEntryList[0].Licenses;

        const userId = subscriptionInfo.UID;
        const userName = subscriptionInfo.LogonID;

        let country: string = subscriptionInfo.Country;
        if (country == null || country == '') {
            country = subscriptionInfo.AuthorizationEntryList[0].Country;
        }

        const params = this.$location.search();
        const sessionState = this.sessionStorage.get(storageKey.loginState);
        if (accessToken && (sessionState == null || sessionState == params.state)) {
            const userAuthentication: IAuthentication = {
                accessToken,
                license,
                userId,
                userName,
                customerId,
                customerOriginId,
                contactId,
                countryOfResidence: subscriptionInfo.CountryOfResidence,
                country,
                subscription_info: subscriptionInfo,
            };

            this.user.setAuthenticated(userAuthentication);

            this.sessionStorage.remove(storageKey.loginState);

            return true;
        }

        return false;
    }

    public async getToken(code: string): Promise<ILogonResult> {
        if (environment.authentication == 'local') {
            return this.generateLocalToken();
        }

        const url = environment.externalAuthenticationUrl + environment.externalTokens;
        const data = {
            grant_type: 'authorization_code',
            code,
            client_id: environment.externalClientId,
            redirect_uri: `${window.location.origin}${urlPath.authenticationCallback}`
        };

        if (environment.authentication == 'oauth2') {
            const verifier = this.sessionStorage.get<string>('code_verifier');
            this.sessionStorage.remove('code_verifier');
            (data as any).code_verifier = verifier;
        }

        const response = await this.$http<ILogonResult>({
            method: 'POST',
            url,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            transformRequest(obj) {
                const str = [] as any;
                for (const p in obj) {
                    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
                }
                return str.join('&');
            },
            data
        });

        // get access token via code
        return response.data;
    }

    public async ensureLicense(logonResult: ILogonResult) {
        if (environment.authentication != 'local' && environment.enableLicenseDecoupling) {
            const config: IRequestConfig = {
                headers: { Authorization: `Bearer ${logonResult.access_token}` },
                ignoreErrors: true
            };

            try {
                // Supress error message because we do not want to notify used. Read commend in catch for more info.
                const res = await this.$http.get<any>(environment.externalLicenseUrl, config);
                logonResult.subscription_info.AuthorizationEntryList[0].Licenses = btoa(JSON.stringify(res.data));
            }
            catch (err){
                // License manager v1 returned error in logonResult.subscription_info.AuthorizationEntryList[0].Licenses, so we save error here to have the same behaviour.
                // HC-License header in backend will also be filled with same error by HC gateway, this is expected.
                logonResult.subscription_info.AuthorizationEntryList[0].Licenses = btoa(JSON.stringify(err.data));
            }
        }

        return logonResult;
    }

    public loggingOut() {
        const url = `${environment.purchaserApplicationWebServiceUrl}UserLoggingOut`;
        const _promise = this.$q.defer<void>();

        this.$http.post<void>(url, null)
            .then(_ => {
                _promise.resolve();
            })
            .catch((response: any) => {
                this.logger.logServiceError(
                    response,
                    'AuthenticationService',
                    'loggingOut');
                _promise.reject(response);
            });

        return _promise.promise;
    }

    public logout() {
        // invalidate authentication on client
        this.user.invalidateAuthentication();
        // navigate to logout page
        // this.$location.path(ModuleConstants.LogoutPath);
    }

    private generateLocalToken(): ILogonResult {
        const generateAccessToken = () => {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYSabcdefghijklmnopqrstuvwxyz0123456789.-_';
            let result = '';

            for (let i = 0; i < 1416; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }

            return result;
        };

        const generateLicenses = () => {
            const obj = {
                application_name: 'P3 Baseplate Engineering Web D1',
                valid_until: '2099-01-12T11:11:11Z',
                licenses: [
                    {
                        name: 'P3 Eng Web  - Basic / User',
                        key: 'DL0ZA7642C',
                        valid_until: '2019-04-30T00:00:00Z',
                        extension: false
                    }
                ],
                features: [
                    {
                        name: 'Basic',
                        key: 'BASIC'
                    },
                    {
                        name: 'Hrail',
                        key: 'HRAIL'
                    },
                    {
                        name: 'Advnc',
                        key: 'ADVNC'
                    }
                ]
            };

            return btoa(JSON.stringify(obj));
        };

        const userId = '7cd76c29cb794905ac96ab0adff915d3';
        const userName = 'justin.bieber@agito.si';

        const customerId = '602588';
        const customerOriginId = '0010314854';
        const contactOriginId = '0002076956';
        const country = 'SI';

        const resultData: ILogonResult = {
            access_token: generateAccessToken(),
            subscription_info: {
                AuthorizationEntryList: [{
                    Scopes: '',
                    CustomerID: customerId,
                    CustomerName: '',
                    ContactIDs: '',
                    ContactFirstName: '',
                    ContactLastName: '',
                    Roles: '',
                    Licenses: generateLicenses(),
                    CustomerOriginID: customerOriginId,
                    ContactOriginID: contactOriginId,
                    City: '',
                    Country: country,
                    PostalCode: '',
                    SecondaryName: '',
                    Street: ''
                }],
                ClientID: '',
                LogonID: userName,
                CustomerID: customerId,
                UID: userId,
                Country: '',
                CountryOfResidence: ''
            }
        };

        return resultData;
    }
}
