import { ModalValue } from '../../../src/app/entities/modal-helper';
import { LocalizationService } from '../../../src/app/services/localization.service';
import { control } from '../controls';
import { BaseModalController } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective, modalProperty } from '../Modal/Modal';
import template from './ConfirmChange.html';

export class ConfirmChangeModalController extends BaseModalController<ConfirmChange> {
    public static $inject = [
        '$uibModalInstance',
        'control',
        'localization'
    ];

    private localization: LocalizationService;

    constructor($uibModalInstance: ng.ui.bootstrap.IModalServiceInstance, control: ConfirmChange, localization: LocalizationService) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.localization = localization;
        })(this.$onInit);
    }

    public get title() {
        return this.control.title != null && this.control.title != '' ? this.localization.sanitizeText(this.control.title, LocalizationService.SubSup) : '&nbsp;';
    }

    public get message() {
        const sanitizeTags = { ...LocalizationService.PBrB, ...LocalizationService.SubSup, ...LocalizationService.A };
        return this.control.message != null && this.control.message != '' ? this.localization.sanitizeText(this.control.message, sanitizeTags) : '&nbsp;';
    }

    public get confirmButtonText() {
        return this.control.confirmButtonText != null && this.control.confirmButtonText != '' ? this.localization.sanitizeText(this.control.confirmButtonText, LocalizationService.SubSup) : '&nbsp;';
    }

    public get cancelButtonText() {
        return this.control.cancelButtonText != null && this.control.cancelButtonText != '' ? this.localization.sanitizeText(this.control.cancelButtonText, LocalizationService.SubSup) : '&nbsp;';
    }

    public get onConfirm() {
        return this.control.onConfirm;
    }

    public get onCancel() {
        return this.control.onCancel;
    }

    public close() {
        this.$uibModalInstance.close();
    }
}

export interface IConfirmChangeConstructor {
    title?: string | ModalValue<string>;
    message?: string | ModalValue<string>;
    confirmButtonText?: string | ModalValue<string>;
    cancelButtonText?: string | ModalValue<string>;
    onConfirm: () => any;
    onCancel: () => any;
}

@control('ConfirmChange')
export class ConfirmChange extends Modal<ConfirmChange> {

    @modalProperty()
    public title: string;

    @modalProperty()
    public message: string;

    @modalProperty()
    public confirmButtonText: string;

    @modalProperty()
    public cancelButtonText: string;

    @modalProperty()
    public onConfirm: () => void;

    @modalProperty()
    public onCancel: () => void;

    constructor(ctor?: IConfirmChangeConstructor) {
        super({
            template,
            templateUrl: 'confirm-change-modal-template.html',
            controller: ConfirmChangeModalController,
            windowClass: 'default-modal confirm-change-modal',
            ...ctor
        });
    }

    public open(id?: string | ModalValue<string>, title?: string | ModalValue<string>, message?: string | ModalValue<string>, confirmButtonText?: string | ModalValue<string>, cancelButtonText?: string | ModalValue<string>,
                onConfirm?: () => any, onCancel?: () => any) {

        if (title != null) {
            if (title instanceof ModalValue) {
                this.configProperty('title', title);
            }
            else {
                this.configPropertyAsValue('title', title);
            }
        }

        if (id != null) {
            if (id instanceof ModalValue) {
                this.configProperty('id', id);
            }
            else {
                this.configPropertyAsValue('id', id);
            }
        }

        if (message != null) {
            if (message instanceof ModalValue) {
                this.configProperty('message', message);
            }
            else {
                this.configPropertyAsValue('message', message);
            }
        }

        if (confirmButtonText != null) {
            if (confirmButtonText instanceof ModalValue) {
                this.configProperty('confirmButtonText', confirmButtonText);
            }
            else {
                this.configPropertyAsValue('confirmButtonText', confirmButtonText);
            }
        }

        if (cancelButtonText != null) {
            if (cancelButtonText instanceof ModalValue) {
                this.configProperty('cancelButtonText', cancelButtonText);
            }
            else {
                this.configPropertyAsValue('cancelButtonText', cancelButtonText);
            }
        }

        if (onConfirm != null) {
            this.onConfirm = onConfirm;
        }

        if (onCancel != null) {
            this.onCancel = onCancel;
        }

        return super.open();
    }
}

class ConfirmChangeController extends ModalController<ConfirmChange> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(ConfirmChange, $scope, $element, $attrs, $uibModal);
    }
}

export class ConfirmChangeDirective extends ModalDirective {
    constructor() {
        super(ConfirmChangeController, ConfirmChange);
    }
}
