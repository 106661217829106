import {
    control, ControlDirective, ControlProperty, property, WebControl, WebControlController,
    WebControlVisibility
} from '../controls';
import { Tooltip } from '../ControlTooltip/ControlTooltip';
import { IRegionControl, IRegionControlConstructor } from '../Region/Region';
import { RegionControlSize } from '../Region/RegionControlSize';
import template from './Group.html';

export interface IGroupConstructor extends IRegionControlConstructor {
    controls?: IRegionControl[] | ControlProperty;
    hideOnEmpty?: boolean | ControlProperty;
}

@control('Group')
export class Group extends WebControl implements IRegionControl {
    @property()
    public title: string;

    @property()
    public size: RegionControlSize;

    @property()
    public tooltip: Tooltip;

    @property()
    public controls: IRegionControl[];

    @property()
    public hideOnEmpty: boolean;

    constructor(group?: IGroupConstructor) {
        super(group);
    }
}

export class GroupDirective extends ControlDirective {
    constructor() {
        super(GroupController, Group, template);

        this.transclude = true;
    }
}

class GroupController extends WebControlController<Group> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$compile',
        '$parse'
    ];

    public title: string;
    public size: RegionControlSize;
    public tooltip: Tooltip;
    public controls: IRegionControl[];
    public hideOnEmpty: boolean;

    private controlWatches: Function[];
    private disposed: boolean;

    constructor(
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes,
        $compile: ng.ICompileService,
        $parse: ng.IParseService
    ) {
        super(Group, $scope, $element, $attrs, $compile, $parse);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            // constants
            this.$scope['RegionControlSize'] = RegionControlSize;

            this.controlWatches = [];

            this.bindControls(this.element.querySelector('.controls'), 'ctrl.controls');

            this.$scope.$watchCollection('ctrl.controls', (...args: any[]) => this.controlsWatch.apply(this, args));
            this.$scope.$watch('ctrl.hideOnEmpty', (...args: any[]) => this.hideOnEmptyWatch.apply(this, args));

            // dispose event
            this.onDisposed((...args: any[]) => this.dispose.apply(this, args));
        })(this.$onInit);
    }

    public focus() {
        if (this.controls != null && this.controls.length > 0) {
            this.controls[0].focus();
        }
    }

    private controlsWatch(controls: IRegionControl[], oldControls: IRegionControl[]) {
        // remove control watches
        for (const controlWatch of this.controlWatches) {
            controlWatch();
        }

        this.controlWatches = [];

        // watch control visibility
        if (this.controls != null) {
            for (const control of this.controls) {
                ((control: IRegionControl) => {
                    this.controlWatches.push(this.$scope.$watch(() => control.visibility, (...args: any[]) => this.controlVisibilityWatch.apply(this, args)));
                })(control);
            }
        }
    }

    private controlVisibilityWatch() {
        this.setVisibility();
    }

    private setVisibility() {
        if (this.hideOnEmpty) {
            if (this.controls == null || this.controls.every((control) => control.visibility != WebControlVisibility.visible)) {
                this.visibility = WebControlVisibility.hidden;
            }
            else {
                this.visibility = WebControlVisibility.visible;
            }
        }
    }

    private hideOnEmptyWatch() {
        this.setVisibility();
    }

    private dispose() {
        if (!this.disposed) {
            this.disposed = true;

            for (const control of this.controls || []) {
                control.dispose();
            }

            this.$scope.$destroy();
        }
    }
}
