import { ModalValue } from '../../../src/app/entities/modal-helper';
import { KnownRegion } from '../../../src/app/generated-modules/Hilti.PE.Purchaser.Entities.Purchaser.Enums';
import { CodeListService } from '../../../src/app/services/code-list.service';
import { LocalizationService } from '../../../src/app/services/localization.service';
import { UserSettingsService } from '../../Services/user-settings-service';
import { Checkbox } from '../Checkbox/Checkbox';
import { control } from '../controls';
import { BaseModalController } from '../Modal/BaseModal';
import {
    IModalConstructor, Modal, ModalController, ModalDirective, modalProperty
} from '../Modal/Modal';
import template from './UserAgreement.html';

export class UserAgreementModalController extends BaseModalController<UserAgreement> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'control',
        '$window',
        'localization',
        '$sce',
        '$http',
        'userSettings',
        'codeList'
    ];

    public endUserAgreement: Checkbox<any>;
    public hiltiDataPrivacyAgreement: Checkbox<any>;
    public customerAcceptance: Checkbox<any>;
    public partyAcceptance: Checkbox<any>;

    public userLicenseAgreementHtml: string;
    public hiltiDataPrivacyUrl: string;
    public userAgreementUrl: string;

    private submitted: boolean;

    private $scope: ng.IScope;
    private $window: ng.IWindowService;
    private localization: LocalizationService;
    private $sce: ng.ISCEService;
    private $http: ng.IHttpService;
    private userSettings: UserSettingsService;
    private codeList: CodeListService;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        control: UserAgreement,
        $window: ng.IWindowService,
        localization: LocalizationService,
        $sce: ng.ISCEService,
        $http: ng.IHttpService,
        userSettings: UserSettingsService,
        codeList: CodeListService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();
            this.$scope = $scope;
            this.$window = $window;
            this.$scope['localization'] = this.localization = localization;
            this.$sce = $sce;
            this.$http = $http;
            this.userSettings = userSettings;
            this.codeList = codeList;

            this.endUserAgreement = new Checkbox({
                text: '',
                checked: false
            });

            this.hiltiDataPrivacyAgreement = new Checkbox({
                text: '',
                checked: false
            });

            this.customerAcceptance = new Checkbox({
                text: '',
                checked: false
            });

            this.partyAcceptance = new Checkbox({
                text: '',
                checked: false
            });

            this.hiltiDataPrivacyUrl = this.control.dataPrivacyLink;
            this.userAgreementUrl = this.control.userAgreementLink;

            this.$scope.$on('modal.closing', (event) => {
                if (!this.submitted) {
                    event.preventDefault();
                }
            });
        })(this.$onInit);
    }

    private get isCustomerAcceptanceEnabled() {
        return this.userSettings.settings.user.general.regionId.value == KnownRegion.Italy;
    }

    private get isContinueDisabled() {
        if (this.userSettings.settings.user.general.regionId.value == KnownRegion.Italy) {
            return this.submitted || !this.endUserAgreement.checked || !this.hiltiDataPrivacyAgreement.checked || !this.customerAcceptance.checked || !this.partyAcceptance.checked;
        }
        else {
            return this.submitted || !this.endUserAgreement.checked || !this.hiltiDataPrivacyAgreement.checked;
        }
    }

    public continue() {
        // If the selected region is something other than Italy, only privacy and user agreement checkboxes are seen, but if the selected region is Italy, there are four checkboxes to check
        if (!this.isCustomerAcceptanceEnabled && (!this.hiltiDataPrivacyAgreement.checked || !this.endUserAgreement.checked) ||
            (!this.hiltiDataPrivacyAgreement.checked || !this.endUserAgreement.checked || !this.customerAcceptance.checked || !this.partyAcceptance.checked) && this.isCustomerAcceptanceEnabled) {
            return;
        }


        this.userSettings.settings.application.general.userAgreement.value = true;

        this.userSettings.save()
            .finally(() => {
                this.submitted = true;
            })
            .then((pending) => {
                this.close();
            });
    }

    public close() {
        this.$uibModalInstance.close();
    }

    public openDataPrivacyLink() {
        this.$window.open(this.hiltiDataPrivacyUrl, '_blank');
    }
}

export interface IUserAgreementConstructor extends IModalConstructor<UserAgreement> {

}

@control('UserAgreement')
export class UserAgreement extends Modal<UserAgreement> {

    @modalProperty()
    public dataPrivacyLink: string;

    @modalProperty()
    public userAgreementLink: string;

    constructor(ctor?: IUserAgreementConstructor) {
        super({
            template,
            templateUrl: 'UserAgreement.html',
            controller: UserAgreementModalController,
            windowClass: 'default-modal user-agreement-modal',
            size: 'lg',
            ...ctor
        });
    }

    public open(dataPrivacyLink?: string | ModalValue<string>, userAgreementLink?: string | ModalValue<string>) {
        if (dataPrivacyLink != null) {
            if (dataPrivacyLink instanceof ModalValue) {
                this.configProperty('dataPrivacyLink', dataPrivacyLink);
            }
            else {
                this.configPropertyAsValue('dataPrivacyLink', dataPrivacyLink);
            }
        }

        if (userAgreementLink != null) {
            if (userAgreementLink instanceof ModalValue) {
                this.configProperty('userAgreementLink', userAgreementLink);
            }
            else {
                this.configPropertyAsValue('userAgreementLink', userAgreementLink);
            }
        }

        return super.open();
    }
}

class UserAgreementController extends ModalController<UserAgreement> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(UserAgreement, $scope, $element, $attrs, $uibModal);
    }
}

export class UserAgreementDirective extends ModalDirective {
    constructor() {
        super(UserAgreementController, UserAgreement);
    }
}
