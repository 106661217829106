import { ModalValue } from '../../../src/app/entities/modal-helper';
import { ProjectUser } from '../../../src/app/entities/project-user';
import { DateTimeService } from '../../../src/app/services/date-time.service';
import { LocalizationService } from '../../../src/app/services/localization.service';
import { Project } from '../../Entities/Project';
import { DocumentService } from '../../Services/document-service';
import { ModalService } from '../../Services/modal-service';
import { Bind, control, Translation } from '../controls';
import { BaseModalController, ModalTranslation } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective, modalProperty } from '../Modal/Modal';
import { TextBox } from '../TextBox/TextBox';
import { Email } from '../TextBox/Validators/Email';
import template from './ShareProject.html';

export class ShareProjectModalController extends BaseModalController<ShareProject> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'control',
        'document',
        'dateTime',
        '$q',
        'localization',
        'modal'
    ];

    public submitted: boolean;
    public users: ProjectUser[];
    public userTextBox: TextBox;

    private pendingSave: boolean;
    private removeProject: boolean;

    private $scope: ng.IScope;
    private document: DocumentService;
    private dateTime: DateTimeService;
    private localization: LocalizationService;
    private $q: ng.IQService;
    private promises: ng.IPromise<any>[];
    private modal: ModalService;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        control: ShareProject,
        document: DocumentService,
        dateTime: DateTimeService,
        $q: ng.IQService,
        localization: LocalizationService,
        modal: ModalService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.$scope = $scope;
            this.document = document;
            this.$q = $q;
            this.$scope['localization'] = this.localization = localization;
            this.modal = modal;
            this.$scope['datetime'] = this.dateTime = dateTime;

            this.users = this.selectedProject.users;

            // don't close the modal if save is pending (close on destroy)
            this.$scope.$on('modal.closing', (event: ng.IAngularEvent, result?: any) => {
                if (result !== 'destroy' && this.pendingSave) {
                    event.preventDefault();
                }

                else if (this.onRemoveProject != null) {
                    this.document.userHasAccessToProject(this.selectedProject.id)
                        .then((res) => {
                            if (!res) {
                                this.onRemoveProject();
                            }
                        });
                }
            });

            this.userTextBox = new TextBox({
                id: 'share-project-add-users',
                title: new Translation('Agito.Hilti.Purchaser.ShareProject.AddUserTitle'),
                placeholder: new Translation('Agito.Hilti.Purchaser.ShareProject.AddUserPlaceholder'),
                disabled: new Bind('ctrl.submitted'),
                model: null,
                attrMax: '250',
                validators: {
                    email: new Email({
                        emailSeperator: ';'
                    })
                },

            });

            setTimeout(() => { this.userTextBox.focus(); });

            this.$scope.$watch(() => this.userTextBox.model, (newVal: string, prevVal: string) => {
                if (newVal == null || newVal == '' || prevVal == null || prevVal == '') {
                    this.$scope['form'].$setPristine();
                }
            });
        })(this.$onInit);
    }

    public get selectedProject() {
        return this.control.selectedProject;
    }

    private get onRemoveProject() {
        return this.control.onRemoveProject;
    }

    public close() {
        this.$uibModalInstance.close();
    }

    public addUser() {
        if (this.submitted || this.pendingSave || this.userTextBox.model == null || this.userTextBox.model.trim() == '' || !this.$scope['form'].$valid) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        const inputUser = this.userTextBox.model.toLowerCase().trim();
        const users = inputUser.split(';').map(it => it.trim()).filter(it => it != '');
        if (users.length == 0) {
            return;
        }

        this.promises = [];
        let completed = 0;
        const updatedUsers: ProjectUser[] = [];
        const defer = this.$q.defer();

        const now: Date = new Date();
        const nowUtc: Date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));

        for (let i = 0; i < users.length; i++) {
            const user = users[i];

            if (user == null || user.trim() == '') {
                continue;
            }

            const newUser: ProjectUser = {
                projectid: this.selectedProject.id,
                user,
                modified: true,
                dateadded: nowUtc,
                isowner: false
            };

            // add only if entry does not exist in the selected project
            if (this.selectedProject.users.findIndex((item) => item.projectid === newUser.projectid && item.user === newUser.user) === -1) {
                this.promises.push(this.document.addUsersOnProjectById(newUser));
            }
        }

        if ((this.promises || []).length > 0) {
            this.promises.forEach((prom: ng.IPromise<any>, index: number) => {
                prom.then((response) => {
                    if (response != undefined) {
                        const user: ProjectUser = {
                            projectid: this.selectedProject.id,
                            user: response.config.data.user,
                            modified: true,
                            dateadded: nowUtc,
                            isowner: false
                        };

                        updatedUsers.push(user);
                        this.selectedProject.users.push(user);
                    }
                }).catch((response) => {

                }).finally(() => {
                    completed++;
                    if (completed == this.promises.length) {
                        if (updatedUsers.length == completed) {
                            defer.resolve(this.promises);
                        }
                        else {
                            defer.reject(this.promises);
                        }
                    }
                });
            });

            defer.promise.finally(() => {
                this.submitted = false;
                this.pendingSave = false;
            });
        }

        else {
            this.submitted = false;
            this.pendingSave = false;
        }

        this.userTextBox.model = null;
    }

    public deleteUser(user: ProjectUser) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        const opened = this.modal.confirmChange.open('confirm-delete-project-permanently',
            new ModalTranslation('Agito.Hilti.Purchaser.ShareProject.ConfirmDelete.Title'),
            new ModalTranslation('Agito.Hilti.Purchaser.ShareProject.ConfirmDelete.Message'),
            new ModalTranslation('Agito.Hilti.Purchaser.ShareProject.ConfirmDelete.Ok'),
            new ModalTranslation('Agito.Hilti.Purchaser.ShareProject.ConfirmDelete.Cancel'),
            () => {
                this.modal.confirmChange.close(true);
            },
            () => {
                this.modal.confirmChange.close(false);
            })[0].result.result;

        this.submitted = this.pendingSave = true;

        opened.then((result: any) => {
            if (result == true) {
                return this.document.removeUsersOnProjectById(user).then(() => {
                    this.selectedProject.users = this.selectedProject.users.filter((item) => item != user);
                });
            }

            return this.$q.when(result);
        })
            .finally(() => {
                this.submitted = this.pendingSave = false;
            });
    }

    private hideShareTable() {
        if (this.users == null) {
            true;
        }

        return this.users.filter((u) => !u.isowner).length < 1;
    }
}

export interface IShareProjectConstructor {
    onRemoveProject?: (removeProject: boolean) => void;
    selectedProject?: Project | ModalValue<Project>;
}

@control('ShareProject')
export class ShareProject extends Modal<ShareProject> {

    @modalProperty()
    public onRemoveProject: () => ng.IPromise<void> | void;

    @modalProperty()
    public selectedProject: Project;

    constructor(ctor?: IShareProjectConstructor) {
        super({
            template,
            templateUrl: 'share-project-modal-template.html',
            controller: ShareProjectModalController,
            windowClass: 'default-modal share-project-modal',
            size: 'n' as any,
            noAnimation: true,
            ...ctor
        });
    }
}

class ShareProjectController extends ModalController<ShareProject> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(ShareProject, $scope, $element, $attrs, $uibModal);
    }
}

export class ShareProjectDirective extends ModalDirective {
    constructor() {
        super(ShareProjectController, ShareProject);
    }
}
