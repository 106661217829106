import { AuthService, IAuthentication } from '../../src/app/services/auth.service';
import { SessionStorageService } from '../../src/app/services/session-storage.service';
import { Design } from '../Entities/Design';
import { EventObject } from '../Entities/EventObject';
import { Project } from '../Entities/Project';
import { LicenseService } from './license-service';

const DesignIdStorageKey = 'designId';

export enum UserEvent {
    authenticated
}

export enum ProjectAndDesignView {
    allDesigns,
    templates,
    drafts,
    projects
}

export class UserService extends EventObject<UserEvent> {
    public static $inject = [
        'sessionStorage',
        'license',
        'authService'
    ];

    private _backendApplicationVersion: string;

    public project: Project;
    public design: Design;
    public fullName: string;
    public occupation: string;

    private _projects: Project[] = [];
    private _autoSelectDesignId: string;

    private sessionStorage: SessionStorageService;
    private license: LicenseService;
    private authService: AuthService;

    constructor(
        sessionStorage: SessionStorageService,
        license: LicenseService,
        authService: AuthService
    ) {
        super();

        this.sessionStorage = sessionStorage;
        this.license = license;
        this.authService = authService;

        this.fullName = 'Stephen Eaton';
        this.occupation = 'Lutz Engineering';
    }


    public get authentication() {
        return this.authService.authentication;
    }

    public get projects() {
        return this._projects;
    }
    public set projects(value: Project[]) {
        this._projects = value;
    }

    public get isAuthenticated() {
        return this.authService.isAuthenticated;
    }

    public get autoSelectDesignId() {
        if (this._autoSelectDesignId === undefined) {
            this._autoSelectDesignId = this.sessionStorage.get(DesignIdStorageKey);
            this.sessionStorage.remove(DesignIdStorageKey);
        }
        return this._autoSelectDesignId;
    }

    public set autoSelectDesignId(val: string) {
        this._autoSelectDesignId = val;
    }

    public get isExternalRussianUser() {
        return this.authService.isExternalRussianUser;
    }

    public get backendApplicationVersion() {
        return this._backendApplicationVersion;
    }

    public get hasFreeLicense() {
        return this.license.isFree();
    }

    public get hasTrialLicense() {
        // user have trial license
        return this.license.isTrial();
    }

    public setAuthenticated(authentication: IAuthentication, remember?: boolean) {
        this.authService.setAuthentication(authentication, remember);


        // trigger authenticated event
        this.trigger(UserEvent.authenticated, this.authentication);
    }

    public setBackendApplicationVersion(version: string) {
        this._backendApplicationVersion = version;
    }

    public invalidateAuthentication() {
        this.authService.invalidateAuthentication();
    }

    public authenticatedFromStorage() {
        this.authService.tryAuthenticateFromStorage();

        if (!this.isAuthenticated) {
            this.invalidateAuthentication();
        }
        else {
            // trigger authenticated event
            this.trigger(UserEvent.authenticated, this.authentication);
        }
    }

    public onAuthenticated(fn: (authentication: IAuthentication) => void) {
        this.on(UserEvent.authenticated, fn);
    }

    public storeRouteParameters(params: any) {
        if (params.guid != null) {
            this.sessionStorage.set(DesignIdStorageKey, params.guid);
        }
    }

    public isInternalHiltiUser() {
        return this.authService.isInternalHiltiUser();
    }
}
