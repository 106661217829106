import angular from 'angular';

interface IEventListener<TEvent> {
    event: TEvent;
    handler: (...args: any[]) => void;
    one?: boolean;
}

let _$rootScope: ng.IRootScopeService;
function $rootScope() {
    if (_$rootScope == null) {
        _$rootScope = angular.element(document.body).injector().get('$rootScope');
    }

    return _$rootScope;
}

export class EventObject<TEvent> {
    private eventListeners: IEventListener<TEvent>[];

    constructor() {
        this.eventListeners = [];
    }

    public on(events: TEvent | TEvent[], handler: (...args: any[]) => void) {
        if (!Array.isArray(events)) {
            events = [events as TEvent];
        }

        for (const event of events as TEvent[]) {
            this.eventListeners.push({
                event,
                handler
            });
        }
    }

    public one(events: TEvent | TEvent[], handler: (...args: any[]) => void) {
        if (!Array.isArray(events)) {
            events = [events as TEvent];
        }

        for (const event of events as TEvent[]) {
            this.eventListeners.push({
                event,
                handler,
                one: true
            });
        }
    }

    public off(events: TEvent | TEvent[], handler?: (...args: any[]) => void) {
        if (!Array.isArray(events)) {
            events = [events as TEvent];
        }

        if (handler == null) {
            for (const event of events as TEvent[]) {
                this.eventListeners = this.eventListeners.filter((eventListener) => eventListener.event !== event);
            }
        }
        else {
            for (const event of events as TEvent[]) {
                this.eventListeners = this.eventListeners.filter((eventListener) => eventListener.event !== event || eventListener.handler !== handler);
            }
        }
    }

    public trigger(events: TEvent | TEvent[], ...args: any[]) {
        if (!Array.isArray(events)) {
            events = [events as TEvent];
        }

        for (const event of events as TEvent[]) {
            const eventListeners = this.eventListeners.filter((eventListener) => eventListener.event === event);

            for (const eventListener of eventListeners) {
                ((eventListener: IEventListener<TEvent>) => {
                    // run in apply if needed
                    if (!$rootScope().$$phase) {
                        $rootScope().$apply(() => {
                            eventListener.handler.apply(null, args);

                            if (eventListener.one) {
                                this.off(eventListener.event, eventListener.handler);
                            }
                        });
                    }
                    else {
                        eventListener.handler.apply(null, args);

                        if (eventListener.one) {
                            this.off(eventListener.event, eventListener.handler);
                        }
                    }
                })(eventListener);
            }
        }
    }

    public triggerAsync(events: TEvent | TEvent[], ...args: any[]) {
        if (!Array.isArray(events)) {
            events = [events as TEvent];
        }

        for (const event of events as TEvent[]) {
            const eventListeners = this.eventListeners.filter((eventListener) => eventListener.event === event);

            for (const eventListener of eventListeners) {
                ((eventListener: IEventListener<TEvent>) => {
                    setTimeout(() => $rootScope().$applyAsync(() => {
                        eventListener.handler.apply(null, args);

                        if (eventListener.one) {
                            this.off(eventListener.event, eventListener.handler);
                        }
                    }));
                })(eventListener);
            }
        }
    }

    public hasEventListeners(events: TEvent | TEvent[]) {
        if (!Array.isArray(events)) {
            events = [events as TEvent];
        }

        for (const event of events as TEvent[]) {
            if (this.eventListeners.some((eventListener) => eventListener.event == event)) {
                return true;
            }
        }

        return false;
    }
}
