import { LocalizationService } from '../../../src/app/services/localization.service';
import { PropertyMetaData } from '../../../src/app/properties/properties';
import { DateTimeService } from '../../../src/app/services/date-time.service';
import { DocumentService } from '../../Services/document-service';
import { ModalDialogType } from '../../../src/app/services/error-handler.service';
import { ModalService } from '../../Services/modal-service';
import { control } from '../controls';
import { BaseModalController, ModalTranslation } from '../Modal/BaseModal';
import { Modal, ModalController, ModalDirective, modalProperty } from '../Modal/Modal';
import template from './Archive.html';

interface Project {
    projectId: string;
    projectName: string;
    designs: number;
    members: number;
    created: Date;
    archived: Date;
    parentProjectId: string;
}

export class ArchiveModalController extends BaseModalController<Archive> {
    public static $inject = [
        '$uibModalInstance',
        '$scope',
        'control',
        'localization',
        'dateTime',
        '$q',
        'document',
        'modal'
    ];

    public submitted: boolean;
    public projects: Project[];

    private pendingSave: boolean;

    private $scope: ng.IScope;
    private localization: LocalizationService;
    private dateTime: DateTimeService;
    private $q: ng.IQService;
    private document: DocumentService;
    private modal: ModalService;

    constructor(
        $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        $scope: ng.IScope,
        control: Archive,
        localization: LocalizationService,
        dateTime: DateTimeService,
        $q: ng.IQService,
        document: DocumentService,
        modal: ModalService
    ) {
        super($uibModalInstance, control);

        this.$onInit = (($onInit) => () => {
            $onInit?.();

            this.modal = modal;
            this.$scope = $scope;
            this.$scope['localization'] = this.localization = localization;
            this.$scope['datetime'] = this.dateTime = dateTime;
            this.$scope['PropertyMetaData'] = PropertyMetaData;
            this.$q = $q;
            this.document = document;

            // don't close the modal if save is pending (close on destroy)
            this.$scope.$on('modal.closing', (event: ng.IAngularEvent, result?: any) => {
                if (result !== 'destroy' && this.pendingSave) {
                    event.preventDefault();
                }
            });

            this.init();
        })(this.$onInit);
    }

    public close() {
        this.$uibModalInstance.close();
    }

    public restoreProject(projectId: string) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        this.submitted = this.pendingSave = true;
        const projectLevelType = this.projects.find((item) => item.projectId == projectId).parentProjectId == null ? ModalDialogType.project : ModalDialogType.subproject;

        this.document.restoreProject(projectId, projectLevelType).then(() => {
            this.projects = this.projects.filter((item) => item.projectId != projectId && item.parentProjectId != projectId);

            // invoke onRestore
            if (this.control.onRestore != null) {
                this.control.onRestore();
            }
        }).finally(() => {
            this.submitted = this.pendingSave = false;
        });
    }

    public deleteProject(projectId: string) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        const opened = this.modal.confirmChange.open('confirm-delete-project-permanently',
            new ModalTranslation('Agito.Hilti.Purchaser.Archive.ConfirmDelete.Title'),
            new ModalTranslation('Agito.Hilti.Purchaser.Archive.ConfirmDelete.Message'),
            new ModalTranslation('Agito.Hilti.Purchaser.Archive.ConfirmDelete.Ok'),
            new ModalTranslation('Agito.Hilti.Purchaser.Archive.ConfirmDelete.Cancel'),
            () => {
                this.modal.confirmChange.close(true);
            },
            () => {
                this.modal.confirmChange.close(false);
            })[0].result.result;

        this.submitted = this.pendingSave = true;

        opened.then((result: any) => {
            if (result == true) {
                return this.document.removeArchivedProject(projectId).then(() => {
                    this.projects = this.projects.filter((item) => item.projectId != projectId);
                });
            }

            return this.$q.when(result);
        })
            .finally(() => {
                this.submitted = this.pendingSave = false;
            });
    }

    private init() {
        this.initProjects();
    }

    private initProjects() {
        this.projects = [];
        for (const idx in this.document.projectsArchive) {
            const item = this.document.projectsArchive[idx];
            this.projects.push({
                projectId: item.id,
                projectName: item.name,
                members: item.members,
                designs: item.designs,
                created: item.created,
                archived: item.archived,
                parentProjectId: item.parentId
            } as Project);
        }

        this.projects.sort((a, b) => {
            return a.projectName.localeCompare(b.projectName);
        });
    }
}

export interface IArchiveConstructor {
    onRestore?: () => void;
}

@control('Archive')
export class Archive extends Modal<Archive> {

    @modalProperty()
    public onRestore: () => void;

    constructor(ctor?: IArchiveConstructor) {
        super({
            template,
            templateUrl: 'Archive.html',
            controller: ArchiveModalController,
            windowClass: 'default-modal archive-modal',
            size: 'lg',
            noAnimation: true,
            ...ctor
        });
    }
}

class ArchiveController extends ModalController<Archive> {
    public static $inject = [
        '$scope',
        '$element',
        '$attrs',
        '$uibModal'
    ];

    constructor($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $uibModal: ng.ui.bootstrap.IModalService) {
        super(Archive, $scope, $element, $attrs, $uibModal);
    }
}

export class ArchiveDirective extends ModalDirective {
    constructor() {
        super(ArchiveController, Archive);
    }
}
