import { Injectable } from '@angular/core';
import { ProjectCodeList } from './code-list.service';
import { NumberService, NumberType } from '../../../App/Services/number-service';
import { UserService } from '../../../App/Services/user-service';
import { UserSettingsService } from '../../../App/Services/user-settings-service';
import { Unit as UnitCodeList } from '../entities/codeLists/unit';
import { Unit, UnitGroup } from '../generated-modules/Hilti.PE.Purchaser.Common.Units';

/**
 * kgf in N
 */
const kgfInN = 9.80665;

/**
 * N in kgf
 */
const NInKgf = 0.10197162129779283;

/**
 * kgfm in Nmm
 */
const kgfmInNmm = 9806.65;

/**
 * Nmm in kgfm
 */
const NmmInKgfm = 0.00010197162129779283;


export const conversionMatrixLength = [
    [1, 0.1, 0.001, 0.0393700787, 0.0393700787 / 12, 0.000000621371192],
    [10, 1, 0.01, 0.393700787, 0.393700787 / 12, 0.00000621371192],
    [1000, 100, 1, 39.3700787, 39.3700787 / 12, 0.000621371192],
    [25.4, 2.54, 0.0254, 1, 1 / 12, 0.0000157828283],
    [304.8, 30.48, 0.3048, 12, 1, 0.000189393939],
    [1609344, 160934.4, 1609.344, 63360, 5280, 1],
    [10, 1, 0.01, 0.393700787, 0.393700787 / 12, 1]
];

export const conversionMatrixVolume = [
    [1, 1000, 1, 0.000001, 0.0610237441, 0.0000353147, 0.0351950797],
    [0.001, 1, 0.001, 0.000000001, 0.0000610237441, 0.0000000353147, 0.0000351950797],
    [1, 1000, 1, 0.000001, 0.0610237441, 0.0000353147, 0.0351950797],
    [1000000, 1000000000, 1000000, 1, 61023.7441, 35.3147, 35195.0797],
    [1 / 0.0610237441, 1000 / 0.0610237441, 1 / 0.0610237441, 0.000001 / 0.0610237441, 1, (1 / 0.0610237441) * 0.0000353147, (1 / 0.0610237441) * 0.0351950797],
    [1 / 0.0000353147, 1000 / 0.0000353147, 1 / 0.0000353147, 0.000001 / 0.0000353147, (1 / 0.0000353147) * 0.0610237441, 1, (1 / 0.0000353147) * 0.0351950797],
    [1 / 0.0351950797, 1000 / 0.0351950797, 1 / 0.0351950797, 0.000001 / 0.0351950797, (1 / 0.0351950797) * 0.0610237441, (1 / 0.0351950797), 1]
];

export const conversionMatrixForce = [
    [1, 0.1, 0.001, 0.224808943, 0.0002248089, NInKgf],
    [10, 1, 0.01, 2.24808943, 0.002248089, 10 * NInKgf],
    [1000, 100, 1, 224.808943, 0.224808943, 1000 * NInKgf],
    [1 / 0.224808943, 1 / 2.24808943, 1 / 224.808943, 1, 0.001, (1 / 0.224808943) * NInKgf],
    [1 / 0.000224808943, 1 / 0.00224808943, 1 / 0.224808943, 1000, 1, (1 / 0.000224808943) * NInKgf],
    [kgfInN, 0.1 * kgfInN, 0.001 * kgfInN, 0.224808943 * kgfInN, 0.0002248089 * kgfInN, 1]
];

export const conversionMatrixMoment = [
    [1, 0.001, 0.0001, 0.000001, 0.000737562149 * 12, 0.000737562149, 0.000000737562149277266 * 12, 0.000000737562149277266, NmmInKgfm],
    [1000, 1, 0.1, 0.001, 0.737562149 * 12, 0.737562149, 0.000737562149277266 * 12, 0.000737562149277266, 1000 * NmmInKgfm],
    [10000, 10, 1, 0.01, 7.37562149 * 12, 7.37562149, 0.00737562149277266 * 12, 0.00737562149277266, 10000 * NmmInKgfm],
    [1000000, 1000, 100, 1, 737.562149 * 12, 737.562149, 0.737562149277266 * 12, 0.737562149277266, 1000000 * NmmInKgfm],
    [1 / (0.000737562149 * 12), 1 / (0.737562149 * 12), 1 / (7.37562149 * 12), 1 / (737.562149 * 12), 1, 1 / 12, 0.001, 0.001 / 12, 1 / (0.000737562149 * 12) * NmmInKgfm],
    [1 / 0.000737562149, 1 / 0.737562149, 1 / 7.37562149, 1 / 737.562149, 12, 1, 0.012, 0.001, 1 / 0.000737562149 * NmmInKgfm],
    [1000 / (0.000737562149277266 * 12), 1000 / (0.737562149277266 * 12), 1000 / (7.37562149277266 * 12), 1000 / (737.562149277266 * 12), 1000, 1000 / 12, 1, 1 / 12, 1000 / (0.000737562149277266 * 12) * NmmInKgfm],
    [1000 / 0.000737562149277266, 1000 / 0.737562149277266, 1000 / 7.37562149277266, 1000 / 737.562149277266, 12000, 1000, 12, 1, 1000 / 0.000737562149277266 * NmmInKgfm],
    [kgfmInNmm, 0.001 * kgfmInNmm, 0.0001 * kgfmInNmm, 0.000001 * kgfmInNmm, 0.000737562149 * 12 * kgfmInNmm, 0.000737562149 * kgfmInNmm, 0.000000737562149277266 * 12 * kgfmInNmm, 0.000000737562149277266 * kgfmInNmm, 1]
];

export const conversionMatrixPercentage = [
    [1, 0.1],
    [10, 1]
];

export const conversionMatrixStress = [
    [1, 145.037738, 0.145037738, 1000, 1],
    [1 / 145.037738, 1, 0.001, 1 / 0.145037738, 1 / 145.037738],
    [1 / 0.145037738, 1000, 1, 1 / 0.000145037738, 1 / 0.145037738],
    [0.001, 0.145037738, 0.000145037738, 1, 0.001],
    [1, 145.037738, 0.145037738, 1000, 1]
];

export const conversionMatrixTime = [
    [1, 0.001, 1 / 60000, 1 / 3600000],
    [1000, 1, 1 / 60, 1 / 3600],
    [60000, 60, 1, 1 / 60],
    [3600000, 3600, 60, 1]
];


export class UnitValue {
    public value: number;
    public unit: Unit;

    constructor(
      value: number,
      unit: Unit) {
        this.value = value;
        this.unit = unit;
    }
}


@Injectable({
    providedIn: 'root'
})
export class UnitService {
    constructor(
        private user: UserService,
        private numberService: NumberService,
        private userSettings: UserSettingsService
    ) { }

    public parseUnitValue(value: string, unitGroup: UnitGroup, defaultUnit?: Unit) {
        if (defaultUnit === undefined) {
            defaultUnit = this.getDefaultUnit(unitGroup);
        }

        if (value != null && typeof value == 'number') {
            return new UnitValue(value, defaultUnit);
        }

        const val = (value ?? '').trim();
        if (val == '') {
            return null;
        }

        const posibleUnits = this.getStringEndUnits(val, unitGroup);

        // try one unit at a time to find a number
        for (const { unit, unitString } of posibleUnits) {
            const numberString = val.substring(0, val.length - unitString.length);
            const numberValue = this.parseNumber(numberString);

            if (numberValue != null && !Number.isNaN(numberValue)) {
                return new UnitValue(numberValue, unit);
            }
        }

        // parse the whole value as number
        const numberValue = this.parseNumber(val);
        if (numberValue == null || Number.isNaN(numberValue)) {
            return this.NaNUnitValue();
        }

        return new UnitValue(numberValue, defaultUnit);
    }

    public parseUnknownUnitValue(value: string) {
        if (value == null || value == '') {
            return null;
        }

        for (const unitGroup of this.getUnitGroups()) {
            const unitValue = this.parseUnitValue(value, unitGroup, Unit.None);

            if (unitValue != null && !Number.isNaN(unitValue.value)) {
                return unitValue;
            }
        }

        return this.NaNUnitValue();
    }

    // Get increment/decrement value based on imperial or metric length unit. If metric than value is 1 if imperial value is 1/8 of inch
    // we use millimeters (internal) for increment/decrement
    public incDecValueByUnit(unit: Unit) {
        if (unit == Unit.inch) {
            return 0.125;
        }
        else if (unit == Unit.ft) {
            return 0.1;
        }
        else if (unit == Unit.mi) {
            return 0.000002;
        }
        else {
            return 1;
        }
    }

    public getUnitGroups() {
        return [UnitGroup.Area, UnitGroup.Force, UnitGroup.Length, UnitGroup.Moment, UnitGroup.Stress, UnitGroup.Temperature, UnitGroup.Percentage, UnitGroup.ForcePerLength, UnitGroup.Time, UnitGroup.LengthLarge, UnitGroup.StressSmall, UnitGroup.Angle, UnitGroup.Volume];
    }

    public formatUnitValue(value: UnitValue, precision?: number) {
        if (value == null) {
            return null;
        }

        return this.formatUnitValueArgs(value.value, value.unit, precision);
    }

    public formatUnitValueArgs(value: number, unit: Unit, precision?: number) {
        if (value == null) {
            return null;
        }

        if (unit == null || unit == Unit.None) {
            return this.formatNumber(value);
        }

        return `${this.formatNumber(value, precision ?? this.getPrecision(unit))} ${this.formatUnit(unit)}`;
    }

    public getUnitGroupFromUnit(unit: Unit) {
        return (Math.floor(unit / 100) * 100) as UnitGroup;
    }

    public getUnitsForUnitGroup(unitGroup: UnitGroup) {
        switch (unitGroup) {
            case UnitGroup.Angle:
                return [Unit.degree, Unit.rad];
            case UnitGroup.Area:
                return [Unit.mm2, Unit.cm2, Unit.m2, Unit.inch2, Unit.ft2];
            case UnitGroup.Force:
                return [Unit.N, Unit.daN, Unit.kN, Unit.lb, Unit.Kip, Unit.kgf];
            case UnitGroup.Length:
            case UnitGroup.LengthLarge:
                return [Unit.mm, Unit.cm, Unit.m, Unit.inch, Unit.ft, Unit.mi];
            case UnitGroup.Moment:
                return [Unit.Nmm, Unit.Nm, Unit.daNm, Unit.kNm, Unit.in_lb, Unit.ft_lb, Unit.in_kip, Unit.ft_kip, Unit.kgfm];
            case UnitGroup.Stress:
            case UnitGroup.StressSmall:
                return [Unit.Nmm2, Unit.PSI, Unit.KSI, Unit.kNm2, Unit.MPa];
            case UnitGroup.Temperature:
                return [Unit.C, Unit.F];
            case UnitGroup.Percentage:
                return [Unit.percent, Unit.permile];
            case UnitGroup.Time:
                return [Unit.min, Unit.h, Unit.s, Unit.ms];
            case UnitGroup.ForcePerLength:
                // Force per length units are combinations of Force and Length.
                // NOTE: the ordering is important here.
                return [Unit.N_mm, Unit.N_cm, Unit.N_m, Unit.N_in, Unit.N_ft,
                Unit.daN_mm, Unit.daN_cm, Unit.daN_m, Unit.daN_in, Unit.daN_ft,
                Unit.kN_mm, Unit.kN_cm, Unit.kN_m, Unit.kN_in, Unit.kN_ft,
                Unit.lb_mm, Unit.lb_cm, Unit.lb_m, Unit.lb_in, Unit.lb_ft,
                Unit.Kip_mm, Unit.Kip_cm, Unit.Kip_m, Unit.Kip_in, Unit.Kip_ft,
                Unit.kgf_mm, Unit.kgf_cm, Unit.kgf_m, Unit.kgf_in, Unit.kgf_ft];
            case UnitGroup.Volume:
                return [Unit.ml, Unit.mm3, Unit.cm3, Unit.m3, Unit.inch3, Unit.ft3, Unit.oz];
            default:
                throw new Error('Unknown unit group.');
        }
    }

    public getUnitGroupCodeList(unitGroup: UnitGroup) {
        switch (unitGroup) {
            case UnitGroup.Area:
                return this.user.design.codeLists[ProjectCodeList.UnitArea] as UnitCodeList[];
            case UnitGroup.Force:
                return this.user.design.codeLists[ProjectCodeList.UnitForce] as UnitCodeList[];
            case UnitGroup.Length:
                return this.user.design.codeLists[ProjectCodeList.UnitLength] as UnitCodeList[];
            case UnitGroup.Moment:
                return this.user.design.codeLists[ProjectCodeList.UnitMoment] as UnitCodeList[];
            case UnitGroup.Stress:
                return this.user.design.codeLists[ProjectCodeList.UnitStress] as UnitCodeList[];
            case UnitGroup.Temperature:
                return this.user.design.codeLists[ProjectCodeList.UnitTemperature] as UnitCodeList[];
            case UnitGroup.ForcePerLength:
                return this.user.design.codeLists[ProjectCodeList.UnitForcePerLength] as UnitCodeList[];
            case UnitGroup.Time:
                return this.user.design.codeLists[ProjectCodeList.UnitTime] as UnitCodeList[];
            case UnitGroup.Volume:
                return this.user.design.codeLists[ProjectCodeList.UnitVolume] as UnitCodeList[];
            default:
                throw new Error('Unknown unit group.');
        }
    }

    public formatUnit(unit: Unit) {
        return this.getUnitStrings(unit)[0];
    }

    public parseUnit(value: string, unitGroup: UnitGroup) {
        if (value == null || value.trim() == '') {
            return null;
        }

        const stringValue = value.trim().toLowerCase();
        const unitCodeList = this.getUnitGroupCodeList(unitGroup);

        for (const unitCodeListItem of unitCodeList) {
            const unit = unitCodeListItem.id as Unit;
            const unitStrings = this.getUnitStrings(unit);

            if (unitStrings.some((unitString) => unitString.toLowerCase() == stringValue)) {
                return unit;
            }
        }

        return Unit.None;
    }

    public parseNumber(value: string, type = NumberType.real) {
        return this.numberService.parse(value, type);
    }

    public formatNumber(value: number, decimals?: number, toFixed?: boolean) {
        return this.numberService.format(value, decimals, toFixed);
    }

    public convertUnitValueToInternalUnitValue(unitValue: UnitValue) {
        const unitGroup = this.getUnitGroupFromUnit(unitValue.unit);
        const internalUnit = this.getInternalUnit(unitGroup);

        return this.convertUnitValueToUnit(unitValue, internalUnit);
    }

    public getInternalUnit(unitGroup: UnitGroup) {
        switch (unitGroup) {
            case UnitGroup.None:
                return Unit.None;
            case UnitGroup.Length:
            case UnitGroup.LengthLarge:
                return Unit.mm;
            case UnitGroup.Area:
                return Unit.mm2;
            case UnitGroup.Stress:
            case UnitGroup.StressSmall:
                return Unit.Nmm2;
            case UnitGroup.Force:
                return Unit.N;
            case UnitGroup.Moment:
                return Unit.Nmm;
            case UnitGroup.Temperature:
                return Unit.C;
            case UnitGroup.Angle:
                return Unit.rad;
            case UnitGroup.Time:
                return Unit.min;
            case UnitGroup.Percentage:
                return Unit.percent;
            case UnitGroup.ForcePerLength:
                return Unit.N_mm;
            case UnitGroup.Volume:
                return Unit.ml;
            default:
                throw new Error('Unknown unit group.');
        }
    }

    public convertUnitValueToUnit(unitValue: UnitValue, unit: Unit) {
        if (unitValue == null) {
            return null;
        }

        return new UnitValue(this.convertUnitValueArgsToUnit(unitValue.value, unitValue.unit, unit), unit);
    }

    public convertUnitValueArgsToUnit(value: number, unitFrom: Unit, unitTo: Unit) {
        if (unitFrom == Unit.None) {
            if (unitTo == Unit.None) {
                return value;
            }

            return null;
        }

        if (unitTo == unitFrom) {
            return value;
        }

        if (unitTo == Unit.None) {
            return null;
        }

        const unitGroup = this.getUnitGroupFromUnit(unitFrom);

        if (unitGroup != this.getUnitGroupFromUnit(unitTo)) {
            return null;
        }

        const unitConvertFromIndex = unitFrom - unitGroup;
        const unitConvertToIndex = unitTo - unitGroup;

        switch (unitGroup) {
            case UnitGroup.Angle:
                switch (unitFrom) {
                    case Unit.degree:
                        value = unitTo == Unit.degree ? value % 360 : (value * (Math.PI / 180)) % (Math.PI * 2);
                        break;
                    case Unit.rad:
                        value = unitTo == Unit.rad ? value % (Math.PI * 2) : (value * (180 / Math.PI)) % 360;
                        break;
                }
                break;
            case UnitGroup.Area:
                value = value * Math.pow(conversionMatrixLength[unitConvertFromIndex][unitConvertToIndex], 2);
                break;
            case UnitGroup.Volume:
                value = value * conversionMatrixVolume[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.Force:
                value = value * conversionMatrixForce[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.Length:
            case UnitGroup.LengthLarge:
                value = value * conversionMatrixLength[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.Moment:
                value = value * conversionMatrixMoment[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.Percentage:
                value = value * conversionMatrixPercentage[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.Stress:
            case UnitGroup.StressSmall:
                value = value * conversionMatrixStress[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.Temperature:
                switch (unitFrom) {
                    case Unit.C:
                        value = ((value * 9) / 5) + 32;
                        break;

                    case Unit.F:
                        value = ((value - 32) * 5) / 9;
                        break;
                }
                break;
            case UnitGroup.Time:
                value = value * conversionMatrixTime[unitConvertFromIndex][unitConvertToIndex];
                break;
            case UnitGroup.ForcePerLength:
                {
                    // ForcePerLength units are combinations of Force and Length.
                    const numLengthUnits = this.getNumberOfUnitsInGroup(UnitGroup.Length) - 1; // number of units in Length group

                    const unitForceFromIndex = Math.floor(unitConvertFromIndex / numLengthUnits); // get index inside Force group for unit with index "unitConvertFromIndex"
                    const unitForceToIndex = Math.floor(unitConvertToIndex / numLengthUnits); // get index inside Force group for unit with index "unitConvertToIndex"

                    const unitLengthFromIndex = unitConvertFromIndex % numLengthUnits; // get index inside Length group for unit with index "unitConvertFromIndex"
                    const unitLengthToIndex = unitConvertToIndex % numLengthUnits; // get index inside Length group for unit with index "unitConvertToIndex"

                    value = value * conversionMatrixForce[unitForceFromIndex][unitForceToIndex] / conversionMatrixLength[unitLengthFromIndex][unitLengthToIndex];
                }
                break;
        }

        return value;
    }

    public getPrecision(unit: Unit) {
        if (this.getUnitGroupFromUnit(unit) == UnitGroup.ForcePerLength) {
            // force per length
            // always 2
            return 2;
        }

        switch (unit) {
            // length
            case Unit.mm:
                return 1;
            case Unit.cm:
                return 2;
            case Unit.inch:
                return 3;
            case Unit.ft:
                return 2;
            case Unit.m:
                return 4;
            case Unit.mi:
                return 6;

            // area
            case Unit.mm2:
                return 0;
            case Unit.cm2:
                return 2;
            case Unit.inch2:
                return 6;
            case Unit.ft2:
                return 4;

            // volume
            case Unit.mm3:
            case Unit.cm3:
            case Unit.inch3:
            case Unit.ft3:
            case Unit.ml:
            case Unit.oz:
                return 2;

            // stress
            case Unit.Nmm2:
            case Unit.PSI:
            case Unit.KSI:
                return 0;
            case Unit.kNm2:
                return 2;

            // force
            case Unit.N:
                return 0;
            case Unit.daN:
                return 1;
            case Unit.kN:
                return 3;
            case Unit.lb:
                return 0;
            case Unit.Kip:
                return 3;

            // moment
            case Unit.Nm:
                return 0;
            case Unit.daNm:
                return 1;
            case Unit.kNm:
                return 3;
            case Unit.in_lb:
                return 3;
            case Unit.ft_lb:
                return 3;
            case Unit.in_kip:
                return 6;
            case Unit.ft_kip:
                return 5;

            // temperature
            case Unit.C:
            case Unit.F:
                return 0;

            // angle
            case Unit.degree:
            case Unit.rad:
                return 6;

            // default
            default:
                return 6;
        }
    }

    public getDefaultUnit(unitGroup: UnitGroup) {
        switch (unitGroup) {
            case UnitGroup.Length:
                return this.parseUserSettingsUnit(this.userSettings.settings.user.units.lengthId.value, Unit.mm);

            case UnitGroup.Volume:
                return this.parseUserSettingsUnit(this.userSettings.settings.user.units.volumeId.value, Unit.ml);

            case UnitGroup.Area:
                return this.parseUserSettingsUnit(this.userSettings.settings.user.units.areaId.value, Unit.mm2);

            case UnitGroup.Angle:
                return this.getInternalUnit(UnitGroup.Angle);
            case UnitGroup.Force:
                return this.getInternalUnit(UnitGroup.Force);
            case UnitGroup.LengthLarge:
                return this.getInternalUnit(UnitGroup.LengthLarge);
            case UnitGroup.Moment:
                return this.getInternalUnit(UnitGroup.Moment);
            case UnitGroup.Stress:
                return this.getInternalUnit(UnitGroup.Stress);
            case UnitGroup.StressSmall:
                return this.getInternalUnit(UnitGroup.StressSmall);
            case UnitGroup.Temperature:
                return this.getInternalUnit(UnitGroup.Temperature);
            case UnitGroup.ForcePerLength:
                return this.getInternalUnit(UnitGroup.ForcePerLength);
            case UnitGroup.Percentage:
                return this.getInternalUnit(UnitGroup.Percentage);
            case UnitGroup.Time:
                return this.getInternalUnit(UnitGroup.Time);

            default:
                throw new Error('Unknown unit group.');
        }
    }

    public getUnitStringDictionary() {
        const data: { [key: number]: string[] } = {};

        data[Unit.mm] = ['mm'];
        data[Unit.cm] = ['cm'];
        data[Unit.m] = ['m'];
        data[Unit.inch] = ['in', 'inc', 'inch'];
        data[Unit.ft] = ['ft', 'feet'];
        data[Unit.mi] = ['mi', 'mile', 'miles'];

        data[Unit.mm2] = ['mm²', 'mm2'];
        data[Unit.cm2] = ['cm²', 'cm2'];
        data[Unit.m2] = ['m²', 'm2'];
        data[Unit.inch2] = ['in²', 'in2', 'inc2', 'inch2'];
        data[Unit.ft2] = ['ft²', 'ft2', 'feet2'];

        data[Unit.Nmm2] = ['N/mm²', 'N/mm2', 'Nmm2'];
        data[Unit.PSI] = ['psi'];
        data[Unit.KSI] = ['ksi'];
        data[Unit.kNm2] = ['kN/m²', 'kN/m2', 'kNm2'];
        data[Unit.MPa] = ['MPa'];

        data[Unit.N] = ['N'];
        data[Unit.daN] = ['daN'];
        data[Unit.kN] = ['kN'];
        data[Unit.lb] = ['lb'];
        data[Unit.Kip] = ['kip'];
        data[Unit.kgf] = ['kgf'];

        data[Unit.Nmm] = ['Nmm'];
        data[Unit.Nm] = ['Nm'];
        data[Unit.daNm] = ['daNm'];
        data[Unit.kNm] = ['kNm'];
        data[Unit.in_lb] = ['in-lb', 'in lb'];
        data[Unit.ft_lb] = ['ft-lb', 'ft lb'];
        data[Unit.in_kip] = ['in-kip', 'in kip'];
        data[Unit.ft_kip] = ['ft-kip', 'ft kip'];
        data[Unit.kgfm] = ['kgfm'];

        data[Unit.C] = ['°C', 'C'];
        data[Unit.F] = ['°F', 'F'];

        data[Unit.ms] = ['ms'];
        data[Unit.s] = ['s'];
        data[Unit.min] = ['min'];
        data[Unit.h] = ['h'];

        data[Unit.degree] = ['°', 'deg'];
        data[Unit.rad] = ['rad'];

        data[Unit.percent] = ['%'];
        data[Unit.permile] = ['‰', 'permil'];

        data[Unit.N_mm] = this.combineForcePerLengthUnitStrings(Unit.N, Unit.mm);
        data[Unit.N_cm] = this.combineForcePerLengthUnitStrings(Unit.N, Unit.cm);
        data[Unit.N_m] = this.combineForcePerLengthUnitStrings(Unit.N, Unit.m);
        data[Unit.N_in] = this.combineForcePerLengthUnitStrings(Unit.N, Unit.inch);
        data[Unit.N_ft] = this.combineForcePerLengthUnitStrings(Unit.N, Unit.ft);
        data[Unit.daN_mm] = this.combineForcePerLengthUnitStrings(Unit.daN, Unit.mm);
        data[Unit.daN_cm] = this.combineForcePerLengthUnitStrings(Unit.daN, Unit.cm);
        data[Unit.daN_m] = this.combineForcePerLengthUnitStrings(Unit.daN, Unit.m);
        data[Unit.daN_in] = this.combineForcePerLengthUnitStrings(Unit.daN, Unit.inch);
        data[Unit.daN_ft] = this.combineForcePerLengthUnitStrings(Unit.daN, Unit.ft);
        data[Unit.kN_mm] = this.combineForcePerLengthUnitStrings(Unit.kN, Unit.mm);
        data[Unit.kN_cm] = this.combineForcePerLengthUnitStrings(Unit.kN, Unit.cm);
        data[Unit.kN_m] = this.combineForcePerLengthUnitStrings(Unit.kN, Unit.m);
        data[Unit.kN_in] = this.combineForcePerLengthUnitStrings(Unit.kN, Unit.inch);
        data[Unit.kN_ft] = this.combineForcePerLengthUnitStrings(Unit.kN, Unit.ft);
        data[Unit.lb_mm] = this.combineForcePerLengthUnitStrings(Unit.lb, Unit.mm);
        data[Unit.lb_cm] = this.combineForcePerLengthUnitStrings(Unit.lb, Unit.cm);
        data[Unit.lb_m] = this.combineForcePerLengthUnitStrings(Unit.lb, Unit.m);
        data[Unit.lb_in] = this.combineForcePerLengthUnitStrings(Unit.lb, Unit.inch);
        data[Unit.lb_ft] = this.combineForcePerLengthUnitStrings(Unit.lb, Unit.ft);
        data[Unit.Kip_mm] = this.combineForcePerLengthUnitStrings(Unit.Kip, Unit.mm);
        data[Unit.Kip_cm] = this.combineForcePerLengthUnitStrings(Unit.Kip, Unit.cm);
        data[Unit.Kip_m] = this.combineForcePerLengthUnitStrings(Unit.Kip, Unit.m);
        data[Unit.Kip_in] = this.combineForcePerLengthUnitStrings(Unit.Kip, Unit.inch);
        data[Unit.Kip_ft] = this.combineForcePerLengthUnitStrings(Unit.Kip, Unit.ft);
        data[Unit.kgf_mm] = this.combineForcePerLengthUnitStrings(Unit.kgf, Unit.mm);
        data[Unit.kgf_cm] = this.combineForcePerLengthUnitStrings(Unit.kgf, Unit.cm);
        data[Unit.kgf_m] = this.combineForcePerLengthUnitStrings(Unit.kgf, Unit.m);
        data[Unit.kgf_in] = this.combineForcePerLengthUnitStrings(Unit.kgf, Unit.inch);
        data[Unit.kgf_ft] = this.combineForcePerLengthUnitStrings(Unit.kgf, Unit.ft);

        data[Unit.mm3] = ['mm³', 'mm2'];
        data[Unit.cm3] = ['cm³', 'cm3'];
        data[Unit.m3] = ['m³', 'm3'];
        data[Unit.inch3] = ['in³', 'in3', 'inc3', 'inch3'];
        data[Unit.ft3] = ['ft³', 'ft3', 'feet3'];
        data[Unit.ml] = ['ml'];
        data[Unit.oz] = ['oz'];

        return data;
    }

    public getUnitStrings(unit: Unit) {
        switch (unit) {
            // length
            case Unit.mm:
                return ['mm'];
            case Unit.cm:
                return ['cm'];
            case Unit.m:
                return ['m'];
            case Unit.inch:
                return ['in', 'inc', 'inch'];
            case Unit.ft:
                return ['ft', 'feet'];
            case Unit.mi:
                return ['mi', 'mile', 'miles'];

            // area
            case Unit.mm2:
                return ['mm²', 'mm2'];
            case Unit.cm2:
                return ['cm²', 'cm2'];
            case Unit.m2:
                return ['m²', 'm2'];
            case Unit.inch2:
                return ['in²', 'in2', 'inc2', 'inch2'];
            case Unit.ft2:
                return ['ft²', 'ft2', 'feet2'];

            // volume
            case Unit.mm3:
                return ['mm³', 'mm3'];
            case Unit.cm3:
                return ['cm³', 'cm3'];
            case Unit.m3:
                return ['m³', 'm3'];
            case Unit.inch3:
                return ['in³', 'in3', 'inc3', 'inch3'];
            case Unit.ft3:
                return ['ft³', 'ft3', 'feet3'];
            case Unit.ml:
                return ['ml'];
            case Unit.oz:
                return ['oz'];

            // stress
            case Unit.Nmm2:
                return ['N/mm²', 'N/mm2', 'Nmm2'];
            case Unit.PSI:
                return ['psi'];
            case Unit.KSI:
                return ['ksi'];
            case Unit.kNm2:
                return ['kN/m²', 'kN/m2', 'kNm2'];
            case Unit.MPa:
                return ['MPa'];

            // force
            case Unit.N:
                return ['N'];
            case Unit.daN:
                return ['daN'];
            case Unit.kN:
                return ['kN'];
            case Unit.lb:
                return ['lb'];
            case Unit.Kip:
                return ['kip'];
            case Unit.kgf:
                return ['kgf'];

            // moment
            case Unit.Nmm:
                return ['Nmm'];
            case Unit.Nm:
                return ['Nm'];
            case Unit.daNm:
                return ['daNm'];
            case Unit.kNm:
                return ['kNm'];
            case Unit.in_lb:
                return ['in-lb', 'in lb'];
            case Unit.ft_lb:
                return ['ft-lb', 'ft lb'];
            case Unit.in_kip:
                return ['in-kip', 'in kip'];
            case Unit.ft_kip:
                return ['ft-kip', 'ft kip'];
            case Unit.kgfm:
                return ['kgfm'];

            // temperature
            case Unit.C:
                return ['°C', 'C'];
            case Unit.F:
                return ['°F', 'F'];

            // time
            case Unit.ms:
                return ['ms'];
            case Unit.s:
                return ['s'];
            case Unit.min:
                return ['min'];
            case Unit.h:
                return ['h'];

            // angle
            case Unit.degree:
                return ['°', 'deg'];
            case Unit.rad:
                return ['rad'];

            // percentage
            case Unit.percent:
                return ['%'];
            case Unit.permile:
                return ['‰', 'permil'];

            // force per length
            case Unit.N_mm:
                return this.combineForcePerLengthUnitStrings(Unit.N, Unit.mm);
            case Unit.N_cm:
                return this.combineForcePerLengthUnitStrings(Unit.N, Unit.cm);
            case Unit.N_m:
                return this.combineForcePerLengthUnitStrings(Unit.N, Unit.m);
            case Unit.N_in:
                return this.combineForcePerLengthUnitStrings(Unit.N, Unit.inch);
            case Unit.N_ft:
                return this.combineForcePerLengthUnitStrings(Unit.N, Unit.ft);
            case Unit.daN_mm:
                return this.combineForcePerLengthUnitStrings(Unit.daN, Unit.mm);
            case Unit.daN_cm:
                return this.combineForcePerLengthUnitStrings(Unit.daN, Unit.cm);
            case Unit.daN_m:
                return this.combineForcePerLengthUnitStrings(Unit.daN, Unit.m);
            case Unit.daN_in:
                return this.combineForcePerLengthUnitStrings(Unit.daN, Unit.inch);
            case Unit.daN_ft:
                return this.combineForcePerLengthUnitStrings(Unit.daN, Unit.ft);
            case Unit.kN_mm:
                return this.combineForcePerLengthUnitStrings(Unit.kN, Unit.mm);
            case Unit.kN_cm:
                return this.combineForcePerLengthUnitStrings(Unit.kN, Unit.cm);
            case Unit.kN_m:
                return this.combineForcePerLengthUnitStrings(Unit.kN, Unit.m);
            case Unit.kN_in:
                return this.combineForcePerLengthUnitStrings(Unit.kN, Unit.inch);
            case Unit.kN_ft:
                return this.combineForcePerLengthUnitStrings(Unit.kN, Unit.ft);
            case Unit.lb_mm:
                return this.combineForcePerLengthUnitStrings(Unit.lb, Unit.mm);
            case Unit.lb_cm:
                return this.combineForcePerLengthUnitStrings(Unit.lb, Unit.cm);
            case Unit.lb_m:
                return this.combineForcePerLengthUnitStrings(Unit.lb, Unit.m);
            case Unit.lb_in:
                return this.combineForcePerLengthUnitStrings(Unit.lb, Unit.inch);
            case Unit.lb_ft:
                return this.combineForcePerLengthUnitStrings(Unit.lb, Unit.ft);
            case Unit.Kip_mm:
                return this.combineForcePerLengthUnitStrings(Unit.Kip, Unit.mm);
            case Unit.Kip_cm:
                return this.combineForcePerLengthUnitStrings(Unit.Kip, Unit.cm);
            case Unit.Kip_m:
                return this.combineForcePerLengthUnitStrings(Unit.Kip, Unit.m);
            case Unit.Kip_in:
                return this.combineForcePerLengthUnitStrings(Unit.Kip, Unit.inch);
            case Unit.Kip_ft:
                return this.combineForcePerLengthUnitStrings(Unit.Kip, Unit.ft);
            case Unit.kgf_mm:
                return this.combineForcePerLengthUnitStrings(Unit.kgf, Unit.mm);
            case Unit.kgf_cm:
                return this.combineForcePerLengthUnitStrings(Unit.kgf, Unit.cm);
            case Unit.kgf_m:
                return this.combineForcePerLengthUnitStrings(Unit.kgf, Unit.m);
            case Unit.kgf_in:
                return this.combineForcePerLengthUnitStrings(Unit.kgf, Unit.inch);
            case Unit.kgf_ft:
                return this.combineForcePerLengthUnitStrings(Unit.kgf, Unit.ft);

            default:
                throw new Error('Unknown unit.');
        }
    }

    public formatInternalValueAsDefault(value: number, unitGroup: UnitGroup, noUnit?: boolean) {
        const internalUnit = this.getInternalUnit(unitGroup);
        const defaultUnit = this.getDefaultUnit(unitGroup);

        const defaultValue = this.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit);

        return noUnit
            ? this.formatNumber(defaultValue, this.getPrecision(defaultUnit))
            : this.formatUnitValueArgs(defaultValue, defaultUnit);
    }

    public parseDefaultValueAsInternal(value: string, unitGroup: UnitGroup) {
        if (value == null) {
            return null;
        }

        const internalUnit = this.getInternalUnit(unitGroup);

        const unitValue = this.parseUnitValue(value, unitGroup);
        const internalUnitValue = this.convertUnitValueToUnit(unitValue, internalUnit);

        return internalUnitValue.value;
    }

    private combineForcePerLengthUnitStrings(forceUnit: Unit, lengthUnit: Unit) {
        const forceStrings = this.getUnitStrings(forceUnit);
        const lengthStrings = this.getUnitStrings(lengthUnit);

        const forcePerLengthStrings: string[] = [];

        // combine with divider
        for (const forceString of forceStrings) {
            for (const lengthString of lengthStrings) {
                forcePerLengthStrings.push(forceString + '/' + lengthString);
            }
        }

        return forcePerLengthStrings;
    }

    private NaNUnitValue() {
        return new UnitValue(Number.NaN, Unit.None);
    }

    private getNumberOfUnitsInGroup(unitGroup: UnitGroup) {
        switch (unitGroup) {
            case UnitGroup.Length:
            case UnitGroup.LengthLarge:
                return 6;
            case UnitGroup.Area:
                return 5;
            case UnitGroup.Stress:
            case UnitGroup.StressSmall:
                return 5;
            case UnitGroup.Force:
                return 6;
            case UnitGroup.Moment:
                return 9;
            case UnitGroup.Temperature:
                return 2;
            case UnitGroup.Time:
                return 4;
            case UnitGroup.Angle:
                return 2;
            case UnitGroup.Percentage:
                return 2;
            case UnitGroup.ForcePerLength:
                return 30;
            case UnitGroup.Volume:
                return 6;
            default:
                throw new Error('Unknown unit group.');
        }
    }

    private getStringEndUnits(value: string, unitGroup: UnitGroup) {
        value = value.trim().toLowerCase();

        const units = this.getUnitsForUnitGroup(unitGroup);
        const values: { unit: Unit, unitString: string }[] = [];

        for (const unit of units) {
            const unitStrings = this.getUnitStrings(unit).map((unitString) => unitString.toLowerCase());

            for (const unitString of unitStrings) {
                if (value.endsWith(unitString)) {
                    values.push({ unit, unitString });
                }
            }
        }

        return values;
    }

    private parseUserSettingsUnit(id: number, defaultUnit: Unit) {


        if (!Number.isNaN(id)) {
            return id as Unit;
        }

        return defaultUnit;
    }
}
