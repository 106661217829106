<app-control-title
    [focusControlId]="id"
    [title]="title"
    [tooltip]="tooltip">
</app-control-title>

<div [id]="id" class="control-container" #controlContainer (keypress)="onKeyPress($event)"
    (keydown)="onKeyDown($event)">
    <button [id]="id + '-button'" class="dropdown-button" #dropdownButton type="button" (click)="onClick($event)"
        [disabled]="disabled">
        <div class="dropdown-button-container">
            <div class="button-item item">
                <p class="text" [innerHtml]="text"></p>
            </div>
            <span class="space"></span>

            <div class="caret-container">
                <div class="caret" [ngClass]="{'caret-down': !isOpen, 'caret-up': isOpen}"></div>
            </div>
        </div>
    </button>

    <div class="dropdown-items" #dropdownItems [ngClass]="{ 'opend': isOpen }">
        <button *ngFor="let item of items" class="dropdown-item" type="button" [class.selected]="itemSelected(item)"
            [ngClass]="{ 'selected': itemSelected(item) }" (click)="onItemClick(item.value)">
            <div class="item">
                <p class="text" *ngIf="item.text">{{item.text}}</p>
            </div>
        </button>
    </div>

</div>
