export class UpdateOnEnter implements ng.IDirective {
    public restrict = 'A';
    public require = 'ngModel';

    public static Factory() {
        return () => new UpdateOnEnter();
    }

    public link: ng.IDirectiveLinkFn = ($scope, element, attributes, ctrl: ng.INgModelController) => {
        const onKeyUp = ($event: JQueryKeyEventObject) => {
            if ($event.keyCode == 13 && ctrl.$viewValue != ctrl.$modelValue) {
                $scope.$apply(() => {
                    ctrl.$commitViewValue();
                    ctrl.$setTouched();
                });
            }
        };

        element.on('keyup', onKeyUp);

        $scope.$on('$destroy', () => {
            element.off('keyup', onKeyUp);
        });
    }
}
