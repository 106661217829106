import { IValidatorContext, Validator } from '../../controls';
import { TextBoxController } from '../TextBox';

const EMAIL_REGEXP = /^[a-z0-9@!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

export interface EmailContext extends IValidatorContext<TextBoxController> {
    watch: Function;
}

export interface IEmailConstructor {
    emailSeperator?: string;
}

export class Email extends Validator<TextBoxController, EmailContext> {
    private emailSeperator: string;

    constructor(ctor?: IEmailConstructor) {
        super();

        if (ctor != null) {
            this.emailSeperator = ctor.emailSeperator;
        }
    }

    public add(context: EmailContext) {
        // refresh validation
        setTimeout(() => context.$scope.$applyAsync(() => {
            context.watch = context.$scope.$watchGroup(['ctrl.rawModel'], () => {
                context.ngModel.$validate();
            });
        }));
    }

    public remove(context: EmailContext) {
        // remove watch
        context.watch();
    }

    public isValid(context: EmailContext) {
        const value = context.control.rawModel;

        if (value == null || value == '') {
            return true;
        }

        if (this.emailSeperator != null && this.emailSeperator.length > 0) {
            const emails = value.trim().split(this.emailSeperator).filter((value) => value != '');
            if (emails.length == 0) {
                return false;
            }

            return emails.every((it) => EMAIL_REGEXP.test(it.trim()));
        } else {
            return EMAIL_REGEXP.test(value);
        }
    }
}
