import { IValidatorContext, Validator } from '../../controls';
import { TextBoxController } from '../TextBox';

const URL_REGEXP = /^(((ftp:|http:|https:)?\/\/(www\.)?)|\/\/)?[-a-zA-Z0-9@:%._\+~#=]{2,}\.[a-z]{2,126}\b([\\\w#!:.?+*=&%@!\-\/()"$;]*)$/;

export interface UrlContext extends IValidatorContext<TextBoxController> {
    watch: Function;
}

export interface IUrlConstructor {

}

export class Url extends Validator<TextBoxController, UrlContext> {
    constructor(ctor?: IUrlConstructor) {
        super();
    }

    public add(context: UrlContext) {
        // refresh validation
        setTimeout(() => context.$scope.$applyAsync(() => {
            context.watch = context.$scope.$watchGroup(['ctrl.rawModel'], () => {
                context.ngModel.$validate();
            });
        }));
    }

    public remove(context: UrlContext) {
        // remove watch
        context.watch();
    }

    public isValid(context: UrlContext) {
        const value = context.control.rawModel;

        if (value == null || value == '') {
            return true;
        }

        return URL_REGEXP.test(value);
    }
}
