import angular from 'angular';
import escape from 'lodash-es/escape';

import { LocalizationService } from '../../../src/app/services/localization.service';
import { environment } from '../../../src/environments/environment';
import { ModalService } from '../../Services/modal-service';

export class ControlTooltipDirective implements ng.IDirective {
    public restrict = 'A';
    public replace = false;
    public terminal = true;
    public priority = 1000;

    private $compile: ng.ICompileService;

    constructor($compile: ng.ICompileService) {
        this.$compile = $compile;
    }

    public static Factory() {
        const directive = ($compile: ng.ICompileService) => new ControlTooltipDirective($compile);

        directive.$inject = ['$compile'];

        return directive;
    }

    public compile = (templateElement: ng.IAugmentedJQuery, templateAttributes: ng.IAttributes, transclude: ng.ITranscludeFunction) => {
        templateElement.attr('uib-tooltip-template', '\'control-tooltip-template.html\'');
        templateElement.attr('tooltip-enable', 'ctrl.tooltip.enabled');
        templateElement.attr('tooltip-placement', '{{ctrl.tooltip.placement}}');

        templateElement.after('<button type="button" class="control-tooltip-popup sprite sprite-info-tooltip" ng-show="ctrl.tooltip.popupVisible" ng-click="ctrl.tooltip.openPopup()" uib-tooltip="{{localization.getLocalizedString(\'Agito.Hilti.Purchaser.ControlTooltip.Popup\')}}"></button>');

        templateElement.removeAttr('agt-control-tooltip');
        templateElement.removeAttr('data-agt-control-tooltip');

        return {
            pre: (scope: ng.IScope, instanceElement: ng.IAugmentedJQuery, instanceAttributes: ng.IAttributes, controller: {}, transclude: ng.ITranscludeFunction) => { },
            post: (scope: ng.IScope, instanceElement: ng.IAugmentedJQuery, instanceAttributes: ng.IAttributes, controller: {}, transclude: ng.ITranscludeFunction) => {
                this.$compile(instanceElement)(scope);
            }
        };
    }
}

export interface ITooltipConstructor {
    titleKey?: string;
    contentKey?: string;
    type?: TooltipType;
    placement?: string;
}

export enum TooltipType {
    normal,
    popup
}

export class Tooltip {

    private static _$injector: ng.auto.IInjectorService;

    private static _localization: LocalizationService;

    private static _modal: ModalService;
    public titleKey: string;
    public contentKey: string;
    public type: TooltipType;
    public placement: string;

    constructor(ctor?: ITooltipConstructor) {
        this.placement = 'auto top';

        if (ctor != null) {
            this.titleKey = ctor.titleKey;
            this.contentKey = ctor.contentKey;
            this.type = ctor.type || TooltipType.normal;
            this.placement = ctor.placement || this.placement;
        }
    }
    protected static getInjector() {
        if (Tooltip._$injector == null) {
            Tooltip._$injector = angular.element(document.body).injector();
        }

        return Tooltip._$injector;
    }
    protected static getLocalization() {
        if (Tooltip._localization == null) {
            Tooltip._localization = Tooltip.getInjector().get<LocalizationService>('localization');
        }

        return Tooltip._localization;
    }
    protected static getModal() {
        if (Tooltip._modal == null) {
            Tooltip._modal = Tooltip.getInjector().get<ModalService>('modal');
        }

        return Tooltip._modal;
    }

    public get enabled() {
        return (this.type == null || this.type == TooltipType.normal) && (this.title != null && this.title != '' || this.content != null && this.content != '');
    }

    public get popupVisible() {
        return this.type == TooltipType.popup && (this.title != null && this.title != '' || this.content != null && this.content != '');
    }

    public get title() {
        if (this.titleKey == null || this.titleKey == '') {
            return null;
        }

        let value = this.translate(this.titleKey);

        if (value.startsWith('<html>')) {
            value = value.replace(/agt-src="~/g, 'src="' + environment.baseUrl.replace(/\/+$/, ''));
        }

        switch (this.type) {
            case TooltipType.normal:
                return escape(value);
            case TooltipType.popup:
                return value;
            default:
                throw new Error('Unknown tooltip type.');
        }
    }

    public get content() {
        if (this.contentKey == null || this.contentKey == '') {
            return null;
        }

        let value = this.translate(this.contentKey);

        if (value.startsWith('<html>')) {
            value = value.replace(/agt-src="~/g, 'src="' + environment.baseUrl.replace(/\/+$/, ''));
        }

        switch (this.type) {
            case TooltipType.normal:
                return escape(value);
            case TooltipType.popup:
                return value;
            default:
                throw new Error('Unknown tooltip type.');
        }
    }

    public translate(key: string) {
        return Tooltip.getLocalization().getLocalizedString(key);
    }

    public openPopup() {
        Tooltip.getModal().confirmChange.open('tooltip-popup', this.title, this.content, this.translate('Agito.Hilti.Purchaser.ControlTooltip.Ok'), null, () => Tooltip.getModal().confirmChange.close());
    }

    public cloneDeep() {
        return new Tooltip({
            titleKey: this.titleKey,
            contentKey: this.contentKey,
            type: this.type
        });
    }
}
