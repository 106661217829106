/* tslint:disable */

export enum AnchorType {
    Unknown = 0,
    Insert = 1,
    Mortar = 2,
    Capsule = 3,
    SieveSleeve = 4
}

export enum DesignStatus {
    Ok = 0,
    Invalid = 1,
    AnchorChangedAvailable = 2,
    AnchorChangedUnavailable = 3,
    NoLongerValid = 4,
    Error = 5,
    NotSupported = 6,
    CalculationTypeChanged = 7
}

export enum WorkingCondition {
    Unknown = 0,
    Good = 1,
    Normal = 2,
    Poor = 3
}
